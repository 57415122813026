import React, { ChangeEvent, MouseEvent, PureComponent } from 'react';

import { get } from 'lodash-es';

import { ActionButtonTooltip, UnconnectedCheckbox } from '../../../../core/components';
import { PermissionGuard } from '../../../../account/components';
import { ROUTE_SEQUENCE_STATUS_ICONS, ROUTE_SEQUENCE_TOOLTIP_COLORS } from '../../../constants';
import { ROUTES_PLANNER_CREATE, ROUTES_PLANNER_DELETE, ROUTES_PLANNER_SELECTION } from '../../../../account/constants';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from '../../../../fleet/constants';
import { TableActionButton, TableCell, TableRow } from '../../../../core/components/styled';
import { hasPermissionBasedOnProperties } from 'src/common/utils/filters';
import { FilterSetting } from 'src/vendors/interfaces/Filters';

interface Props {
  createRouteFromRouteTemplate: (id: number, scheduledDay: string, event: MouseEvent) => void;
  deleteRouteTemplate: (id: number, event: MouseEvent) => void;
  handleTableRowClick: (
    id: number,
    routeSequenceStatus: string,
    jobId: string,
    isEdit: boolean,
    vehicleTypeId: number,
  ) => void;
  hasRouteSequencePermission: boolean;
  id: number;
  isChecked: boolean;
  isEnabled: boolean;
  jobId: string;
  noOfStops: number;
  routeDayOfService: string;
  routeSequenceStatus: string;
  routeSequencingEnabled: boolean;
  routeStatus: string;
  routeTemplateName: string;
  scheduledDay: string;
  selectRouteTemplate: (event: ChangeEvent, id: number) => void;
  vehicleTypeId: number;
  vehicleTypeName?: string;
  wasteMaterialTypeName?: string;
  filtersPreferences?: FilterSetting[];
}

class RouteTemplateTableRow extends PureComponent<Props> {
  render() {
    const {
      createRouteFromRouteTemplate,
      deleteRouteTemplate,
      handleTableRowClick,
      hasRouteSequencePermission,
      id,
      isChecked,
      isEnabled,
      jobId,
      noOfStops,
      routeDayOfService,
      routeSequenceStatus,
      routeSequencingEnabled,
      routeStatus,
      routeTemplateName,
      scheduledDay,
      selectRouteTemplate,
      vehicleTypeId,
      vehicleTypeName,
      wasteMaterialTypeName,
      filtersPreferences,
    } = this.props;

    const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
    const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;
    const hasPermissionsBasedOnGlobalFilters = hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId });

    return (
      <TableRow
        onClick={() => handleTableRowClick(id, routeSequenceStatus, jobId, false, vehicleTypeId)}
        id={`route-template-${id}`}
      >
        <TableCell width="5%">
          <PermissionGuard permission={ROUTES_PLANNER_SELECTION}>
            <UnconnectedCheckbox
              block
              size="small"
              name={id.toString()}
              id={`route-template-${id}-checkbox`}
              checked={isChecked}
              disabled={!(noOfStops > 0 && isEnabled)}
              onClick={event => event.stopPropagation()}
              onChange={event => selectRouteTemplate(event, id)}
            />
          </PermissionGuard>
        </TableCell>

        <TableCell width="26%" id={`route-template-${id}-name`}>
          {routeTemplateName}
        </TableCell>

        <TableCell width="15%" id={`route-template-${id}-material-type`}>
          {!isSnowPlowRoute && !isStreetSweeperRoute && wasteMaterialTypeName}
        </TableCell>

        <TableCell width="12%" id={`route-template-${id}-day-of-service`}>
          {!isSnowPlowRoute && !isStreetSweeperRoute && routeDayOfService}
        </TableCell>

        <TableCell width="8%" id={`route-template-${id}-no-of-stops`}>
          {noOfStops}
        </TableCell>

        <TableCell width="4%" align="right">
          {routeSequencingEnabled && routeSequenceStatus && hasRouteSequencePermission && (
            <TableActionButton>
              <ActionButtonTooltip
                color={(ROUTE_SEQUENCE_TOOLTIP_COLORS as any)[routeSequenceStatus]}
                imageSrc={get(ROUTE_SEQUENCE_STATUS_ICONS[routeSequenceStatus], 'icon')}
                tooltip={`routeSequence${routeSequenceStatus}`}
              />
            </TableActionButton>
          )}
        </TableCell>

        <TableCell width="15%" id={`route-template-${id}-vehicle-type`}>
          {vehicleTypeName}
        </TableCell>

        <TableCell width="10%" id={`route-template-${id}-status`}>
          {routeStatus}
        </TableCell>

        <TableCell width="10%" align="right">
          {isEnabled &&
            !!noOfStops &&
            !isSnowPlowRoute &&
            !isStreetSweeperRoute &&
            hasPermissionsBasedOnGlobalFilters && (
              <PermissionGuard permission={ROUTES_PLANNER_CREATE}>
                <TableActionButton
                  id={`route-template-${id}-duplicate-button`}
                  onClick={event => createRouteFromRouteTemplate(id, scheduledDay, event)}
                >
                  <ActionButtonTooltip icon="duplicate" tooltip="copyTemplate" />
                </TableActionButton>
              </PermissionGuard>
            )}

          {!isSnowPlowRoute && !isStreetSweeperRoute && hasPermissionsBasedOnGlobalFilters && (
            <PermissionGuard permission={ROUTES_PLANNER_DELETE}>
              <TableActionButton
                id={`route-template-${id}-delete-button`}
                onClick={event => deleteRouteTemplate(id, event)}
              >
                <ActionButtonTooltip icon="delete" tooltip="deleteTemplate" />
              </TableActionButton>
            </PermissionGuard>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

export default RouteTemplateTableRow;
