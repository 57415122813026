import { map, orderBy, size } from 'lodash-es';

import {
  DashboardContainerItemsApart,
  DashboardFilterContainer,
  DashboardFilterPanel,
  DashboardFilterPanelContent,
  DashboardFilterSection,
  DashboardFilterTitle,
  DashboardFilterTitleWrapper,
  DashboardListContainer,
} from 'src/dashboard/components/styled/DashboardFilterMapbox';
import DispatchFilterRouteItemMapbox from './DispatchFilterRouteItemMapbox';
import translate from 'src/core/services/translate';
import { DispatchBoardSimpleRoute } from 'src/routes/interfaces/DispatchBoardRoute';
import SearchJobsForm from './SearchJobsForm';
import { DispatchBoardMapFilterCloseButton, DispatchBoardMapFilterCloseIcon } from 'src/routes/components/styled';

interface PropsWithoutReduxForm {
  isCollapsed?: boolean;
  isLoading: boolean;
  routesList: DispatchBoardSimpleRoute[];
  selectedRouteIdsFilter: number[];
  onSelectRoute: (routeId: number) => void;
  searchJobs: (value: string) => void;
  onClose: () => void;
}

const DashboardMapboxFiltersForm = ({
  isCollapsed,
  isLoading,
  routesList,
  selectedRouteIdsFilter,
  onSelectRoute,
  searchJobs,
  onClose,
}: PropsWithoutReduxForm) => {
  return (
    <DashboardFilterContainer isOpen={!isCollapsed} isLoading={isLoading} isDispatchMap>
      <DashboardFilterPanel>
        <DashboardFilterPanelContent>

          <DashboardFilterSection>
            <DashboardFilterTitleWrapper>
              <DashboardFilterTitle>{translate('routes.routes')} </DashboardFilterTitle>
              <DispatchBoardMapFilterCloseButton id="back-button" onClick={onClose}>
                <DispatchBoardMapFilterCloseIcon icon="back" />
              </DispatchBoardMapFilterCloseButton>
            </DashboardFilterTitleWrapper>
            <DashboardContainerItemsApart>
              <SearchJobsForm searchJobs={searchJobs} />
            </DashboardContainerItemsApart>
          </DashboardFilterSection>

          <DashboardListContainer isLoading={false} isForRoutes>
            {size(routesList)
              ? map(orderBy(routesList, 'statusId', 'asc'), route => (
                  <DispatchFilterRouteItemMapbox
                    key={route.id}
                    route={route}
                    onSelectRoute={onSelectRoute}
                    isActive={selectedRouteIdsFilter.includes(route.id)}
                  />
                ))
              : translate('routes.noRoutes')}
          </DashboardListContainer>

        </DashboardFilterPanelContent>
      </DashboardFilterPanel>
    </DashboardFilterContainer>
  );
};

export default DashboardMapboxFiltersForm;
