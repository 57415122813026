import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { dateAndTime } from 'src/utils/services/formatter';
import { getSegmentCenter, parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import { Grid, GridColumn, Label, Separator, Text } from 'src/core/components/styled';
import { MapGLPopup, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { MapGLPopupContent } from '../../styled';
import { RouteMapFiltersTitle } from 'src/routes/components/styled/RouteMapFilters';
import {
  STREET_SEGMENT_SERVICE_SIDES,
  STREET_SWEEPER_BROOM_SIDE_TYPE,
  STREET_SWEEPER_SEGMENT_TYPE,
  SNOW_OR_SWEEPER_SERVICE_ACTIVITY_TYPE,
} from 'src/customers/constants/streetNetwork';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

export const getTypesAsString = (types: number[] = [], isBroomSideType?: boolean) => {
  return types.length
    ? types
        .map(
          (serviceType: number) =>
            (isBroomSideType ? STREET_SWEEPER_BROOM_SIDE_TYPE : STREET_SWEEPER_SEGMENT_TYPE)[serviceType]?.name,
        )
        .join(', ')
    : '-';
};

const getServiceActivityAsString = (alternativeFleetServiceTypes: TechnicalType[] = []) => {
  return alternativeFleetServiceTypes.length
    ? alternativeFleetServiceTypes
        .map((type: TechnicalType) => SNOW_OR_SWEEPER_SERVICE_ACTIVITY_TYPE[type.id]?.name)
        .join(', ')
    : '-';
};

export default function StreetNetworkMapRouteSegmentsGLPopup({
  isDailyRoute,
  isRouteTemplate,
  isSnowPlowRoute,
  isStreetSweeperRoute,
}: {
  isDailyRoute?: boolean;
  isRouteTemplate?: boolean;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
}) {
  const dispatch = useDispatch();

  const {
    streetNetworkSegmentDetails,
    streetNetworkSegmentDetailsForDailyRoute,
    isLoadingSegmentDetails,
    isLoadingSegmentDetailsForDailyRoute,
  } = useSelector(state => state.customers.streetNetwork);

  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const routeSegment = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.routeSegments) {
      return undefined;
    }

    const selectedSegment: any = isDailyRoute ? streetNetworkSegmentDetailsForDailyRoute : streetNetworkSegmentDetails;

    if (!selectedSegment) {
      return undefined;
    }

    const segmentCenter = getSegmentCenter(parseSegmentJSON(selectedSegment.lineSegment));

    return {
      ...selectedSegment,
      segmentCenter,
    };
  }, [selectedFeature, isDailyRoute, streetNetworkSegmentDetailsForDailyRoute, streetNetworkSegmentDetails]);

  if (!routeSegment || isLoadingSegmentDetails || isLoadingSegmentDetailsForDailyRoute) {
    return null;
  }

  const serviceSideTypes = STREET_SEGMENT_SERVICE_SIDES.filter(side => side.id === routeSegment.serviceSideTypeId);

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={routeSegment.segmentCenter[1]}
      longitude={routeSegment.segmentCenter[0]}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupContent>
          <RouteMapFiltersTitle>{translate('customers.streetNetwork.streetDetails')}</RouteMapFiltersTitle>
          <Grid multiLine>
            <GridColumn size="9/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.streetName')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.name || '-'} singleLine>
                {routeSegment.name || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="3/12" padding="no" margin="xSmall no no no">
              <Label color={routeSegment.isActive ? 'success' : 'info'}>
                {routeSegment.isActive
                  ? translate('customers.streetNetwork.active')
                  : translate('customers.streetNetwork.inactive')}
              </Label>
            </GridColumn>

            {routeSegment.pickupStatusTypeName && !isRouteTemplate && (isSnowPlowRoute || isStreetSweeperRoute) && (
              <GridColumn size="6/12" padding="no" margin="xSmall no no no">
                <Text block weight="medium" size="medium">
                  {translate('customers.streetNetwork.segmentStatus')}
                </Text>
                <Text
                  block
                  margin="xxSmall no no no"
                  size="medium"
                  title={routeSegment.pickupStatusTypeName || '-'}
                  singleLine
                >
                  {routeSegment.pickupStatusTypeName || '-'}
                </Text>
              </GridColumn>
            )}

            {isDailyRoute && (
              <>
                <GridColumn size="6/12" padding="no" margin="xSmall no no no">
                  <Text block weight="medium" size="medium">
                    {translate('customers.streetNetwork.serviceActivity.serviceActivity')}
                  </Text>
                  <Text
                    block
                    margin="xxSmall no no no"
                    size="medium"
                    title={getServiceActivityAsString(routeSegment.alternativeFleetServiceTypes)}
                  >
                    {getServiceActivityAsString(routeSegment.alternativeFleetServiceTypes)}
                  </Text>
                </GridColumn>
                <GridColumn size="12/12" padding="no" margin="small no no no">
                  <Text block weight="medium" size="medium" color="grayDark">
                    {translate('customers.streetNetwork.segmentHistory')}
                  </Text>
                </GridColumn>
                <GridColumn size="7/12" padding="no" margin="xxSmall no no no">
                  <Separator color="gray" />
                </GridColumn>

                <GridColumn size="6/12" padding="no" margin="xSmall no no no">
                  <Text block weight="medium" size="medium">
                    {translate('customers.streetNetwork.lastPassDate')}
                  </Text>
                  <Text
                    block
                    margin="xxSmall no no no"
                    size="medium"
                    title={routeSegment.lastPassDateTime ? dateAndTime(routeSegment.lastPassDateTime) : '-'}
                    singleLine
                  >
                    {routeSegment.lastPassDateTime ? dateAndTime(routeSegment.lastPassDateTime) : '-'}
                  </Text>
                </GridColumn>

                <GridColumn size="6/12" padding="no" margin="xSmall no no no">
                  <Text block weight="medium" size="medium">
                    {translate('customers.streetNetwork.lastPassVehicle')}
                  </Text>
                  <Text
                    block
                    margin="xxSmall no no no"
                    size="medium"
                    title={routeSegment.vehicleName || '-'}
                    singleLine
                  >
                    {routeSegment.vehicleName || '-'}
                  </Text>
                </GridColumn>

                <GridColumn size="6/12" padding="no" margin="xSmall no no no">
                  <Text block weight="medium" size="medium">
                    {translate('customers.streetNetwork.lastPassDriver')}
                  </Text>
                  <Text block margin="xxSmall no no no" size="medium" title={routeSegment.driverName || '-'} singleLine>
                    {routeSegment.driverName || '-'}
                  </Text>
                </GridColumn>

                <GridColumn size="6/12" padding="no" margin="xSmall no no no">
                  <Text block weight="medium" size="medium">
                    {translate('customers.streetNetwork.lastPassRoute')}
                  </Text>
                  <Text block margin="xxSmall no no no" size="medium" title={routeSegment.routeName || '-'} singleLine>
                    {routeSegment.routeName || '-'}
                  </Text>
                </GridColumn>
              </>
            )}
            <GridColumn size="12/12" padding="no" margin="small no no no">
              <Text block weight="medium" size="medium" color="grayDark">
                {translate('customers.streetNetwork.segmentInfo')}
              </Text>
            </GridColumn>
            <GridColumn size="7/12" padding="no" margin="xxSmall no no no">
              <Separator color="gray" />
            </GridColumn>

            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.numberOfLanes')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.numberOfLanes || '-'} singleLine>
                {routeSegment.numberOfLanes || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.totalPasses')}
              </Text>
              <Text
                block
                margin="xxSmall no no no"
                size="medium"
                title={(routeSegment.forwardPasses || 0) + (routeSegment.reversePasses + 0)}
                singleLine
              >
                {(routeSegment.forwardPasses || 0) + (routeSegment.reversePasses + 0)}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.forwardPasses')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.forwardPasses || 0} singleLine>
                {routeSegment.forwardPasses || 0}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.reversePasses')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.reversePasses || 0} singleLine>
                {routeSegment.reversePasses || 0}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.speedLimit')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.speedLimit || '-'} singleLine>
                {routeSegment.speedLimit || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.travelDirection')}
              </Text>
              <Text
                block
                margin="xxSmall no no no"
                size="medium"
                title={translate(`customers.streetNetwork.${routeSegment.isOneWay ? 'oneWay' : 'twoWay'}`)}
                singleLine
              >
                {translate(`customers.streetNetwork.${routeSegment.isOneWay ? 'oneWay' : 'twoWay'}`)}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.segmentWidth')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.segmentWidth || '-'} singleLine>
                {routeSegment.segmentWidth || '-'}
              </Text>
            </GridColumn>

            {isStreetSweeperRoute && (
              <GridColumn size="6/12" padding="no" margin="xSmall no no no">
                <Text block weight="medium" size="medium">
                  {translate('customers.streetNetwork.serviceSide.serviceSide')}
                </Text>
                <Text
                  block
                  margin="xxSmall no no no"
                  size="medium"
                  title={serviceSideTypes.length ? serviceSideTypes[0].label : '-'}
                  singleLine
                >
                  {serviceSideTypes.length ? serviceSideTypes[0].label : '-'}
                </Text>
              </GridColumn>
            )}

            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.classification')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.type || '-'} singleLine>
                {routeSegment.type || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.priority')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.priorityTypeId || '-'} singleLine>
                {routeSegment.priorityTypeId || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.turnRestrictions')}
              </Text>
              <Text
                block
                margin="xxSmall no no no"
                size="medium"
                title={routeSegment.turnRestrictions || '-'}
                singleLine
              >
                {routeSegment.turnRestrictions || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.streetID')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.streetId || '-'} singleLine>
                {routeSegment.streetId || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.sweeperSideType')}
              </Text>
              <Text
                block
                margin="xxSmall no no no"
                size="medium"
                title={getTypesAsString(routeSegment.streetSweeperBroomSideTypes, true)}
                singleLine
              >
                {getTypesAsString(routeSegment.streetSweeperBroomSideTypes, true)}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="xSmall no no no">
              <Text block weight="medium" size="medium">
                {translate('customers.streetNetwork.segmentType.segmentType')}
              </Text>
              <Text
                block
                margin="xxSmall no no no"
                size="medium"
                title={getTypesAsString(routeSegment.streetSegmentServiceTypes)}
                singleLine
              >
                {getTypesAsString(routeSegment.streetSegmentServiceTypes)}
              </Text>
            </GridColumn>
          </Grid>
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
