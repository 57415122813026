import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Resolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { loadLocationAlerts, loadCustomer, loadCustomerLocations } from '../../ducks';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import CustomerLocationsEditorModal from './CustomerLocationsEditorModal';
import { AppState } from '../../../store';
import { DuckFunction } from 'src/contracts/ducks';

const vendorIdSelector = currentVendorIdSelector as any;

interface Props extends RouteComponentProps {
  vendorId?: number;
  loadLocationAlerts: any;
  onCancel: any;
  locationId?: number;
  customerId?: number;
  onSaveLocation?: any;
  newCustomerId?: number;
  serviceContractLocation?: string;
  serviceContractFullAddress?: any;
  loadCustomer: DuckFunction<typeof loadCustomer>;
  loadCustomerLocations: DuckFunction<typeof loadCustomerLocations>;
}

class CustomerLocationsEditorModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { vendorId, loadLocationAlerts, loadCustomer, loadCustomerLocations, customerId } = this.props;

    const dependencies: Promise<unknown>[] = [loadLocationAlerts(vendorId)];

    if (customerId) {
      dependencies.push(loadCustomer(customerId), loadCustomerLocations(customerId));
    }

    return Promise.all(dependencies);
  };

  render() {
    const {
      onCancel,
      onSaveLocation,
      locationId,
      customerId,
      newCustomerId,
      serviceContractLocation,
      serviceContractFullAddress,
    } = this.props;

    return (
      <Fragment>
        <Resolver
          successComponent={CustomerLocationsEditorModal}
          loadingComponent={PageLoadingOverlay}
          successProps={{
            onCancel,
            onSaveLocation,
            locationId,
            newCustomerId: newCustomerId || customerId,
            serviceContractLocation,
            serviceContractFullAddress,
          }}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: vendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadLocationAlerts,
  loadCustomer,
  loadCustomerLocations,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerLocationsEditorModalResolver));
