import { omit } from 'lodash-es';
import { http } from '../../core/services/http';
import { RouteSegment, RouteSegmentsQueryParams } from '../interfaces/RouteSegment';

export const loadRouteSegments = (params: RouteSegmentsQueryParams) =>
  http
    .get<RouteSegment[]>(`${params.vendorId}/routes/${params.routeId}/routeDetail/segments`, {
      params: {
        ...omit(params, ['vendorId', 'routeId', 'noLoadingIndicator', 'isForDashboard']),
      },
    })
    .then(response => response.data);

export const loadRouteSegmentsForDashboard = (params: RouteSegmentsQueryParams) =>
  http
    .get<RouteSegment[]>(`/dashboard/routes/${params.routeId}/segments`, {
      params: {
        ...omit(params, ['vendorId', 'routeId', 'noLoadingIndicator', 'isForDashboard']),
      },
    })
    .then(response => response.data);
