import moment, { unitOfTime } from 'moment';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import {
  getIconTypeForDashboardMapboxVehicleInsight,
  getIconTypeForGeoFenceInsight,
} from 'src/common/constants/insightIcons';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { GeoFenceIncidentsItem } from 'src/dashboard/interfaces/routesData';
import { VehicleInsightDetails } from 'src/dashboard/interfaces/vehiclePositions';
import { SPEEDING } from 'src/insights/constants';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { duration } from 'src/utils/services/formatter';
import { timeFormat } from 'src/utils/services/validator';

const DashboardGeoFenceIncidentsGLPopup = () => {
  const dispatch = useDispatch();

  const incidenceGeoFenceIncidents = useSelector(
    state => state.dashboard.routesData.geoFenceIncidents || [],
  ) as GeoFenceIncidentsItem[];

  const vehicleInsights = useSelector(
    state => (state.dashboard.vehiclesData.vehicleInsightDetails || []) as VehicleInsightDetails[],
  );

  const vehiclesData = useSelector(state => state.dashboard.vehiclesData.vehicleInformation || []) as any;

  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);

  const geoFenceIncident = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'vehicleInsights') {
      return undefined;
    }
    let geoFenceIncident = null;
    if (selectedFeature.vehicleInsightsSource === 'geoFenceIncidents') {
      geoFenceIncident = incidenceGeoFenceIncidents.find(incident => incident.id === selectedFeature.id);
    }
    return geoFenceIncident;
  }, [incidenceGeoFenceIncidents, selectedFeature]);

  const vehicleInsight = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'vehicleInsights') {
      return undefined;
    }
    let vehicleInsight = null;
    if (selectedFeature.vehicleInsightsSource === 'safetyIncidents') {
      vehicleInsight = vehicleInsights[selectedFeature.id - 1];
    }
    return vehicleInsight;
  }, [selectedFeature, vehicleInsights]);

  const iconType = useMemo(() => {
    if (!selectedFeature) {
      return undefined;
    }
    let iconType = null;
    if (geoFenceIncident) {
      iconType = getIconTypeForGeoFenceInsight(geoFenceIncident);
    } else if (vehicleInsight) {
      iconType = getIconTypeForDashboardMapboxVehicleInsight(vehicleInsight);
    }
    return iconType;
  }, [selectedFeature, vehicleInsight, geoFenceIncident]);

  const dataToDisplay = useMemo(() => {
    if (geoFenceIncident) {
      return geoFenceIncident;
    }
    if (vehicleInsight) {
      return {
        vehicleName: vehiclesData.vehicleName,
        reportDateTime: vehicleInsight.reportDateTime,
        occurrences: vehicleInsight.count,
        reportType: vehicleInsight.insightTypeName,
        insightMessage: vehicleInsight?.insightMessage || '',
        latitude: vehicleInsight.latitude,
        longitude: vehicleInsight.longitude,
        units: vehicleInsight.units,
        value: vehicleInsight.value,
      };
    }
  }, [geoFenceIncident, vehicleInsight, vehiclesData.vehicleName]);

  const durationFormatted = useMemo(() => {
    if (dataToDisplay?.units === 'NA') {
      return '';
    }

    if (dataToDisplay?.units) {
      // transform in  hours:minutes:seconds
      const unit = dataToDisplay.units.toLowerCase();
      const formatted = duration(dataToDisplay.value, unit as unitOfTime.DurationConstructor);
      return formatted;
    }

    return dataToDisplay?.value;
  }, [dataToDisplay]);

  if (!dataToDisplay) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={dataToDisplay.latitude}
      longitude={dataToDisplay.longitude}
      onClose={() => dispatch(clearDashboardSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle
          beforeTitleContent={
            <Box mr={8} width={15} height={15}>
              <img src={iconType?.iconUrl} width="15" height="15" alt="" />
            </Box>
          }
          title={translate(createTranslationKey(dataToDisplay.reportType, 'dashboard'))}
        />

        <MapGLPopupContent>
          {!!dataToDisplay.vehicleName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.truck')}
              value={dataToDisplay.vehicleName}
            />
          )}

          {!!dataToDisplay.reportDateTime && (
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={
                dataToDisplay.reportType === SPEEDING
                  ? translate('dashboard.speedingStartedAt')
                  : translate('common.timestamp')
              }
              value={dataToDisplay.reportDateTime && moment(dataToDisplay.reportDateTime).format(timeFormat)}
            />
          )}

          {dataToDisplay.reportType === SPEEDING && dataToDisplay.insightMessage && (
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('dashboard.topSpeed')}
              value={dataToDisplay.insightMessage}
            />
          )}

          {!!dataToDisplay.value && vehicleInsight?.value !== 0 && vehicleInsight?.units !== 'NA' && (
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.duration')}
              value={durationFormatted}
            />
          )}

          {!!dataToDisplay.occurrences && (
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('dashboard.occurrencesSoFarToday')}
              value={dataToDisplay.occurrences}
            />
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default DashboardGeoFenceIncidentsGLPopup;
