import { getFormValues } from 'redux-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import React from 'react';

import { AdvancedSearchFormValues } from 'src/dashboard/interfaces/routesData';
import { AppState } from 'src/store';
import { createErrorNotification } from 'src/core/services/createNotification';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { loadAdvancedSearchRoutes, resetAdvancedSearchRoutes } from 'src/dashboard/ducks/routesData';
import { MapboxFiltersFormValues } from '../forms/DashboardMapboxFiltersForm';
import { Modal, Table } from '../../../core/components';
import { ModalSection, PanelSection, TableCell } from '../../../core/components/styled';
import { SEARCH_BY_ACCOUNT_NUMBER, SEARCH_BY_NAME_OR_ADDRESS } from 'src/common/constants';
import { SearchType } from 'src/common/interfaces/SearchType';
import { TABLE_ROW_HEIGHT_xxLARGE } from 'src/core/constants';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import AdvancedSearchForm from '../forms/AdvancedSearchForm';
import AdvancedSearchModalTableRow from './AdvancedSearchModalTableRow';
import translate from '../../../core/services/translate';

export interface Props {
  onClose(pristine?: boolean): void;
  onSelectRoute: (
    routeId: number,
    isSourceAdvancedSearch: boolean,
    routeLocationId: number,
    correctDate: Date | string,
    isSnowPlowOrStreetSweeperRoute: boolean,
  ) => void;
}

const CustomerInfo = () => (
  <TableCell width="100%" vertical align="center" padding="no">
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.customerNameAndAccountNumber')}
    </Text>
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.locationNameAndAccountNumber')}
    </Text>
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.locationAddress')}
    </Text>
  </TableCell>
);

const ServiceInfo = () => (
  <TableCell width="100%" vertical align="center" padding="no">
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.containerTypeAndCount')}
    </Text>
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.containerSize')}
    </Text>
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.materialType')}
    </Text>
  </TableCell>
);

const RouteInfo = () => (
  <TableCell width="100%" vertical align="center" padding="no">
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.routeName')}
    </Text>
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.vehicleName')}
    </Text>
    <Text size="xSmall" block>
      {translate('dashboard.advancedSearch.driverName')}
    </Text>
  </TableCell>
);

const advancedSearchRoutesTableCells = [
  {
    name: 'customerInfo',
    width: '35%',
    component: CustomerInfo,
  },
  { name: 'serviceInfo', width: '30%', component: ServiceInfo },
  { name: 'routeInfo', width: '20%', component: RouteInfo },
  { name: 'options', label: translate('common.options'), width: '15%' },
];

const AdvancedSearchModal: React.FC<Props> = ({ onClose, onSelectRoute }) => {
  const dispatch = useDispatch();

  const { advancedSearchRoutes, isLoadingAdvancedSearchRoutes } = useSelector(
    (state: AppState) => state.dashboard.routesData,
  );
  const formValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;
  const date = formValues?.date ? moment(formValues.date).format('YYYY-MM-DD') : null;
  const correctDate = moment(moment(date).format('MM/DD/YYYY')).isValid()
    ? moment(date).format('MM/DD/YYYY')
    : moment().format('MM/DD/YYYY');

  const closeModal = (pristine = false) => {
    onClose(pristine);
    dispatch(resetAdvancedSearchRoutes());
  };

  const handleSubmit = (advancedSearchFormValues: AdvancedSearchFormValues) => {
    dispatch(resetAdvancedSearchRoutes());
    let searchField: SearchType = advancedSearchFormValues.customerLocationAddress
      ? SEARCH_BY_NAME_OR_ADDRESS
      : SEARCH_BY_ACCOUNT_NUMBER;
    let searchCriteria = advancedSearchFormValues.customerLocationAddress || advancedSearchFormValues.accountNumber;
    if (!advancedSearchFormValues.customerLocationAddress && !advancedSearchFormValues.accountNumber) {
      createErrorNotification(translate('dashboard.advancedSearch.emptyFieldsErrorMessage'));
    } else {
      loadAdvancedSearchRoutes(correctDate, searchField, searchCriteria)(dispatch);
    }
  };

  const selectRoute = (routeId: number, routeLocationId: number, isSnowPlowOrStreetSweeperRoute: boolean) => {
    const isSourceAdvancedSearch = true;
    onSelectRoute(routeId, isSourceAdvancedSearch, routeLocationId, correctDate, isSnowPlowOrStreetSweeperRoute);
    closeModal();
  };

  const virtualizedProps = {
    itemSize: TABLE_ROW_HEIGHT_xxLARGE,
    height: Math.min(advancedSearchRoutes.length * TABLE_ROW_HEIGHT_xxLARGE, TABLE_ROW_HEIGHT_xxLARGE * 6) || 1,
  };

  return (
    <Modal
      size="large"
      padding="medium no no"
      title={translate('dashboard.advancedSearch.advancedSearch')}
      onClose={closeModal}
    >
      <ModalSection margin="medium no no" minHeight="400px">
        <AdvancedSearchForm onSubmit={handleSubmit}></AdvancedSearchForm>

        <PanelSection padding="no sMedium medium sMedium" isLoading={isLoadingAdvancedSearchRoutes} minHeight={300}>
          {!!advancedSearchRoutes.length ? (
            <Table
              cells={advancedSearchRoutesTableCells}
              rows={advancedSearchRoutes}
              rowComponent={AdvancedSearchModalTableRow}
              rowProps={{ onSelectRoute: selectRoute }}
              virtualized
              virtualizedProps={virtualizedProps}
            />
          ) : (
            translate('routes.noRoutes')
          )}
        </PanelSection>
      </ModalSection>
    </Modal>
  );
};

export default AdvancedSearchModal;
