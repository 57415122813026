import moment from 'moment';
import { map, get } from 'lodash-es';
import { date } from '../../utils/services/formatter';
import { Service } from '../interfaces/Services';

export const transformLoadService = ({ effectiveDate, cancellationDate, container, ...service }: any): Service => {
  const fromDate = get(service.futureAccountStatus, 'fromDate');
  const toDate = get(service.futureAccountStatus, 'toDate');

  return {
    ...service,
    dayOfServices: map(service.dayOfServices, dayOfService => ({
      ...dayOfService,
      routeTemplateIds: map(dayOfService.routeTemplates, routeTemplate => Number(routeTemplate.routeTemplateId)),
      checked: true,
    })),
    rates: map(service.rates, rate => ({
      ...rate,
      effectiveFromDate: moment(rate.effectiveFromDate).format('MM/DD/YYYY'),
    
    })),
    effectiveDate: moment(effectiveDate).format('MM/DD/YYYY'),
    cancellationDate: cancellationDate && moment(cancellationDate).format('MM/DD/YYYY'),
    binNumber: container ? container.binNumber : null,
    containers: map(
      [container],
      ({ binLatitude, binLongitude, displayLatitude, displayLongitude, id, forwardPasses, reversePasses }) => ({
        lat: displayLatitude || binLatitude,
        lng: displayLongitude || binLongitude,
        id,
        forwardPasses,
        reversePasses,
      }),
    ),
    futureAccountStatus: {
      ...service.futureAccountStatus,
      accountStatusId: get(service.futureAccountStatus, 'accountStatusId') || undefined,
      fromDate: fromDate ? date(fromDate, 'MM/DD/YYYY') : undefined,
      toDate: toDate ? date(toDate, 'MM/DD/YYYY') : undefined,
    },
  };
};

export const transformSaveService = (service: Service) => ({
  ...service,
  container:
    service.containers &&
    map(service.containers, ({ lat, lng, id, binMappingTypeId, binNumber, forwardPasses, reversePasses }) => ({
      binNumber,
      binLatitude: lat,
      binLongitude: lng,
      binMappingTypeId,
      id,
      forwardPasses,
      reversePasses,
    }))[0],
});
