import { filter, map, size } from 'lodash-es';
import { ChangeEvent, FC, useMemo } from 'react';
import { WrappedFieldInputProps } from 'redux-form';

import { TypedField } from 'src/core/components';
import MultiSelect, { MultiSelectProps } from 'src/core/components/MultiSelect';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { NONE_ID } from 'src/routes/constants/dispatchBoard';
import { DropDownFieldProps } from './DropDownFieldProps';
import { TechnicalType } from '../interfaces/TechnicalType';
import { AppState } from 'src/store';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('common.allEquipments')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('haulerProfile.noEquipmentConfigured')
    : translate('common.xEquipmentsSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  excludeEquipmentIds?: number[];
  includeNoneOption?: boolean;
}

const EquipmentTypesMultiSelect: FC<Props> = ({
  input,
  multiSelectProps,
  excludeEquipmentIds,
  label,
  withLabel,
  placeholder,
  withPlaceholder,
  ...rest
}) => {
  const equipmentTypes: TechnicalType[] = useSelector((state: AppState) => state.common.equipmentTypes.equipmentTypes);

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    input.onChange(value);
    multiSelectProps?.onChange && multiSelectProps.onChange(value);
  };

  const options = useMemo(() => {
    const filtered = map(
      filter(equipmentTypes, equipment => !!equipment.id && !excludeEquipmentIds?.includes(equipment.id)),
      equipment => ({ label: equipment.name, value: equipment.id }),
    );
    return filtered;
  }, [excludeEquipmentIds, equipmentTypes]);

  return (
    <TypedField
      name={input.name}
      component={MultiSelect}
      onChange={handleChange}
      {...rest}
      props={{
        label: label || (withLabel ? translate('common.equipmentType') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('common.equipmentType') : undefined),
        options: options,
        canCheckAll: multiSelectProps?.canCheckAll || false,
        isClearable: true,
        normalizeValues: Number,
        formatText,
        ...multiSelectProps,
      }}
    />
  );
};

export default EquipmentTypesMultiSelect;
