import humps from 'humps';
import { every, filter, find, groupBy, isEmpty, map, reduce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';

import {
  CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
  CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
} from 'src/common/constants';
import { Checkbox, PopoverWrapper, Switch, TypedField } from 'src/core/components';
import { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import { Button, Popover, Separator, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  ROUTE,
  ROUTE_TEMPLATE,
  STREET_NETWORK,
  STREET_SEGMENT_ASSIGNED_STATUSES,
  STREET_SEGMENT_ASSIGNED_TYPES_CREATE,
  STREET_SEGMENT_ASSIGNED_TYPES_STATUSES,
  STREET_SEGMENT_MAP_FILTERS_FORM_NAME,
  // STREET_SEGMENT_NUMBER_OF_LANES,
  STREET_SEGMENT_NUMBER_OF_PASSES,
  STREET_SEGMENT_SERVICE_SIDES,
  STREET_SEGMENT_SNOW_OR_SWEEPER_ROUTE_STATUSES,
  STREET_SEGMENT_SNOW_OR_SWEEPER_ROUTE_STATUSES_NOT_COMPLEX,
  STREET_SEGMENT_STATUSES,
} from 'src/customers/constants/streetNetwork';
import { TravelPathField } from 'src/routes/components/forms';
import {
  HAULER_LOCATION_FILTERS,
  SNOW_SWEEPER_HAULER_LOCATION_FILTERS,
} from 'src/routes/components/pages/routes/routePageSections/routeMap/RouteMapFiltersForm';
import {
  RouteMapFiltersClose,
  RouteMapFiltersCloseIcon,
  RouteMapFiltersSectionTitle,
  RouteMapFiltersSection as RouteMapFiltersSectionWrapper,
  RouteMapFiltersTitle,
  RouteMapFiltersWrapper,
} from 'src/routes/components/styled/RouteMapFilters';
import {
  COMPLETED,
  GENERAL_GEO_FENCE_ID,
  GEO_FENCES_ALL_TYPES,
  SCHEDULED as SCHEDULED_ROUTE,
} from 'src/routes/constants';
import { DAILY_ROUTE_GEO_FENCE_ID } from 'src/routes/constants/geoFenceType';
import { GeoFenceMapFilterOption } from 'src/routes/ducks/geoFenceFilterOptions';
import { getPersistentMapFilters } from 'src/routes/services/persistentMapFilterSettingStorage';
import { getAgingIntervalOptionLabel } from 'src/routes/utils/routeDetails';
import { checkIfGeoFenceIsEnabled } from 'src/vendors/ducks/features';
import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { SegmentConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { getServiceActivityOptions } from '../pages/streetNetwork/utils';
import { ExpandableMapFiltersSection } from './ExpandableMapFiltersSection';
import { ExpandableMapFiltersSubsection } from './ExpandableMapFiltersSubsection';
import { ExpandableMapCityAlertsSubsection } from './ExpandableMapCityAlertsSubsection';
import { getActiveCityAlertTypes } from 'src/fleet/components/forms/LocationAndCityAlertsSearchForm';
import { ALERT_TYPES, ROUTE_CITY_ALERTS, ALL_CITY_ALERTS } from 'src/fleet/constants/locationAndCityAlerts';

type FiltersSectionProps = {
  change: InjectedFormProps['change'];
  customHandleCheckAll?: boolean;
  checkAllFieldName: string;
  filters: { id: number | string; label: string }[];
  name: string;
  title: string;
};

const normalizeFilterArray = (array: boolean[]) =>
  reduce(array, (acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

// returns the keys of the elements that are true
const normalizeFilterObject = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: string[], cur) => (cur[1] ? [...acc, cur[0]] : acc), []);

// returns the keys of the elements that are true as numbers
const normalizeFilterObjectAsNumbersAndRemovePrefix = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: number[], cur) => (cur[1] ? [...acc, Number(cur[0].replace('_', ''))] : acc), []);

function RouteMapFiltersSection({
  change,
  checkAllFieldName,
  filters,
  name,
  title,
  customHandleCheckAll,
}: FiltersSectionProps) {
  const handleCheckAll: TypedFieldOnChangeFunction = customHandleCheckAll
    ? (_, isChecked) => {
        filters.forEach(filter => {
          change(`${name}[${filter.id}]`, isChecked);
        });
      }
    : (_, isChecked) => {
        filters.forEach((filter, index) => {
          change(`${name}[${index}]`, isChecked);
        });
      };

  const handleChangeFilter = () => {
    change(checkAllFieldName, false);
  };

  return (
    <RouteMapFiltersSectionWrapper>
      <RouteMapFiltersSectionTitle>{title}</RouteMapFiltersSectionTitle>
      <TypedField
        name={checkAllFieldName}
        component={Checkbox}
        onChange={handleCheckAll}
        props={{
          label: translate('common.all'),
          block: true,
          margin: 'xSmall no',
          size: 'small',
        }}
      />

      {filters.map((filter, index) => (
        <TypedField
          key={index}
          name={customHandleCheckAll ? `${name}[${filter.id}]` : `${name}[${index}]`}
          component={filter.label ? Checkbox : () => null}
          onChange={handleChangeFilter}
          props={{
            label: filter.label,
            block: true,
            margin: 'xSmall no',
            size: 'small',
          }}
        />
      ))}
    </RouteMapFiltersSectionWrapper>
  );
}

type PropsWithoutReduxForm = {
  closeStreetSegmentsMapFilters: () => void;
  handleApplyLastFilters: (currentRouteFilters: any) => void;
  isDailyRoute?: boolean;
  isDriverExperienceComplex?: boolean;
  isEditMode?: boolean;
  isNoRoute?: boolean;
  isRouteTemplate?: boolean;
  isSnowPlowRoute?: boolean;
  isStreetSegmentsMapFiltersOpen: boolean;
  isStreetSweeperRoute?: boolean;
  numberOfSegments?: number;
  routeId?: number;
  routeStatusTypeId?: number;
  routeTemplateId?: number;
  routeName?: string;
  routeDate?: string;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

export default reduxForm<any, PropsWithoutReduxForm>({ form: STREET_SEGMENT_MAP_FILTERS_FORM_NAME })(
  function StreetSegmentsMapFiltersForm({
    change,
    closeStreetSegmentsMapFilters,
    handleApplyLastFilters,
    handleSubmit,
    isDailyRoute,
    isDriverExperienceComplex,
    isEditMode,
    isNoRoute,
    isRouteTemplate,
    isSnowPlowRoute,
    isStreetSegmentsMapFiltersOpen,
    isStreetSweeperRoute,
    numberOfSegments,
    routeId,
    routeStatusTypeId,
    routeTemplateId,
    routeName,
    routeDate,
  }: Props) {
    const formValues = useSelector(getFormValues(STREET_SEGMENT_MAP_FILTERS_FORM_NAME)) as any;

    const SNOW_HAULER_LOCATION_FILTERS = SNOW_SWEEPER_HAULER_LOCATION_FILTERS.filter(
      el => el.id !== CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
    );
    const SWEEPER_HAULER_LOCATION_FILTERS = SNOW_SWEEPER_HAULER_LOCATION_FILTERS.filter(
      el => el.id !== CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
    );

    const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
    const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
    const hasCityAlerts = getActiveCityAlertTypes(cityAlertSettings).hasCityAlerts;

    const { geoFenceMapFilterOptions } = useSelector(
      state => state.routes.geoFencesFilterOptions.geoFencesFilterOptions,
    );
    const { geoFence: routeGeoFence } = useSelector(state => state.routes.geoFence);

    const isGeoFenceEnabled = useSelector(checkIfGeoFenceIsEnabled);

    const { locationFlagTypes } = useSelector(state => state.common.locationFlagTypes);
    const { filters: vehicleFilters, routeMapFilters } = useSelector(state => state.routes.routeMapVehicleData);
    const { filters: vehicleInsightsFiltersData } = useSelector(state => state.routes.routeMapVehicleInsights);
    const { filters: cityInsightsFiltersData } = useSelector(state => state.routes.routeMapCityInsights);

    const { displayConfiguration, serviceConfiguration: snowServiceConfiguration } = useSelector(
      state => state.vendors.snowPlowSettings.snowPlowSettings,
    );
    const { segmentColorSettings, serviceConfiguration: sweeperServiceConfiguration } = useSelector(
      state => state.vendors.streetSweepingSettings.streetSweepingSettings,
    );

    const initialDriversOptions = map(routeMapFilters.drivers, driver => ({
      label: `${driver.firstName} ${driver.lastName}`,
      id: driver.driverId,
    }));

    const segmentConfig = isSnowPlowRoute ? displayConfiguration?.segmentConfiguration : segmentColorSettings;
    const agingIntervalOptions =
      segmentConfig
        ?.filter(item => !!item.enabled)
        ?.map((item: SegmentConfiguration) => {
          const { id } = item.streetSegmentAgingInterval.timeMeasurementType;
          const { id: streetSegmentAgingIntervalId, name } = item.streetSegmentAgingInterval;

          return {
            label: getAgingIntervalOptionLabel(id, name),
            id: streetSegmentAgingIntervalId,
          };
        }) || [];
    const serviceActivityOptions = getServiceActivityOptions(
      isSnowPlowRoute ? snowServiceConfiguration : sweeperServiceConfiguration,
    );

    const [isGeoFenceFiltersOpen, setIsGeoFenceFiltersOpen] = useState(false);
    const [isLastActivityFiltersOpen, setIsLastActivityFiltersOpen] = useState(false);
    const [isDriversFiltersOpen, setIsDriversFiltersOpen] = useState(false);
    const [isHaulerLocationsOpen, setIsHaulerLocationsOpen] = useState(false);
    const [isCityAlertFiltersOpen, setIsCityAlertFiltersOpen] = useState(false);
    const [driversOptions, setDriversOptions] = useState<{ label: string; id: number }[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const isScheduledRoute = routeStatusTypeId === SCHEDULED_ROUTE;

    const pageType = isDailyRoute ? ROUTE : isRouteTemplate ? ROUTE_TEMPLATE : STREET_NETWORK;
    const routeOrTemplateId = isDailyRoute ? routeId : isRouteTemplate ? routeTemplateId : 0;
    const isApplyLastFiltersDisabled = isEmpty(getPersistentMapFilters(routeOrTemplateId, pageType));

    const vehicleInsightsFilters = vehicleInsightsFiltersData
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(vehicleInsightsFilter => ({
        id: vehicleInsightsFilter.name,
        label: `${translate(`dashboard.${humps.camelize(vehicleInsightsFilter.name)}`)} (${
          vehicleInsightsFilter.insightFormattedValue
        })`,
      }));

    const cityInsightsFilters = cityInsightsFiltersData.map(({ locationFlaggingTypeId, count }) => {
      const locationFlagType = locationFlagTypes.find(
        locationFlagType => locationFlagType.id === locationFlaggingTypeId,
      );
      return {
        id: locationFlaggingTypeId,
        label: `${translate(
          `vendors.locationFlags.${humps.camelize(locationFlagType?.technicalName || '')}`,
        )} (${count})`,
      };
    });

    const cityAlertsFilters = useMemo(
      () =>
        [
          {
            id: ALERT_TYPES[ROUTE_CITY_ALERTS].id,
            label: ALERT_TYPES[ROUTE_CITY_ALERTS].name,
          },
          {
            id: ALERT_TYPES[ALL_CITY_ALERTS].id,
            label: ALERT_TYPES[ALL_CITY_ALERTS].name,
          },
        ] as any,
      [],
    );

    const normalizedCityAlertsFiltersIds = normalizeFilterArray(formValues.cityAlertsFilters);
    const cityAlertsSubFilters = normalizedCityAlertsFiltersIds.map(id => {
      return {
        label: cityAlertsFilters.find((cityAlert: CityAlert) => cityAlert.id === id).label,
        id: id,
        subFilters: map(activeCityAlertTypes, (cityAlert: CityAlert) => ({
          id: `${id}_${cityAlert.cityAlertType.id}`,
          label: cityAlert.cityAlertType.name,
        })) as any,
      };
    });

    const onCityAlertsFilterChange = (_: any, isChecked: boolean, id: string | number) => {
      isChecked &&
        cityAlertsFilters.forEach((cityAlertsFilter: any) => {
          cityAlertsFilter.id !== Number(id) && change(`cityAlertsFilters.[${cityAlertsFilter.id}]`, false);
        });
    };

    const handleChangeShowVehicleTracking: TypedFieldOnChangeFunction = (_, isChecked) => {
      change('showStopConfirmation', isChecked);
      change('showXDeviceTrackings', isChecked);
      change('showYDeviceTrackings', isChecked);
    };

    useEffect(() => {
      if (formValues?.showVehicleTrackings === true && every(formValues?.vehicleFilters, (v: boolean) => v === false))
        change('showVehicleTrackings', false);
    }, [formValues, change]);

    const geoFencesGroupedByType = useMemo(
      () => groupBy(geoFenceMapFilterOptions, (geoFence: GeoFenceMapFilterOption) => geoFence.zoneTypeId),
      [geoFenceMapFilterOptions],
    );

    const geoFencesFilters = useMemo(() => {
      /// General GeoFence has to be first in the list
      let geoFenceTypeIds = Object.keys(geoFencesGroupedByType);
      if (geoFenceTypeIds.includes(GENERAL_GEO_FENCE_ID.toString())) {
        geoFenceTypeIds.splice(geoFenceTypeIds.indexOf(GENERAL_GEO_FENCE_ID.toString()), 1);
        geoFenceTypeIds.unshift(GENERAL_GEO_FENCE_ID.toString());
      }

      // remove daily route geoFences on template or street-network page
      if (isRouteTemplate || !(isDailyRoute || isRouteTemplate))
        geoFenceTypeIds = filter(geoFenceTypeIds, id => id !== DAILY_ROUTE_GEO_FENCE_ID.toString());

      const filters = map(geoFenceTypeIds, geoFenceZoneTypeId => {
        const geoFences = geoFencesGroupedByType[geoFenceZoneTypeId];
        const geoFenceZoneTypeName = GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId];
        return {
          id: Number(geoFenceZoneTypeId),
          label: geoFenceZoneTypeName.name,
          rightLabel: `${geoFences.length}`,
          subFilters: map(geoFences, (option: GeoFenceMapFilterOption) => ({
            id: option.id,
            label: option.name,
          })) as any,
        };
      });

      if (routeGeoFence && routeGeoFence.id && !isRouteTemplate) {
        filters.unshift({
          id: routeGeoFence.id,
          label: translate('routes.geoFences.routeGeoFence'),
          subFilters: [],
          rightLabel: '',
        });
      }
      return filters;
    }, [geoFencesGroupedByType, isDailyRoute, isRouteTemplate, routeGeoFence]);

    const subGeoFenceFilters = useMemo(() => {
      /// General GeoFence has to be first in the list
      const geoFenceTypeIds = Object.keys(geoFencesGroupedByType);
      if (geoFenceTypeIds.includes(GENERAL_GEO_FENCE_ID.toString())) {
        geoFenceTypeIds.splice(geoFenceTypeIds.indexOf(GENERAL_GEO_FENCE_ID.toString()), 1);
        geoFenceTypeIds.unshift(GENERAL_GEO_FENCE_ID.toString());
      }
      return map(
        filter(geoFenceTypeIds, (id: number) => formValues?.geoFencesTypesFilters[id]) as any,
        geoFenceZoneTypeId => {
          const geoFences = geoFencesGroupedByType[geoFenceZoneTypeId] as GeoFenceMapFilterOption[];
          const filteredGeoFences = filter(
            geoFences,
            gf => gf.name.toLowerCase().indexOf(formValues?.geoFenceSearchTerm.toLowerCase()) > -1,
          );
          return {
            label: GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId].name,
            id: geoFenceZoneTypeId,
            rightLabel: `${geoFences.length}`,
            subFilters: map(filteredGeoFences, (option: GeoFenceMapFilterOption) => ({
              id: `_${option.id}`,
              label: option.name,
            })) as any,
          };
        },
      );
    }, [geoFencesGroupedByType, formValues?.geoFencesTypesFilters, formValues?.geoFenceSearchTerm]);

    // uncheck all subFilters if category is unchecked
    useEffect(() => {
      const normalizedGeoFencesIds = normalizeFilterObjectAsNumbersAndRemovePrefix(formValues?.geoFenceSubFilters);
      const normalizedGeoFencesTypesIds = normalizeFilterArray(formValues?.geoFencesTypesFilters);
      const filteredGeoFencesIdsToDeselect = normalizedGeoFencesIds.filter((id: number) => {
        const geoFenceOption = find(geoFenceMapFilterOptions, { id });
        return (
          geoFenceOption &&
          geoFenceOption.zoneTypeId &&
          !normalizedGeoFencesTypesIds.includes(geoFenceOption.zoneTypeId)
        );
      });
      filteredGeoFencesIdsToDeselect.forEach(subFilter => {
        change(`geoFenceSubFilters._${subFilter}`, false);
      });
    }, [change, formValues?.geoFenceSubFilters, formValues?.geoFencesTypesFilters, geoFenceMapFilterOptions]);

    // uncheck all cityAlertsSubFilters if category is unchecked
    useEffect(() => {
      const normalizedCityAlertsIds = normalizeFilterObject(formValues.cityAlertsSubFilters || {});
      const normalizedCityAlertsTypesIds = normalizeFilterArray(formValues.cityAlertsFilters);
      const filteredCityAlertsIdsToDeselect = normalizedCityAlertsIds.filter(normalizedCityAlertsId => {
        const cityAlertsOption = cityAlertsFilters.find(
          (cityAlertsFilter: any) => cityAlertsFilter.id === Number(normalizedCityAlertsId.toString().split('_')[0]),
        );
        return cityAlertsOption && !normalizedCityAlertsTypesIds.includes(cityAlertsOption.id);
      });
      filteredCityAlertsIdsToDeselect.forEach(subFilter => {
        change(`${ALERT_TYPES[filteredCityAlertsIdsToDeselect[0].toString().split('_')[0]].name}_checkAll`, false);
        change(`cityAlertsSubFilters.[${subFilter}]`, false);
      });
    }, [change, formValues.cityAlertsSubFilters, formValues.cityAlertsFilters, cityAlertsFilters]);

    const applyLastFilters = () => {
      const currentRouteFilters = getPersistentMapFilters(routeOrTemplateId, pageType);

      Object.keys(currentRouteFilters).forEach(filter => {
        change(filter, currentRouteFilters[filter]);
      });

      handleApplyLastFilters(currentRouteFilters);
    };

    const onSetDriversSearchTerm = (searchTerm: string) => {
      const drivers = map(routeMapFilters.drivers, driver => ({
        label: `${driver.firstName} ${driver.lastName}`,
        id: driver.driverId,
      }));

      setDriversOptions(
        searchTerm ? drivers.filter(driver => driver.label.toUpperCase().includes(searchTerm.toUpperCase())) : drivers,
      );

      setSearchTerm(searchTerm);
    };

    const showTravelPathAction =
      numberOfSegments && numberOfSegments >= 2 && !isEditMode && !!(routeTemplateId || routeId);

    return isStreetSegmentsMapFiltersOpen ? (
      <form onSubmit={handleSubmit}>
        <RouteMapFiltersWrapper width={routeTemplateId ? '302' : undefined}>
          <div>
            <RouteMapFiltersTitle>{translate('tooltips.mapFilters')}</RouteMapFiltersTitle>
            <RouteMapFiltersClose onClick={closeStreetSegmentsMapFilters}>
              <RouteMapFiltersCloseIcon />
            </RouteMapFiltersClose>

            {isDailyRoute && (
              <RouteMapFiltersSection
                name="streetSegmentAssignedStatuses"
                title={translate('customers.streetNetwork.status.assignedStatus')}
                filters={STREET_SEGMENT_ASSIGNED_STATUSES}
                change={change}
                checkAllFieldName="showAllSegmentAssignedStatuses"
              />
            )}
            {isRouteTemplate && (
              <RouteMapFiltersSection
                name="streetSegmentAssignedTypesStatus"
                title={translate('customers.streetNetwork.status.assignedStatus')}
                filters={
                  routeTemplateId ? STREET_SEGMENT_ASSIGNED_TYPES_STATUSES : STREET_SEGMENT_ASSIGNED_TYPES_CREATE
                }
                change={change}
                checkAllFieldName="showAllSegmentAssignedTypesStatuses"
              />
            )}

            {/*TODO: show this back when data is available
            <RouteMapFiltersSection
              name="streetSegmentNumberOfLanes"
              title={translate('customers.streetNetwork.numberOfLanes')}
              filters={STREET_SEGMENT_NUMBER_OF_LANES}
              change={change}
              checkAllFieldName="showAllNumberOfLanes"
            /> */}

            {!isNoRoute && (
              <>
                <RouteMapFiltersSection
                  name="streetSegmentStatuses"
                  title={translate('customers.streetNetwork.status.status')}
                  filters={STREET_SEGMENT_STATUSES}
                  change={change}
                  checkAllFieldName="showAllSegmentStatuses"
                />
                <RouteMapFiltersSection
                  name="streetSegmentNumberOfPasses"
                  title={translate('customers.streetNetwork.totalPasses')}
                  filters={STREET_SEGMENT_NUMBER_OF_PASSES}
                  change={change}
                  checkAllFieldName="showAllNumberOfPasses"
                />

                <RouteMapFiltersSection
                  name="streetSegmentServiceSides"
                  title={translate('customers.streetNetwork.serviceSide.serviceSide')}
                  filters={STREET_SEGMENT_SERVICE_SIDES}
                  change={change}
                  checkAllFieldName="showAllServiceSides"
                />
              </>
            )}

            {isDailyRoute && !isNoRoute && (
              <>
                <RouteMapFiltersSection
                  name="pickupStatusTypeIds"
                  title={translate('customers.streetNetwork.segmentStatus')}
                  filters={
                    isDriverExperienceComplex
                      ? STREET_SEGMENT_SNOW_OR_SWEEPER_ROUTE_STATUSES
                      : STREET_SEGMENT_SNOW_OR_SWEEPER_ROUTE_STATUSES_NOT_COMPLEX
                  }
                  change={change}
                  customHandleCheckAll={true}
                  checkAllFieldName="showAllSegmentsStatus"
                />

                {!!serviceActivityOptions.length && (
                  <RouteMapFiltersSection
                    name="alternativeFleetServiceTypeIds"
                    title={translate('customers.streetNetwork.serviceActivity.serviceActivity')}
                    filters={serviceActivityOptions}
                    change={change}
                    customHandleCheckAll={true}
                    checkAllFieldName="showAllServiceActivity"
                  />
                )}
              </>
            )}

            {!isScheduledRoute && routeId && !isEditMode && (
              <>
                <RouteMapFiltersSectionWrapper>
                  <RouteMapFiltersSectionTitle>{translate('vehicles.vehicles')}</RouteMapFiltersSectionTitle>
                  <TypedField
                    name="showVehicleTrackings"
                    component={Switch}
                    onChange={handleChangeShowVehicleTracking}
                    props={{
                      label: translate('routes.filters.showVehicleTrackings'),
                      margin: 'xSmall no',
                      size: 'medium',
                      disabled: every(formValues?.vehicleFilters, (v: boolean) => v === false),
                    }}
                  />
                  {vehicleFilters
                    .sort((a, b) => Number(a.isAssisting) - Number(b.isAssisting))
                    .map(vehicleFilter => (
                      <TypedField
                        key={vehicleFilter.vehicleId}
                        name={`vehicleFilters._${vehicleFilter.vehicleId}`}
                        component={Checkbox}
                        props={{
                          label: `${vehicleFilter.vehicleName}${
                            vehicleFilter.isAssisting ? ` (${translate('vehicles.assisted')})` : ''
                          }`,
                          block: true,
                          margin: 'xSmall no',
                          size: 'small',
                        }}
                      />
                    ))}
                </RouteMapFiltersSectionWrapper>

                {!!vehicleInsightsFilters.length && (
                  <RouteMapFiltersSection
                    name="vehicleInsightsFilters"
                    customHandleCheckAll={true}
                    title={translate('dashboard.vehicleInsights')}
                    change={change}
                    checkAllFieldName="showAllVehicleInsights"
                    filters={vehicleInsightsFilters}
                  />
                )}

                {!!cityInsightsFilters.length && (
                  <RouteMapFiltersSection
                    name="cityInsightsFilters"
                    title={translate('dashboard.cityInsights')}
                    customHandleCheckAll={true}
                    change={change}
                    checkAllFieldName="showAllCityInsights"
                    filters={cityInsightsFilters}
                  />
                )}
              </>
            )}

            {isDailyRoute && (
              <>
                {!isNoRoute && (
                  <Box margin="xSmall no no" display="block">
                    <ExpandableMapFiltersSection
                      title={translate('dashboard.lastActivity')}
                      change={change}
                      filters={agingIntervalOptions}
                      isOpen={isLastActivityFiltersOpen}
                      name="lastActivity"
                      setIsOpen={setIsLastActivityFiltersOpen}
                      hasCheckAll
                    />
                  </Box>
                )}
                <Box margin="xSmall no no" display="block">
                  <ExpandableMapFiltersSection
                    title={translate('drivers.drivers')}
                    change={change}
                    filters={searchTerm ? driversOptions : initialDriversOptions}
                    isOpen={isDriversFiltersOpen}
                    name="driverIds"
                    onSetDriversSearchTerm={onSetDriversSearchTerm}
                    setIsOpen={setIsDriversFiltersOpen}
                    noDataMessage={translate('drivers.noDrivers')}
                  />
                </Box>

                {hasCityAlerts && (
                  <Box margin="xSmall no no" display="block">
                    <ExpandableMapFiltersSection
                      title={translate('vendors.cityAlerts.cityAlerts')}
                      change={change}
                      filters={cityAlertsFilters}
                      isOpen={isCityAlertFiltersOpen}
                      name="cityAlertsFilters"
                      setIsOpen={setIsCityAlertFiltersOpen}
                      onFilterChange={onCityAlertsFilterChange}
                    />
                  </Box>
                )}
              </>
            )}

            {!isScheduledRoute && routeId && !isEditMode && (
              <>
                <Box margin="xSmall no no" display="block">
                  <ExpandableMapFiltersSection
                    title={translate('dashboard.vendorLocations')}
                    change={change}
                    filters={
                      isSnowPlowRoute
                        ? SNOW_HAULER_LOCATION_FILTERS
                        : isStreetSweeperRoute
                        ? SWEEPER_HAULER_LOCATION_FILTERS
                        : HAULER_LOCATION_FILTERS
                    }
                    isOpen={isHaulerLocationsOpen}
                    name="haulerLocationsFilters"
                    setIsOpen={setIsHaulerLocationsOpen}
                    hasCheckAll
                  />
                </Box>
              </>
            )}

            {/* General & Route & StreetSweeper GeoFences grouped */}
            {isGeoFenceEnabled && !!geoFenceMapFilterOptions?.length && (
              <Box margin="xSmall no no" display="block">
                <ExpandableMapFiltersSection
                  title={translate('routes.geoFences.geoFencesTitle')}
                  filters={geoFencesFilters}
                  name="geoFencesTypesFilters"
                  change={change}
                  isOpen={isGeoFenceFiltersOpen}
                  setIsOpen={setIsGeoFenceFiltersOpen}
                />
              </Box>
            )}

            {!!showTravelPathAction && (
              <>
                <Separator margin="small no no" />
                <TypedField
                  name="showTravelPath"
                  component={TravelPathField}
                  props={{
                    displayTravelPath: true,
                    isActionButtonAvailable: isDailyRoute ? routeStatusTypeId !== COMPLETED : true,
                    routeId: routeId,
                    routeTemplateId,
                    isAlternativeFleet: true,
                    isSnowPlow: isSnowPlowRoute,
                    routeName: routeName || '',
                    date: routeDate,
                  }}
                />
              </>
            )}

            <PopoverWrapper
              triggerButton={
                <Text
                  color="primary"
                  cursor="pointer"
                  onClick={() => (isApplyLastFiltersDisabled ? undefined : applyLastFilters())}
                  block
                  align="center"
                  disabled={isApplyLastFiltersDisabled}
                >
                  {translate('dashboard.applyLastFilters')}
                </Text>
              }
              popoverContent={
                isApplyLastFiltersDisabled ? (
                  <Popover>{translate('dashboard.alertMessages.lastFiltersNotAvailable')}</Popover>
                ) : undefined
              }
              size="medium"
              margin="medium no small no"
              width="100%"
            />

            <Button type="submit" color="primary" fullWidth margin="no">
              {translate('common.apply')}
            </Button>
          </div>
        </RouteMapFiltersWrapper>

        <ExpandableMapFiltersSubsection
          isOpen={isGeoFenceFiltersOpen}
          setIsOpen={setIsGeoFenceFiltersOpen}
          change={change}
          name="geoFenceSubFilters"
          filters={subGeoFenceFilters}
          leftPosition={
            routeTemplateId
              ? '300px'
              : normalizedCityAlertsFiltersIds.length && isCityAlertFiltersOpen
              ? '500px'
              : '250px'
          }
        />

        <ExpandableMapCityAlertsSubsection
          isOpen={isCityAlertFiltersOpen}
          setIsOpen={setIsCityAlertFiltersOpen}
          change={change}
          name="cityAlertsSubFilters"
          filters={cityAlertsSubFilters}
          leftPosition="250px"
        />
      </form>
    ) : null;
  },
);
