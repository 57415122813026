import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { map, orderBy, size } from 'lodash-es';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useMemo, useState } from 'react';
import moment from 'moment';

import {
  ActionButtonTooltip,
  DatePicker,
  Icon,
  PanelSearch,
  PopoverWrapper,
  Switch,
  TypedField,
} from 'src/core/components';
import { createUrl } from 'src/utils/services/queryParams';
import {
  DashboardContainerFilterExpandButton,
  DashboardContainerItemsApart,
  DashboardFilterContainer,
  DashboardFilterIconWrapper,
  DashboardFilterPanel,
  DashboardFilterPanelContent,
  DashboardFiltersContainer,
  DashboardFilterSection,
  DashboardFilterSubtitle,
  DashboardFilterTitle,
  DashboardFilterTitleWrapper,
  DashboardListContainer,
} from '../styled/DashboardFilterMapbox';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { DashboardFilterVehicleItemMapbox } from './dashboardFilterFormSections';
import { FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { getExcludedFiltersIds, getExcludeVehicleFilters } from 'src/common/utils/filters';
import { getVehiclesListParams, loadActiveVehiclePositions } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { Button, Grid, GridColumn, Popover, Tab, TableActionButton, Tabs } from 'src/core/components/styled';
import { GroupsMultiSelect, RouteStatusesMultiSelect, SupervisorsMultiSelect } from 'src/routes/components';
import { InfoIcon } from 'src/routes/components/styled';
import { isDateValidValidator, isRequired } from 'src/utils/services/validator';
import { loadVehiclesList } from 'src/dashboard/ducks';
import { MAP_CITY_ZOOM_IN, TODAY, TODAY_FORMATTED } from 'src/core/constants';
import { ROUTE } from 'src/fleet/constants';
import { setDashboardViewport } from 'src/dashboard/ducks/mapControls';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehicleTypeForVendorMultiSelect } from 'src/fleet/components';
import { vendorGusIdSelector } from 'src/account/ducks';
import AdvancedSearchModal from '../modals/AdvancedSearchModal';
import DashboardFilterRouteItemMapbox from './dashboardFilterFormSections/DashboardFilterRouteItemMapbox';
import translate from 'src/core/services/translate';
import { useMemoizedDashboardFilters } from 'src/dashboard/hooks/useMemoizedDashboardFilters';

interface PropsWithoutReduxForm {
  isCollapsed?: boolean;
  isSubMenuHidden: boolean;
  onSelectRoute: (
    routeId: number,
    isSourceAdvancedSearch?: boolean,
    routeLocationId?: number,
    date?: Date | string,
    isSnowPlowOrStreetSweeperRoute?: boolean,
  ) => void;
  onSelectVehicle: (vehicleId: number, isSnowOrSweeper?: boolean) => void;
  setIsAutoRefresh: (isAutoRefresh: boolean) => void;
  vendorId: number;
}

export interface MapboxFiltersFormValues {
  activeTab: FILTER_TABS;
  allCityInsightTypes: boolean;
  date: Date | string;
  includeInactive: boolean;
  routeStatusTypeIds: number[];
  searchedVehicleId?: number;
  searchTerm: string;
  supervisorIds: number[];
  vehicleTypeIds: number[];
  groupIds: number[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<MapboxFiltersFormValues, PropsWithoutReduxForm>;

export enum FILTER_TABS {
  'vehicles' = 'vehicles',
  'routes' = 'routes',
}

const DashboardMapboxFiltersForm = ({
  isCollapsed,
  change,
  handleSubmit,
  onSelectRoute,
  onSelectVehicle,
  setIsAutoRefresh,
  vendorId,
}: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const formValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;

  const [persistentFilters] = useState<any>({});
  const [searchedDateValue] = useState<any>(null);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [isAdvancedSearchOpened, setIsAdvancedSearchOpened] = useState<boolean>(false);
  const [isSelectedDateToday, setIsSelectedDateToday] = useState<boolean>(
    (TODAY_FORMATTED as Date | string) === (formValues?.date as Date | string),
  );

  const isVendorWithGusId = useSelector(state => vendorGusIdSelector(state.account.login, state.vendors.defaultVendor));

  const supervisors = useSelector(state => state.routes.supervisors.supervisors);
  const { vehiclesList, isLoading: isLoadingVehiclesList } = useSelector(state => state.dashboard.vehiclesData);
  const { routesList, isLoadingRoutesList } = useSelector(state => state.dashboard.routesData);
  const { latitude: vendorAddressLatitude, longitude: vendorAddressLongitude } = useSelector(
    state => state.vendors?.vendor?.vendor?.homeAddress,
  );

  const filterPreferences = useSelector(state => state.common.filters.filters);
  const excludeVehicleFilters = getExcludeVehicleFilters(filterPreferences);
  const excludedSupervisorsFiltersIds = getExcludedFiltersIds(supervisors, filterPreferences, FILTER_SUPERVISOR_ID);

  const reapplyLastFilters = useMemoizedDashboardFilters('allOps-lastDashboardFilters', DASHBOARD_FILTER_FORM_MAPBOX);

  const onDateSelected = (event: any, date?: Date | string) => {
    const isCorrectDate = moment(date, 'MM/DD/YYYY', true).isValid();
    const correctDate = moment(date).format('MM/DD/YYYY');

    if (isCorrectDate) {
      const isToday = (TODAY_FORMATTED as Date | string) === (correctDate as Date | string);
      setIsSelectedDateToday(isToday);
      if (!isToday) dispatch(change('includeInactive', true));

      dispatch(
        push(
          createUrl(location.pathname, location.search, {
            date: correctDate,
          }),
        ),
      );
    }
  };

  const activeTab = useMemo(() => {
    return formValues?.activeTab || persistentFilters?.activeTab || FILTER_TABS.vehicles;
  }, [formValues?.activeTab, persistentFilters?.activeTab]);

  const setActiveTabVehicles = () => {
    activeTab !== FILTER_TABS.vehicles && dispatch(change('activeTab', FILTER_TABS.vehicles));

    const vehiclesListParams = getVehiclesListParams(formValues);

    loadVehiclesList(
      vehiclesListParams.date,
      vehiclesListParams.includeInactive,
      vehiclesListParams.vehicleTypeIds.toString(),
      vehiclesListParams.supervisorIds.toString(),
      vehiclesListParams.searchTerm,
    )(dispatch).then((response: any) => {
      setIsAutoRefresh(false);
      loadActiveVehiclePositions(response.vehiclesList, vendorId, vehiclesListParams.date, dispatch);
    });
  };

  const setActiveTabRoutes = () => {
    activeTab !== FILTER_TABS.routes && dispatch(change('activeTab', FILTER_TABS.routes));
    setIsAutoRefresh(false);

    vendorAddressLatitude &&
      vendorAddressLongitude &&
      dispatch(
        setDashboardViewport({
          latitude: vendorAddressLatitude,
          longitude: vendorAddressLongitude,
          zoom: MAP_CITY_ZOOM_IN,
        }),
      );
  };

  return (
    <form onSubmit={handleSubmit}>
      <DashboardFilterContainer isOpen={!isCollapsed} isLarger={!isVendorWithGusId}>
        <DashboardFilterPanel>
          <DashboardFilterPanelContent>
            <DashboardFilterSection>
              <DashboardFilterTitleWrapper>
                <DashboardFilterTitle>{translate('dashboard.filters')} </DashboardFilterTitle>
                <Button onClick={reapplyLastFilters} color="primary" text>
                  {translate('dashboard.applyLastFilters')}
                </Button>
              </DashboardFilterTitleWrapper>

              <DashboardFilterSubtitle>{translate('dashboard.filtersWillBeApplied')}</DashboardFilterSubtitle>
              <DashboardFiltersContainer>
                <TypedField
                  name="date"
                  component={DatePicker as any}
                  props={{
                    placeholder: translate('common.date'),
                    disabledDays: [{ after: TODAY }],
                    inputDefaultValue: searchedDateValue,
                    id: 'dashboard-filter-date',
                    width: '250px',
                    margin: 'no',
                    errorOnSingleLine: true,
                  }}
                  validate={[isRequired, isDateValidValidator]}
                  onChange={onDateSelected}
                />
                <Tabs fullWidth margin="no no no medium">
                  <Tab
                    flexDisplay
                    isSelected={activeTab === FILTER_TABS.vehicles}
                    onClick={() => setActiveTabVehicles()}
                    small
                  >
                    {translate('common.vehicles')}
                  </Tab>
                  <Tab
                    flexDisplay
                    isSelected={activeTab === FILTER_TABS.routes}
                    onClick={() => setActiveTabRoutes()}
                    small
                  >
                    {translate('common.routes')}
                  </Tab>
                </Tabs>
              </DashboardFiltersContainer>
              <DashboardContainerItemsApart>
                <TypedField
                  name="searchTerm"
                  component={PanelSearch}
                  props={{ margin: 'small no small no', borderBottom: true, minWidth: '250px', isClearable: true }}
                />
                <DashboardFilterIconWrapper>
                  <TableActionButton onClick={() => setIsAdvancedSearchOpened(true)}>
                    <ActionButtonTooltip icon="search" tooltip="advancedSearch" />
                  </TableActionButton>
                </DashboardFilterIconWrapper>
                <DashboardContainerFilterExpandButton onClick={() => setIsFilterExpanded(!isFilterExpanded)}>
                  <Icon
                    icon={`${isFilterExpanded ? 'minus' : 'plus'}`}
                    customViewBox="0 0 32 32"
                    width="30px"
                    height="30px"
                  />
                </DashboardContainerFilterExpandButton>
              </DashboardContainerItemsApart>
            </DashboardFilterSection>

            {isFilterExpanded && (
              <>
                <DashboardFilterSection>
                  <Grid margin="no" multiLine>
                    <GridColumn size={activeTab === FILTER_TABS.vehicles ? '10/12' : '6/12'} padding="no">
                      <TypedField
                        name="vehicleTypeIds"
                        component={VehicleTypeForVendorMultiSelect}
                        props={{
                          vehicleRoleTypeId: activeTab === FILTER_TABS.vehicles ? undefined : ROUTE,
                          multiSelectProps: { margin: 'no' },
                          placeholder: translate('vehicles.allVehicleTypes'),
                          withTechnicalName: false,
                          excludeVehicleTypes: excludeVehicleFilters,
                        }}
                      />
                    </GridColumn>
                    {activeTab === FILTER_TABS.routes && (
                      <GridColumn size="6/12" padding="no no no">
                        <TypedField
                          name="routeStatusTypeIds"
                          component={RouteStatusesMultiSelect}
                          props={{
                            multiSelectProps: { margin: 'no no no small', fitContentWidth: true },
                            placeholder: translate('routes.allRouteStatuses'),
                          }}
                        />
                      </GridColumn>
                    )}
                  </Grid>
                </DashboardFilterSection>

                {activeTab === FILTER_TABS.routes && (
                  <DashboardFilterSection>
                    <Grid margin="no no small no" multiLine>
                      <GridColumn size="12/12" padding="no">
                        <TypedField
                          name="supervisorIds"
                          component={SupervisorsMultiSelect}
                          props={{
                            multiSelectProps: {
                              margin: 'xSmall no no no',
                              normalizeValues: Number,
                            },
                            withOptionNone: true,
                            placeholder: translate('routes.allSupervisors'),
                            excludeSupervisorsIds: excludedSupervisorsFiltersIds,
                          }}
                        />
                      </GridColumn>
                      <GridColumn size="12/12" padding="no">
                        <TypedField
                          name="groupIds"
                          component={GroupsMultiSelect}
                          props={{
                            withPlaceholder: true,
                            includeNoneOption: true,
                            multiSelectProps: {
                              normalizeValues: Number,
                              canCheckAll: true,
                              margin: 'xSmall no no no',
                            },
                          }}
                        />
                      </GridColumn>
                    </Grid>
                  </DashboardFilterSection>
                )}
              </>
            )}
            {isAdvancedSearchOpened && (
              <AdvancedSearchModal onClose={() => setIsAdvancedSearchOpened(false)} onSelectRoute={onSelectRoute} />
            )}

            {activeTab === FILTER_TABS.vehicles && (
              <DashboardFilterSection flex>
                <TypedField
                  name="includeInactive"
                  component={Switch}
                  props={{
                    label: translate('vehicles.showInactiveVehicles'),
                    margin: 'small no',
                    disabled: !isSelectedDateToday,
                  }}
                />

                <PopoverWrapper
                  margin="no no no small"
                  triggerButton={<InfoIcon />}
                  popoverContent={<Popover>{translate('vehicles.inactiveVehiclesMessage')}</Popover>}
                  size="large"
                />
              </DashboardFilterSection>
            )}

            {/* ============================
             *       VEHICLES LIST
             * ============================ */}
            {activeTab === FILTER_TABS.vehicles && (
              <DashboardListContainer isLoading={isLoadingVehiclesList} isFilterExpanded={isFilterExpanded}>
                {size(vehiclesList)
                  ? map(orderBy(vehiclesList, 'isActive', 'desc'), vehicle => (
                      <DashboardFilterVehicleItemMapbox
                        key={vehicle.id}
                        vehicle={vehicle}
                        onSelectVehicle={onSelectVehicle}
                      />
                    ))
                  : translate('vehicles.noVehicles')}
              </DashboardListContainer>
            )}

            {/* ============================
             *       ROUTES LIST
             * ============================ */}
            {activeTab === FILTER_TABS.routes && (
              <DashboardListContainer isLoading={isLoadingRoutesList} isForRoutes isFilterExpanded={isFilterExpanded}>
                {size(routesList)
                  ? map(orderBy(routesList, 'statusType.id', 'desc'), route => (
                      <DashboardFilterRouteItemMapbox key={route.id} route={route} onSelectRoute={onSelectRoute} />
                    ))
                  : translate('routes.noRoutes')}
              </DashboardListContainer>
            )}
          </DashboardFilterPanelContent>
        </DashboardFilterPanel>
      </DashboardFilterContainer>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: DASHBOARD_FILTER_FORM_MAPBOX,
  onSubmit: () => {},
})(DashboardMapboxFiltersForm);
