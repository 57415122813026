import { map } from 'lodash-es';

import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';
import { http } from 'src/core/services/http';
import { RateCodes, RateConfigurationFormValuesToSubmit } from '../interfaces/RateManager';
import { RateConfigurationAddRateFormValues } from '../components/forms/RateConfigurationAddRateForm';
import translate from 'src/core/services/translate';

const transformLoadRateCodes = (rateCodes: RateCodes[]) =>
  map(rateCodes, rateCode => ({
    ...rateCode,
  }));

export const loadRateCodes = () =>
  http.get('/rateCodes/full', {}).then(response => transformLoadRateCodes(response.data));

export const saveRateCode = (formData: RateConfigurationAddRateFormValues) =>
  http.post('/rateCodes/add', { ...formData }).then(response => response.data);

export const saveRateCodes = (formData: RateConfigurationFormValuesToSubmit[], serviceTypeId?: number) =>
  http.post(`/rateCodes/update/${serviceTypeId}`, formData).then(response => response.data);

export const saveRateAccountingCode = (formData: RateConfigurationAddRateFormValues) =>
  http.post('/rateAccountingCodes', { ...formData }).then(response => response.data);

export const loadBillingUnitOfMeasureTypes = () =>
  http.get('/common/systemTypes/unitOfMeasureType/billing', {}).then(response => response.data);

export const loadRateAccountingCodes = () => http.get('/rateAccountingCodes', {}).then(response => response.data);

export const exportRateConfiguration = (serviceTypeId?: number, searchText?: string, enabled?: boolean) =>
  downloadExcelFile('rateCodes/export', `${translate('finance.rateManager.rateConfiguration')}`, {
    serviceTypeId,
    searchText,
    enabled,
  });
