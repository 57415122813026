import { RouteComponentProps, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import {
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
  isCityAlertFeatureEnabled,
  isNavi3FeatureEnabled,
  isTravelPathNavigationFeatureEnabled,
} from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadEquipmentSizes, loadLocationFlagTypes, loadWasteTypes } from 'src/common/ducks';
import {
  loadGeoFenceFilterOptions,
  loadRoute,
  loadRouteLocationDetails,
  loadRouteLocationIssues,
  loadRouteMapCityInsights,
  loadRouteMapFilters,
  loadRouteMapVehicleData,
  loadRouteMapVehicleInsights,
  loadRouteSequenceHistory,
  loadSnowSweeperRouteSummary,
  loadTravelPathStatusDetails,
  resetRouteSegments,
} from 'src/routes/ducks';
import { loadRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import {
  loadCityAlertSettings,
  loadRouteSequenceSettings,
  loadSnowPlowSettings,
  loadStreetSweepingSettings,
  loadVendor,
} from 'src/vendors/ducks';
import { loadStreetNetwork, resetSnowOrSweeperStreetNetwork } from 'src/customers/ducks';
import { loadVehicleTypes } from 'src/fleet/ducks';
import { PageLoading } from 'src/common/components/styled';
import { removePersistentMapFilters } from 'src/routes/services/persistentMapFilterSettingStorage';
import { SEQUENCE_SOURCE_TYPE_DAILY_ROUTE } from 'src/routes/constants';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import SnowOrSweeperRoutePage from './SnowOrSweeperRoutePage';
import store from 'src/store';
import translate from 'src/core/services/translate';

interface RouteParams {
  routeId?: string;
}

interface LocationState {
  createdDailyRoute?: boolean;
}

const SnowOrSweeperRoutePageResolver: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useParams<RouteParams>();

  const vendorId = useSelector(currentVendorId);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);
  const isCityAlertEnabled = useSelector(isCityAlertFeatureEnabled);
  const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings.cityAlertSettings);

  const { pathname } = history.location;
  const isSnowPlowRoute = pathname.includes('snow-tracker');
  const isStreetSweeperRoute = pathname.includes('sweeper-tracker');

  const routeId = Number(params.routeId);
  const locationState = location.state as LocationState;

  const urlParams = getQueryParams(location.search);
  const shouldOpenImagesModal = urlParams?.focus === 'images';
  const routeLocationId = urlParams?.routeLocationId ? Number(urlParams.routeLocationId) : undefined;

  useEffect(() => {
    removePersistentMapFilters();
  }, []);

  const loadDependencies = async () => {
    const dependencies = [];
    if (vendorId && routeId) {
      dispatch(resetRouteSegments());
      dispatch(resetSnowOrSweeperStreetNetwork());

      const { routeDate } = await loadRoute(routeId)(dispatch);

      dependencies.push(
        loadRouteSequenceHistory(routeId, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE)(dispatch),
        loadRouteSequenceSettings(vendorId)(dispatch),
        loadEquipmentSizes()(dispatch),
        loadWasteTypes()(dispatch),
        loadRouteMapVehicleData(vendorId, routeId)(dispatch),
        loadRouteMapFilters(vendorId, routeId, routeDate, routeDate)(dispatch),
        loadRouteMapVehicleInsights(vendorId, routeId)(dispatch),
        loadRouteMapCityInsights(vendorId, routeId)(dispatch),
        loadRouteGeoFence(routeId)(dispatch),
        loadVehicleTypes()(dispatch),
        loadLocationFlagTypes()(dispatch),
        loadGeoFenceFilterOptions({ vendorId, routeId, routeDate })(dispatch),
        loadVendor(vendorId)(dispatch),
      );

      const { routeSegmentCount } = await loadSnowSweeperRouteSummary(
        vendorId,
        routeId,
        routeDate,
        routeDate,
      )(dispatch);

      if (isCityAlertEnabled && !cityAlertSettings?.cityAlertTypes?.length && routeId) {
        dependencies.push(loadCityAlertSettings(vendorId)(dispatch));
      }

      if (routeSegmentCount && routeSegmentCount >= 2 && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled))
        dependencies.push(loadTravelPathStatusDetails(routeId)(dispatch));

      const isSnowPlowFeatureEnabled = checkIfSnowPlowIsEnabled(store.getState());
      if (isSnowPlowFeatureEnabled && isSnowPlowRoute) dependencies.push(loadSnowPlowSettings(vendorId)(dispatch));

      const isStreetSweeperFeatureEnabled = checkIfStreetSweepingIsEnabled(store.getState());
      if (isStreetSweeperFeatureEnabled && isStreetSweeperRoute)
        dependencies.push(loadStreetSweepingSettings(vendorId)(dispatch));

      // if the route was just created, has no segments,then load all the segments
      if (locationState && locationState?.createdDailyRoute) {
        // reset the state
        window.history.pushState({ createdDailyRoute: false }, '');
        const routeTemplateId = undefined;
        const includeRouteTemplateIds = undefined;
        const streetSegmentAssignedStatus = undefined;

        dependencies.push(
          loadStreetNetwork({
            vendorId,
            routeId,
            routeTemplateId,
            includeRouteTemplateIds,
            streetSegmentAssignedStatus,
          })(dispatch),
        );
      }

      if (routeLocationId)
        dependencies.push([
          loadRouteLocationIssues(routeId, routeLocationId)(dispatch),
          loadRouteLocationDetails(vendorId, routeId, routeLocationId)(dispatch),
        ]);
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.routeDetails')}</DocumentTitle>

      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/customers/service-network"
        resolve={loadDependencies}
        successComponent={SnowOrSweeperRoutePage}
        successProps={{ routeLocationId, shouldOpenImagesModal, isSnowPlowRoute, isStreetSweeperRoute }}
      />
    </>
  );
};

export default SnowOrSweeperRoutePageResolver;
