import { round } from 'lodash-es';

import { Box } from 'src/core/components/styled/Box';
import { DashboardContainerItemsApart, DashboardListItemContainer } from 'src/dashboard/components/styled/DashboardFilterMapbox';
import { LABEL_COLORS, ROUTE_STATUSES_BY_ID } from 'src/routes/constants';
import { Label, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { DispatchBoardSimpleRoute } from 'src/routes/interfaces/DispatchBoardRoute';

interface Props {
  onSelectRoute: (routeId: number) => void;
  route: DispatchBoardSimpleRoute;
  isActive: boolean;
}

const DispatchFilterRouteItemMapbox = ({ onSelectRoute, route, isActive }: Props) => {
  return (
    <DashboardListItemContainer
      onClick={() => onSelectRoute(route.id)}
      key={`${route.id}_${route.name}`}
      isActive={isActive}
    >
      <DashboardContainerItemsApart>
        <Text weight="medium" singleLine title={route.name}>
          {route.name}
        </Text>
        {route.id > -1 ? (
          <Box display="inline-flex">
            <Label color={LABEL_COLORS[route.statusId]}>
              {`${ROUTE_STATUSES_BY_ID[route.statusId]?.name} - ${round((route.numberOfCompletedJobs / route.totalNumberOfJobs) * 100).toFixed(0)}%`}
            </Label>
          </Box>
        ) : null }
      </DashboardContainerItemsApart>
      <DashboardContainerItemsApart>
        {route.id > -1 ? (
          <>
            <Text margin="xxSmall no no">{route.driverName}</Text>
            <Text margin="xxSmall no no">
              {`${route.numberOfCompletedJobs} / ${route.totalNumberOfJobs} ${translate('routes.stops')}`}
            </Text>
          </>
        ) : (
          <Text margin="xxSmall no no">
            {route.totalNumberOfJobs} {translate('routes.stops')}
          </Text>
        )}
      </DashboardContainerItemsApart>
    </DashboardListItemContainer>
  );
};

export default DispatchFilterRouteItemMapbox;
