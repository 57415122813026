import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { BareButtonStyle } from 'src/core/components/styled';
import { loadingOverlay } from 'src/core/styles';
import { colorMapper } from 'src/utils/styles';

interface Props {
  alignItems?: string;
  hasBorderRight?: boolean;
  height?: string;
  isCursorPointer?: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
  width?: string;
  lineHeight?: string;
  color?: string;
  verticalAlign?: string;
}

export const ContainerStatisticsWrapper = styled.div<Props>`
  display: block;
  width: 101%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};

  &::after,
  &::before {
    content: '';
    display: flex;
    width: 50px;
    height: calc(100% - 30px);
    position: absolute;
    z-index: 99;
  }
  &::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 40%, #fff 70%);
    margin-left: auto;
    top: 0;
    right: 0;
  }
  &::before {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 40%, #fff 70%);
    margin-right: auto;
    top: 0;
    left: 0;
  }
`;

export const ContainerStatisticsTable = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.isExpanded &&
    css`
      width: 100%;
      ${ContainerStatisticsElement} {
        width: auto:  !important;;
      }
    `};
`;

export const ContainerStatisticsTableBody = styled.div<Props>`
  height: 90px;
  overflow: hidden;
  color: ${props => props.theme.grayDark};

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;
export const ContainerStatisticsTableBodyContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${props => props.theme.grayDark};

  ${props =>
    props.isExpanded &&
    css`
      flex-direction: row;
      width: auto;
      ${ContainerStatisticsElement} {
        width: auto:  !important;
      }
      ${ContainerStatisticsTableBody} {
        width: ${props.width || '140px'};
        margin: 0 15px 0 0;

        &:last-child {
          margin-right: 0;
        }
      }
    `};
`;

export const ContainerStatisticsTableCell = styled.div<Props>`
  padding: 5px 0;
  line-height: 15px;

  ${props =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};
`;

export const ContainerStatisticsTableHead = styled.div`
  color: ${props => props.theme.black};
  font-weight: bold;
`;

export const ContainerStatisticsTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  font-size: 15px;
`;

export const ContainerStatisticsTableFeaturedValue = styled.span`
  color: ${props => props.theme.black};
  font-size: 26px;
`;

export const ContainerStatisticsTableValue = styled.span`
  color: ${props => props.theme.black};
`;

export const ContainerStatisticsTableToggle = styled.button`
  ${BareButtonStyle};
  text-transform: capitalize;
  font-size: 14px;
  color: ${props => props.theme.brandPrimary};
  margin: 0 0 0 10px;

  &:hover {
    color: ${props => darken(0.05, props.theme.brandPrimary)};
    cursor: pointer;
  }
`;

export const ContainerStatisticsElement = styled.div<Props>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  min-width: 25%;
  vertical-align: top;

  ${props =>
    props.hasBorderRight &&
    css`
      border-right: 2px solid ${props => props.theme.grayLight};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};

  ${props =>
    props.verticalAlign &&
    css`
      vertical-align: ${props.verticalAlign};
    `};
`;

export const ContainerStatisticsTotalValue = styled.div`
  color: ${props => props.theme.black};
`;
