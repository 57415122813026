import { filter, find, forEach, replace, startsWith, uniqBy } from 'lodash-es';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { getFormValues } from 'redux-form';

import useDataPolling from 'src/common/hooks/useDataPolling';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { useSelector } from 'src/core/hooks/useSelector';
import { multiWordAndSearch } from 'src/core/services/search';
import translate from 'src/core/services/translate';
import { RouteSegmentExtended } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { getSegmentsToShow } from 'src/customers/components/pages/streetNetwork/utils';
import { setCheckedDashboardStreetNetwork } from 'src/customers/ducks/streetNetwork';
import { VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { loadGeoFenceFilterOptions, loadGeoFences, resetGeoFences } from 'src/routes/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { dateFormat } from 'src/utils/services/validator';
import { IMPERIAL } from 'src/vendors/constants';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { MapboxFiltersFormValues } from '../components/forms/DashboardMapboxFiltersForm';
import {
  DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME,
  DashboardMapboxRouteDetailsFormValues,
} from '../components/forms/DashboardMapboxRouteDetailsForm';
import {
  DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM,
  DashboardMapboxVehicleDetailsFormValues,
} from '../components/forms/DashboardMapboxVehicleDetailsForm';
import {
  DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME,
  DashboardExtraMapLayersFormValues,
} from '../components/pages/dashboardPageSections/dashboardWidgets/ExtraMapLayersWidgetContent';
import { DASHBOARD_FILTER_FORM_MAPBOX } from '../constants/dashboardFilter';
import {
  loadFleetInsights,
  loadMapInsightsMapbox,
  loadRouteGeoFenceIncidents,
  loadRouteVehiclePositions,
  loadRouteVehiclesBreadCrumbs,
  loadRoutesList,
  loadRoutesVehiclePositions,
  loadVehiclePositions,
  loadVehiclesList,
  resetActiveVehiclePositions,
  resetGeoFenceIncidents,
  resetMapInsightsMapbox,
  resetRouteVehiclePositions,
  resetRouteVehiclesBreadCrumbs,
  resetRoutesData,
  resetVehiclePositions,
  setMapRouteStops,
  setShowRouteVehiclesAppStatus,
  setShowVehicleAppStatus,
  setVendorLocationsToDisplay,
} from '../ducks';
import { RouteStopItem, RoutesListItem } from '../interfaces/routesData';
import { loadCityAlerts, resetCityAlerts } from 'src/vendors/ducks';

export const getMeasurementType = (systemOfMeasurementId?: number) =>
  systemOfMeasurementId && translate(`common.speedUnit.${systemOfMeasurementId === IMPERIAL ? 'MPH' : 'KPH'}`);

export const getVehiclesListParams = (formValues?: MapboxFiltersFormValues) => {
  const date = formValues?.date ? moment(formValues.date).format('YYYY-MM-DD') : null;
  const includeInactive = formValues?.includeInactive ? formValues.includeInactive : false;
  const vehicleTypeIds = formValues?.vehicleTypeIds ? formValues.vehicleTypeIds : [];
  const supervisorIds = [] as number[];
  const searchTerm = formValues?.searchTerm ? formValues.searchTerm : '';

  const correctDate = moment(moment(date).format('MM/DD/YYYY')).isValid()
    ? moment(date).format('MM/DD/YYYY')
    : moment().format('MM/DD/YYYY');

  return {
    date: correctDate,
    includeInactive,
    vehicleTypeIds,
    supervisorIds,
    searchTerm,
  };
};

export const loadActiveVehiclePositions = (
  vehiclesList: VehiclePosition[],
  vendorId: number,
  date: Date | string,
  dispatch: Dispatch,
) => {
  const activeVehiclesList = vehiclesList?.filter((vehicle: VehiclePosition) => vehicle.isActive) || [];
  const vehicleIds =
    (activeVehiclesList.length && activeVehiclesList.map((vehicle: VehiclePosition) => vehicle.id)) || [];
  vehicleIds.length
    ? loadVehiclePositions(vendorId, date, vehicleIds)(dispatch)
    : dispatch(resetActiveVehiclePositions());
};

export const getRoutesListParams = (formValues?: MapboxFiltersFormValues) => {
  const date = formValues?.date ? moment(formValues.date).format('YYYY-MM-DD') : null;
  const vehicleTypeIds = formValues?.vehicleTypeIds ? formValues.vehicleTypeIds : [];
  const supervisorIds = formValues?.supervisorIds ? formValues.supervisorIds : [];
  const groupIds = formValues?.groupIds ? formValues.groupIds : [];
  const routeStatusTypeIds = formValues?.routeStatusTypeIds ? formValues.routeStatusTypeIds : [];
  const searchTerm = formValues?.searchTerm ? formValues.searchTerm : '';

  const correctDate = moment(moment(date).format('MM/DD/YYYY')).isValid()
    ? moment(date).format('MM/DD/YYYY')
    : moment().format('MM/DD/YYYY');

  return {
    date: correctDate,
    vehicleTypeIds: vehicleTypeIds.join(','),
    supervisorIds: supervisorIds.join(','),
    routeStatusTypeIds: routeStatusTypeIds.join(','),
    groupIds: groupIds.join(','),
    searchTerm,
  };
};

const useLoadDataForMapboxDashboard = (
  selectedRouteId?: number,
  setIsAutoRefresh?: (isAutoRefresh: boolean) => void,
) => {
  const vendorId = useSelector(currentVendorId);

  const { routeStops, routesList } = useSelector(state => state.dashboard.routesData);
  const headerInfo = useMemo(() => {
    const route = find(routesList, { id: selectedRouteId });
    return route;
  }, [routesList, selectedRouteId]) as RoutesListItem;
  const isSnowPlowRoute = headerInfo?.vehicleType?.id === SNOW_PLOW_ID;
  const isStreetSweeperRoute = headerInfo?.vehicleType?.id === STREET_SWEEPER_ID;

  const haulerLocations = useSelector(state => state.dashboard.vendorLocations.vendorLocationsForMapbox);
  const formValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;

  const { streetNetwork } = useSelector(state => state.customers.streetNetwork);
  const { routeSegments: segmentsList } = useSelector(state => state.routes.routeSegments);
  const { segmentList } = useSelector(state => state.routes.snowPlowSegmentList);

  const routeDetailsFormValues = useSelector(
    getFormValues(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME),
  ) as DashboardMapboxRouteDetailsFormValues;

  const vehicleDetailsFormValues = useSelector(
    getFormValues(DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM),
  ) as DashboardMapboxVehicleDetailsFormValues;

  const extraMapLayersFormValues = useSelector(
    getFormValues(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME),
  ) as DashboardExtraMapLayersFormValues;

  const dispatch = useDispatch();

  const activeTab = useMemo(() => {
    return formValues?.activeTab ? formValues.activeTab : 'vehicles';
  }, [formValues?.activeTab]);

  const vehiclesListParams = useMemo(() => {
    return getVehiclesListParams(formValues);
  }, [formValues]);

  const routesListParams = useMemo(() => {
    return getRoutesListParams(formValues);
  }, [formValues]);

  const [currentSearchTerm, setCurrentSearchTerm] = useState(vehiclesListParams.searchTerm);
  const [currentDate, setCurrentDate] = useState(vehiclesListParams.date);
  const [currentVehicleTypeIds, setCurrentVehicleTypeIds] = useState(vehiclesListParams.vehicleTypeIds);
  const [currentSupervisorIds, setCurrentSupervisorIds] = useState(vehiclesListParams.supervisorIds);
  const [currentIncludeInactive, setCurrentIncludeInactive] = useState(vehiclesListParams.includeInactive);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const getIsFormSearchNotChanged = () => {
    return (
      currentSearchTerm === vehiclesListParams.searchTerm &&
      currentDate === vehiclesListParams.date &&
      currentVehicleTypeIds.toString() === vehiclesListParams.vehicleTypeIds.toString() &&
      currentSupervisorIds.toString() === vehiclesListParams.supervisorIds.toString() &&
      currentIncludeInactive === vehiclesListParams.includeInactive
    );
  };

  const setVehiclesListParams = () => {
    setCurrentSearchTerm(vehiclesListParams.searchTerm);
    setCurrentDate(vehiclesListParams.date);
    setCurrentVehicleTypeIds(vehiclesListParams.vehicleTypeIds);
    setCurrentSupervisorIds(vehiclesListParams.supervisorIds);
    setCurrentIncludeInactive(vehiclesListParams.includeInactive);
    setIsFirstLoad(false);
  };

  const autoRefreshIntervalInMs = 60000;

  // KEEP TRACK OF FIRST RENDER
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  /* ===========================================================================
  *        FILTERS OPTIONS,  GEO FENCES, CITY INSIGHTS, HAULER LOCATIONS
    ============================================================================ */

  // GeoFences options and Supervisors are filtered by date
  useEffect(() => {
    if (firstRender.current || !vendorId) return;

    const date = formValues?.date ? moment(formValues.date).format(dateFormat) : moment().format(dateFormat);
    loadGeoFenceFilterOptions({ vendorId, routeDate: date })(dispatch);
    loadSupervisors(vendorId, date)(dispatch);
    formValues?.date && loadFleetInsights(vendorId, formValues?.date, 10, undefined)(dispatch);
  }, [dispatch, formValues?.date, vendorId]);

  // geoFences
  useEffect(() => {
    if (firstRender.current || !vendorId || !extraMapLayersFormValues?.geoFences?.groups) return;
    const geoIds: number[] = [];
    forEach(extraMapLayersFormValues?.geoFences?.groups, (group: { groupFilters: any }) => {
      forEach(group.groupFilters, (value, key) => {
        if (value === true && startsWith(key, 'geoId_')) {
          const geoId = replace(key, 'geoId_', '');
          const geoIdNum = parseInt(geoId);
          if (!isNaN(geoIdNum)) {
            geoIds.push(geoIdNum);
          }
        }
      });
    });
    if (geoIds.length) {
      const geoFenceZoneTypeIds = undefined;
      const geoFenceName = undefined;
      const page = 1;
      const limit = 200;
      const sortOrder = undefined;
      const sortedBy = undefined;
      const routeDate = undefined;
      loadGeoFences({
        vendorId,
        geoFenceZoneTypeIds,
        geoFenceName,
        page,
        limit,
        sortOrder,
        sortedBy,
        geoFenceIdsCSV: geoIds.join(','),
        routeDate,
      })(dispatch);
    } else {
      dispatch(resetGeoFences());
    }
  }, [dispatch, extraMapLayersFormValues?.geoFences?.groups, vendorId]);

  // hauler locations
  useEffect(() => {
    if (firstRender.current || !vendorId || !extraMapLayersFormValues?.haulerLocations?.groups) return;
    const locationSubTypeIds: number[] = [];
    const locationIndividualIds: number[] = [];
    forEach(extraMapLayersFormValues?.haulerLocations?.groups, (group: { groupFilters: any }) => {
      forEach(group.groupFilters, (value, key) => {
        if (value === true && startsWith(key, 'locationSubTypeId_')) {
          const haulerId = replace(key, 'locationSubTypeId_', '');
          const haulerIdNum = parseInt(haulerId);
          if (!isNaN(haulerIdNum)) {
            locationSubTypeIds.push(haulerIdNum);
          }
        } else if (value.groupFilters) {
          forEach(value.groupFilters, (value, key) => {
            if (value === true && startsWith(key, 'locationId_')) {
              const haulerId = replace(key, 'locationId_', '');
              const haulerIdNum = parseInt(haulerId);
              if (!isNaN(haulerIdNum)) {
                locationIndividualIds.push(haulerIdNum);
              }
            }
          });
        }
      });
    });
    if (locationSubTypeIds.length || locationIndividualIds.length) {
      const filteredHaulerLocations = filter(haulerLocations, location => {
        return locationSubTypeIds.includes(location.customerSubTypeId) || locationIndividualIds.includes(location.id);
      });
      dispatch(setVendorLocationsToDisplay(filteredHaulerLocations));
    } else dispatch(setVendorLocationsToDisplay([]));
  }, [dispatch, extraMapLayersFormValues?.haulerLocations?.groups, haulerLocations, vendorId]);

  // city insights
  useEffect(() => {
    if (firstRender.current || !vendorId || !extraMapLayersFormValues?.cityInsights?.groups) return;
    const cityInsightTypes: string[] = [];
    forEach(extraMapLayersFormValues?.cityInsights?.groups, (group: { groupFilters: any }) => {
      forEach(group.groupFilters, (value, key) => {
        if (value === true) {
          cityInsightTypes.push(key);
        }
      });
    });
    if (cityInsightTypes.length) loadMapInsightsMapbox(vehiclesListParams.date, cityInsightTypes)(dispatch);
    else dispatch(resetMapInsightsMapbox());
  }, [dispatch, extraMapLayersFormValues?.cityInsights?.groups, vehiclesListParams.date, vendorId]);

  //city Alerts
  useEffect(() => {
    if (firstRender.current || !vendorId || !extraMapLayersFormValues?.cityAlerts?.groups) return;
    const cityAlertTypes: number[] = [];
    forEach(extraMapLayersFormValues?.cityAlerts?.groups, (group: { groupFilters: any }) => {
      forEach(group.groupFilters, (value, key) => {
        if (value === true) {
          const keyNum = Number(replace(key, 'alertType_', ''));
          cityAlertTypes.push(keyNum);
        }
      });
    });

    if (cityAlertTypes.length) loadCityAlerts(cityAlertTypes, selectedRouteId)(dispatch);
    else dispatch(resetCityAlerts());
  }, [dispatch, extraMapLayersFormValues?.cityAlerts?.groups, vendorId, selectedRouteId]);

  /* ========================================
  *          Vehicles  Screen
    ========================================= */

  // when switching tabs clear the other tab data
  useEffect(() => {
    if (activeTab === 'vehicles') {
      dispatch(resetRoutesData());
    } else {
      dispatch(resetVehiclePositions());
    }
  }, [activeTab, dispatch]);

  // vehicles
  useEffect(
    () => {
      if (
        firstRender.current ||
        activeTab !== 'vehicles' ||
        (currentSearchTerm !== vehiclesListParams.searchTerm &&
          (currentDate === vehiclesListParams.date ||
            currentVehicleTypeIds.toString() === vehiclesListParams.vehicleTypeIds.toString() ||
            currentSupervisorIds.toString() === vehiclesListParams.supervisorIds.toString() ||
            currentIncludeInactive === vehiclesListParams.includeInactive)) ||
        (getIsFormSearchNotChanged() && !isFirstLoad)
      )
        return;

      loadVehiclesList(
        vehiclesListParams.date,
        vehiclesListParams.includeInactive,
        vehiclesListParams.vehicleTypeIds.toString(),
        vehiclesListParams.supervisorIds.toString(),
        vehiclesListParams.searchTerm,
      )(dispatch).then((response: any) => {
        setVehiclesListParams();
        setIsAutoRefresh && setIsAutoRefresh(false);
        loadActiveVehiclePositions(response.vehiclesList, vendorId, vehiclesListParams.date, dispatch);
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeTab,
      vehiclesListParams.date,
      vehiclesListParams.includeInactive,
      vehiclesListParams.vehicleTypeIds,
      vehiclesListParams.supervisorIds,
    ],
  );

  // vehicles debounce for search
  useEffect(
    () => {
      if (
        firstRender.current ||
        activeTab !== 'vehicles' ||
        (currentSearchTerm === vehiclesListParams.searchTerm &&
          (currentDate !== vehiclesListParams.date ||
            currentVehicleTypeIds.toString() !== vehiclesListParams.vehicleTypeIds.toString() ||
            currentSupervisorIds.toString() !== vehiclesListParams.supervisorIds.toString() ||
            currentIncludeInactive !== vehiclesListParams.includeInactive)) ||
        getIsFormSearchNotChanged()
      )
        return;

      const timeout = setTimeout(() => {
        loadVehiclesList(
          vehiclesListParams.date,
          vehiclesListParams.includeInactive,
          vehiclesListParams.vehicleTypeIds.toString(),
          vehiclesListParams.supervisorIds.toString(),
          vehiclesListParams.searchTerm,
        )(dispatch).then((response: any) => {
          setVehiclesListParams();
          setIsAutoRefresh && setIsAutoRefresh(false);
          loadActiveVehiclePositions(response.vehiclesList, vendorId, vehiclesListParams.date, dispatch);
        });
      }, 470);
      return () => clearTimeout(timeout);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeTab,
      vehiclesListParams.date,
      vehiclesListParams.includeInactive,
      vehiclesListParams.vehicleTypeIds,
      vehiclesListParams.supervisorIds,
    ],
  );

  // vehicle Positions refresh every 60 seconds
  const refreshVehicles = useCallback(async () => {
    const noLoadingIndicator = true;
    // vehicle positions are refreshed only if date is today
    const isToday = moment(vehiclesListParams.date).isSame(moment(), 'day');

    if (getIsVendorNotChanged(vendorId) && activeTab !== 'routes' && isToday) {
      await loadVehiclesList(
        vehiclesListParams.date,
        vehiclesListParams.includeInactive,
        vehiclesListParams.vehicleTypeIds.toString(),
        vehiclesListParams.supervisorIds.toString(),
        vehiclesListParams.searchTerm,
        noLoadingIndicator,
      )(dispatch).then((response: any) => {
        setIsAutoRefresh && setIsAutoRefresh(true);
        loadActiveVehiclePositions(response.vehiclesList, vendorId, vehiclesListParams.date, dispatch);
      });
    }
  }, [
    activeTab,
    dispatch,
    vehiclesListParams.date,
    vehiclesListParams.includeInactive,
    vehiclesListParams.searchTerm,
    vehiclesListParams.supervisorIds,
    vehiclesListParams.vehicleTypeIds,
    vendorId,
    setIsAutoRefresh,
  ]);

  useDataPolling(refreshVehicles, autoRefreshIntervalInMs, Infinity);

  // vehicle application status
  useEffect(() => {
    if (firstRender.current) return;
    if (activeTab !== 'vehicles') return;

    dispatch(setShowVehicleAppStatus(vehicleDetailsFormValues?.showAppStatus));
  }, [activeTab, dispatch, vehicleDetailsFormValues?.showAppStatus]);

  /* ========================================
  *          ROUTES SCREEN
    ========================================= */

  // routes debounce for search
  useEffect(
    () => {
      if (firstRender.current || activeTab !== 'routes') return;
      const timeout = setTimeout(() => {
        loadRoutesList(routesListParams)(dispatch);
        loadRoutesVehiclePositions(routesListParams)(dispatch);
      }, 470);
      return () => clearTimeout(timeout);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [routesListParams.searchTerm],
  );

  // routes
  useEffect(
    () => {
      if (firstRender.current || activeTab !== 'routes') return;
      loadRoutesList(routesListParams)(dispatch);
      loadRoutesVehiclePositions(routesListParams)(dispatch);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeTab,
      routesListParams.date,
      routesListParams.vehicleTypeIds,
      routesListParams.supervisorIds,
      routesListParams.groupIds,
      routesListParams.routeStatusTypeIds,
    ],
  );

  // routeStops
  useEffect(() => {
    const timeout = setTimeout(() => {
      const searchTerm = routeDetailsFormValues?.routeStopsFilters?.searchTerm;
      const locationAlerts = routeDetailsFormValues?.routeStopsFilters?.locationAlert;
      const issueReported = routeDetailsFormValues?.routeStopsFilters?.issueReported;
      const pickupStatuses = routeDetailsFormValues?.routeStopsFilters?.pickupStatusIds || [];
      const jobPriorityTypeIds = routeDetailsFormValues?.routeStopsFilters?.jobPriorityTypeIds || [];

      const routeSegments = segmentList;
      const routeSegmentIds = [...routeSegments.map(segment => (segment as any).id)];
      const routeSegmentsWithStreetPasses = uniqBy(
        segmentsList.filter((el: RouteSegment) => routeSegmentIds.includes(el.streetSegmentId)),
        'streetSegmentId',
      );

      const segmentsToShow = getSegmentsToShow(
        routeSegments,
        streetNetwork,
        routeSegmentsWithStreetPasses,
        segmentsList,
        [],
        isSnowPlowRoute,
      );
      const filteredSegments = segmentsToShow;

      const filteredStops =
        isSnowPlowRoute || isStreetSweeperRoute
          ? filter(
              filteredSegments,
              (stop: RouteSegmentExtended) =>
                (!pickupStatuses || !pickupStatuses.length || pickupStatuses.includes(stop.statusId)) &&
                (!searchTerm ||
                  multiWordAndSearch(stop.customerName, searchTerm) ||
                  multiWordAndSearch(stop.name, searchTerm) ||
                  multiWordAndSearch(stop.streetName, searchTerm)),
            )
          : filter(
              routeStops,
              (stop: RouteStopItem) =>
                (!pickupStatuses || !pickupStatuses.length || pickupStatuses.includes(stop.pickupStatusTypeId)) &&
                (!jobPriorityTypeIds ||
                  !jobPriorityTypeIds.length ||
                  jobPriorityTypeIds.includes(stop.jobPriorityTypeId || 0)) &&
                ((locationAlerts && locationAlerts === stop.hasLocationAlert) || !locationAlerts) &&
                ((issueReported && issueReported === stop.hasIssueReported) || !issueReported) &&
                (!searchTerm ||
                  multiWordAndSearch(stop.customerName, searchTerm) ||
                  multiWordAndSearch(stop.locationName, searchTerm) ||
                  multiWordAndSearch(`${stop.locationName}, ${stop.streetNumber} ${stop.street}`, searchTerm) ||
                  multiWordAndSearch(stop.accountNumber, searchTerm) ||
                  multiWordAndSearch(stop.vendorAccountNo, searchTerm)),
            );

      const selectedStops = routeDetailsFormValues?.routeStopsFilters?.stops || {};
      const selectedStopIds = Object.keys(selectedStops).filter(key => selectedStops[key]);
      const selectedStopIdsWithoutPrefix = selectedStopIds.map(id => Number(id.replace('_', '')));

      const selectedStopsFiltered = filter(filteredStops, (stop: RouteStopItem) =>
        selectedStopIdsWithoutPrefix.includes(stop.id),
      );

      if (isSnowPlowRoute || isStreetSweeperRoute) {
        dispatch(setCheckedDashboardStreetNetwork(selectedStopsFiltered as any));
      } else {
        dispatch(setMapRouteStops(selectedStopsFiltered as any));
      }
    }, 250);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    routeDetailsFormValues?.routeStopsFilters,
    routeStops,
    isSnowPlowRoute,
    isStreetSweeperRoute,
    segmentList,
    segmentsList,
    streetNetwork,
  ]);

  // vehiclePositions on route details screen
  useEffect(() => {
    const vehicles = routeDetailsFormValues?.driversVehicles || {};
    const vehicleIdsChecked = Object.keys(vehicles).filter(key => vehicles[key]);
    const vehicleIds = vehicleIdsChecked.map(id => Number(id.replace('_', '')));

    if (vehicleIds.length)
      routeDetailsFormValues?.routeId &&
        loadRouteVehiclePositions(
          routeDetailsFormValues.routeId,
          routesListParams.date,
          vehicleIds.join(','),
        )(dispatch);
    else dispatch(resetRouteVehiclePositions());
  }, [dispatch, routeDetailsFormValues?.driversVehicles, routeDetailsFormValues?.routeId, routesListParams.date]);

  // vehiclePositions refresh every 60 seconds
  const refreshVehiclePositions = useCallback(async () => {
    const vehicles = routeDetailsFormValues?.driversVehicles || {};
    const vehicleIdsChecked = Object.keys(vehicles).filter(key => vehicles[key]);
    const vehicleIds = vehicleIdsChecked.map(id => Number(id.replace('_', '')));
    const noLoadingIndicator = true;

    // taking in consideration routes that run overnights
    const dateIsNoOlderThanTwoDays = moment().diff(moment(routesListParams.date), 'days') < 2;

    if (
      vehicleIds.length &&
      getIsVendorNotChanged(vendorId) &&
      routeDetailsFormValues?.routeId &&
      dateIsNoOlderThanTwoDays
    ) {
      await loadRouteVehiclePositions(
        routeDetailsFormValues.routeId,
        routesListParams.date,
        vehicleIds.join(','),
        noLoadingIndicator,
      )(dispatch);
    }
  }, [
    dispatch,
    routeDetailsFormValues?.driversVehicles,
    routeDetailsFormValues?.routeId,
    routesListParams.date,
    vendorId,
  ]);

  useDataPolling(refreshVehiclePositions, autoRefreshIntervalInMs, Infinity);

  // GeoFence Incidents
  useEffect(() => {
    const routeIncidents = routeDetailsFormValues?.incidents || {};
    const routeIncidentsChecked = Object.keys(routeIncidents).filter(
      key => routeIncidents[key as keyof typeof routeIncidents] === true,
    );
    if (routeIncidentsChecked.length)
      routeDetailsFormValues?.routeId &&
        loadRouteGeoFenceIncidents(
          routeDetailsFormValues.routeId,
          routesListParams.date,
          routeIncidentsChecked.join(','),
        )(dispatch);
    else dispatch(resetGeoFenceIncidents());
  }, [dispatch, routeDetailsFormValues?.incidents, routeDetailsFormValues?.routeId, routesListParams.date]);

  // VehiclesBreadCrumbs
  useEffect(() => {
    const vehicles = routeDetailsFormValues?.vehicleBreadCrumbs || {};
    const vehicleChecked = Object.keys(vehicles).filter(key => vehicles[key]);
    const vehicleIds = vehicleChecked.map(id => Number(id.replace('_', '')));
    if (vehicleIds.length) {
      const noLoadingIndicator = false;

      routeDetailsFormValues?.routeId &&
        loadRouteVehiclesBreadCrumbs(
          routeDetailsFormValues.routeId,
          routesListParams.date,
          vehicleIds.join(','),
          noLoadingIndicator,
        )(dispatch);
    } else dispatch(resetRouteVehiclesBreadCrumbs());
  }, [dispatch, routeDetailsFormValues?.vehicleBreadCrumbs, routeDetailsFormValues?.routeId, routesListParams.date]);

  // vehicleBreadcrumb refresh every 60 seconds
  const refreshVehicleBreadCrumbs = useCallback(async () => {
    const vehicles = routeDetailsFormValues?.vehicleBreadCrumbs || {};
    const vehicleChecked = Object.keys(vehicles).filter(key => vehicles[key]);
    const vehicleIds = vehicleChecked.map(id => Number(id.replace('_', '')));
    const noLoadingIndicator = true;
    // taking in consideration routes that run overnights
    const dateIsNoOlderThanTwoDays = moment().diff(moment(routesListParams.date), 'days') < 2;

    if (
      vehicleIds.length &&
      getIsVendorNotChanged(vendorId) &&
      routeDetailsFormValues?.routeId &&
      dateIsNoOlderThanTwoDays
    ) {
      loadRouteVehiclesBreadCrumbs(
        routeDetailsFormValues.routeId,
        routesListParams.date,
        vehicleIds.join(','),
        noLoadingIndicator,
      )(dispatch);
    }
  }, [
    dispatch,
    routeDetailsFormValues?.routeId,
    routeDetailsFormValues?.vehicleBreadCrumbs,
    routesListParams.date,
    vendorId,
  ]);

  useDataPolling(refreshVehicleBreadCrumbs, autoRefreshIntervalInMs, Infinity);

  // route vehicle application status
  useEffect(() => {
    //from the object, get the keys that are true and remove the prefix using lodash
    const vehicleIds: number[] = [];
    forEach(routeDetailsFormValues?.showAppStatus, (value, key) => {
      if (value) {
        const vehicleId = key.replace('_', '');
        vehicleIds.push(+vehicleId);
      }
    });
    dispatch(setShowRouteVehiclesAppStatus(vehicleIds));
  }, [dispatch, routeDetailsFormValues?.showAppStatus]);
};

export default useLoadDataForMapboxDashboard;
