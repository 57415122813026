import { FC } from 'react';

import { debounce } from 'lodash-es';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { PanelSearch, TypedField } from '../../../../../../core/components';
import translate from '../../../../../../core/services/translate';
import { DispatchBoardMapFilterContainer, DispatchBoardMapSearch } from 'src/routes/components/styled';

interface ComponentProps {
  searchJobs: (value: string) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const SearchJobsForm: FC<Props> = ({ searchJobs }) => {
  const doSearchJobs = debounce(value => {
    searchJobs(value);
  }, 200);

  const onKeyPress = (e: any) => {
    const value = e.target.value;
    doSearchJobs(value);
  };

  return (
    <form style={{ width: '100%' }} onSubmit={e => e.preventDefault()}>
      <DispatchBoardMapFilterContainer>
        <DispatchBoardMapSearch>
          <TypedField
            name="searchJobs"
            component={PanelSearch}
            props={{
              placeholder: translate('dispatchBoard.mapSearchJobs'),
              margin: 'small no small no',
              borderBottom: true,
              minWidth: '250px',
              isClearable: false,
            }}
            onChange={onKeyPress}
          />
        </DispatchBoardMapSearch>
      </DispatchBoardMapFilterContainer>
    </form>
  );
}

export default reduxForm<any, ComponentProps>({
  form: 'searchJobsForm',
})(SearchJobsForm);
