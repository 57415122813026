import { Fragment, FC, useState, isValidElement, useRef, useEffect, useCallback } from 'react';
import { size, map, filter, every, pickBy, keys, reduce, isEqual, has, values, flatMap, some, get } from 'lodash-es';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';

import {
  FormGroup,
  FormGroupClearContainer,
  FormGroupClear,
  FormLabel,
  FormError,
  Input as FormInput,
  MultiSelectOverlay,
  MultiSelectOptions,
  Button,
  ButtonSet,
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  CheckboxText,
} from './styled';
import { UnconnectedInput } from '.';
import translate from '../services/translate';
import { DropdownOption } from './Dropdown';
import { Loader } from '../../customers/components/styled/CustomerLocations';
import { AsyncOptions } from './styled/MultiSelect';

export interface MultiSelectOption extends DropdownOption {
  isDisabled?: boolean;
  isOrderedMultiselect?: boolean;
  unselectable?: boolean;
  canOnlyUnselect?: boolean;
  isUncheckedByDefault?: boolean;
  renderCustomOption?: (loadAsyncOptions: () => void) => JSX.Element;
}

export enum AsyncOptionsPositions {
  Top = 'top',
  Bottom = 'bottom',
}

export enum OptionDropdownPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export interface MultiSelectValues {
  [key: string]: { all: boolean; subOptions?: { [key: string]: boolean } };
}

export interface MultiSelectProps {
  asyncOptionsPosition?: AsyncOptionsPositions;
  cachedAsyncOptions?: DropdownOption[];
  canCheckAll?: boolean;
  checkBoxClickHandler?: (e: any) => void;
  checkedOptionsFirst?: boolean;
  defaultToAll?: boolean;
  disabled?: boolean;
  fitContentWidth?: boolean;
  formatText?: (value: any[], checkAll: boolean, options: MultiSelectOption[]) => string;
  id?: string;
  input: WrappedFieldInputProps;
  inputWidth?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  keepOverlayOpen?: any;
  label?: string;
  loadAsyncOptions?: () => Promise<MultiSelectOption[]>;
  margin?: string;
  maxWidth?: string;
  menuPosition?: string;
  meta: WrappedFieldMetaProps;
  minWidth?: string;
  noOptionsMessage?: string;
  normalizeValues?: (values: any) => any;
  noWrap?: boolean;
  onChange?: (value: any) => void;
  optionDropdownPosition?: OptionDropdownPosition;
  options: MultiSelectOption[];
  placeholder?: string;
  reinitializeKey?: any;
}

const MultiSelectEnhanced: FC<MultiSelectProps> = props => {
  const {
    asyncOptionsPosition,
    cachedAsyncOptions,
    checkBoxClickHandler,
    checkedOptionsFirst,
    disabled,
    fitContentWidth,
    input,
    inputWidth,
    isClearable,
    isLoading,
    isSearchable,
    keepOverlayOpen,
    label,
    margin,
    maxWidth,
    menuPosition,
    meta,
    minWidth,
    normalizeValues,
    noWrap,
    optionDropdownPosition,
    options,
    placeholder,
    reinitializeKey,
    formatText,
    loadAsyncOptions,
  } = props;
  const [checkAll, setCheckAll] = useState<boolean>(false);

  const [selectedValues, setSelectedValues] = useState<MultiSelectValues>({});

  const [selectedValuesCount, setSelectedValuesCount] = useState<number>(0);
  const [text, setText] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<MultiSelectOption[]>([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [optionsFromPromise, setOptionsFromPromise] = useState<MultiSelectOption[] | undefined>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean | undefined>(false);
  const [orderedOptions, setOrderedOptions] = useState<MultiSelectOption[]>([]);

  const hasLoadedOptions = false;
  const canCheckAll = true;
  const defaultToAll = true;
  const noOptionsMessage = translate('common.noResults');

  const prevPropsRef = useRef<MultiSelectProps>({} as MultiSelectProps);
  useEffect(() => {
    prevPropsRef.current = props;
  });

  const getAllOptions = useCallback((): MultiSelectOption[] => {
    let additionalOptions: MultiSelectOption[] = [];
    if (!!optionsFromPromise) {
      additionalOptions = optionsFromPromise;
    }
    if (!!cachedAsyncOptions) {
      additionalOptions = cachedAsyncOptions;
    }
    return [...options, ...additionalOptions];
  }, [cachedAsyncOptions, options, optionsFromPromise]);

  const getInitialState = useCallback(() => {
    setSelectedValues(
      reduce(
        getAllOptions().filter(option => !option.isDisabled && !option.unselectable),
        (selectedValues, option) => {
          const newVal: any = { ...selectedValues };
          if (!!option.options) {
            option.options.forEach(subOption => {
              const isSubOptionSelected = some(input.value, obj =>
                get(obj, `subOptions.${subOption.value.toString() || parseInt(subOption.value as string, 10)}`, false),
              );
              const subOptions = {
                ...newVal[option.value]?.subOptions,
                [subOption.value as string]: isSubOptionSelected || (defaultToAll && !size(input.value)),
              };
              newVal[option.value] = {
                all: every(subOptions) || (defaultToAll && !option.isUncheckedByDefault && !size(input.value)),
                subOptions,
              };
            });
          } else {
            const isOptionSelected = input.value[option.value.toString() || parseInt(option.value, 10)]?.all;
            newVal[option.value as string] = {
              all: isOptionSelected || (defaultToAll && !option.isUncheckedByDefault && !size(input.value)),
            };
          }
          return newVal;
        },
        {},
      ) as { [key: string]: { all: boolean; subOptions: { [key: string]: boolean } } },
    );

    setOrderedOptions(
      [...options].sort((a, b) => {
        if (selectedValues[a.value]?.all && selectedValues[b.value]?.all) return 0;
        if (selectedValues[a.value]?.all && !selectedValues[b.value]?.all) return -1;
        if (!selectedValues[a.value]?.all && selectedValues[b.value]?.all) return 1;

        return 1;
      }),
    );

    setSelectedValuesCount(
      flatMap(values(selectedValues), value =>
        value.subOptions ? filter(values(value.subOptions), subOption => subOption).length : value.all ? 1 : 0,
      ).reduce((acc, count) => acc + count, 0),
    );
    setSearchTerm(searchTerm || '');
    setFilteredOptions(
      searchTerm
        ? filter(
            getAllOptions().filter(o => !o.unselectable),
            option =>
              (typeof option.label === 'string' || isValidElement(option.label)) &&
              ((typeof option.label === 'string' &&
                option.label.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                isValidElement(option.label)),
          )
        : options,
    );
    setIsOverlayOpen(keepOverlayOpen !== null && keepOverlayOpen ? keepOverlayOpen : false);
    setCheckAll(every(input.value, 'all'));
  }, [defaultToAll, getAllOptions, input.value, keepOverlayOpen, options, searchTerm, selectedValues]);

  useEffect(() => {
    if (!isOverlayOpen && !!searchTerm.length) {
      setSearchTerm('');
    }

    if (
      (prevPropsRef.current.input.value && !input.value) ||
      !isEqual(prevPropsRef.current.input.value, input.value) ||
      !isEqual(prevPropsRef.current.options, options) ||
      prevPropsRef.current.reinitializeKey !== reinitializeKey
    ) {
      getInitialState();
      setIsOverlayOpen(true);
    }
  }, [isOverlayOpen, searchTerm.length, input.value, options, reinitializeKey, getInitialState]);

  const onApply = () => {
    setText(formatTextFunc(selectedValues, checkAll));
    setSearchTerm('');
    setFilteredOptions(options);
    setIsOverlayOpen(keepOverlayOpen || false);

    return input.onChange(selectedValues);
  };

  const onCheckAllChange = () => {
    setSelectedValues(
      reduce(
        options.filter(filter => !filter.isDisabled),
        (selectedValues, option) => {
          const newValue: any = { ...selectedValues };
          newValue[option.value] = { all: option.canOnlyUnselect ? false : !checkAll };
          if (!!option.options) {
            option.options.forEach(subOption => {
              newValue[option.value] = {
                ...newValue[option.value],
                subOptions: {
                  ...newValue[option.value]?.subOptions,
                  [subOption.value as string]: option.canOnlyUnselect ? false : !checkAll,
                },
              };
            });
          }
          return newValue;
        },
        {},
      ),
    );

    setSelectedValuesCount(
      !checkAll
        ? reduce(selectedValues, (count, value) => count + (has(value, 'subOptions') ? size(value.subOptions) : 1), 0)
        : 0,
    );
    setCheckAll(!checkAll);
  };

  const onSearchTermChange = (value: string) => {
    setSearchTerm(value || searchTerm);
    setFilteredOptions(
      filter(
        getAllOptions().filter(o => !o.unselectable),
        option =>
          (typeof option.label === 'string' || isValidElement(option.label)) &&
          ((typeof option.label === 'string' && option.label.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
            isValidElement(option.label)),
      ),
    );
  };

  const onOptionChange = (optionValue: string, subOptionValue?: string) => {
    // search the option in the options array
    const option = getAllOptions().find(o => o.value === optionValue);

    let newSelectedValues = { ...selectedValues };
    if (!!option?.options) {
      if (!!subOptionValue) {
        const subOptions = {
          ...newSelectedValues[optionValue]?.subOptions,
          [subOptionValue as string]: option?.canOnlyUnselect
            ? false
            : !selectedValues[optionValue]?.subOptions?.[subOptionValue],
        };
        newSelectedValues[optionValue] = { all: option?.canOnlyUnselect ? false : every(subOptions), subOptions };
      } else {
        newSelectedValues[optionValue] = { all: option?.canOnlyUnselect ? false : !selectedValues[optionValue].all };
        option.options.forEach(subOption => {
          newSelectedValues[optionValue] = {
            ...newSelectedValues[optionValue],
            subOptions: {
              ...newSelectedValues[optionValue].subOptions,
              [subOption.value]: option?.canOnlyUnselect ? false : !selectedValues[optionValue].all,
            },
          };
        });
      }
    } else {
      newSelectedValues[optionValue] = { all: option?.canOnlyUnselect ? false : !selectedValues[optionValue].all };
    }

    setSelectedValues(newSelectedValues);

    setSelectedValuesCount(
      flatMap(values(newSelectedValues), value =>
        value.subOptions ? filter(values(value.subOptions), subOption => subOption).length : value.all ? 1 : 0,
      ).reduce((acc, count) => acc + count, 0),
    );

    setCheckAll(every(newSelectedValues, 'all'));
  };

  const onOverlayMouseDown = (event: React.SyntheticEvent) => {
    event.nativeEvent.stopImmediatePropagation();
  };

  const clearInput = () => {
    input.onChange(null);
  };

  const formatTextFunc = useCallback(
    (values: MultiSelectValues, checkAll: boolean) => {
      const selectedValuesKeys = flatMap(values, (value, key) => {
        if (value.subOptions) {
          return keys(pickBy(value.subOptions, subValue => subValue));
        } else {
          if (value.all) return [key];
        }
        return [];
      });
      const normalizedValues = normalizeValues ? map(selectedValuesKeys, normalizeValues) : selectedValuesKeys;
      const formattedValue = normalizedValues.length ? normalizedValues : null;
      const length = size(formattedValue);
      if (!length) return '';

      if (formatText)
        return formatText(
          formattedValue as any[],
          checkAll,
          getAllOptions().filter(o => !o.unselectable),
        );

      return checkAll ? translate('common.all') : translate('common.xSelected', { selected: length });
    },
    [formatText, getAllOptions, normalizeValues],
  );

  useEffect(() => {
    if (!every(input.value, 'all')) {
      setText(formatTextFunc(input.value, checkAll))
    } else {
      setText(formatTextFunc(input.value, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAsyncOptionsFunc = () => {
    if (loadAsyncOptions) {
      // we need this because open overlay is called both on focus and on click, which will result in a double request when clicked
      setIsLoadingOptions(true);
      loadAsyncOptions()
        .then(optionsFromPromise => {
          setOptionsFromPromise(optionsFromPromise);
          setIsLoadingOptions(false);
        })
        .catch(() => setIsLoadingOptions(false));
    }
  };

  const openOverlay = () => {
    const shouldLoadOptions = !!loadAsyncOptions && !cachedAsyncOptions && hasLoadedOptions && isLoadingOptions;
    if (shouldLoadOptions) {
      loadAsyncOptionsFunc();
    }
    getInitialState();
    setIsOverlayOpen(true);
    setTimeout(() => {
      document.addEventListener('mousedown', closeOverlay);
    });
  };

  const closeOverlay = () => {
    getInitialState();

    setTimeout(() => {
      document.removeEventListener('mousedown', closeOverlay);
    });
  };

  const getOption = (option: MultiSelectOption, index: number) => {
    return (
      <CheckboxContainer
        block
        key={index}
        isDisabled={option.isDisabled}
        noWrap={noWrap}
        size="small"
        margin="no no xSmall no"
        isOrderedMultiselect={option.isOrderedMultiselect}
      >
        {!option.unselectable && (
          <>
            <CheckboxInput
              onClick={checkBoxClickHandler}
              type="checkbox"
              name={option.value}
              disabled={option.isDisabled}
              checked={option.isDisabled ? !option.isDisabled : !!selectedValues[option.value]?.all}
              onChange={() => onOptionChange(option.value)}
            />
            <CheckboxCheck />
          </>
        )}

        <CheckboxText>
          {option.renderCustomOption ? option.renderCustomOption(loadAsyncOptionsFunc) : option.label}
        </CheckboxText>
        {!!option.options &&
          map(option.options, (subOption, subOptionIndex) => {
            return (
              <CheckboxContainer block key={subOptionIndex} noWrap={noWrap} size="small" padding="xSmall no no xSmall">
                <CheckboxInput
                  onClick={checkBoxClickHandler}
                  type="checkbox"
                  name={subOption.value as any}
                  checked={!!selectedValues[option.value]?.subOptions?.[subOption.value]}
                  onChange={() => onOptionChange(option.value, subOption.value as string)}
                />
                <CheckboxCheck />
                <CheckboxText>{subOption.label}</CheckboxText>
              </CheckboxContainer>
            );
          })}
      </CheckboxContainer>
    );
  };

  const getAsyncOptions = () => {
    return (
      (loadAsyncOptions || cachedAsyncOptions) && (
        <AsyncOptions position={asyncOptionsPosition || 'top'}>
          {isLoadingOptions ? <Loader /> : (optionsFromPromise || cachedAsyncOptions || []).map(getOption)}
        </AsyncOptions>
      )
    );
  };

  return (
    <FormGroup hasValue={!!size(input.value)} isLoading={isLoading} margin={margin} width={inputWidth}>
      {!!label && <FormLabel>{label}</FormLabel>}

      {isClearable && (Array.isArray(input.value) ? !!input.value.length : !!input.value) && (
        <FormGroupClearContainer>
          <FormGroupClear disabled={disabled} onClick={clearInput} />
        </FormGroupClearContainer>
      )}

      <FormInput
        disabled={disabled}
        name={input.name}
        onClick={openOverlay}
        onFocus={openOverlay}
        placeholder={placeholder}
        readOnly
        value={text}
        withSelectStyle
        {...props}
      />

      {isOverlayOpen && (
        <MultiSelectOverlay
          fitContentWidth={fitContentWidth}
          position={optionDropdownPosition}
          menuPosition={menuPosition}
          minWidth={minWidth}
          maxWidth={maxWidth}
          onMouseDown={onOverlayMouseDown}
        >
          {isSearchable && !!size(options) && (
            <UnconnectedInput onChange={onSearchTermChange} placeholder={translate('common.search')} />
          )}

          {!!size(filteredOptions) && (
            <Fragment>
              <MultiSelectOptions>
                {canCheckAll && (
                  <CheckboxContainer block size="small" margin="no no xSmall no">
                    <CheckboxInput type="checkbox" name="all" checked={checkAll} onChange={onCheckAllChange} />
                    <CheckboxCheck />
                    <CheckboxText>{translate('common.all')}</CheckboxText>
                  </CheckboxContainer>
                )}
                {!searchTerm &&
                  asyncOptionsPosition &&
                  asyncOptionsPosition === AsyncOptionsPositions.Top &&
                  getAsyncOptions()}
                {map(searchTerm ? filteredOptions : checkedOptionsFirst ? orderedOptions : options, getOption)}
                {!searchTerm &&
                  asyncOptionsPosition &&
                  asyncOptionsPosition === AsyncOptionsPositions.Bottom &&
                  getAsyncOptions()}
              </MultiSelectOptions>

              <ButtonSet margin="small no no">
                <Button
                  fluid
                  color="primary"
                  size="small"
                  type="button"
                  disabled={!selectedValuesCount}
                  onClick={onApply}
                >
                  {translate('common.apply')}
                </Button>
              </ButtonSet>
            </Fragment>
          )}

          {!size(getAllOptions()) && noOptionsMessage}
        </MultiSelectOverlay>
      )}

      {meta.submitFailed && meta.error && <FormError>{meta.error}</FormError>}
    </FormGroup>
  );
};

export default MultiSelectEnhanced;
