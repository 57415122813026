import { findIndex, map } from 'lodash-es';
import { getFeatureCollection, getLineStringFeature, getPointFeature } from 'src/common/components/map/util';
import { POLYLINE_COLORS } from 'src/common/constants';
import { RouteDriversVehiclesItem, RouteVehiclesBreadCrumbsItem } from 'src/dashboard/interfaces/routesData';
import { VehicleBreadcrumbs } from 'src/dashboard/interfaces/vehiclePositions';
import { VehicleTracking } from 'src/dashboard/interfaces/vehicleTrackings';
interface GeoJSONProperties {
  [key: string]: any; // Define specific types as per your GeoJSON properties
}

interface GeoJSONFeature {
  type: string;
  properties: GeoJSONProperties;
  geometry: {
      type: string;
      coordinates: number[]; // Adjust based on your specific GeoJSON structure
  };
  id?: string; // Optional ID property if needed
}

export interface GeoJSON {
  type: string;
  features: GeoJSONFeature[];
}

export const getTrackingGroupId = (tracking: VehicleTracking, groupIndex: number) =>
  /**
   * I know it looks funky, but the id can be only number because of some Mapbox querying
   * problem.
   *
   * Read more: https://github.com/mapbox/mapbox-gl-js/issues/2716
   */
  +`${tracking.vehicle.id}.${groupIndex}`;

export const getMapboxTrackingForVehicleGroupId = (tracking: VehicleBreadcrumbs, groupIndex: number) =>
  +`${tracking.id}.${groupIndex}`;

export const getMapboxTrackingForRouteGroupId = (tracking: RouteVehiclesBreadCrumbsItem, groupIndex: number) =>
  +`${tracking.id}.${groupIndex}`;

export type VehicleTrackingFeatureProperties = {
  id: number;
  clickable: boolean;
  vehicleId: number;
  groupIndex: number;
  color: string;
  source?: 'vehicle' | 'route';
  sourceId?: string;
};

export const getVehicleTrackingsGeoJSON = (vehicleTrackings: VehicleTracking[]) =>
  getFeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>(
    vehicleTrackings
      .map((tracking, index) =>
        tracking.coordinateGroups.map((group, groupIndex) =>
          getLineStringFeature(
            getTrackingGroupId(tracking, groupIndex),
            group.coordinates.map(({ latitude, longitude }) => [longitude, latitude]),
            {
              id: getTrackingGroupId(tracking, groupIndex),
              clickable: true,
              vehicleId: tracking.vehicle.id,
              groupIndex: groupIndex,
              color: POLYLINE_COLORS[index],
            },
          ),
        ),
      )
      .reduce((acc, current) => acc.concat(current), []),
  );

export const getMapboxVehicleTrackingsForVehicleGeoJSON = (vehicleBreadcrumbs: VehicleBreadcrumbs) =>
  getFeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>(
    map(vehicleBreadcrumbs.coords, (vehicleTracking: any, coordinateGroupIndex: number) =>
      getLineStringFeature(
        getMapboxTrackingForVehicleGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
        vehicleTracking.map(({ lat, lng }: any) => [lng, lat]),
        {
          id: getMapboxTrackingForVehicleGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
          clickable: true,
          vehicleId: vehicleBreadcrumbs.id,
          groupIndex: coordinateGroupIndex,
          color: POLYLINE_COLORS[0],
          source: 'vehicle',
        },
      ),
    ),
  );

export const getMapboxVehicleTrackingsIndividualPointsForVehicleGeoJSON = (vehicleBreadcrumbs: VehicleBreadcrumbs) =>
  getFeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>(
    map(vehicleBreadcrumbs.coords, (vehicleTracking: any, coordinateGroupIndex: number) =>
      vehicleTracking.map(({ lat, lng, bg }: any) =>
        getPointFeature(getMapboxTrackingForVehicleGroupId(vehicleBreadcrumbs, coordinateGroupIndex), [lng, lat], {
          id: getMapboxTrackingForVehicleGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
          clickable: true,
          vehicleId: vehicleBreadcrumbs.id,
          groupIndex: coordinateGroupIndex,
          color: POLYLINE_COLORS[0],
          source: 'vehicle',
          bearing: bg ? bg - 90 : undefined,
        }),
      ),
    ).reduce((acc, current) => acc.concat(current), []),
  );

export const getMapboxVehicleTrackingsForRouteGeoJSON = (
  routeVehiclesBreadCrumbs: RouteVehiclesBreadCrumbsItem[],
  routeVehicles?: RouteDriversVehiclesItem[],
) =>
  getFeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>(
    map(routeVehiclesBreadCrumbs, (vehicleBreadcrumbs, index: number) => {
      let color = index;
      // find the index of the vehicle in the routeVehiclesBreadCrumbs
      const vehicleIndex = findIndex(routeVehicles, vehicle => vehicle.vehicleId === vehicleBreadcrumbs.id);
      if (vehicleIndex > -1) {
        color = vehicleIndex;
      }

      if (color > POLYLINE_COLORS.length - 1) color = 1;

      return map(vehicleBreadcrumbs.coords, (vehicleTracking: any, coordinateGroupIndex: number) =>
        getLineStringFeature(
          getMapboxTrackingForRouteGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
          vehicleTracking.map(({ lat, lng }: any) => [lng, lat]),
          {
            id: getMapboxTrackingForRouteGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
            clickable: true,
            vehicleId: vehicleBreadcrumbs.id,
            groupIndex: coordinateGroupIndex,
            color: POLYLINE_COLORS[color],
            source: 'route' as 'route',
          },
        ),
      );
    }).reduce((acc, current) => acc.concat(current), []),
  );

export const getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON = (
  routeVehiclesBreadCrumbs: RouteVehiclesBreadCrumbsItem[],
  routeVehicles?: RouteDriversVehiclesItem[],
) =>
  getFeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>(
    map(routeVehiclesBreadCrumbs, (vehicleBreadcrumbs, index: number) => {
      let color = index;
      // find the index of the vehicle in the routeVehiclesBreadCrumbs
      const vehicleIndex = findIndex(routeVehicles, vehicle => vehicle.vehicleId === vehicleBreadcrumbs.id);
      if (vehicleIndex > -1) {
        color = vehicleIndex;
      }

      if (color > POLYLINE_COLORS.length - 1) color = 1;

      return map(vehicleBreadcrumbs.coords, (vehicleTracking: any, coordinateGroupIndex: number) =>
        vehicleTracking.map(({ lat, lng, bg }: any) =>
          getPointFeature(getMapboxTrackingForRouteGroupId(vehicleBreadcrumbs, coordinateGroupIndex), [lng, lat], {
            id: getMapboxTrackingForRouteGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
            clickable: true,
            vehicleId: vehicleBreadcrumbs.id,
            groupIndex: coordinateGroupIndex,
            color: POLYLINE_COLORS[color],
            source: 'route' as 'route',
            bearing: bg - 90,
          }),
        ),
      ).reduce((acc, current) => {
        acc.push(...current);
        return acc;
      }, []);
    }).reduce((acc, current) => {
      acc.push(...current);
      return acc;
    }, []),
  );

  const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 9); // Generates a random string
};

export const assignRandomIds = (geoJson: GeoJSON): GeoJSON => {
  if (geoJson.type !== 'FeatureCollection' || !Array.isArray(geoJson.features)) {
      throw new Error('Invalid GeoJSON object');
  }

  const updatedFeatures = geoJson.features.map((feature: GeoJSONFeature) => {
      return {
          ...feature,
          id: generateRandomId(),
          properties: {
              ...feature.properties,
              id: generateRandomId() // Update the properties ID if required
          }
      };
  });

  return {
      ...geoJson,
      features: updatedFeatures
  };
};