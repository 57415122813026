import { camelCase } from 'lodash-es';

import { DashboardContainerItemsApart, DashboardListItemContainer } from '../../styled/DashboardFilterMapbox';
import { dateAndTime } from 'src/utils/services/formatter';
import { Text } from 'src/core/components/styled';
import { VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import translate from 'src/core/services/translate';
import { SNOW_PLOW, STREET_SWEEPER } from 'src/fleet/constants';

interface Props {
  vehicle: VehiclePosition;
  onSelectVehicle: (vehicleId: number, isSnowSweeper?: boolean) => void;
}

const DashboardFilterVehicleItemMapbox = ({ vehicle, onSelectVehicle }: Props) => {
  return (
    <DashboardListItemContainer
      onClick={() => onSelectVehicle(vehicle.id, vehicle.type === SNOW_PLOW || vehicle.type === STREET_SWEEPER)}
      key={`${vehicle.id}_${vehicle.name}`}
      isActive={vehicle.isActive}
    >
      <DashboardContainerItemsApart>
        <Text weight="medium">{vehicle.name}</Text>
        <Text>{translate(`common.${!!vehicle.isActive ? 'active' : 'inactive'}`)}</Text>
      </DashboardContainerItemsApart>
      <DashboardContainerItemsApart>
        <Text>{translate(`vehicles.vehicleTypes.${camelCase(vehicle.type)}`)}</Text>
        {vehicle.lastLoggedIn && !vehicle.isActive && <Text>{dateAndTime(vehicle.lastLoggedIn)}</Text>}
      </DashboardContainerItemsApart>
    </DashboardListItemContainer>
  );
};

export default DashboardFilterVehicleItemMapbox;
