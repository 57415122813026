import styled from 'styled-components';

export const DoughnutChartWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
`;

interface DoughnutChartSizedWrapperProps {
  size: number;
}

export const DoughnutChartDrawingWrapper = styled.div<DoughnutChartSizedWrapperProps>`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

interface DoughnutChartTitleProps {
  size: number;
}

export const DoughnutChartTitlePopOverWrapper = styled.div<DoughnutChartTitleProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DoughnutChartTitle = styled.div<DoughnutChartTitleProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${props => Math.min(props.size / 8.5, 15)}px;
  font-weight: 500;
  white-space: break-spaces;
`;

export const DoughnutTopChartTitle = styled.div<DoughnutChartTitleProps>`
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;
export const DoughnutChartLegendTitle = styled.div`
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const DoughnutChartTitleWithPopOver = styled.div<DoughnutChartTitleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${props => Math.min(props.size / 8.5, 15)}px;
  font-weight: 500;
  white-space: break-spaces;
`;

export const DoughnutChartLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: max-content;
`;

export const DoughnutChartLegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 4px 0;
`;

interface DoughnutChartLegendItemColorProps {
  color: string;
}

export const DoughnutChartLegendItemColor = styled.div<DoughnutChartLegendItemColorProps>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: ${props => props.color};
  margin-right: 8px;
`;
