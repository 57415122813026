import React, { useState } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';

import { RouteLocationIssuesModalResolver, RouteLocationNotesModalResolver } from '..';
import { PermissionGuard } from '../../../../account/components';
import { ROUTES_TRACKER_VIEW_ADD_NOTE } from '../../../../account/constants';
import { ActionButtonTooltip, UnconnectedCheckbox, UnconnectedDropdown } from '../../../../core/components';
import Confirmation from '../../../../core/components/Confirmation';
import { DropdownOption } from '../../../../core/components/Dropdown';
import { TableActionButton } from '../../../../core/components/styled';
import { Grid as GridUntyped, GridColumn as GridColumnUntyped } from '../../../../core/components/styled/Grid';
import translate from '../../../../core/services/translate';
import { AppState } from '../../../../store';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { clearException } from '../../../ducks/dispatchBoard';
import { ExceptionManagerJobDispatchException } from '../../../interfaces/ExceptionManagerJob';
import {
  DispatchBoardFlexWrapper as DispatchBoardFlexWrapperUntyped,
  DispatchBoardJobCustomerName,
  DispatchBoardJobDetail,
  DispatchBoardJobLocationAddress,
  DispatchBoardJobLocationName,
} from '../../styled';
import {
  AssignedTo,
  AssignedToContainer,
  AssignedToTitleContainer,
  CheckboxContainer,
  IconsContainer,
  InfoContainer,
  JobInfoContainer,
  JobWithExceptionContainer,
  StopInformation,
} from './Styled';

const Grid = GridUntyped as any;
const GridColumn = GridColumnUntyped as any;

const DispatchBoardFlexWrapper = DispatchBoardFlexWrapperUntyped as any;

interface Props {
  assignmentInfo?: ExceptionManagerJobDispatchException;
  clearException: (vendorId: number, routeLocationId: number) => void;
  isChecked: boolean;
  isViewOnly: boolean;
  job: any;
  onCheckJob: (checked: boolean, job: any) => void;
  removeAssignment: (jobId: number) => void;
  vendorId: number;
  reasonCodes: DropdownOption[];
  selectedReasonCode?: number;
  setReasonCode(reasonCodeTypeId: number | undefined, job: any): void;
}

const ExceptionManagerJobItem: React.SFC<Props> = ({
  assignmentInfo,
  clearException,
  isChecked,
  isViewOnly,
  job,
  onCheckJob,
  removeAssignment,
  vendorId,
  reasonCodes,
  selectedReasonCode,
  setReasonCode,
}) => {
  const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isClearJobModalOpen, setIsClearJobModalOpen] = useState(false);

  const clearJob = () => {
    clearException(vendorId, job.routeLocationId);
    setIsClearJobModalOpen(false);
  };

  return (
    <React.Fragment>
      {isIssueModalOpen && (
        <RouteLocationIssuesModalResolver
          modalTitle={job.customerName}
          modalSubTitle={job.locationName}
          routeId={job.routeId}
          routeLocationId={job.routeLocationId}
          closeModal={() => setIsIssueModalOpen(false)}
        />
      )}

      {isNotesModalOpen && (
        <RouteLocationNotesModalResolver
          modalTitle={job.customerName}
          modalSubTitle={job.locationName}
          routeId={job.routeId}
          routeLocationId={job.routeLocationId}
          closeModal={() => setIsNotesModalOpen(false)}
          isReadOnly={isViewOnly}
        />
      )}

      {isClearJobModalOpen && (
        <Confirmation
          resolve={clearJob}
          reject={() => setIsClearJobModalOpen(false)}
          title={translate('dispatchBoard.exceptionsDispatcher.clearConfirmationTitle')}
          message={translate('dispatchBoard.exceptionsDispatcher.clearConfirmationMessage')}
        />
      )}

      <JobWithExceptionContainer transparent isSelected={isChecked} padding="small no">
        <DispatchBoardFlexWrapper>
          {!isViewOnly && (
            <CheckboxContainer>
              <UnconnectedCheckbox
                margin="sMedium no no"
                onChange={(event: any) => onCheckJob(event.target.checked, job)}
                checked={isChecked}
              />
            </CheckboxContainer>
          )}

          <Grid multiLine>
            <GridColumn size="4/12" alignVerticalCenter>
              <DispatchBoardJobCustomerName>
                {job.customerName} {job.customerAccountNumber && `(${job.customerAccountNumber})`}
              </DispatchBoardJobCustomerName>
              <DispatchBoardJobLocationName>
                {job.locationName} {job.locationAccountNumber && `(${job.locationAccountNumber})`}
              </DispatchBoardJobLocationName>
              <DispatchBoardJobLocationAddress>{job.address}</DispatchBoardJobLocationAddress>
            </GridColumn>

            <GridColumn size={assignmentInfo ? '2/12' : '4/12'} alignVerticalCenter>
              <StopInformation>
                <DispatchBoardJobDetail>{job.serviceType.name}</DispatchBoardJobDetail>
                <DispatchBoardJobDetail>
                  {job.equipmentType.name} ({job.equipmentCount})
                </DispatchBoardJobDetail>
                <DispatchBoardJobDetail>{job.materialType.name}</DispatchBoardJobDetail>
              </StopInformation>
            </GridColumn>

            {assignmentInfo && (
              <GridColumn size="2/12" alignVerticalCenter>
                <AssignedToContainer>
                  <AssignedTo>
                    <AssignedToTitleContainer>
                      <TableActionButton onClick={() => removeAssignment(job.routeLocationId)}>
                        <ActionButtonTooltip icon="undo" content={translate('tooltips.undo')} />
                      </TableActionButton>
                    </AssignedToTitleContainer>

                    <DispatchBoardJobDetail>{assignmentInfo.route.name}</DispatchBoardJobDetail>
                    <DispatchBoardJobDetail>{assignmentInfo.date}</DispatchBoardJobDetail>
                    <DispatchBoardJobDetail>
                      {assignmentInfo.wasteMaterialType ? assignmentInfo.wasteMaterialType.name : undefined}
                    </DispatchBoardJobDetail>
                  </AssignedTo>
                </AssignedToContainer>
              </GridColumn>
            )}

            <GridColumn size="2/12" alignVerticalCenter>
              <JobInfoContainer>
                <InfoContainer>
                  <DispatchBoardJobDetail>{moment(job.routeDate).format('MM/DD/YYYY')}</DispatchBoardJobDetail>

                  {isViewOnly ? (
                    <DispatchBoardJobDetail>
                      {selectedReasonCode &&
                        reasonCodes &&
                        reasonCodes.find(reasoneCode => reasoneCode.value === selectedReasonCode)?.label}
                    </DispatchBoardJobDetail>
                  ) : (
                    <UnconnectedDropdown
                      isClearable
                      options={reasonCodes}
                      width="100%"
                      label={translate('common.reasonCode')}
                      margin="no"
                      value={selectedReasonCode}
                      onChange={(reasonCodeTypeId: number | undefined) => setReasonCode(reasonCodeTypeId, job)}
                    />
                  )}
                </InfoContainer>
              </JobInfoContainer>
            </GridColumn>

            <GridColumn size="2/12" align="right" alignVerticalCenter>
              <JobInfoContainer>
                <InfoContainer>
                  <DispatchBoardJobDetail>{job.pickupType.name}</DispatchBoardJobDetail>
                  <DispatchBoardJobDetail>{job.workOrderNumber}</DispatchBoardJobDetail>
                  <DispatchBoardJobDetail>{job.exceptions[0].name}</DispatchBoardJobDetail>
                </InfoContainer>

                <IconsContainer>
                  <TableActionButton onClick={() => setIsIssueModalOpen(true)}>
                    <ActionButtonTooltip icon="issue" tooltip="issues" content={job.pickupExceptionCount} />
                  </TableActionButton>

                  <PermissionGuard permission={ROUTES_TRACKER_VIEW_ADD_NOTE}>
                    <TableActionButton onClick={() => setIsNotesModalOpen(true)}>
                      <ActionButtonTooltip icon="note" tooltip="notes" content={job.routeNoteCount} />
                    </TableActionButton>
                  </PermissionGuard>

                  {!isViewOnly && (
                    <TableActionButton onClick={() => setIsClearJobModalOpen(true)}>
                      <ActionButtonTooltip icon="close" tooltip="clear" />
                    </TableActionButton>
                  )}
                </IconsContainer>
              </JobInfoContainer>
            </GridColumn>
          </Grid>
        </DispatchBoardFlexWrapper>
      </JobWithExceptionContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

export default connect(mapStateToProps, { clearException })(ExceptionManagerJobItem);
