import { FC, useState } from 'react';
import { find } from 'lodash-es';
import { getFormValues, submit } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Modal } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { previewPDFFilePOST } from 'src/utils/services/downloadExcelFile';
import { saveBillingModule } from 'src/vendors/ducks';
import { IBillingModule } from 'src/vendors/interfaces/BillingModule';
import { ModalProps } from 'src/vendors/interfaces/ModalProps';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import EmailPreviewModal from './BillingModuleSections/EmailPreviewModal';
import BillingModuleInvoiceDetailsForm, {
  BILLING_MODULE_INVOICE_DETAILS_FORM,
} from '../forms/BillingModuleInvoiceDetailsForm';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';

const billingModuleModalId = 'billing-module-modal-id';

interface Props extends ModalProps {}

const convertToBase64 = async (selectedFile: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      resolve(reader?.result?.toString().split(',').pop() || '');
    };
    reader.onerror = error => {
      reject(error);
    };
  });
};

const BillingModuleModal: FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [emailModal, setEmailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const billingModuleIsLoading = useSelector(state => state.vendors.billingModule.isLoading);
  const billingModuleIsSaving = useSelector(state => state.vendors.billingModule.isSaving);

  const billingModuleInitialValues = useSelector(state => state.vendors.billingModule.billingModule);
  const feeStructureIsLoading = useSelector(state => state.common.feeStructure.isLoading);

  const formValues: any = useSelector(state => getFormValues(BILLING_MODULE_INVOICE_DETAILS_FORM)(state));

  const vendorId = useSelector(currentVendorId);
  const feeStructureOptions = useSelector(state => state.common.feeStructure.feeStructure);

  const formatFormValues = async (formData: IBillingModule) => {
    const feeStructure: any =
      find(feeStructureOptions, {
        id: formData?.lateFeeSetting?.feeStructure,
      }) || null;

    let imageBase64: any = null;
    let fileName: string | null = null;
    let fileType = null;

    if (formData?.pdfOptions?.vendorLogoFileContent && formData?.pdfOptions?.vendorLogoFileContent?.length > 0) {
      fileName = formData?.pdfOptions?.vendorLogoFileContent[0]?.name;
      fileType = formData?.pdfOptions?.vendorLogoFileContent[0]?.type;
      imageBase64 = await convertToBase64(formData?.pdfOptions?.vendorLogoFileContent[0]);
    }

    return {
      ...formData,
      lateFeeSetting: {
        ...formData.lateFeeSetting,
        feeStructure: feeStructure,
      },
      pdfOptions: {
        ...formData.pdfOptions,
        vendorLogoFileContent: imageBase64,
        vendorLogoFileName: fileName,
        vendorLogoFileType: fileType,
      },
    };
  };

  const handleSubmit = async (isFormValid: boolean) => {
    dispatch(submit(BILLING_MODULE_INVOICE_DETAILS_FORM));
    if (isFormValid) {
      const formattedFormData: IBillingModule = await formatFormValues(formValues);

      scrollToTopOfModal(billingModuleModalId);

      try {
        await saveBillingModule(vendorId, formattedFormData)(dispatch);
        closeModal();
        createSuccessNotification(translate('common.alertMessages.successMessage'));
      } catch (e) {
        createErrorNotificationIncludingTechnicalMessage(e, translate('common.alertMessages.feedbackSentFail'));
      }
    }
  };

  const previewPDF = async () => {
    const formattedFormData: IBillingModule = await formatFormValues(formValues);

    try {
      setIsLoading(true);
      await previewPDFFilePOST(`/vendors/${vendorId}/billingConfig/pdf/preview`, formattedFormData);
      setIsLoading(false);
    } catch (e) {
      createErrorNotificationIncludingTechnicalMessage(e, translate('common.alertMessages.feedbackSentFail'));
      setIsLoading(false);
    }
  };

  const closeEmailModal = () => setEmailModal(false);

  const getFormattedFormValues = async () => await formatFormValues(formValues);
  const isLoadingModal = isLoading || billingModuleIsLoading || feeStructureIsLoading || billingModuleIsSaving;

  return (
    <Modal
      verticalSize="medium"
      minWidth="1000px"
      title={translate('finance.paymentManagement.invoiceDetails')}
      onClose={closeModal}
      padding="medium no no no"
      id={billingModuleModalId}
      isLoading={isLoadingModal}
      overflow={isLoadingModal ? 'hidden' : 'auto'}
    >
      <BillingModuleInvoiceDetailsForm
        isLoading={isLoadingModal}
        initialValues={billingModuleInitialValues}
        handleSave={handleSubmit}
        onCancel={closeModal}
        previewPDF={previewPDF}
        setEmailModal={setEmailModal}
      />

      {emailModal && (
        <EmailPreviewModal
          closeModal={closeEmailModal}
          vendorId={vendorId}
          getFormattedFormValues={getFormattedFormValues}
        />
      )}
    </Modal>
  );
};

export default BillingModuleModal;
