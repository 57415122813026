/* eslint-disable react-hooks/exhaustive-deps */
import booleanIntersects from '@turf/boolean-intersects';
import * as turf from '@turf/turf';
import Cookie from 'js-cookie';
import { debounce, filter, find, isEqual, map, size, uniq } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { change, formValueSelector, getFormValues, submit } from 'redux-form';

import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { MapGL } from 'src/common/components/map/MapGL';
import { getMapBounds } from 'src/common/components/map/util';
import useGeoFenceControllerMapbox, { NEW_GEO_FENCE_ID } from 'src/common/hooks/geoFenceControllerMapbox';
import useRefreshDataInterval from 'src/common/hooks/useRefreshDataInterval';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { DuckFunction } from 'src/contracts/ducks';
import {
  GEO_FENCE_OPTIONS,
  GEO_FENCE_OPTIONS_SAT,
  GEO_FENCE_OPTIONS_SELECTED,
  MAP_CITY_ZOOM_IN,
  MAP_CITY_ZOOM_IN_BIGGER,
} from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import {
  ROUTE,
  ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW,
  ROUTE_TEMPLATE,
  STREET_NETWORK,
  STREET_SEGMENT_ASSIGNED_STATUSES,
  STREET_SEGMENT_ASSIGNED_TYPES_CREATE,
  STREET_SEGMENT_ASSIGNED_TYPES_STATUSES,
  STREET_SEGMENT_MAP_FILTERS_FORM_NAME,
  STREET_SEGMENT_NUMBER_OF_LANES,
  STREET_SEGMENT_NUMBER_OF_PASSES,
  STREET_SEGMENT_SERVICE_SIDES,
  STREET_SEGMENT_STATUSES,
} from 'src/customers/constants/streetNetwork';
import {
  clearStreetNetworkDataLayers,
  loadStreetNetwork,
  loadStreetNetworkDataLayers,
  loadStreetNetworkSegmentDetails,
  loadStreetNetworkServiceAreas,
  resetSnowOrSweeperStreetNetwork,
  setIsDrawSelectionModeOn,
  setIsEditGeoFencesModeOn,
  setIsEditRouteGeoFenceModeOn,
  setStreetNetworkMapViewport,
} from 'src/customers/ducks';
import { ServiceAreas, StreetNetwork, StreetNetworkSection } from 'src/customers/interfaces/StreetNetwork';
import {
  loadRouteVehiclesBreadCrumbs,
  loadVendorLocations,
  resetRouteVehiclesBreadCrumbs,
  resetVendorLocations,
} from 'src/dashboard/ducks';
import { AlternativeFleetStreetSegment } from 'src/dashboard/interfaces/alterativeFleetOps';
import { parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { ROUTE_STOPS_PICKUP_STATUS_FIELD, ROUTE_STOPS_SEARCH } from 'src/routes/components/forms/RouteStopsSearchForm';
import { SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME } from 'src/routes/components/forms/SnowOrSweeperRouteTemplateEditorForm';
import { SNOW_SWEEPER_DATE_PICKER_FORM } from 'src/routes/components/forms/SnowSweeperDatePickerForm';
import { DrawingInstructions } from 'src/routes/components/pages/routeTemplateBuilder/routeTemplateBuilderMap/DrawingInstructions';
import MapLastRefresh from 'src/routes/components/pages/routes/routePageSections/routeMap/MapLastRefresh';
import RouteMapApplicationStatusGL from 'src/routes/components/pages/routes/routePageSections/routeMap/applicationStatus/RouteMapApplicationStatusGL';
import CityAlertsGL from 'src/routes/components/pages/routes/routePageSections/routeMap/cityAlerts/CityAlertsGL';
import RouteMapCityInsightsGL from 'src/routes/components/pages/routes/routePageSections/routeMap/cityInsights/RouteMapCityInsightsGL';
import {
  ROUTE_MAP_CLUSTERS_SOURCE,
  ROUTE_MAP_VEHICLE_POSITIONS_LAYER,
} from 'src/routes/components/pages/routes/routePageSections/routeMap/constants';
import RouteMapGeoFencesGL from 'src/routes/components/pages/routes/routePageSections/routeMap/geoFences/RouteMapGeoFencesGL';
import RouteMapHaulerLocationsGL from 'src/routes/components/pages/routes/routePageSections/routeMap/haulerLocations/RouteMapHaulerLocationsGL';
import RouteMapTravelPathGL from 'src/routes/components/pages/routes/routePageSections/routeMap/travelPath/RouteMapTravelPathGL';
import RouteMapVehicleInsightsGL from 'src/routes/components/pages/routes/routePageSections/routeMap/vehicleInsights/RouteMapVehicleInsightsGL';
import RouteMapVehiclePositionsGL from 'src/routes/components/pages/routes/routePageSections/routeMap/vehiclePositions/RouteMapVehiclePositionsGL';
import RouteMapVehicleTrackingsGL from 'src/routes/components/pages/routes/routePageSections/routeMap/vehicleTrackings/RouteMapVehicleTrackingsGL';
import { IN_PROGRESS } from 'src/routes/constants';
import {
  downloadTravelPathGeoJsonFile,
  loadRouteMapVehicleData,
  loadRouteSegments,
  loadSnowPlowSegmentList,
  loadStreetSweeperSegmentList,
  resetGeoFences,
  resetRouteMapCityInsights,
  resetTravelPathDetails,
  setPickupStatusIds,
  setRouteMapSelectedFeature,
  setShowTravelPath,
} from 'src/routes/ducks';
import { GeoFenceJsonList, loadGeoFences, saveGeoFences } from 'src/routes/ducks/geoFences';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { loadRouteGeoFence, resetGeoFence } from 'src/routes/ducks/routeGeoFence';
import { resetRouteMapVehicleData } from 'src/routes/ducks/routeMapVehicleData';
import { resetRouteMapVehicleInsights } from 'src/routes/ducks/routeMapVehicleInsights';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { setPersistentMapFilters } from 'src/routes/services/persistentMapFilterSettingStorage';
import { AppState } from 'src/store';
import { loadCityAlerts, loadVendor, resetCityAlerts } from 'src/vendors/ducks';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import { currentVendorId, currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import StreetSegmentsMapFiltersForm from '../../forms/StreetSegmentsMapFiltersForm';
import { MapGLWrapper } from '../../styled';
import StreetNetworkMapClustersGL from './StreetNetworkMapClustersGL';
import StreetNetworkMapControls from './StreetNetworkMapControls';
import StreetNetworkMapLegend from './StreetNetworkMapLegend';
import StreetNetworkMapRouteSegmentsGL from './StreetNetworkMapRouteSegmentsGL';
import StreetNetworkRouteMapActions from './StreetNetworkRouteMapActions';
import { filterTrackerSegments, getSegmentColorsLegendBasedOnDriversPasses } from './utils';
import { ALL_CITY_ALERTS } from 'src/fleet/constants/locationAndCityAlerts';
import StreetNetworkDataGL from './StreetNetworkDataGL';
import StreetNetworkMapInformation from './StreetNetworkMapInformation';

export interface RouteSegmentExtended extends StreetNetwork, RouteSegment {}

type RouteMapFilters = {
  showStopConfirmation: boolean;
  showVehicleTrackings: boolean;
  showXDeviceTrackings: boolean;
  showYDeviceTrackings: boolean;
};

interface LocationState {
  createdDailyRoute?: boolean;
}
interface Props {
  geoFencesTypesFilters: any[];
  geoFenceSubFilters: any[];
  hasFilteredSegments?: boolean;
  isDailyRoute?: boolean;
  isDriverExperienceComplex?: boolean;
  isNoRoute?: boolean;
  isRouteTemplate?: boolean;
  isRouteTemplateEditor?: boolean;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
  isStreetNetwork?: boolean;
  lastActivity?: any[];
  loadGeoFences: DuckFunction<typeof loadGeoFences>;
  loadStreetNetwork: DuckFunction<typeof loadStreetNetwork>;
  loadStreetNetworkSegmentDetails: DuckFunction<typeof loadStreetNetworkSegmentDetails>;
  newSegments?: RouteLocation[];
  numberOfRouteSegments?: number;
  onHandleSubmitFilters?: () => void;
  routeDate?: string;
  routeId?: number;
  routeName?: string;
  routeSegments?: RouteSegmentExtended[];
  routeStatusTypeId?: number;
  routeTemplateId?: number;
  segmentsFilterEndDate?: Date | string;
  segmentsFilterStartDate?: Date | string;
  selectedSegments?: number[];
  setSelectedSegments?: React.Dispatch<React.SetStateAction<number[]>>;
}

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

const normalizeFilterObject = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: string[], cur) => (cur[1] ? [...acc, cur[0]] : acc), []);

// returns the keys of the elements that are true as numbers
const normalizeFilterObjectAsNumbersAndRemovePrefix = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: number[], cur) => (cur[1] ? [...acc, Number(cur[0].replace('_', ''))] : acc), []);

const StreetNetworkMapGL: React.FC<Props> = ({
  geoFencesTypesFilters,
  geoFenceSubFilters,
  hasFilteredSegments,
  isDailyRoute,
  isDriverExperienceComplex,
  isNoRoute,
  isRouteTemplate,
  isRouteTemplateEditor,
  isSnowPlowRoute,
  isStreetNetwork,
  isStreetSweeperRoute,
  lastActivity,
  loadGeoFences,
  loadStreetNetwork,
  loadStreetNetworkSegmentDetails,
  newSegments,
  numberOfRouteSegments,
  onHandleSubmitFilters,
  routeDate,
  routeId,
  routeName,
  routeSegments,
  routeStatusTypeId,
  routeTemplateId,
  segmentsFilterEndDate,
  segmentsFilterStartDate,
  selectedSegments,
  setSelectedSegments,
}) => {
  const dispatch = useDispatch();
  const editorRef = useRef<Editor>(null);
  const location = useLocation();
  const state = location.state as LocationState;

  const vendorId = useSelector(currentVendorId);
  const {
    streetNetwork,
    isEditSegmentsModeOn,
    isDrawSelectionModeOn,
    isEditGeoFencesModeOn,
    isEditRouteGeoFenceModeOn,
    isLoading,
    viewport,
  } = useSelector(state => state.customers.streetNetwork) as any;
  const { geoFence: stateGeoFence } = useSelector(state => state.routes.geoFence);

  const geoFenceEditing = useSelector(state => state.routes.geoFence.geoFenceEditing);
  const {
    isLoading: isLoadingRouteMapData,
    vehiclePositions,
    mainVehicleId,
    lastRefreshed,
    routeMapFilters,
  } = useSelector(state => state.routes.routeMapVehicleData);
  const { geoFences } = useSelector(state => state.routes.geoFences.geoFences);
  const isLoadingGeoFences = useSelector(state => state.routes.geoFences.isLoading);
  const vehicleTrackings = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs?.vehicles);
  const isLoadingRouteVehicleTrackings = useSelector(
    state => state.dashboard.routesData.isLoadingRouteVehiclesBreadCrumbs,
  );
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const vendor = useSelector(state => state.vendors.vendor.vendor) as any as Vendor;

  const { vehicleInsights } = useSelector(state => state.routes.routeMapVehicleInsights);
  const { cityInsights } = useSelector(state => state.routes.routeMapCityInsights);
  const { cityAlerts } = useSelector(state => state.vendors.cityAlerts);
  const { vendorLocations: haulerLocations } = useSelector(state => state.dashboard.vendorLocations);
  const {
    routeId: travelPathForRouteId,
    routeTemplateId: travelPathForRouteTemplateId,
    travelPathStatusDetails,
    isDownloadingGeoJsonFile,
    showTravelPath,
    travelPathData,
  } = useSelector(state => state.routes.travelPath);

  const { isLoading: isLoadingCityAlerts } = useSelector(state => state.vendors.cityAlerts);

  const { serviceAreas } = useSelector(state => state.customers.streetNetworkServiceAreas);
  const {
    streetNetwork: streetNetworkDataLayers,
    streetNetworkWithBbox,
    errorMessage,
  } = useSelector(state => state.customers.streetNetworkDataLayer);

  // TODO uncomment this when creating alert is enabled on map
  // const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
  // const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;

  const [editPolygon, setEditPolygon] = useState<boolean>(true); // for selection flag
  const [polygon, setPolygon] = useState<any>([]); //for selection
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();
  const [editedGeoFences, setEditedGeoFences] = useState<any>(); // for street network
  const [editedRouteGeoFence, setEditedRouteGeoFence] = useState<any>([]); // for route

  const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false);

  const [isRouteMapActionsOpen, setRouteMapActionsOpen] = useState(false);
  const [isStreetSegmentsMapFiltersOpen, setIsStreetSegmentsMapFiltersOpen] = useState(false);
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();
  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);
  const [showRouteGeoFence, setShowRouteGeoFence] = useState(false);
  const [cityInsightsFilters, setCityInsightsFilters] = useState<number[]>([]);
  const [vehicleFilters, setVehicleFilters] = useState<number[]>(mainVehicleId ? [mainVehicleId] : []);
  const [vehicleInsightsFilters, setVehicleInsightsFilters] = useState<string[]>([]);
  const [noData, setNoData] = useState<boolean>(false);
  const [isSaveGeoFence, setIsSaveGeoFence] = useState<boolean>(false);
  const [vehicleTrackingsFilters, setVehicleTrackingsFilters] = useState<RouteMapFilters>({
    showStopConfirmation: false,
    showVehicleTrackings: false,
    showXDeviceTrackings: false,
    showYDeviceTrackings: false,
  });
  const [isAutoRefresh, setIsAutoRefresh] = useState<boolean>(false);

  const [currentServiceAreas, setCurrentServiceAreas] = useState<ServiceAreas[] | undefined>(undefined);
  const [layerFilter, setLayerFilter] = useState<number | turf.BBox>(0);

  const formValues = useSelector(state =>
    isRouteTemplateEditor ? getFormValues(SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME)(state) : null,
  ) as any;

  const geoFenceExists = useMemo(() => {
    const hasFormGeoFence =
      formValues?.geoFence?.geoFenceCoordinates?.length > 0 || formValues?.geoFence?.hasDeletedAllPolygons;
    const hasStateGeoFence = stateGeoFence?.id && stateGeoFence?.geoFenceCoordinates?.length > 0;

    if (hasFormGeoFence || hasStateGeoFence) {
      if (isRouteTemplate) {
        setShowRouteGeoFence(true);
      }
      return true;
    }

    return false;
  }, [stateGeoFence, isRouteTemplate, formValues]);

  const geoFence = useMemo(() => {
    if (formValues && formValues.geoFence) return formValues.geoFence;
    return stateGeoFence;
  }, [formValues, stateGeoFence]);

  const {
    getAllCoords,
    allGeoFencesGeoJson,
    hasChanges,
    mapGeoFences,
    selectedGeoFenceGeo,
    addGeoFence,
    addPolygonToGeoFence,
    bulkAddGeoFences,
    emptyGeoFences,
    getGeoJsonForGeoFence,
    removeGeoFence,
    removeUnsavedGeoFenceOrPolygons,
    removePolygonFromGeoFence,
    selectGeoFencePolygon,
    unselectGeoFencePolygon,
    updatePolygonInGeoFence,
    undoPolygonInGeoFence,
    getAllGeoJsonForEditing,
    checkIfGeoFenceExists,
  } = useGeoFenceControllerMapbox();

  const selectedLastActivityIds = lastActivity ? Object.keys(lastActivity).filter(k => lastActivity[k as any]) : [];

  const filteredStreetNetwork = useMemo(() => {
    if (!streetNetwork) {
      return [];
    }

    let segmentsOnRoute: AlternativeFleetStreetSegment[] = [];
    let segmentNotOnRoute: AlternativeFleetStreetSegment[] = [];
    if (routeId && !!selectedLastActivityIds.length) {
      for (let i = 0; i <= streetNetwork.length - 1; i++) {
        if (routeSegments?.some(routeSegment => streetNetwork[i].id === routeSegment.id)) {
          segmentsOnRoute.push(streetNetwork[i]);
        } else {
          segmentNotOnRoute.push(streetNetwork[i]);
        }
      }
    }

    const filteredSegments =
      routeId && !!selectedLastActivityIds.length
        ? [
            ...segmentNotOnRoute,
            ...filterTrackerSegments(segmentsOnRoute, selectedLastActivityIds, isSnowPlowRoute),
            ...((newSegments || []) as RouteLocation[]),
          ]
        : streetNetwork;

    if (hasFilteredSegments && (routeId || routeTemplateId))
      return filteredSegments.filter((s: { id: number }) => routeSegments?.map(sr => sr.id).includes(s.id));

    return filteredSegments;
  }, [streetNetwork, routeSegments, routeTemplateId, routeId, hasFilteredSegments]);

  const filteredVehiclePositions = useMemo(
    () => vehiclePositions.filter(vehiclePosition => vehicleFilters.includes(vehiclePosition.vehicleId)),
    [vehiclePositions, vehicleFilters],
  );

  const filteredVehicleTrackings = useMemo(
    () =>
      vehicleTrackingsFilters.showVehicleTrackings && vehicleFilters.length
        ? filter(vehicleTrackings, tr => vehicleFilters.includes(tr.id))
        : [],
    [vehicleTrackings, vehicleFilters, vehicleTrackingsFilters.showVehicleTrackings],
  );

  const filteredVehicleInsights = useMemo(
    () => vehicleInsights.filter(vehicleInsight => vehicleInsightsFilters.includes(vehicleInsight.insightType.name)),
    [vehicleInsights, vehicleInsightsFilters],
  );

  const filteredCityInsights = useMemo(
    () => cityInsights.filter(cityInsight => cityInsightsFilters.includes(cityInsight.locationFlaggingTypeId)),
    [cityInsights, cityInsightsFilters],
  );

  useEffect(() => {
    if (!isDrawSelectionModeOn && !isEditGeoFencesModeOn) {
      const bulkToSet = (geoFences || []).map((geoFence: any) => {
        const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
        return {
          ...geoFence,
          geoFenceCoordinates: parsedGeoFenceJson?.geometry?.coordinates,
        };
      });
      bulkToSet.length && bulkAddGeoFences(bulkToSet);

      if (geoFence && showRouteGeoFence && geoFenceExists) {
        addGeoFence(geoFence);
      } else if (geoFenceExists && !showRouteGeoFence && geoFence.id) {
        removeGeoFence(geoFence.id);
      }
    }
  }, [
    geoFence,
    geoFences,
    isDrawSelectionModeOn,
    isEditGeoFencesModeOn,
    isEditRouteGeoFenceModeOn,
    showRouteGeoFence,
    showTravelPath,
  ]);

  useEffect(() => {
    dispatch(setIsDrawSelectionModeOn(false));
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(setIsDrawSelectionModeOn(false));
      dispatch(setIsEditGeoFencesModeOn(false));
      dispatch(setIsEditRouteGeoFenceModeOn(false));
      dispatch(resetGeoFences());
      dispatch(resetGeoFence());
      dispatch(resetRouteMapVehicleData());
      dispatch(resetRouteMapVehicleInsights());
      dispatch(resetRouteMapCityInsights());
      dispatch(resetVendorLocations());
      dispatch(resetTravelPathDetails());
      dispatch(resetRouteVehiclesBreadCrumbs());
      dispatch(resetTravelPathDetails());
      dispatch(resetCityAlerts());
      emptyGeoFences();
    },
    [dispatch, emptyGeoFences],
  );

  useEffect(() => {
    if (!isEditSegmentsModeOn) {
      dispatch(setIsDrawSelectionModeOn(false));
      setPolygon([]);
    }
  }, [isEditSegmentsModeOn, dispatch]);

  // if segments were deleted via the lasso tool, we need to disable the draw mode and reset the polygon
  useEffect(() => {
    dispatch(setIsEditRouteGeoFenceModeOn(false));
    unselectGeoFencePolygon();
    setIsAddingPolygon(false);
    setEditPolygon(true);
  }, [routeSegments?.length]);

  useEffect(() => {
    const editor = editorRef.current;

    if (isDrawSelectionModeOn) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
      }
    } else if (isEditGeoFencesModeOn) {
      if (!drawMode) {
        setDrawMode(new EditingMode());
      }
    } else if (isEditRouteGeoFenceModeOn) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
        setIsAddingPolygon(true);
      }
    } else {
      if (polygon?.length && editor) {
        editor.deleteFeatures(polygon.map((_: any, i: number) => i));
        setPolygon([]);
      } else if (editedRouteGeoFence?.length && editor) {
        editor.deleteFeatures(editedRouteGeoFence.map((_: any, i: number) => i));
        setEditedRouteGeoFence([]);
      } else if (editedGeoFences?.length && editor) {
        editor.deleteFeatures(editedGeoFences.map((_: any, i: number) => i));
        setEditedGeoFences([]);
      }

      if (drawMode) {
        setDrawMode(undefined);
      }
    }
  }, [
    isDrawSelectionModeOn,
    isEditGeoFencesModeOn,
    drawMode,
    polygon,
    editedRouteGeoFence,
    isEditRouteGeoFenceModeOn,
    editedGeoFences,
  ]);

  // map customizations
  useEffect(() => {
    mapInstance?.once('load', () => {
      mapInstance.on('click', event => {
        const [feature] = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === ROUTE_MAP_CLUSTERS_SOURCE &&
              feature.properties?.layer === ROUTE_MAP_VEHICLE_POSITIONS_LAYER,
          );

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.vehiclePositions, feature.id as number));
        }
      });
      mapInstance.on('mousemove', event => {
        const [feature] = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW);

        mapInstance.getCanvas().style.cursor = feature && feature.id ? 'pointer' : '';
      });

      mapInstance.on('mousemove', event => {
        const features = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.properties?.clickable === true || !!feature.properties?.cluster_id);
        if (!isEditSegmentsModeOn) {
          mapInstance.getCanvas().style.cursor = features.length ? 'pointer' : '';
        }
      });

      // move vehicles on top of all layers
      mapInstance.on('idle', () => {
        if (mapInstance.getLayer(ROUTE_MAP_VEHICLE_POSITIONS_LAYER)) {
          mapInstance.moveLayer(ROUTE_MAP_VEHICLE_POSITIONS_LAYER);
        }
      });
    });
  }, [mapInstance, dispatch, loadStreetNetworkSegmentDetails, vendorId, isEditSegmentsModeOn, geoFences, geoFence]);

  useEffect(() => {
    if (!isEqual(currentServiceAreas, serviceAreas)) setCurrentServiceAreas(serviceAreas);
  }, [serviceAreas]);

  // set map viewport to fit all features displayed
  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    streetNetwork.forEach(({ lineSegment }: StreetNetwork) => {
      parseSegmentJSON(lineSegment).forEach(([longitude, latitude]) => {
        points.push({ latitude, longitude });
      });
    });

    filteredVehiclePositions.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    filteredVehicleTrackings.forEach(vehicleTracking => {
      vehicleTracking.coords.forEach(coordinateGroup => {
        coordinateGroup.forEach(({ lat, lng }) => {
          points.push({ latitude: lat, longitude: lng });
        });
      });
    });

    showTravelPath &&
      travelPathData?.features?.forEach(travelPath => {
        travelPath.geometry.coordinates.forEach((coordinate: any[]) => {
          points.push({ latitude: coordinate[1], longitude: coordinate[0] });
        });
      });

    filteredVehicleInsights.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    filteredCityInsights.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    haulerLocations.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    cityAlerts.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    if (isStreetNetwork)
      currentServiceAreas?.forEach(serviceArea => {
        serviceArea?.polygons?.forEach(polygon => {
          const coordinates = JSON.parse(polygon?.json).geometry.coordinates[0];
          coordinates.forEach((coordinate: number[]) =>
            points.push({ latitude: coordinate[1], longitude: coordinate[0] }),
          );
        });
      });

    if (mapGeoFences.length > 0 && !isEditGeoFencesModeOn && !isEditRouteGeoFenceModeOn && !isEditSegmentsModeOn) {
      points.push(...getAllCoords);
    }

    if (!!points.length && !isSaveGeoFence && !isAutoRefresh) {
      const bounds = getMapBounds(points, {
        capZoom: MAP_CITY_ZOOM_IN_BIGGER,
      });

      if (isStreetNetwork && bounds.zoom) {
        debounceSetLayerFilter(bounds.zoom);
      }

      dispatch(setStreetNetworkMapViewport(bounds));
    } else if (!points.length && vendor?.id) {
      const mapCenterByVendor = getMapBounds(
        [{ latitude: vendor.homeAddress.latitude, longitude: vendor.homeAddress.longitude }],
        {
          capZoom: MAP_CITY_ZOOM_IN,
        },
      );

      dispatch(setStreetNetworkMapViewport(mapCenterByVendor));
    }
  }, [
    dispatch,
    streetNetwork.length,
    filteredVehiclePositions.length,
    filteredVehicleTrackings,
    filteredVehicleInsights,
    filteredCityInsights,
    haulerLocations,
    isSaveGeoFence,
    travelPathData,
    showTravelPath,
    cityAlerts,
    currentServiceAreas,
  ]);

  useEffect(() => {
    if (!isEditGeoFencesModeOn && !isEditRouteGeoFenceModeOn && !isRouteTemplateEditor) {
      const points: { latitude: number; longitude: number }[] = [];
      if (mapGeoFences.length > 0) {
        points.push(...getAllCoords);
      }
      if (!!points.length) {
        const bounds = getMapBounds(points, {
          capZoom: MAP_CITY_ZOOM_IN,
        });
        dispatch(setStreetNetworkMapViewport(bounds));
      }
    }
  }, [dispatch, getAllCoords, isEditGeoFencesModeOn, isRouteTemplateEditor, isEditRouteGeoFenceModeOn, mapGeoFences]);

  const getNormalizeFilterArray = (formSection: any) => {
    return normalizeFilterArray(formSection)
      .filter((index: number) => index >= 0)
      .toString();
  };

  const loadSelectedGeoFences = async (geoFenceSubFilters: any, geoFencesTypesFilters: any[]) => {
    const normalizedGeoFencesIds = normalizeFilterObjectAsNumbersAndRemovePrefix(geoFenceSubFilters);
    const normalizedGeoFencesTypesIds = normalizeFilterArray(geoFencesTypesFilters);

    if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
      emptyGeoFences();
      await loadGeoFences({
        vendorId,
        geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
        limit: 200,
        geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
      });
    } else {
      dispatch(resetGeoFences());
      emptyGeoFences();
    }
  };

  const handleApplyLastFilters = (currentRouteFilters: any) => {
    const isLastFiltersApplied = true;
    handleSubmitFilters({ ...currentRouteFilters, isLastFiltersApplied });
  };

  const handleSubmitFilters = async (formData: any) => {
    dispatch(resetSnowOrSweeperStreetNetwork());

    if (!formData.isLastFiltersApplied) {
      const pageType = isDailyRoute ? ROUTE : isRouteTemplate ? ROUTE_TEMPLATE : STREET_NETWORK;
      const routeOrTemplateId = isDailyRoute ? routeId : isRouteTemplate ? routeTemplateId : 0;
      setPersistentMapFilters({ ...formData, routeOrTemplateId, pageType });
    }

    const streetSegmentStatusActive = formData.streetSegmentStatuses[0];
    const streetSegmentStatusInactive = formData.streetSegmentStatuses[1];
    const streetSegmentStatus =
      streetSegmentStatusActive && !streetSegmentStatusInactive
        ? true
        : !streetSegmentStatusActive && streetSegmentStatusInactive
        ? false
        : undefined;

    const streetSegmentAssignedStatusActive = formData.streetSegmentAssignedStatuses[0];
    const streetSegmentAssignedStatusInactive = formData.streetSegmentAssignedStatuses[1];
    const streetSegmentAssignedStatus =
      streetSegmentAssignedStatusActive && !streetSegmentAssignedStatusInactive
        ? true
        : !streetSegmentAssignedStatusActive && streetSegmentAssignedStatusInactive
        ? false
        : undefined;

    if (
      (isDailyRoute && find(formData.streetSegmentAssignedStatuses, s => s === true)) ||
      (isRouteTemplate && find(formData.streetSegmentAssignedTypesStatus, s => s === true)) ||
      (!isDailyRoute && !isRouteTemplate)
    ) {
      setNoData(false);
    } else {
      setNoData(true);
    }

    const getStreetSegmentData = (streetSegmentData: boolean[], streetSegmentSection: StreetNetworkSection[]) => {
      return streetSegmentData.filter((segment: boolean) => segment).length === streetSegmentSection.length
        ? undefined
        : getNormalizeFilterArray(streetSegmentData);
    };

    const streetSegmentNumberOfLanes = getStreetSegmentData(
      formData.streetSegmentNumberOfLanes,
      STREET_SEGMENT_NUMBER_OF_LANES,
    );

    const streetSegmentNumberOfPasses = getStreetSegmentData(
      formData.streetSegmentNumberOfPasses,
      STREET_SEGMENT_NUMBER_OF_PASSES,
    );

    const streetSegmentServiceSides = getStreetSegmentData(
      formData.streetSegmentServiceSides,
      STREET_SEGMENT_SERVICE_SIDES,
    );

    const streetSegmentAssignedTypesStatus = getStreetSegmentData(
      formData.streetSegmentAssignedTypesStatus,
      STREET_SEGMENT_ASSIGNED_TYPES_STATUSES,
    );

    const alternativeFleetServiceTypeIds = formData.alternativeFleetServiceTypeIds
      ? normalizeFilterObjectAsNumbersAndRemovePrefix(formData.alternativeFleetServiceTypeIds)
      : [];

    const pickupStatusTypeIds =
      formData.pickupStatusTypeIds && normalizeFilterObjectAsNumbersAndRemovePrefix(formData.pickupStatusTypeIds);

    dispatch(change(ROUTE_STOPS_SEARCH, ROUTE_STOPS_PICKUP_STATUS_FIELD, pickupStatusTypeIds));
    dispatch(setPickupStatusIds(uniq(pickupStatusTypeIds)));

    if (
      formData.streetSegmentNumberOfLanes.filter((lane: boolean) => lane).length === 0 &&
      formData.streetSegmentNumberOfPasses.filter((pass: boolean) => pass).length === 0 &&
      formData.streetSegmentServiceSides.filter((side: boolean) => side).length === 0 &&
      formData.streetSegmentStatuses.filter((status: boolean) => status).length === 0 &&
      formData.streetSegmentAssignedStatuses.filter((status: boolean) => status).length === 0 &&
      formData.streetSegmentAssignedTypesStatus.filter((status: boolean) => status).length === 0 &&
      formData.alternativeFleetServiceTypeIds.filter((type: boolean) => type).length === 0 &&
      formData.pickupStatusTypeIds.filter((statusTypeId: boolean) => statusTypeId).length === 0
    ) {
      createErrorNotification(translate('customers.alertMessages.filterRequired'));
    } else {
      const includeRouteTemplateIds = isRouteTemplate;
      const templateId = isRouteTemplate ? routeTemplateId || 0 : undefined;
      const driverIds = !!formData.driverIds ? getNormalizeFilterArray(formData.driverIds) : undefined;
      if ((isAutoRefresh && streetNetwork.length > 0) || !isAutoRefresh)
        await loadStreetNetwork({
          vendorId,
          routeId,
          routeTemplateId: templateId,
          includeRouteTemplateIds,
          streetSegmentAssignedStatus,
          streetSegmentAssignedTypesStatus,
          streetSegmentStatus,
          streetSegmentNumberOfLanes,
          streetSegmentNumberOfPasses,
          streetSegmentServiceSides,
          driverIds,
          pickupStatusTypeIds: pickupStatusTypeIds.join(','),
          segmentsFilterEndDate,
          segmentsFilterStartDate,
          noLoadingIndicator: isAutoRefresh,
          alternativeFleetServiceTypeIds: alternativeFleetServiceTypeIds.join(','),
          vehicleTypeId: !routeTemplateId
            ? isSnowPlowRoute
              ? SNOW_PLOW_ID
              : isStreetSweeperRoute
              ? STREET_SWEEPER_ID
              : undefined
            : undefined,
        });
      !isAutoRefresh && setIsStreetSegmentsMapFiltersOpen(false);
    }

    const includeVehicleTracking = false;
    routeId && loadRouteMapVehicleData(vendorId, routeId, includeVehicleTracking, isAutoRefresh)(dispatch);

    if (formData.showVehicleTrackings) {
      // we load the vehicle tracking data from the same endpoint as the dashboard map
      const vehicles = formData?.vehicleFilters || {};
      const vehicleChecked = Object.keys(vehicles).filter(key => vehicles[key]);
      const vehicleIds = vehicleChecked.map(id => Number(id.replace('_', '')));
      if (vehicleIds.length) {
        routeId &&
          routeSummary &&
          routeSummary.date &&
          (await loadRouteVehiclesBreadCrumbs(routeId, routeSummary.date, vehicleIds.join(','))(dispatch));
      } else dispatch(resetRouteVehiclesBreadCrumbs());
    }

    if (isDailyRoute && routeId && isAutoRefresh) {
      const routeSegments = await loadRouteSegments({
        vendorId,
        routeId,
        noLoadingIndicator: isAutoRefresh,
        startDate: segmentsFilterStartDate,
        endDate: segmentsFilterEndDate,
      })(dispatch);

      const segmentsIds = routeSegments.map((segment: any) => segment.streetSegmentId);
      if (segmentsIds.length)
        if (isSnowPlowRoute) await loadSnowPlowSegmentList(vendorId, segmentsIds, isAutoRefresh)(dispatch);
        else if (isStreetSweeperRoute)
          await loadStreetSweeperSegmentList(vendorId, segmentsIds, isAutoRefresh)(dispatch);
    }

    if (!isAutoRefresh) {
      await loadSelectedGeoFences(formData.geoFenceSubFilters, formData.geoFencesTypesFilters);

      if (geoFence && formData.geoFencesTypesFilters[geoFence.id] && (routeId || routeTemplateId)) {
        const isTemplate = !!routeTemplateId;
        await loadRouteGeoFence((routeId || routeTemplateId) as number, isTemplate)(dispatch);
        setShowRouteGeoFence(true);
      } else if (!isRouteTemplate) {
        emptyGeoFences();
        setShowRouteGeoFence(false);
      }
      const filterHaulerLocations = normalizeFilterArray(formData.haulerLocationsFilters || []);
      if (filterHaulerLocations?.length) await loadVendorLocations(vendorId, filterHaulerLocations)(dispatch);
      else dispatch(resetVendorLocations());

      const filterCityAlertTypes = normalizeFilterArray(formData.cityAlertsFilters || []);
      if (filterCityAlertTypes.length) {
        const filterCityAlertSubTypes = normalizeFilterObject(formData.cityAlertsSubFilters || {});

        if (filterCityAlertSubTypes.length) {
          const filterCityAlertTypesSelected = filterCityAlertSubTypes.map(cityAlertsSubFilter =>
            Number(cityAlertsSubFilter.split('_')[0]),
          );
          const filterCityAlertSubTypesSelected = filterCityAlertSubTypes.map(cityAlertsSubFilter =>
            Number(cityAlertsSubFilter.split('_')[1]),
          );
          const isAllCityAlertsSelected = filterCityAlertTypesSelected.includes(ALL_CITY_ALERTS);

          await loadCityAlerts(
            filterCityAlertSubTypesSelected,
            isAllCityAlertsSelected ? undefined : routeId,
          )(dispatch);
        }
      } else dispatch(resetCityAlerts());

      if (formData.showTravelPath && !travelPathStatusDetails?.inProgress) {
        if (
          (routeId && travelPathForRouteId !== routeId) ||
          (routeTemplateId && travelPathForRouteTemplateId !== routeTemplateId)
        )
          await downloadTravelPathGeoJsonFile(routeId, routeTemplateId)(dispatch);
        dispatch(setShowTravelPath(true));
      } else dispatch(setShowTravelPath(false));

      setVehicleTrackingsFilters({
        showVehicleTrackings: formData.showVehicleTrackings,
        showStopConfirmation: formData.showStopConfirmation,
        showXDeviceTrackings: formData.showXDeviceTrackings,
        showYDeviceTrackings: formData.showXDeviceTrackings,
      });

      const normalizedVehicleFiltersIds = normalizeFilterObjectAsNumbersAndRemovePrefix(formData.vehicleFilters);
      setVehicleFilters(normalizedVehicleFiltersIds);

      setVehicleInsightsFilters(normalizeFilterObject(formData.vehicleInsightsFilters));
      setCityInsightsFilters(normalizeFilterArray(formData.cityInsightsFilters));

      setIsSaveGeoFence(false);

      onHandleSubmitFilters && onHandleSubmitFilters();
    }

    setIsAutoRefresh(false);
  };

  const getFiltersFormInitialValues = () => {
    const vehicleFiltersInitialValues: { [key: string]: boolean } = {};
    if (mainVehicleId) {
      vehicleFiltersInitialValues[`_${mainVehicleId}`] = true;
    }

    return {
      showAllNumberOfLanes: true,
      showAllNumberOfPasses: true,
      showAllSegmentStatuses: true,
      showAllSegmentAssignedStatuses: state?.createdDailyRoute || false,
      showAllSegmentAssignedTypesStatuses: false,
      showAllServiceSides: true,
      showAllSegmentsStatus: true,
      streetSegmentNumberOfLanes: STREET_SEGMENT_NUMBER_OF_LANES.map(() => true),
      streetSegmentNumberOfPasses: STREET_SEGMENT_NUMBER_OF_PASSES.map(() => true),
      streetSegmentServiceSides: STREET_SEGMENT_SERVICE_SIDES.map(() => true),
      streetSegmentStatuses: STREET_SEGMENT_STATUSES.map(() => true),
      streetSegmentAssignedStatuses: STREET_SEGMENT_ASSIGNED_STATUSES.map((_, index) =>
        state?.createdDailyRoute ? true : index === 0,
      ),
      streetSegmentAssignedTypesStatus:
        isRouteTemplate && !routeTemplateId
          ? STREET_SEGMENT_ASSIGNED_TYPES_CREATE.map(() => true)
          : STREET_SEGMENT_ASSIGNED_TYPES_STATUSES.map((_, index) =>
              isDailyRoute || isRouteTemplate ? index === 0 : true,
            ),
      pickupStatusTypeIds: isDriverExperienceComplex
        ? {
            _1: true,
            _2: true,
            _3: true,
          }
        : {
            _1: true,
            _3: true,
          },
      showAllGeoFences: false,
      showRouteGeoFence: false,
      showVehicleTrackings: false,
      showStopConfirmation: false,
      showXDeviceTrackings: false,
      showYDeviceTrackings: false,
      vehicleFilters: vehicleFiltersInitialValues,
      showAllVehicleInsights: false,
      vehicleInsightsFilters: {},
      showAllCityInsights: false,
      cityInsightsFilters: [],
      showAllHaulerLocations: false,
      haulerLocationsFilters: [],
      geoFencesTypesFilters: [],
      geoFenceSubFilters: {},
      geoFenceSearchTerm: '',
    };
  };

  const handleExitDrawSelectionMode = () => {
    setSelectedSegments && setSelectedSegments([]);
    !editPolygon && setEditPolygon(true);
    dispatch(setIsDrawSelectionModeOn(false));
  };

  const handleEnterDrawSelectionMode = () => {
    !editPolygon && setEditPolygon(true);
    dispatch(setIsDrawSelectionModeOn(true));
  };

  const selectPointsInPolygon = useCallback(
    (segments: StreetNetwork[], newPolygon: any) => {
      const includedPoints = segments
        .filter((p: StreetNetwork) => {
          const lineSegmentFormatted = JSON.parse(p.lineSegment);
          const lineSegment = turf.lineString(lineSegmentFormatted);
          const polygon = turf.polygon([newPolygon[0].geometry?.coordinates[0]]);
          return booleanIntersects(lineSegment, polygon) && p.id;
        })
        .map((p: StreetNetwork) => p.id);
      if (setSelectedSegments) setSelectedSegments(includedPoints);
      setPolygon(newPolygon);
    },
    [setSelectedSegments],
  );

  const selectPointsInPolygonDebounced = useMemo(() => debounce(selectPointsInPolygon, 200), [selectPointsInPolygon]);

  const checkSegmentsInPolygon = (event: any) => {
    const newPolygon = event.data;
    setPolygon(newPolygon);
    if (event.editType === 'addFeature') {
      selectPointsInPolygon(filteredStreetNetwork, newPolygon);
      setDrawMode(new EditingMode());
    } else if (event.editType === 'movePosition') {
      selectPointsInPolygonDebounced(filteredStreetNetwork, newPolygon);
    }
  };
  /************************************************
   * GEO-FENCES Management
   ************************************************/
  //streetNetwork bulkGeoFences

  const handleEditSaveGeoFences = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const geoJsonToSave = map(mapGeoFences, geoFence => {
      const hasOnlyOnePolygon = geoFence.polygons.length === 1;
      const geoFenceJson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: hasOnlyOnePolygon ? 'Polygon' : 'MultiPolygon',
          coordinates: hasOnlyOnePolygon
            ? [geoFence.polygons[0].coordinates]
            : geoFence.polygons.map(polygon => [polygon.coordinates]),
        },
      };
      return {
        id: geoFence.id,
        name: geoFence.name,
        geoFenceJson: JSON.stringify(geoFenceJson),
      };
    }) as GeoFenceJsonList;

    dispatch(resetGeoFences());
    setIsSaveGeoFence(true);

    saveGeoFences(
      geoJsonToSave,
      vendorId,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.geoFences.alertMessages.changesSaved'));
        dispatch(setIsEditGeoFencesModeOn(false));

        loadSelectedGeoFences(geoFenceSubFilters, geoFencesTypesFilters);
      })
      .catch(() => {
        createErrorNotification(translate(`routes.geoFences.alertMessages.changesSavedError`));
      });
  };

  const handleEditCancelEditGeoFences = () => {
    if (isEditGeoFencesModeOn) {
      dispatch(setIsEditGeoFencesModeOn(false));
      setDrawMode(undefined);
      unselectGeoFencePolygon();
      removeUnsavedGeoFenceOrPolygons();
      if (editedGeoFences && editorRef.current) {
        setEditedGeoFences(undefined);
      }
    } else {
      dispatch(setIsEditGeoFencesModeOn(true));
      setDrawMode(new EditingMode());
      const features = getAllGeoJsonForEditing();
      setEditedGeoFences(features);
    }
  };

  const updateGeoFenceHistoryDebounced = useMemo(
    () =>
      debounce((newPolygons: any) => {
        const updatedPolygon = find(newPolygons, (polygon: any) => {
          return (
            polygon.properties?.polygonId === selectedGeoFenceGeo?.polygonId &&
            polygon.properties?.geoFenceId === selectedGeoFenceGeo?.id
          );
        });

        if (updatedPolygon && selectedGeoFenceGeo) {
          updatePolygonInGeoFence(
            selectedGeoFenceGeo?.id,
            selectedGeoFenceGeo?.polygonId,
            updatedPolygon.geometry.coordinates[0],
          );
        }
      }, 200),
    [selectedGeoFenceGeo, updatePolygonInGeoFence],
  );

  const onEditRouteGeoFences = (event: any) => {
    const newPolygons = event.data;
    const last = newPolygons.length - 1;

    if (event.editType === 'addFeature' && isEditRouteGeoFenceModeOn) {
      setIsAddingPolygon(false);
      setDrawMode(new EditingMode());
      const polygonId = addPolygonToGeoFence(newPolygons[last].geometry.coordinates[0], geoFence?.id);
      setEditedRouteGeoFence([
        ...editedRouteGeoFence,
        { ...newPolygons[last], properties: { polygonId, geoFenceId: geoFence?.id } },
      ]);
    } else {
      updateGeoFenceHistoryDebounced(newPolygons);
      isEditRouteGeoFenceModeOn && setEditedRouteGeoFence(newPolygons);
    }
  };

  const onEditGeoFences = (event: any) => {
    const newPolygons = event.data;

    updateGeoFenceHistoryDebounced(newPolygons);
    isEditGeoFencesModeOn && setEditedGeoFences(newPolygons);
  };

  const handleUndoGeoFence = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedGeoFenceGeo) {
      undoPolygonInGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
    }
  };

  useEffect(() => {
    isEditGeoFencesModeOn && setEditedGeoFences(getAllGeoJsonForEditing());
  }, [getAllGeoJsonForEditing, isEditGeoFencesModeOn, mapGeoFences]);

  const handleSelectInGeoFenceEditor = useCallback(
    (selected: any) => {
      if (selected?.selectedFeatureIndex === null) {
        unselectGeoFencePolygon();
        return;
      }
      if (
        (selected?.selectedFeature?.properties?.polygonId || selected?.selectedFeature?.properties?.polygonId === 0) &&
        selected?.selectedFeature?.properties?.geoFenceId
      ) {
        selectGeoFencePolygon(
          selected?.selectedFeature?.properties?.geoFenceId,
          selected?.selectedFeature?.properties?.polygonId,
        );
      } else {
        const geoFenceId = geoFence?.id || NEW_GEO_FENCE_ID;
        selectGeoFencePolygon(geoFenceId, selected?.selectedFeatureIndex);
      }
    },
    [geoFence?.id, selectGeoFencePolygon, unselectGeoFencePolygon],
  );

  /// SINGLE GEO-FENCE (DAILY ROUTES)
  //this also takes care of undo for geo-fence editor
  useEffect(() => {
    if (isEditRouteGeoFenceModeOn) {
      const allFeatures = getAllGeoJsonForEditing();
      // filter out only the features that belong to the current geo-fence
      const currentGeoFence = allFeatures.filter(
        feature =>
          feature.properties?.geoFenceId === geoFence?.id || feature.properties?.geoFenceId === NEW_GEO_FENCE_ID,
      );

      setEditedRouteGeoFence(currentGeoFence);
    }
  }, [isEditRouteGeoFenceModeOn, mapGeoFences, getGeoJsonForGeoFence, getAllGeoJsonForEditing]);

  // action handlers
  const handleEnterDrawMode = useCallback(() => {
    dispatch(setIsEditRouteGeoFenceModeOn(true));
    if (!isEditSegmentsModeOn) setIsAddingPolygon(false);
    setIsStreetSegmentsMapFiltersOpen(false);
  }, [dispatch, isEditSegmentsModeOn]);

  const editGeoFence = useCallback(() => {
    dispatch(setIsEditRouteGeoFenceModeOn(true));
    if (formValues?.geoFence?.hasDeletedAllPolygons) {
      setIsAddingPolygon(true);
      setDrawMode(new DrawPolygonMode());
    } else {
      setDrawMode(new EditingMode());
    }
    setShowRouteGeoFence(true);
    geoFence && dispatch(change(STREET_SEGMENT_MAP_FILTERS_FORM_NAME, `geoFencesTypesFilters[${geoFence.id}]`, true));
    geoFence && setEditedRouteGeoFence(getGeoJsonForGeoFence(geoFence.id, geoFence));
    setIsStreetSegmentsMapFiltersOpen(false);
  }, [dispatch, geoFence, getGeoJsonForGeoFence]);

  const handleAddPolygon = useCallback(() => {
    dispatch(setIsEditRouteGeoFenceModeOn(true));
    setDrawMode(new DrawPolygonMode());
    setShowRouteGeoFence(true);
    geoFence && dispatch(change(STREET_SEGMENT_MAP_FILTERS_FORM_NAME, `geoFencesTypesFilters[${geoFence.id}]`, true));
    setIsAddingPolygon(true);
    unselectGeoFencePolygon();
  }, [dispatch, geoFence, unselectGeoFencePolygon]);

  const handleExitDrawMode = useCallback(() => {
    // If there is an unsaved geoFence remove it from the list
    dispatch(setIsEditRouteGeoFenceModeOn(false));
    unselectGeoFencePolygon();
    removeUnsavedGeoFenceOrPolygons();
    setIsAddingPolygon(false);
  }, [dispatch, removeUnsavedGeoFenceOrPolygons, unselectGeoFencePolygon]);

  const handleDeletePolygon = useCallback(() => {
    if (selectedGeoFenceGeo) {
      removePolygonFromGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
      setEditedRouteGeoFence([
        ...filter(editedRouteGeoFence, (p: any) => p.properties?.polygonId !== selectedGeoFenceGeo.polygonId),
      ]);
      unselectGeoFencePolygon();
    }
  }, [selectedGeoFenceGeo, removePolygonFromGeoFence, editedRouteGeoFence, unselectGeoFencePolygon]);

  // SINGLE GEO-FENCE (ROUTE TEMPLATES)
  const geoFenceId = useMemo(() => {
    if (stateGeoFence && stateGeoFence.id) if (routeTemplateId) return stateGeoFence.id;
    return undefined;
  }, [stateGeoFence, routeTemplateId]);

  const updateGeoFence = useCallback(() => {
    if (mapInstance) {
      let geoFence;
      if (geoFenceId && checkIfGeoFenceExists(geoFenceId)) {
        geoFence = find(mapGeoFences, { id: geoFenceId });
      } else {
        geoFence = find(mapGeoFences, { id: NEW_GEO_FENCE_ID });
      }
      if (geoFence) {
        const hasOnlyOnePolygon = geoFence.polygons.length === 1;
        const coordinates = hasOnlyOnePolygon
          ? [[geoFence.polygons[0].coordinates]]
          : geoFence.polygons.map(polygon => [polygon.coordinates]);

        dispatch(
          change(
            SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME,
            'geoFence.geoFenceCoordinates',
            geoFence.polygons.length && coordinates ? coordinates : [],
          ),
        );
        if (geoFence.polygons.length === 0)
          dispatch(change(SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME, 'geoFence.hasDeletedAllPolygons', true));
        else dispatch(change(SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME, 'geoFence.hasDeletedAllPolygons', false));
      }
    }
  }, [checkIfGeoFenceExists, dispatch, geoFenceId, mapGeoFences, mapInstance]);

  const handleSaveGeoFence = useCallback(() => {
    updateGeoFence();

    handleExitDrawMode();
  }, [handleExitDrawMode, updateGeoFence]);

  const getFeatureStyle = ({
    feature,
    index,
    state,
  }: {
    feature: any;
    index: number;
    state: 'SELECTED' | 'HOVERED' | 'INACTIVE' | 'UNCOMMITTED' | 'CLOSING';
  }) => {
    const isSelectedOrHovered = state === 'SELECTED' || state === 'HOVERED';
    const isSameGeoFence = feature.properties.geoFenceId === selectedGeoFenceGeo?.id;
    const isSamePolygon = feature.properties.polygonId === selectedGeoFenceGeo?.polygonId;
    const cursor =
      drawMode instanceof EditingMode ? 'pointer' : drawMode instanceof DrawPolygonMode ? 'crosshair' : 'default';
    if ((isSelectedOrHovered || isSameGeoFence) && !(isRouteTemplate || isDailyRoute))
      return {
        stroke: GEO_FENCE_OPTIONS_SELECTED.strokeColor,
        fill: GEO_FENCE_OPTIONS_SELECTED.fillColor,
        strokeWidth: 2,
        fillOpacity: 0.1,
        cursor,
      };

    if (isSelectedOrHovered && isSamePolygon && (isRouteTemplate || isDailyRoute))
      return {
        stroke: GEO_FENCE_OPTIONS_SELECTED.strokeColor,
        fill: GEO_FENCE_OPTIONS_SELECTED.fillColor,
        strokeWidth: 2,
        fillOpacity: 0.1,
        cursor,
      };

    return {
      stroke: isSatelliteView ? GEO_FENCE_OPTIONS_SAT.strokeColor : GEO_FENCE_OPTIONS.strokeColor,
      fill: isSatelliteView ? GEO_FENCE_OPTIONS_SAT.fillColor : GEO_FENCE_OPTIONS.fillColor,
      strokeWidth: 2,
      fillOpacity: 0.1,
      cursor,
    };
  };
  /************************************************
   * End of GEO-FENCES Management
   * *********************************************/

  // AUTO_REFRESH - WHEN THE ROUTE IS IN PROGRESS
  // refresh the vehicle positions and trackings if route is in progress
  const REFRESH_INTERVAL = 60000; // 1 minute

  const refreshVehiclePositionsAndTrackings = useCallback(async () => {
    if (
      routeStatusTypeId === IN_PROGRESS &&
      getIsVendorNotChanged(vendorId) &&
      Cookie.get(SESSION_COOKIE_KEY) &&
      !isEditSegmentsModeOn &&
      !isEditGeoFencesModeOn &&
      !isDrawSelectionModeOn &&
      !isEditRouteGeoFenceModeOn
    ) {
      setIsAutoRefresh(true);
      await loadVendor(vendorId)(dispatch);

      // have to wrap it in a async timeout to wait for the auto refresh to be set
      setTimeout(() => {
        dispatch(submit(STREET_SEGMENT_MAP_FILTERS_FORM_NAME));
      });
    }
  }, [
    dispatch,
    isDrawSelectionModeOn,
    isEditGeoFencesModeOn,
    isEditRouteGeoFenceModeOn,
    isEditSegmentsModeOn,
    routeStatusTypeId,
    vendorId,
  ]);

  useRefreshDataInterval({
    callback: refreshVehiclePositionsAndTrackings,
    interval: REFRESH_INTERVAL,
    refreshOnWindowFocus: true,
  });

  const driversOptions = map(routeMapFilters.drivers, driver => ({
    driverName: `${driver.firstName} ${driver.lastName}`,
  }));

  const driversColorsMap = useMemo(() => {
    if (!routeSegments) return {};
    const colorsMap = getSegmentColorsLegendBasedOnDriversPasses(driversOptions);
    if (size(colorsMap) === 0) return {};
    return colorsMap;
  }, [routeSegments]);

  // TODO uncomment this when creating alert is enabled on map
  // const cityAlertsFilters = useMemo(
  //   () =>
  //     map(activeCityAlertTypes, cityAlertType => ({
  //       id: cityAlertType.cityAlertType.id,
  //       label: cityAlertType.cityAlertType.name,
  //     })),
  //   [activeCityAlertTypes],
  // );

  // const handleLoadCityAlertsWhenCreatedNew = useCallback(() => {
  //   const newValues = cityAlertsFilters.reduce((acc, filter) => {
  //     acc[filter.id] = true;
  //     return acc;
  //   }, [] as any);
  //   dispatch(change(STREET_SEGMENT_MAP_FILTERS_FORM_NAME, 'cityAlertsFilters', newValues));
  //   dispatch(change(STREET_SEGMENT_MAP_FILTERS_FORM_NAME, 'cityAlertsFilters_checkAll', true));
  //   dispatch(submit(STREET_SEGMENT_MAP_FILTERS_FORM_NAME));
  // }, [cityAlertsFilters, dispatch]);

  const debounceSetLayerFilter = useMemo(
    () =>
      debounce((param: number | turf.BBox) => {
        if (typeof param === 'number') {
          param >= 11
            ? setLayerFilter(6)
            : param >= 10
            ? setLayerFilter(5)
            : param >= 7
            ? setLayerFilter(4)
            : setLayerFilter(2);
        } else {
          setLayerFilter(param);
        }
      }, 100),
    [],
  );

  // set street class on every zoom end
  useEffect(() => {
    mapInstance?.once('load', () => {
      mapInstance.on('zoomend', () => {
        const bbox = mapInstance.getBounds().toArray().flat() as turf.BBox;
        if (isStreetNetwork)
          mapInstance.getZoom() >= 14 ? debounceSetLayerFilter(bbox) : debounceSetLayerFilter(mapInstance.getZoom());
      });
      mapInstance.on('moveend', () => {
        const bbox = mapInstance.getBounds().toArray().flat() as turf.BBox;
        if (isStreetNetwork && mapInstance?.getZoom() > 14) debounceSetLayerFilter(bbox);
      });
    });
  }, [debounceSetLayerFilter, mapInstance, serviceAreas]);

  // load street network based on the zoom level
  const handleLoadStreetNetworkDataLayers = useCallback(
    (layerFilter: number | turf.BBox, noLoadingIndicator?: boolean) => {
      loadStreetNetworkDataLayers(vendorId, layerFilter, noLoadingIndicator)(dispatch);
    },
    [dispatch, vendorId],
  );

  // load on zoom change
  useEffect(() => {
    const shouldLoadStreetNetwork =
      isStreetNetwork &&
      !!layerFilter &&
      ((typeof layerFilter === 'number' &&
        !streetNetworkDataLayers?.find(
          streetNetworkDataLayer => streetNetworkDataLayer.zoomClassNumber === layerFilter,
        )) ||
        typeof layerFilter !== 'number') &&
      !!serviceAreas?.length &&
      !serviceAreas[0].isLocked;
    const noLoadingIndicator = false;

    if (shouldLoadStreetNetwork && getIsVendorNotChanged(vendorId))
      handleLoadStreetNetworkDataLayers(layerFilter, noLoadingIndicator);
  }, [layerFilter, handleLoadStreetNetworkDataLayers]);

  // refresh street network
  const refreshStreetNetwork = useCallback(() => {
    const shouldRefreshStreetNetwork = isStreetNetwork && !!serviceAreas?.length && serviceAreas[0].isLocked;
    const noLoadingIndicator = true;

    if (shouldRefreshStreetNetwork && getIsVendorNotChanged(vendorId)) {
      loadStreetNetworkServiceAreas(vendorId, noLoadingIndicator)(dispatch);
      handleLoadStreetNetworkDataLayers(layerFilter, noLoadingIndicator);
    }
  }, [serviceAreas, dispatch, vendorId]);

  useRefreshDataInterval({
    callback: refreshStreetNetwork,
    interval: 5000,
    refreshOnWindowFocus: true,
  });

  useEffect(() => {
    setTimeout(() => {
      if (!!serviceAreas?.length && !streetNetworkDataLayers.length && !streetNetworkWithBbox)
        dispatch(clearStreetNetworkDataLayers());
    }, 0);
  }, []);

  return (
    <MapGLWrapper
      drawingEnabled={!!drawMode && editPolygon}
      isDrawing={drawMode && drawMode instanceof DrawPolygonMode}
      isLoading={
        isLoading ||
        isLoadingRouteMapData ||
        isLoadingRouteVehicleTrackings ||
        isLoadingGeoFences ||
        isDownloadingGeoJsonFile ||
        isLoadingCityAlerts
      }
    >
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={setMapInstance}
        setIsSatelliteViewEnabled={setIsSatelliteView}
      >
        {/* TODO uncomment this when creating alert is enabled on map */}
        {/* {isDailyRoute && (
          <CityAlertCreateTrigger
            xOffset={0}
            yOffset={50}
            position="top-right"
            onSavedSuccess={handleLoadCityAlertsWhenCreatedNew}
            routeId={routeId}
          />
        )} */}
        {!!size(filteredStreetNetwork) && (
          <StreetNetworkMapLegend
            driversColorsMap={driversColorsMap}
            isDailyRoute={isDailyRoute}
            isDriverExperienceComplex={isDriverExperienceComplex}
            isRouteTemplate={isRouteTemplate}
            isSatelliteView={isSatelliteView}
            isSnowPlowRoute={isSnowPlowRoute}
            routeId={routeId}
          />
        )}
        <StreetNetworkMapControls
          editGeoFence={editGeoFence}
          editPolygon={editPolygon}
          geoFenceEditing={geoFenceEditing}
          geoFenceExists={geoFenceExists}
          geoFenceId={geoFenceId}
          handleAddPolygon={handleAddPolygon}
          handleDeletePolygon={handleDeletePolygon}
          handleEditCancelEditGeoFences={handleEditCancelEditGeoFences}
          handleEditSaveGeoFences={handleEditSaveGeoFences}
          handleEnterDrawMode={handleEnterDrawMode}
          handleEnterDrawSelectionMode={handleEnterDrawSelectionMode}
          handleExitDrawMode={handleExitDrawMode}
          handleExitDrawSelectionMode={handleExitDrawSelectionMode}
          handleSaveGeoFence={handleSaveGeoFence}
          handleUndoGeoFence={handleUndoGeoFence}
          hasChanges={hasChanges}
          hasDeletedAllPolygons={formValues?.geoFence?.hasDeletedAllPolygons}
          isAddingPolygon={isAddingPolygon}
          isDailyRoute={isDailyRoute}
          isRouteTemplate={isRouteTemplate}
          isRouteTemplateEditor={isRouteTemplateEditor}
          isSnowPlowRoute={isSnowPlowRoute}
          isStreetNetwork={isStreetNetwork}
          isStreetSweeperRoute={isStreetSweeperRoute}
          polygon={polygon}
          selectedGeoFenceGeo={selectedGeoFenceGeo}
          setEditPolygon={setEditPolygon}
          setIsStreetSegmentsMapFiltersOpen={setIsStreetSegmentsMapFiltersOpen}
          setRouteMapActionsOpen={setRouteMapActionsOpen}
          setShowRouteGeoFence={setShowRouteGeoFence}
          showRouteGeoFence={showRouteGeoFence}
        />
        {drawMode && (isEditSegmentsModeOn || isEditGeoFencesModeOn || isEditRouteGeoFenceModeOn) && (
          <Editor
            ref={editorRef}
            clickRadius={12}
            mode={drawMode}
            onUpdate={
              isEditSegmentsModeOn && !isEditGeoFencesModeOn && !isEditRouteGeoFenceModeOn
                ? checkSegmentsInPolygon
                : isEditGeoFencesModeOn
                ? onEditGeoFences
                : isEditRouteGeoFenceModeOn
                ? onEditRouteGeoFences
                : () => {}
            }
            features={
              (isEditSegmentsModeOn && !isEditGeoFencesModeOn && !isEditRouteGeoFenceModeOn
                ? polygon
                : isEditGeoFencesModeOn
                ? editedGeoFences
                : isEditRouteGeoFenceModeOn
                ? editedRouteGeoFence
                : []) || []
            }
            onSelect={isEditSegmentsModeOn && !isEditRouteGeoFenceModeOn ? () => {} : handleSelectInGeoFenceEditor}
            featureStyle={getFeatureStyle}
          />
        )}
        {!!mapInstance && (
          <>
            {isStreetNetwork ? (
              <>
                <StreetNetworkDataGL zoomClassNumber={typeof layerFilter === 'number' ? layerFilter : undefined} />
                <StreetNetworkMapInformation message={errorMessage} />
              </>
            ) : (
              <>
                <StreetNetworkMapRouteSegmentsGL
                  isDailyRoute={isDailyRoute}
                  isEditMode={isEditSegmentsModeOn}
                  isRouteTemplate={isRouteTemplate}
                  isSatelliteView={isSatelliteView}
                  isSnowPlowRoute={isSnowPlowRoute}
                  isStreetSweeperRoute={isStreetSweeperRoute}
                  map={mapInstance}
                  routeId={routeId}
                  routeSegments={routeSegments}
                  selectedSegmentIds={selectedSegments}
                  streetNetwork={!noData ? filteredStreetNetwork : []}
                  driversColorMap={driversColorsMap}
                />

                {!isEditGeoFencesModeOn &&
                  !isEditRouteGeoFenceModeOn &&
                  (isRouteTemplate ? !isDrawSelectionModeOn : true) && (
                    <>
                      <RouteMapGeoFencesGL
                        map={mapInstance}
                        geoFencesGeoJSON={allGeoFencesGeoJson}
                        isSatellite={isSatelliteView}
                      />
                      <RouteMapTravelPathGL map={mapInstance} />
                    </>
                  )}

                {routeId && !isEditSegmentsModeOn && (
                  <>
                    <StreetNetworkMapClustersGL
                      map={mapInstance}
                      vehicleTrackings={filteredVehicleTrackings}
                      vehiclePositions={filteredVehiclePositions}
                      vehicleInsights={filteredVehicleInsights}
                      cityInsights={filteredCityInsights}
                    />

                    <RouteMapVehiclePositionsGL map={mapInstance} />
                    <RouteMapVehicleInsightsGL map={mapInstance} />
                    <RouteMapCityInsightsGL map={mapInstance} />
                    <CityAlertsGL map={mapInstance} />
                    <RouteMapHaulerLocationsGL map={mapInstance} />
                    <RouteMapVehicleTrackingsGL map={mapInstance} vehicleTrackings={filteredVehicleTrackings} />
                    <RouteMapApplicationStatusGL map={mapInstance} />
                  </>
                )}
              </>
            )}
          </>
        )}
        {routeStatusTypeId === IN_PROGRESS &&
          !isEditSegmentsModeOn &&
          !isEditGeoFencesModeOn &&
          !isDrawSelectionModeOn &&
          !isEditRouteGeoFenceModeOn && (
            <MapLastRefresh
              isSatelliteView={isSatelliteView}
              lastRefreshed={lastRefreshed}
              onRefresh={refreshVehiclePositionsAndTrackings}
            />
          )}
      </MapGL>

      <StreetSegmentsMapFiltersForm
        closeStreetSegmentsMapFilters={() => setIsStreetSegmentsMapFiltersOpen(false)}
        handleApplyLastFilters={handleApplyLastFilters}
        initialValues={getFiltersFormInitialValues()}
        isDailyRoute={isDailyRoute}
        isDriverExperienceComplex={isDriverExperienceComplex}
        isEditMode={isEditSegmentsModeOn}
        isNoRoute={isNoRoute}
        isRouteTemplate={isRouteTemplate}
        isSnowPlowRoute={isSnowPlowRoute}
        isStreetSegmentsMapFiltersOpen={isStreetSegmentsMapFiltersOpen}
        isStreetSweeperRoute={isStreetSweeperRoute}
        numberOfSegments={size(routeSegments) || numberOfRouteSegments}
        onSubmit={handleSubmitFilters}
        routeId={routeId}
        routeStatusTypeId={routeStatusTypeId}
        routeTemplateId={routeTemplateId}
        routeName={routeName}
        routeDate={routeDate}
      />

      <StreetNetworkRouteMapActions
        isRouteMapActionsOpen={isRouteMapActionsOpen}
        closeRouteMapActions={() => {
          dispatch(setIsEditRouteGeoFenceModeOn(false));
          setRouteMapActionsOpen(false);
        }}
        isEditingSegments={isEditSegmentsModeOn}
        isAddingPolygon={isAddingPolygon}
        polygonsInEdit={editedRouteGeoFence}
        enterDrawMode={handleEnterDrawMode}
        exitDrawMode={handleExitDrawMode}
        editGeoFence={editGeoFence}
        handleAddPolygon={handleAddPolygon}
        handleDeletePolygon={handleDeletePolygon}
        drawMode={drawMode}
        showRouteGeoFence={(id?: number) => {
          setShowRouteGeoFence(true);
          (geoFence || id) &&
            dispatch(
              change(STREET_SEGMENT_MAP_FILTERS_FORM_NAME, `geoFencesTypesFilters[${geoFence?.id || id}]`, true),
            );
        }}
        removeRouteGeoFence={removeGeoFence}
        undoDraw={handleUndoGeoFence}
        selectedGeoFence={selectedGeoFenceGeo}
        routeTemplateId={routeTemplateId}
      />

      {drawMode && (isDailyRoute || isRouteTemplate) && <DrawingInstructions />}
    </MapGLWrapper>
  );
};

const formSelector = formValueSelector(STREET_SEGMENT_MAP_FILTERS_FORM_NAME);
export const formSegmentsFilterDateRange = formValueSelector(SNOW_SWEEPER_DATE_PICKER_FORM);

const mapStateToProps = (state: AppState) => ({
  geoFencesTypesFilters: formSelector(state, 'geoFencesTypesFilters'),
  geoFenceSubFilters: formSelector(state, 'geoFenceSubFilters'),
  lastActivity: formSelector(state, 'lastActivity'),
  segmentsFilterEndDate: formSegmentsFilterDateRange(state, 'endDate'),
  segmentsFilterStartDate: formSegmentsFilterDateRange(state, 'startDate'),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadGeoFences,
  loadStreetNetwork,
  loadStreetNetworkSegmentDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(StreetNetworkMapGL);
