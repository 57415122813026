import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { find } from 'lodash-es';

import { ActionButtonTooltip, Icon, PopoverWrapper, UnconnectedCheckbox } from 'src/core/components';
import { checkIfViewOnly, checkIfSupport } from 'src/account/utils/permissions';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { dateAndTime } from 'src/utils/services/formatter';
import { getMapBounds } from 'src/common/components/map/util';
import {
  calculateIsDriverExperienceSimple,
  getPassForwardColor,
  getPassReverseColor,
} from 'src/customers/components/pages/streetNetwork/utils';
import { getSegmentCenter, parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import {
  Grid,
  GridColumn,
  Label,
  Popover,
  TableActionButton,
  TableCell,
  TableRow,
  Text,
} from 'src/core/components/styled';
import { loadStreetNetworkSegmentDetails, setStreetNetworkMapViewport } from 'src/customers/ducks';
import { PICKUP_STATUS_LABEL_COLORS, SCHEDULED } from 'src/routes/constants';
import { RouteLocationImagesModalResolver, RouteLocationIssuesModalResolver } from '../../modals';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { setRouteMapSelectedFeature, setRouteMapViewport } from 'src/routes/ducks';
import { SNOW_OR_SWEEPER_ROUTE_STATUSES } from 'src/common/constants';
import {
  StatusContainer,
  PassesStatusComplex,
  PlowHistoryContent,
  PlowHistoryHeader,
  PlowHistoryIcon,
} from '../../styled';
import { STREET_SEGMENT_SERVICE_SIDES } from 'src/customers/constants/streetNetwork';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props extends StreetNetwork {
  passDateTime?: string;
  rowHeight?: number;
  selectedSegments: number[];
  editSegment: (id: number) => void;
  selectSegment: (id: number) => void;
  deleteSegment: (id: number, routeStopId: number) => void;
}

type ComponentProps = Props & RouteSegment;

export default function StreetSweeperSegmentsTableRow({
  customerName,
  deleteSegment,
  forwardPasses,
  forwardStatusId,
  id,
  isActive,
  isOneWay,
  isSelected,
  lineSegment,
  name,
  numberOfImages,
  numberOfIssues,
  numberOfPasses,
  passDateTime,
  reversePasses,
  reverseStatusId,
  routeStopId,
  selectedSegments,
  selectSegment,
  serviceSideTypeId,
  statusId,
  streetPasses,
}: ComponentProps) {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const [lastStreetPass, setLastStreetPass] = useState<any>();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { wasteAuditTypes } = useSelector(state => state.common.wasteAuditTypes);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { isEditSegmentsModeOn } = useSelector(state => state.customers.streetNetwork);

  const [isRouteStopIssuesModalOpen, setRouteStopIssuesModalOpen] = useState(false);
  const [isRouteStopImagesModalOpen, setRouteStopImagesModalOpen] = useState(false);

  useEffect(() => {
    if (streetPasses) {
      setLastStreetPass(streetPasses[0]);
    }
  }, [setLastStreetPass, streetPasses]);

  if (!routeSummary) return null;

  const routeId = routeSummary.routeId;
  const vehicleTypeId = routeSummary.vehicleTypeId;
  const wasteAuditType = wasteAuditTypes.find(wasteAuditType => wasteAuditType.id === routeSummary.wasteAuditTypeId);
  const status = isActive ? translate('common.active') : translate('common.inactive');
  const serviceSide = STREET_SEGMENT_SERVICE_SIDES.find(el => el.id === serviceSideTypeId);
  const serviceSideName = serviceSide ? serviceSide.label : '-';
  // TODO: when data is available display the number of lanes back
  // const numberOfLanes = numberOfPasses;
  const direction = isOneWay
    ? translate('customers.streetNetwork.oneWay')
    : translate('customers.streetNetwork.twoWay');

  const handleClick = (event: any) => {
    const isTableRowClicked = event ? event.target.parentNode.tagName === 'DIV' : false;
    if (isTableRowClicked) {
      const parsedSegment = parseSegmentJSON(lineSegment);
      const segmentCenter = getSegmentCenter(parsedSegment);
      const point = { longitude: segmentCenter[0], latitude: segmentCenter[1] };
      const bounds = getMapBounds([point], {
        padding: 100,
        capZoom: 16,
      });

      dispatch(setStreetNetworkMapViewport(bounds));
      dispatch(setRouteMapViewport(bounds));
      loadStreetNetworkSegmentDetails(vendorId, Number(id), routeId, routeSummary.vehicleTypeId)(dispatch);
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeSegments, Number(id), { noTableScroll: true }));
    }
  };

  const totalPasses = numberOfPasses || (forwardPasses || 0) + (reversePasses || 0);

  const isSnowPlowRoute = false;
  const isDriverExperienceSimple = calculateIsDriverExperienceSimple(isSnowPlowRoute);

  return (
    <>
      <TableRow
        onClick={handleClick}
        height={TABLE_ROW_HEIGHT_LARGE}
        isHighlighted={!!find(selectedSegments, s => s === id) || selectedFeature?.id === id}
      >
        {isEditSegmentsModeOn && (
          <TableCell width="3%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
            {!checkIfViewOnly() && !checkIfSupport() && (
              <UnconnectedCheckbox
                block
                checked={isSelected}
                onChange={e => selectSegment(id)}
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            )}
          </TableCell>
        )}
        <TableCell width={`${isEditSegmentsModeOn ? '16%' : '19%'}`}>
          <Text block singleLine title={name}>
            {name}
          </Text>
        </TableCell>
        <TableCell width="9%">{status}</TableCell>
        <TableCell width="14%">
          <Label color={PICKUP_STATUS_LABEL_COLORS[statusId || SCHEDULED]}>
            {SNOW_OR_SWEEPER_ROUTE_STATUSES[statusId || SCHEDULED].name}
          </Label>
        </TableCell>
        {/* TODO: when data is available display the number of lanes back
         <TableCell width="5%">
           {numberOfLanes || '-'}
        </TableCell> */}
        <TableCell width="11%">
          <StatusContainer vertical>
            <PassesStatusComplex
              forwardColor={getPassForwardColor(forwardStatusId, reverseStatusId)}
              reverseColor={
                isDriverExperienceSimple ? undefined : getPassReverseColor(forwardStatusId, reverseStatusId, isOneWay)
              }
            ></PassesStatusComplex>
            <Text whiteSpace="nowrap" margin="no no no small">{`${streetPasses ? streetPasses.length : 0}/${
              totalPasses || 0
            }`}</Text>
            {!!lastStreetPass && lastStreetPass?.isAssisting && (
              <TableActionButton margin="no small">
                <ActionButtonTooltip
                  icon="infoFill"
                  tooltipProps={{ vehicleName: lastStreetPass?.vehicleName }}
                  tooltip="assistedBy"
                />
              </TableActionButton>
            )}
          </StatusContainer>
        </TableCell>

        <TableCell width="16%">
          <Grid>
            <GridColumn width="100%" padding="no">
              <Text block singleLine size="medium" title={passDateTime ? dateAndTime(passDateTime) : '-'}>
                {passDateTime ? dateAndTime(passDateTime) : '-'}
              </Text>
              <Text block size="small" margin="xxSmall no no">
                {lastStreetPass?.vehicleName}
                {lastStreetPass?.driverName && ` / ${lastStreetPass?.driverName}`}
              </Text>
            </GridColumn>
          </Grid>
        </TableCell>

        <TableCell width="10%">{direction}</TableCell>
        <TableCell width="11%">{serviceSideName}</TableCell>

        <TableCell width="9%" align="right">
          {!!numberOfIssues && (
            <TableActionButton onClick={() => setRouteStopIssuesModalOpen(true)}>
              <ActionButtonTooltip icon="issue" tooltip="issues" content={numberOfIssues} />
            </TableActionButton>
          )}

          {!!numberOfImages && (
            <TableActionButton onClick={() => setRouteStopImagesModalOpen(true)}>
              <ActionButtonTooltip icon="image" tooltip="images" content={numberOfImages} />
            </TableActionButton>
          )}
          {streetPasses && !!streetPasses.length && (
            <PopoverWrapper
              triggerButton={
                <PlowHistoryIcon>
                  <Icon icon="plowHistory" height="14" />
                </PlowHistoryIcon>
              }
              popoverContent={
                <Popover>
                  <Text align="center" block size="large" weight="medium" margin="no no small no">
                    {translate('routes.streetSweeper.sweepHistory')}
                  </Text>
                  <PlowHistoryHeader>
                    <Text margin="no no xSmall no">
                      <b>{translate('routes.snowPlow.vehicle')}</b>
                    </Text>
                    <Text minWidth="160" align="right" margin="no no no small">
                      <b>{translate('routes.snowPlow.dateAndTime')}</b>
                    </Text>
                  </PlowHistoryHeader>
                  <PlowHistoryContent>
                    {streetPasses.map((pass, index) => {
                      return (
                        <Text justifyContent="space-between" key={index} flex align="left" margin="no no xSmall no">
                          {pass.vehicleName}
                          {pass.passDateTime && (
                            <Text minWidth="160" align="right" margin="no no no small">
                              {dateAndTime(pass.passDateTime)}
                            </Text>
                          )}
                        </Text>
                      );
                    })}
                  </PlowHistoryContent>
                </Popover>
              }
              size="large"
            />
          )}
          {isEditSegmentsModeOn && (
            <TableActionButton onClick={() => deleteSegment(id, routeStopId)} margin="no no no small">
              <ActionButtonTooltip size="sMedium" icon="delete" tooltip="deleteSegment" />
            </TableActionButton>
          )}
        </TableCell>
      </TableRow>
      {isRouteStopIssuesModalOpen && (
        <RouteLocationIssuesModalResolver
          modalTitle={customerName}
          modalSubTitle={name}
          routeId={routeId}
          routeLocationId={routeStopId}
          closeModal={() => setRouteStopIssuesModalOpen(false)}
          wasteAuditType={wasteAuditType}
          vehicleTypeId={vehicleTypeId}
        />
      )}

      {isRouteStopImagesModalOpen && (
        <RouteLocationImagesModalResolver
          routeId={routeId}
          routeLocationId={routeStopId}
          closeModal={() => setRouteStopImagesModalOpen(false)}
        />
      )}
    </>
  );
}
