import { round } from 'lodash-es';

import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { DashboardContainerItemsApart, DashboardListItemContainer } from '../../styled/DashboardFilterMapbox';
import { IN_PROGRESS, LABEL_COLORS, ROUTE_STATUSES_BY_ID } from 'src/routes/constants';
import { Label, TableActionButton, Text } from 'src/core/components/styled';
import { RoutesListItem } from 'src/dashboard/interfaces/routesData';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import routeAssistance from 'src/common/assets/img/insights/routeAssistance.png';
import translate from 'src/core/services/translate';

interface Props {
  onSelectRoute: (
    routeId: number,
    isSourceAdvancedSearch?: boolean,
    routeLocationId?: number,
    date?: Date | string,
    isSnowPlowOrStreetSweeperRoute?: boolean,
  ) => void;
  route: RoutesListItem;
}

const DashboardFilterRouteItemMapbox = ({ onSelectRoute, route }: Props) => {
  const isSnowPlowOrStreetSweeperRoute = [STREET_SWEEPER_ID, SNOW_PLOW_ID].includes(route.vehicleType?.id);

  return (
    <DashboardListItemContainer
      onClick={() => onSelectRoute(route.id, false, undefined, undefined, isSnowPlowOrStreetSweeperRoute)}
      key={`${route.id}_${route.routeName}`}
      isActive={true}
    >
      <DashboardContainerItemsApart>
        <Text weight="medium" singleLine title={route.routeName}>
          {route.routeName}
        </Text>
        <Box display="inline-flex">
          <Label color={LABEL_COLORS[route.statusType?.id]}>
            {`${ROUTE_STATUSES_BY_ID[route.statusType?.id]?.name} - ${round(route.completedPercentage).toFixed(0)}%`}
          </Label>
          {!!route.assistingVehiclesCount && (
            <TableActionButton margin="no no no xxSmall">
              <ActionButtonTooltip
                imageSrc={routeAssistance}
                tooltip={
                  route.assistingVehiclesCount
                    ? route.statusType?.id === IN_PROGRESS
                      ? 'assistingVehicles'
                      : 'assistedVehicles'
                    : undefined
                }
                tooltipProps={{ numberOfAssistingVehicles: route.assistingVehiclesCount }}
              />
            </TableActionButton>
          )}
        </Box>
      </DashboardContainerItemsApart>
      <DashboardContainerItemsApart>
        <Text margin="xxSmall no no">{VEHICLE_TYPE_IDS[route.vehicleType?.id].name}</Text>
        <Text margin="xxSmall no no">{`${route.completedStopsCount} / ${route.totalStopsCount} ${
          isSnowPlowOrStreetSweeperRoute ? translate('routes.segments') : translate('routes.stops')
        }`}</Text>
      </DashboardContainerItemsApart>
    </DashboardListItemContainer>
  );
};

export default DashboardFilterRouteItemMapbox;
