import { size } from 'lodash-es';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import {
  COMPLETED,
  SCHEDULED,
  SNOW_OR_SWEEPER_ROUTE_LIST_STATUSES,
  SNOW_OR_SWEEPER_ROUTE_STATUSES,
} from 'src/common/constants';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Grid, GridColumn, Label, Separator, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { STREET_SEGMENT_SERVICE_SIDES } from 'src/customers/constants/streetNetwork';
import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { getSegmentCenter, parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import { RouteMapFeature, clearRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { dateAndTime } from 'src/utils/services/formatter';
import { RouteSegmentExtended } from './StreetNetworkMapGL';
import { getTypesAsString } from './StreetNetworkMapRouteSegmentsGLPopup';

type Props = {
  isDashboardPage?: boolean;
  isSnowPlowRoute?: boolean;
  isRouteTemplate?: boolean;
  routeSegments?: RouteSegmentExtended[];
};

export default function StreetNetworkMapCenteredRouteSegmentsGLPopup({
  isDashboardPage,
  isSnowPlowRoute,
  isRouteTemplate,
  routeSegments,
}: Props) {
  const dispatch = useDispatch();
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { selectedFeature: selectedFeatureDashboard } = useSelector(state => state.dashboard.mapControls);

  const routeSegment = useMemo(() => {
    if (isDashboardPage && (!selectedFeatureDashboard || selectedFeatureDashboard.namespace !== 'routeSegments'))
      return undefined;
    if (!isDashboardPage && (!selectedFeature || selectedFeature.feature !== RouteMapFeature.routeSegments)) {
      return undefined;
    }
    let selectedSegment;
    if (routeSegments) {
      selectedSegment = routeSegments.find(routeSegment =>
        isDashboardPage ? routeSegment.id === selectedFeatureDashboard?.id : routeSegment.id === selectedFeature?.id,
      );
    }

    if (!selectedSegment) {
      return undefined;
    }

    const segmentCenter = getSegmentCenter(parseSegmentJSON(selectedSegment.lineSegment));

    return {
      ...selectedSegment,
      segmentCenter,
    };
  }, [isDashboardPage, selectedFeatureDashboard, selectedFeature, routeSegments]);

  const statusColor = useMemo(() => {
    if (!routeSegment || !routeSegment.statusId || routeSegment.statusId === SCHEDULED) return 'info';

    if (routeSegment.statusId === COMPLETED) return 'success';

    return 'warning';
  }, [routeSegment]);

  if (!routeSegment) return null;

  const lastStreetPass = routeSegment.streetPasses && routeSegment.streetPasses.length && routeSegment.streetPasses[0];
  const serviceSideTypeId = STREET_SEGMENT_SERVICE_SIDES.filter(side => side.id === routeSegment.serviceSideTypeId);
  const routeSegmentStatusId = isRouteTemplate ? routeSegment?.statusId : routeSegment?.statusId || SCHEDULED;

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={routeSegment?.segmentCenter[1]}
      longitude={routeSegment?.segmentCenter[0]}
      onClose={() =>
        isDashboardPage ? dispatch(clearDashboardSelectedFeature()) : dispatch(clearRouteMapSelectedFeature())
      }
    >
      <MapGLPopup minHeight={20}>
        <MapGLPopupTitle
          title={
            isDashboardPage
              ? routeSegment?.streetName
                ? routeSegment?.streetName
                : routeSegment?.name
              : SNOW_OR_SWEEPER_ROUTE_STATUSES[routeSegmentStatusId]?.name
          }
          secondTitle={
            isDashboardPage ? (
              <Box margin="xSmall no no no" alignItems="center" display="flex">
                <Label color={routeSegment.isActive ? 'success' : 'info'}>
                  {routeSegment.isActive
                    ? translate('customers.streetNetwork.active')
                    : translate('customers.streetNetwork.inactive')}
                </Label>
                {!isRouteTemplate && (
                  <>
                    <Text padding="no xSmall">/</Text>
                    <Label color={statusColor}>
                      {SNOW_OR_SWEEPER_ROUTE_LIST_STATUSES[routeSegment.statusId]?.name}
                    </Label>
                  </>
                )}
              </Box>
            ) : null
          }
        />
        {isDashboardPage ? (
          <MapGLPopupContent>
            {!isRouteTemplate && (
              <Grid multiLine margin="small no">
                {!!routeSegment.streetId && (
                  <GridColumn size="12/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.streetID')}
                      value={routeSegment.streetId}
                    />
                  </GridColumn>
                )}
                {!!lastStreetPass && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.lastPassDate')}
                      value={!!lastStreetPass.passDateTime ? dateAndTime(lastStreetPass.passDateTime) : '-'}
                    />
                  </GridColumn>
                )}
                {routeSegment.vehicleName && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.lastPassVehicle')}
                      value={routeSegment.vehicleName}
                    />
                  </GridColumn>
                )}
                {routeSegment.driverName && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.lastPassDriver')}
                      value={routeSegment.driverName}
                    />
                  </GridColumn>
                )}
                {routeSegment.routeName && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.lastPassRoute')}
                      value={routeSegment.routeName}
                    />
                  </GridColumn>
                )}
                <GridColumn size="6/12">
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('routes.snowPlow.totalRecordedPasses')}
                    value={`${routeSegment.streetPasses ? routeSegment.streetPasses.length : 0} / ${
                      (routeSegment.forwardPasses || 0) + (routeSegment.reversePasses + 0)
                    }`}
                  />
                </GridColumn>
                {/* GENERAL STREET SEGMENT INFORMATION */}
                <GridColumn size="12/12">
                  <Separator width="80%" color="grayDark" margin="small xxSmall" />
                </GridColumn>
                <GridColumn size="6/12">
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('customers.streetNetwork.travelDirection')}
                    value={translate(`customers.streetNetwork.${routeSegment.isOneWay ? 'oneWay' : 'twoWay'}`)}
                  />
                </GridColumn>
                {!!routeSegment.numberOfLanes && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.numberOfLanes')}
                      value={routeSegment.numberOfLanes}
                    />
                  </GridColumn>
                )}
                <GridColumn size="6/12">
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('customers.streetNetwork.forwardPasses')}
                    value={routeSegment.forwardPasses || 0}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('customers.streetNetwork.reversePasses')}
                    value={routeSegment.reversePasses || 0}
                  />
                </GridColumn>
                {!!routeSegment.segmentWidth && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.segmentWidth')}
                      value={routeSegment.segmentWidth}
                    />
                  </GridColumn>
                )}
                {!!routeSegment.speedLimit && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.speedLimit')}
                      value={routeSegment.speedLimit}
                    />
                  </GridColumn>
                )}
                {routeSegment.type && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.classification')}
                      value={routeSegment.type}
                    />
                  </GridColumn>
                )}
                {!!serviceSideTypeId.length && !isSnowPlowRoute && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.serviceSide.serviceSide')}
                      value={serviceSideTypeId[0].label}
                    />
                  </GridColumn>
                )}
                {!!routeSegment.priorityTypeId && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.priority')}
                      value={routeSegment.priorityTypeId}
                    />
                  </GridColumn>
                )}
                {!!routeSegment.turnRestrictions && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.turnRestrictions')}
                      value={routeSegment.turnRestrictions}
                    />
                  </GridColumn>
                )}
                {!!size(routeSegment.streetSegmentServiceTypes) && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.segmentType.segmentType')}
                      value={getTypesAsString(routeSegment.streetSegmentServiceTypes)}
                    />
                  </GridColumn>
                )}
                {!!size(routeSegment.streetSegmentServiceTypes) && !isSnowPlowRoute && (
                  <GridColumn size="6/12">
                    <LabeledDataView
                      width="calc(100% - 10px)"
                      size="small"
                      label={translate('customers.streetNetwork.sweeperSideType')}
                      value={getTypesAsString(routeSegment.streetSegmentServiceTypes, true)}
                    />
                  </GridColumn>
                )}
              </Grid>
            )}
          </MapGLPopupContent>
        ) : (
          <MapGLPopupContent>
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('routes.snowPlow.streetName')}
              value={routeSegment?.streetName ? routeSegment?.streetName : routeSegment?.name}
            />

            {!isRouteTemplate && (
              <>
                <LabeledDataView
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('routes.snowPlow.mostRecentPass')}
                  value={
                    !!lastStreetPass && lastStreetPass?.passDateTime ? dateAndTime(lastStreetPass.passDateTime) : '-'
                  }
                />

                <LabeledDataView
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('routes.snowPlow.vehicleName')}
                  value={(!!lastStreetPass && lastStreetPass?.vehicleName) || '-'}
                />

                <LabeledDataView
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('routes.snowPlow.totalRecordedPasses')}
                  value={routeSegment?.streetPasses ? String(routeSegment?.streetPasses?.length) : '-'}
                />
              </>
            )}
          </MapGLPopupContent>
        )}
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
