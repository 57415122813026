import { push } from 'connected-react-router';
import update from 'immutability-helper';
import Cookie from 'js-cookie';
import { filter, find, findIndex, get, map, size } from 'lodash-es';
import moment from 'moment';
import { ChangeEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { reset } from 'redux-form';

import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { AppState } from 'src/store';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { PermissionGuard } from '../../../../account/components';
import {
  ROUTES_PLANNER_CREATE,
  ROUTES_PLANNER_DELETE,
  ROUTES_PLANNER_EXPORT,
  ROUTES_PLANNER_PLAN,
  ROUTES_PLANNER_SCHEDULE,
  ROUTES_PLANNER_SELECTION,
  ROUTES_PLANNER_SERVICE_ZONES,
  ROUTES_TRACKER_SEQUENCE_ROUTE,
} from '../../../../account/constants';
import { ROUTES_PLANNER_HOLIDAY_PLANNER } from '../../../../account/constants/permissions';
import { hasPermissionSelector, isAdminSelector, isSuperAdminSelector } from '../../../../account/ducks';
import {
  checkIfHasRouteTemplateBuilderAccess,
  checkIfSupport,
  checkIfViewOnly,
  hasPermission,
} from '../../../../account/utils/permissions';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageFooter,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { DuckAction, DuckFunction } from '../../../../contracts/ducks';
import { MoreButton, Pagination, Table, UnconnectedCheckbox } from '../../../../core/components';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from '../../../../core/components/styled';
import { BOTTOM, LIMIT_PER_PAGE, TOP, WEEKDAYS_BY_ID, WEEKDAYS_BY_SHORTCODE } from '../../../../core/constants';
import confirm from '../../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import createPopover from '../../../../core/services/createPopover';
import informationPrompt from '../../../../core/services/informationPrompt';
import translate from '../../../../core/services/translate';
import { loadVehicleTypesForVendor, resetVehicleTypesForVendor } from '../../../../fleet/ducks';
import { createUrl, getQueryParams } from '../../../../utils/services/queryParams';
import { routeSequencingStatusSelector } from '../../../../vendors/ducks';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import {
  CANCELED,
  COMPLETE,
  FAILED,
  FAILED_CONFIRMED,
  PENDING,
  SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
} from '../../../constants';
import {
  assignTemplatesToGroups,
  createRouteFromRouteTemplate,
  deleteRouteTemplate,
  exportRouteTemplates,
  loadRouteSequenceStatus,
  loadRouteTemplates,
  resetRouteTemplate,
  resetRouteTemplates,
  updateRouteSequenceStatus,
} from '../../../ducks';
import { RouteTemplate } from '../../../interfaces/RouteTemplates';
import { CreateRouteFromTemplateForm, RouteTemplatesForm } from '../../forms';
import { AddToGroupsModalResolver, RoutePlannerNewSchedulerModalResolver, RouteUploaderModal } from '../../modals';
import { BetaBaseWrapper, BetaButton } from '../../styled/RouteTracker';
import RouteTemplateTableRow from './RouteTemplateTableRow';
import { PlanARoute } from './routeTemplatePageSections';
import { AddToGroupsFormValues } from '../../forms/AddToGroupsForm';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';

const CheckAllRouteTemplatesCheckbox = ({ checkAll, checked }: any) => (
  <UnconnectedCheckbox
    block
    noLabel
    size="small"
    margin="no no xSmall no"
    name="checkAllRouteTemplates"
    checked={checked}
    onChange={() => checkAll()}
  />
);

interface Props extends RouteComponentProps {
  assignTemplatesToGroups: DuckFunction<typeof assignTemplatesToGroups>;
  createRouteFromRouteTemplate: DuckFunction<typeof createRouteFromRouteTemplate>;
  deleteRouteTemplate: DuckFunction<typeof deleteRouteTemplate>;
  exportRouteTemplates: DuckFunction<typeof exportRouteTemplates>;
  filtersPreferences?: FilterSetting[];
  hasCreateRoutePermission: boolean;
  hasDeleteRoutePermission: boolean;
  hasRouteSequencePermission: boolean;
  hasRouteTemplateBuilderAccess: boolean;
  isSnowPlowEnabled: boolean;
  isStreetSweeperEnabled: boolean;
  hasSelectionPermission: boolean;
  isCreating: boolean;
  isExporting: boolean;
  isLoading: boolean;
  isRubiconAdmin: boolean;
  loadRouteSequenceStatus: DuckFunction<typeof loadRouteSequenceStatus>;
  loadRouteTemplates: DuckFunction<typeof loadRouteTemplates>;
  location: any;
  push: typeof push;
  reset: DuckAction<typeof reset>;
  resetRouteTemplate: DuckAction<typeof resetRouteTemplate>;
  resetRouteTemplates: DuckAction<typeof resetRouteTemplates>;
  resetVehicleTypesForVendor: DuckAction<typeof resetVehicleTypesForVendor>;
  routeSequencingEnabled: boolean;
  routeStatuses?: any[];
  routeTemplates: RouteTemplate[];
  total: number;
  updateRouteSequenceStatus: DuckFunction<typeof updateRouteSequenceStatus>;
  vendorId: number;
  isViewOnlyOrSupport: boolean;
}

interface State {
  allRouteTemplatesChecked: boolean;
  allSnowPlowRoutesScheduled: boolean;
  allStreetSweepingRoutesScheduled: boolean;
  routeTemplates: RouteTemplate[];
  scheduleModalIsVisible: boolean;
  scheduleModalTemplates: RouteTemplate[];
  uploadRouteModalIsVisible: boolean;
  addToGroupsModalIsVisible: boolean;
}

class RouteTemplatesPage extends PureComponent<Props, State> {
  refreshRouteSequenceStatus?: number;
  closePopover?: () => void;

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState = (props: Props) => {
    const { routeTemplates } = props;

    const routeTemplateOptions = map(routeTemplates, routeTemplate => ({ ...routeTemplate, isChecked: false }));
    return {
      routeTemplates: routeTemplateOptions,
      allRouteTemplatesChecked: false,
      allSnowPlowRoutesScheduled: false,
      allStreetSweepingRoutesScheduled: false,
      uploadRouteModalIsVisible: false,
      scheduleModalIsVisible: false,
      addToGroupsModalIsVisible: false,
      scheduleModalTemplates: [],
    };
  };

  componentDidMount = () => {
    this.refreshRouteSequenceStatus = window.setInterval(async () => {
      const { loadRouteSequenceStatus, vendorId } = this.props;
      const { routeTemplates } = this.state;
      const routeIds = map(routeTemplates, route => route.id);
      if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
        await loadRouteSequenceStatus(routeIds, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE);
      }
    }, 60000);
  };

  componentDidUpdate(prevProps: Props) {
    const {
      loadRouteSequenceStatus,
      loadRouteTemplates,
      location,
      reset,
      routeTemplates,
      vendorId,
      filtersPreferences = [],
    } = this.props;
    if (prevProps.location.search !== location.search) {
      // if all the preferences are unselected send the globalFiltersIds
      const vehicleFilterPreferences = getVehicleFiltersPreferencesIds(filtersPreferences).length
        ? getVehicleFiltersPreferencesIds(filtersPreferences)
        : [];

      const {
        routeTemplateName,
        daysOfServiceIds,
        vehicleTypeIds = vehicleFilterPreferences.join(','),
        routeStatus,
        page,
        limit,
        sortOrder,
        sortedBy,
        groupIds,
      } = getQueryParams(location.search);

      loadRouteTemplates({
        vendorId,
        routeTemplateName,
        daysOfServiceIds,
        vehicleTypeIds,
        routeStatus,
        page,
        limit,
        sortOrder,
        sortedBy,
        groupIds,
      }).then(data => {
        const routeIds = map(data.routeTemplates, route => route.id);
        if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
          loadRouteSequenceStatus(routeIds, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE);
        }
      });
    }

    if (prevProps.routeTemplates !== routeTemplates) {
      this.setState(this.getInitialState(this.props));
    }

    reset('routeTemplates');
  }

  componentWillUnmount() {
    const { resetRouteTemplate, resetRouteTemplates, resetVehicleTypesForVendor } = this.props;
    resetRouteTemplate();
    resetRouteTemplates();
    resetVehicleTypesForVendor();
    window.clearInterval(this.refreshRouteSequenceStatus);
  }

  onSortOrderChange = (sortedBy: string, sortOrder: string) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  onRouteTemplateSelected = (event: ChangeEvent, id: number) => {
    event.stopPropagation();

    const routeTemplateIndex = findIndex(this.state.routeTemplates, { id });

    const routeTemplates = update(this.state.routeTemplates, {
      [routeTemplateIndex]: {
        isChecked: { $set: !this.state.routeTemplates[routeTemplateIndex].isChecked },
      },
    });

    const allRouteTemplatesChecked = size(find(routeTemplates, { isChecked: false })) === 0;

    this.setState({
      allRouteTemplatesChecked,
      routeTemplates,
    });
  };

  setUploadRouteModalIsVisible = (visibility = false) => {
    this.setState({ uploadRouteModalIsVisible: visibility });
  };

  exportRouteTemplates = () => {
    const { vendorId, exportRouteTemplates, location, filtersPreferences = [] } = this.props;
    const {
      routeTemplateName,
      daysOfServiceIds,
      vehicleTypeIds = getVehicleFiltersPreferencesIds(filtersPreferences),
      routeStatus,
      page,
      limit,
      sortOrder,
      sortedBy,
      groupIds
    } = getQueryParams(location.search);

    exportRouteTemplates(
      vendorId,
      routeTemplateName,
      daysOfServiceIds,
      vehicleTypeIds,
      routeStatus,
      page,
      limit,
      sortOrder,
      sortedBy, 
      "", 
      "",
      groupIds,
    );
  };

  deleteRouteTemplate = async (routeTemplateId: number, event: ChangeEvent) => {
    event.stopPropagation();
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteRouteTemplate')))) {
      return;
    }
    this.props.deleteRouteTemplate(routeTemplateId);
  };

  createRoute = async (id: number, scheduledDay: number, { date }: any) => {
    const { createRouteFromRouteTemplate, vendorId, push } = this.props;

    const dayOfWeek = moment(date, 'MM/DD/YYYY').day();
    const dayOfWeekId = dayOfWeek !== 0 ? dayOfWeek : 7;

    const { id: scheduledDayId, name: scheduledDayName } = WEEKDAYS_BY_ID[dayOfWeekId];
    const { id: originalScheduledDayId, name: originalScheduledDayName } = WEEKDAYS_BY_SHORTCODE[scheduledDay];

    if (
      scheduledDayId !== originalScheduledDayId &&
      !(await confirm(
        translate('routes.alertMessages.confirmCreateRouteForAnotherDayOfWeek', {
          scheduledDayName,
          originalScheduledDayName,
        }),
      ))
    ) {
      return;
    }

    createRouteFromRouteTemplate(vendorId, id, date)
      .then(({ id: routeId }) => {
        !!this.closePopover && this.closePopover();
        createSuccessNotification(translate('routes.alertMessages.routeCreatedFromTemplate'));
        push(`/routes/route-tracker/${routeId}`);
      })
      .catch(error => {
        const { code } = error?.response?.data;

        switch (code) {
          case 'VendorNotFoundOrDisabled':
            createErrorNotification(translate('routes.alertMessages.vendorNotFoundOrDisabled'));
            break;

          case 'RouteTemplateNotFoundOrDisabled':
            createErrorNotification(translate('routes.alertMessages.routeTemplateNotFoundOrDisabled'));
            break;

          case 'StartDateGreaterThanRequestedRouteDate':
            createErrorNotification(translate('routes.alertMessages.startDateGreaterThanRequestedRouteDate'));
            break;

          case 'SeasonalityStartDateGreaterThanRequestedRouteDate':
            createErrorNotification(
              translate('routes.alertMessages.seasonalityStartDateGreaterThanRequestedRouteDate'),
            );
            break;

          case 'SeasonalityEndDateLowerThanRequestedRouteDate':
            createErrorNotification(translate('routes.alertMessages.seasonalityEndDateLowerThanRequestedRouteDate'));
            break;

          case 'NoLocationFoundRouteCannotBeCreated':
            createErrorNotification(translate('routes.alertMessages.noLocationFoundRouteCannotBeCreated'));
            break;

          case 'RouteExistsOnDate':
            createErrorNotification(translate('routes.alertMessages.newRouteExistsOnDate'));
            break;

          case 'BadRequest':
            createErrorNotification(translate('routes.alertMessages.newRouteCreationFromTemplateFailed'));
            break;

          default:
            createErrorNotification(translate('routes.alertMessages.newRouteCreationFromTemplateFailed'));
            break;
        }
      });
  };

  openScheduler = (isScheduledAll = false) => {
    const { routeTemplates } = this.state;
    const scheduleModalTemplates = routeTemplates.filter(routeTemplate => routeTemplate.isChecked);

    this.setState({
      scheduleModalIsVisible: true,
      scheduleModalTemplates: isScheduledAll ? [] : scheduleModalTemplates,
    });
  };

  toggleAddToGroupsModal = () => {
    this.setState(prevState => ({
      addToGroupsModalIsVisible: !prevState.addToGroupsModalIsVisible,
    }));
  };

  closeScheduler = (successful = false) => {
    if (successful) {
      this.setState(this.getInitialState(this.props));
      return;
    }

    this.setState({
      scheduleModalIsVisible: false,
      scheduleModalTemplates: [],
    });
  };

  addTemplatesToGroups = ({ groupIds }: AddToGroupsFormValues) => {
    if (groupIds.length === 0) return;
    const { routeTemplates } = this.state;
    const { assignTemplatesToGroups } = this.props;

    const selectedTemplateIds = map(filter(routeTemplates, { isChecked: true }), 'id');

    assignTemplatesToGroups(selectedTemplateIds, groupIds)
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.routeTemplatesAddedToGroup'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.routeTemplatesAddedToGroupError'));
      })
      .finally(() => {
        this.toggleAddToGroupsModal();
        this.setState(this.getInitialState(this.props));
      });
  };

  createRouteFromRouteTemplate = (id: number, scheduledDay: number, event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    this.closePopover = createPopover(
      event.target,
      CreateRouteFromTemplateForm,
      { onSubmit: (data: RouteTemplate) => this.createRoute(id, scheduledDay, data) },
      { position: TOP },
    );
  };

  planARoute = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    this.closePopover = createPopover(event.target, PlanARoute, undefined, {
      position: BOTTOM,
      tabletRightAlign: true,
    });
  };

  checkAllRouteTemplates = () => {
    this.setState(prevState => {
      const routeTemplates = map(prevState.routeTemplates, routeTemplate => ({
        ...routeTemplate,
        isChecked: routeTemplate.noOfStops > 0 && routeTemplate.isEnabled ? !prevState.allRouteTemplatesChecked : false,
      }));

      return {
        routeTemplates,
        allRouteTemplatesChecked: !prevState.allRouteTemplatesChecked,
      };
    });
  };

  handleTableRowClick = async (
    id: number,
    routeSequenceStatus: string,
    jobId: number,
    isEdit: boolean,
    vehicleTypeId: number,
  ) => {
    const { push, updateRouteSequenceStatus, routeSequencingEnabled, hasRouteSequencePermission } = this.props;
    const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
    const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;

    const getRedirectUrl = () => {
      if (isSnowPlowRoute) return `/routes/route-templates/snow-plow/${id}${isEdit ? '/edit' : ''}`;
      if (isStreetSweeperRoute) return `/routes/route-templates/street-sweeper/${id}`;
      return `/routes/route-templates/${id}${isEdit ? '/edit' : ''}`;
    };

    if (routeSequencingEnabled && hasRouteSequencePermission) {
      switch (routeSequenceStatus) {
        case PENDING: {
          if (!(await confirm(translate('routes.alertMessages.confirmCancelSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: id,
            jobId,
            status: CANCELED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
          }).then(() => push(getRedirectUrl()));
          break;
        }

        case COMPLETE:
          push(`/routes/route-templates/${id}/route-sequence`);
          break;

        case FAILED:
          if (!(await informationPrompt(translate('routes.alertMessages.confirmFailedRouteSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: id,
            jobId,
            status: FAILED_CONFIRMED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
          }).then(() => push(getRedirectUrl()));
          break;

        default:
          push(getRedirectUrl());
      }
    } else push(getRedirectUrl());
  };

  render() {
    const {
      filtersPreferences,
      hasCreateRoutePermission,
      hasDeleteRoutePermission,
      hasRouteSequencePermission,
      hasRouteTemplateBuilderAccess,
      hasSelectionPermission,
      isCreating,
      isExporting,
      isLoading,
      push,
      routeSequencingEnabled,
      routeStatuses,
      total,
      vendorId,
      isSnowPlowEnabled,
      isStreetSweeperEnabled,
      isViewOnlyOrSupport,
    } = this.props;

    const {
      routeTemplates,
      allRouteTemplatesChecked,
      uploadRouteModalIsVisible,
      scheduleModalIsVisible,
      scheduleModalTemplates,
      addToGroupsModalIsVisible,
    } = this.state;

    const routeTemplatesOptions = map(routeTemplates, route => {
      const routeSequence = find(routeStatuses, { routeId: route.id });
      return {
        ...route,
        routeSequenceStatus: get(routeSequence, 'status'),
        jobId: get(routeSequence, 'jobId'),
      };
    });

    const routesTableCells = [
      {
        name: 'selectAll',
        component: hasSelectionPermission && CheckAllRouteTemplatesCheckbox,
        componentProps: {
          checkAll: this.checkAllRouteTemplates,
          checked: allRouteTemplatesChecked,
        },
        width: hasSelectionPermission ? '5%' : '0',
      },
      { name: 'routeTemplateName', label: translate('routes.routeName'), width: '26%', sortable: true },
      {
        name: 'wasteMaterialTypeName',
        label: translate('routes.materialType'),
        width: '15%',
        sortable: true,
      },
      { name: 'routeDate', label: translate('routes.dayOfService'), width: '12%', sortable: true },
      { name: 'noOfStops', label: translate('routes.numberOfStops'), width: '12%', sortable: true },
      { name: 'vehicleTypeName', label: translate('vehicles.vehicleType'), width: '15%', sortable: true },
      { name: 'isEnabled', label: translate('common.status'), width: '10%', sortable: true },
      {
        name: 'options',
        label: (hasCreateRoutePermission || hasDeleteRoutePermission) && translate('common.options'),
        width: '10%',
        align: 'right',
      },
    ];

    const moreButtonItems = [];

    if (!isViewOnlyOrSupport) {
      moreButtonItems.push({
        id: 'upload-route-button',
        text: translate('dispatchBoard.routeUploader.buttonTitle'),
        handler: () => this.setUploadRouteModalIsVisible(true),
      });
    }

    if (hasPermission(ROUTES_PLANNER_EXPORT)) {
      moreButtonItems.push({
        id: 'export-route-templates-button',
        text: translate('common.export'),
        handler: this.exportRouteTemplates,
      });
    }

    if (
      hasPermission(ROUTES_PLANNER_SCHEDULE) &&
      (isSnowPlowEnabled || isStreetSweeperEnabled) &&
      !isViewOnlyOrSupport
    ) {
      moreButtonItems.push({
        id: 'schedule-all-routes-button',
        text: `${translate('tooltips.scheduleAll')}...`,
        handler: () => this.openScheduler(true),
      });
    }

    if (hasPermission(ROUTES_PLANNER_SERVICE_ZONES)) {
      moreButtonItems.push({
        id: 'service-zones-button',
        text: translate('routes.serviceZones'),
        isLink: true,
        to: '/routes/service-zones',
      });
    }

    if (hasPermission(ROUTES_PLANNER_HOLIDAY_PLANNER)) {
      moreButtonItems.push({
        id: 'holiday-planner-button',
        text: translate('routes.holidayPlanner.holidayPlanner'),
        isLink: true,
        to: '/routes/holiday-planner',
      });
    }

    if (hasRouteTemplateBuilderAccess) {
      moreButtonItems.push({
        id: 'route-template-builder-button',
        text: translate('routeTemplateBuilder.routeTemplateBuilder'),
        isLink: true,
        to: '/routes/route-template-builder',
      });
    }

    moreButtonItems.push({
      id: 'manage-groups-button',
      text: translate('routes.groups.manageGroups'),
      isLink: true,
      to: '/routes/groups',
    });

    return (
      <>
        <PageContent>
          <PageHeader>
            <PageDetails>
              <PageTitleContainer>
                <PageTitle>{translate('routes.planner.routePlanner')}</PageTitle>
                <PageSubtitle>
                  <BetaBaseWrapper inline>
                    <BetaButton
                      noUnderline
                      align="right"
                      padding="no"
                      color="primary"
                      margin="no"
                      onClick={() => {
                        push('/routes/route-planner');
                      }}
                    >
                      {translate('routes.tryBeta')}
                    </BetaButton>
                  </BetaBaseWrapper>
                </PageSubtitle>
              </PageTitleContainer>
            </PageDetails>
            <PageActions flex>
              {!!moreButtonItems.length && <MoreButton margin="no small no no" items={moreButtonItems} />}

              <PermissionGuard permission={ROUTES_PLANNER_PLAN}>
                <Button
                  color="primary"
                  onClick={(event: ChangeEvent<HTMLInputElement>) => this.planARoute(event)}
                  id="route-plan-button"
                >
                  {translate('routes.planARoute')}
                </Button>
              </PermissionGuard>
            </PageActions>
          </PageHeader>

          <Panel>
            <PanelSectionGroup isLoading={isLoading || isExporting || isCreating}>
              <RouteTemplatesForm />
              <PanelSection>
                {!!size(routeTemplates) && (
                  <Table
                    cells={routesTableCells}
                    rows={routeTemplatesOptions}
                    rowComponent={RouteTemplateTableRow}
                    rowProps={{
                      createRouteFromRouteTemplate: this.createRouteFromRouteTemplate,
                      deleteRouteTemplate: this.deleteRouteTemplate,
                      handleTableRowClick: this.handleTableRowClick,
                      hasCreateRoutePermission,
                      hasDeleteRoutePermission,
                      hasRouteSequencePermission,
                      push,
                      routeSequencingEnabled,
                      selectRouteTemplate: this.onRouteTemplateSelected,
                      filtersPreferences,
                    }}
                    sort={this.onSortOrderChange}
                    withClickableRows
                  />
                )}
              </PanelSection>

              {!size(routeTemplates) && <Message padding="sMedium">{translate('routes.noRouteTemplates')}</Message>}

              <Pagination totalResults={total} limitPerPage={LIMIT_PER_PAGE} />

              {!!find(routeTemplates, { isChecked: true }) && (
                <PanelSection centered padding="no">
                  <PageFooter>
                    <Button
                      id="schedule-route-button"
                      color="primary"
                      onClick={() => this.openScheduler()}
                      margin="no small no no"
                      disabled={!find(routeTemplates, { isChecked: true }) || !hasPermission(ROUTES_PLANNER_SCHEDULE)}
                    >
                      {`${translate('routes.schedule')} (${size(filter(routeTemplates, { isChecked: true }))})`}
                    </Button>
                    <Button
                      id="add-to-groups-button"
                      color="primary"
                      onClick={this.toggleAddToGroupsModal}
                      margin="no small no no"
                    >
                      {`${translate('routes.groups.addToGroups')}  (${size(
                        filter(routeTemplates, { isChecked: true }),
                      )})`}
                    </Button>
                  </PageFooter>
                </PanelSection>
              )}
            </PanelSectionGroup>
          </Panel>
        </PageContent>

        {uploadRouteModalIsVisible && (
          <RouteUploaderModal vendorId={vendorId} onClose={() => this.setUploadRouteModalIsVisible(false)} />
        )}

        {scheduleModalIsVisible && (
          <RoutePlannerNewSchedulerModalResolver templates={scheduleModalTemplates} onClose={this.closeScheduler} />
        )}

        {addToGroupsModalIsVisible && (
          <AddToGroupsModalResolver
            onAddToGroups={this.addTemplatesToGroups}
            closeModal={this.toggleAddToGroupsModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  hasCreateRoutePermission: hasPermissionSelector(state.account.permissions, ROUTES_PLANNER_CREATE),
  hasDeleteRoutePermission: hasPermissionSelector(state.account.permissions, ROUTES_PLANNER_DELETE),
  hasRouteSequencePermission: hasPermissionSelector(state.account.permissions, ROUTES_TRACKER_SEQUENCE_ROUTE),
  hasSelectionPermission: hasPermissionSelector(state.account.permissions, ROUTES_PLANNER_SELECTION),
  isCreating: state.routes.routeTemplates.isCreating,
  isExporting: state.routes.routeTemplates.isExporting,
  isLoading: state.routes.routeTemplates.isLoading,
  routeSequencingEnabled: routeSequencingStatusSelector(state.vendors.features.features) || false,
  routeStatuses: state.routes.routeSequence.routeStatuses,
  routeTemplates: state.routes.routeTemplates.routeTemplates || [],
  total: state.routes.routeTemplates.total || 0,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  hasRouteTemplateBuilderAccess: checkIfHasRouteTemplateBuilderAccess(state),
  filtersPreferences: state.common.filters.filters,
  isRubiconAdmin: isAdminSelector(state.account.login) || isSuperAdminSelector(state.account.login),
  isSnowPlowEnabled: checkIfSnowPlowIsEnabled(state),
  isStreetSweeperEnabled: checkIfStreetSweepingIsEnabled(state),
  isViewOnlyOrSupport: checkIfSupport() || checkIfViewOnly(),
});

const mapDispatchToProps = {
  assignTemplatesToGroups,
  createRouteFromRouteTemplate,
  deleteRouteTemplate,
  exportRouteTemplates,
  loadRouteSequenceStatus,
  loadRouteTemplates,
  loadVehicleTypesForVendor,
  push,
  reset,
  resetRouteTemplate,
  resetRouteTemplates,
  resetVehicleTypesForVendor,
  updateRouteSequenceStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteTemplatesPage));
