import { InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, GridColumn } from 'src/core/components/styled';
import { Dropdown, TypedField, Input, TextArea } from 'src/core/components';
import { FormContainer, FormOverlay } from 'src/core/components/styled/Form';
import { isOptionalNumberOrZero, maxLength500, minValueNumeric0 } from 'src/utils/services/validator';
import { STREET_ID, STREET_SWEEPING_ID } from 'src/common/constants/serviceTypes';
import { trimAndNormalizeNumber } from 'src/core/services/normalizeNumber';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

export const STREET_SERVICE_DETAILS_EDITOR_FORM = 'streetServiceDetailsEditorForm';

type PropsWithoutReduxForm = {
  isReadonly?: boolean;
  onCancel?: (pristine: boolean) => void;
  renderLocationMap: () => JSX.Element;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<{}, PropsWithoutReduxForm>;

export default reduxForm<{}, PropsWithoutReduxForm>({
  form: STREET_SERVICE_DETAILS_EDITOR_FORM,
})(function StreetServiceDetailsEditorForm({ handleSubmit, isReadonly, renderLocationMap }: Props) {
  const streetSegment = useSelector(state => state.customers.location.location?.streetSegment);

  return (
    <FormContainer width="100%">
      {isReadonly && <FormOverlay />}
      <form onSubmit={handleSubmit}>
        <Grid margin="small no no no">
          <GridColumn size="6/12">
            <Grid margin="no">
              <GridColumn size="6/12">
                <TypedField
                  name="serviceTypeId"
                  component={Dropdown}
                  props={{
                    disabled: true,
                    label: translate('common.serviceType'),
                    margin: 'no',
                    options: [
                      { label: translate('common.serviceTypes.snowPlow'), value: STREET_ID },
                      { label: translate('common.serviceTypes.streetSweeping'), value: STREET_SWEEPING_ID },
                    ],
                  }}
                />
              </GridColumn>
            </Grid>
            <Grid margin="sMedium no no no">
              <GridColumn size="6/12">
                <TypedField
                  name="containers[0].forwardPasses"
                  component={Input}
                  normalize={trimAndNormalizeNumber}
                  validate={[isOptionalNumberOrZero, minValueNumeric0]}
                  props={{
                    label: translate('customers.forwardPasses'),
                    placeholder: streetSegment ? String(streetSegment.forwardPasses) : '',
                    margin: 'no',
                    raisedLabel: true,
                  }}
                />
              </GridColumn>
              <GridColumn size="6/12">
                <TypedField
                  name="containers[0].reversePasses"
                  component={Input}
                  normalize={trimAndNormalizeNumber}
                  validate={[isOptionalNumberOrZero, minValueNumeric0]}
                  props={{
                    label: translate('customers.reversePasses'),
                    placeholder: streetSegment ? String(streetSegment.reversePasses) : '',
                    margin: 'no',
                    raisedLabel: true,
                  }}
                />
              </GridColumn>
            </Grid>
            <Grid margin="sMedium no no no">
              <GridColumn size="6/12">
                <TypedField
                  name="streetSweeperBroomSideTypes"
                  component={Input}
                  props={{
                    label: translate('customers.streetNetwork.sweeperSideType'),
                    margin: 'no',
                    raisedLabel: true,
                    disabled: true,
                  }}
                />
              </GridColumn>
              <GridColumn size="6/12">
                <TypedField
                  name="streetSegmentServiceTypes"
                  component={Input}
                  props={{
                    label: translate('customers.streetNetwork.streetSegmentServiceType'),
                    margin: 'no',
                    raisedLabel: true,
                    disabled: true,
                  }}
                />
              </GridColumn>
            </Grid>
            <Grid margin="sMedium no no no">
              <GridColumn size="12/12">
                <TypedField
                  name="placementInstructions"
                  component={TextArea}
                  validate={[maxLength500]}
                  props={{
                    disabled: false,
                    isLoading: false,
                    label: translate('customers.serviceNotes'),
                    margin: 'no',
                    maxLength: 500,
                    placeholder: '',
                    rows: 5,
                    scrollYVisible: true,
                  }}
                />
              </GridColumn>
            </Grid>
          </GridColumn>

          <GridColumn size="6/12" padding="no no no small">
            {renderLocationMap()}
          </GridColumn>
        </Grid>
      </form>
    </FormContainer>
  );
});
