import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getRouteSegmentsGeoJSON } from './utils';
import { loadStreetNetworkSegmentDetails, loadStreetNetworkSegmentDetailsForDailyRoute } from 'src/customers/ducks';
import { ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW } from 'src/customers/constants/streetNetwork';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { RouteSegmentExtended } from './StreetNetworkMapGL';
import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { StyledPopUpWrapper } from '../../styled';
import { useSelector } from 'src/core/hooks/useSelector';
import StreetNetworkMapCenteredRouteSegmentsGLPopup from './StreetNetworkMapCenteredRouteSegmentsGLPopup';
import StreetNetworkMapRouteSegmentsGLPopup from './StreetNetworkMapRouteSegmentsGLPopup';
import StreetNetworkMapRouteSegmentsGLSource from './StreetNetworkMapRouteSegmentsGLSource';

type Props = {
  driversColorMap?: { [key: string]: string };
  isDailyRoute?: boolean;
  isDashboardPage?: boolean;
  isEditMode?: boolean;

  isRouteTemplate?: boolean;
  isSatelliteView: boolean;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
  map: mapboxgl.Map;
  routeId?: number;
  routeSegments?: RouteSegmentExtended[];
  selectedSegmentIds?: number[];
  streetNetwork: RouteSegmentExtended[];
};

export default function StreetNetworkMapRouteSegmentsGL({
  driversColorMap,
  isDailyRoute,
  isDashboardPage,
  isEditMode,
  isRouteTemplate,
  isSatelliteView,
  isSnowPlowRoute,
  isStreetSweeperRoute,
  map,
  routeId,
  routeSegments,
  selectedSegmentIds,
  streetNetwork,
}: Props) {
  const { selectedFeature: selectedMapFeature } = useSelector(state => state.routes.mapControls);
  const { selectedFeature: selectedDashboardFeature } = useSelector(state => state.dashboard.mapControls);
  const { segmentColorType } = useSelector(state => state.customers.streetNetwork);
  const { isLoadingSegmentDetails, isLoadingSegmentDetailsForDailyRoute } = useSelector(
    state => state.customers.streetNetwork,
  );

  const vendorId = useSelector(currentVendorId);

  const selectedFeature = isDashboardPage ? selectedDashboardFeature : selectedMapFeature;

  const dispatch = useDispatch();

  const geoJSON = useMemo(() => {
    const result = getRouteSegmentsGeoJSON(
      streetNetwork,
      routeSegments,
      isRouteTemplate,
      isDailyRoute,
      isEditMode,
      isSatelliteView,
      segmentColorType,
      driversColorMap,
      isSnowPlowRoute,
    );

    return result;
  }, [
    streetNetwork,
    routeSegments,
    isRouteTemplate,
    isDailyRoute,
    isEditMode,
    isSatelliteView,
    segmentColorType,
    driversColorMap,
    isSnowPlowRoute,
  ]);

  // this is weird but breaks the map if manipulating features state before style is loaded
  const [styleLoaded, setStyleLoaded] = useState(false);

  useEffect(() => {
    map.on('style.load', () => {
      setStyleLoaded(true);
    });
  }, [map]);

  useEffect(() => {
    if (!map || !styleLoaded) return;

    map.removeFeatureState({ source: ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW });

    if (selectedFeature?.id) {
      map.setFeatureState(
        {
          id: selectedFeature.id,
          source: ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW,
        },
        {
          selected: true,
        },
      );
    }
    selectedSegmentIds?.forEach(id => {
      map.setFeatureState(
        {
          id: id,
          source: ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW,
        },
        {
          selected: true,
        },
      );
    });
  }, [map, selectedFeature, selectedSegmentIds, isEditMode, styleLoaded]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW);

        if (feature && feature.id) {
          const vehicleTypeId = isSnowPlowRoute ? SNOW_PLOW_ID : isStreetSweeperRoute ? STREET_SWEEPER_ID : undefined;

          if (isDailyRoute && routeId)
            loadStreetNetworkSegmentDetailsForDailyRoute(vendorId, Number(feature.id), routeId)(dispatch);
          else if (!isDashboardPage)
            loadStreetNetworkSegmentDetails(vendorId, Number(feature.id), routeId, vehicleTypeId)(dispatch);

          if (isDashboardPage) dispatch(setDashboardSelectedFeature('routeSegments', Number(feature.id)));
          else dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeSegments, Number(feature.id)));
        }
      });
    });
  }, [map, dispatch, vendorId, isDashboardPage, routeId, isSnowPlowRoute, isStreetSweeperRoute, isDailyRoute]);

  return (
    <>
      <StreetNetworkMapRouteSegmentsGLSource geoJSON={geoJSON} />
      <StyledPopUpWrapper isLoadingSegmentDetails={isLoadingSegmentDetails || isLoadingSegmentDetailsForDailyRoute}>
        {!isDashboardPage && (
          <StreetNetworkMapRouteSegmentsGLPopup
            isDailyRoute={isDailyRoute}
            isRouteTemplate={isRouteTemplate}
            isSnowPlowRoute={isSnowPlowRoute}
            isStreetSweeperRoute={isStreetSweeperRoute}
          />
        )}
      </StyledPopUpWrapper>
      <StreetNetworkMapCenteredRouteSegmentsGLPopup
        isDashboardPage={isDashboardPage}
        isRouteTemplate={isRouteTemplate}
        isSnowPlowRoute={isSnowPlowRoute}
        routeSegments={routeSegments}
      />
    </>
  );
}
