import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { BareButtonStyle } from '../../../core/components/styled';
import { colorMapper } from 'src/utils/styles';
import { Icon } from '../../../core/components';
import { loadingOverlay } from '../../../core/styles';
import { sizeMapper } from 'src/utils/styles';

export const DashboardFilterCloseIcon = styled(Icon).attrs({ icon: 'arrowLeft' })`
  width: 13px;
  height: 13px;
  margin-top: 4px;
`;

export const DashboardFilterClose = styled.button.attrs({ type: 'button' })<{
  isDetailsScreen?: boolean;
  isSubPanelOpen?: boolean;
}>`
  ${BareButtonStyle};
  display: none;
  ${props =>
    props.isDetailsScreen
      ? css`
          left: 10px;
          top: 48px !important;
          position: relative !important;
        `
      : css`
          right: ${props.isSubPanelOpen ? '-360px' : '-20px'};
        `};
`;

export const DashboardFilterPanelContentDetails = styled.div<{ isCollapsed?: boolean }>`
  position: relative;
  margin-top: ${props => (props.isCollapsed ? '20px' : '-60px')};
`;

export const DashboardFilterTitle = styled.span`
  display: block;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 24px;
  text-transform: capitalize;
`;

export const DashboardFilterSubtitle = styled.span`
  display: block;
  margin-bottom: 20px;
  line-height: 18px;
  font-size: 12px;
  color: ${props => props.theme.grayDarker};
`;

export const DashboardFilterCategoryHeaderIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  position: absolute;
  top: 14px;
  right: 22px;
  width: 12px;
  height: 12px;
  color: ${props => transparentize(0.3, props.theme.brandPrimary)};
  transition: transform 0.3s ease-out;
`;

export const DashboardFilterCategoryHeader = styled.div`
  position: relative;
  padding: 12px 20px 10px;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  text-transform: capitalize;
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeightMedium};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const DashboardFilterNumberOfSelectedFilters = styled.span`
  margin-right: 20px;
  margin-top: 2px;
  font-size: 11px;
  color: ${p => p.theme.brandPrimary};
  font-weight: bold;
`;

export const DashboardFilterCategoryBody = styled.div`
  overflow: hidden;
`;

interface DashboardFilterCategoryProps {
  isOpen?: boolean;
}

export const DashboardFilterCategory = styled.div<DashboardFilterCategoryProps>`
  transition: background-color 0.3s ease-out;

  ${props =>
    props.isOpen &&
    css`
      background-color: ${props => props.theme.grayLighter};

      ${DashboardFilterCategoryHeaderIcon} {
        transform: rotate(180deg);
      }
      ${DashboardFilterCategoryHeader} {
        background-color: ${props => transparentize(0.8, props.theme.brandPrimary)};
      }
    `};
`;

export const DashboardFilterSearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 12px;
  height: 12px;
  margin-top: 6px;
  color: ${props => props.theme.gray};
`;

export const DashboardFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* padding: 10px 20px; */
`;

export const DashboardFilterTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

export const DashboardFilterSection = styled.div<{ flex?: boolean }>`
  padding: 0 10px 0 20px;
  width: 98%;
  ${props =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
    `};
`;

export const DashboardFilterIconWrapper = styled.div`
  background: #00a599;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
    height: 100%;

    span {
      color: #fff;
    }
  }
`;

export const DashboardFilterDetailScreenSection = styled.div<{
  isLoading?: boolean;
  noPadding?: boolean;
  minHeight?: string;
}>`
  padding: 5px 10px;
  min-height: ${props => (props.minHeight ? props.minHeight : '50px')};
  position: relative;
  background-color: ${props => props.theme.grayLighter};
  overflow-x: hidden;
  ${props =>
    props.isLoading &&
    css`
      ${props => loadingOverlay('24px', props.theme.bodyBackgroundColor)};
    `};

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `};
`;

export const DashboardFilterRouteStopsFormContainer = styled.div`
  background-color: white;
  margin: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  input {
    font-size: 13px;
  }
`;

export const DashboardFilterDetailScreenDataContainer = styled.div<{
  fullWidth: boolean;
  leftMargin?: boolean;
  labelFixedWidth?: string;
  hasBellowContent?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #fff;
  margin: 2px 0;
  /* second child  */

  & > *:nth-child(2) {
    text-align: right;
  }

  ${props =>
    props.labelFixedWidth &&
    css`
      & > *:nth-child(1) {
        width: ${props.labelFixedWidth};
      }
    `};

  ${props =>
    props.fullWidth &&
    css`
      flex-wrap: wrap;

      & > *:nth-child(-n + 2) {
        flex-grow: 1;
        flex-basis: 50%;
      }
    `};

  ${props =>
    props.leftMargin &&
    css`
      padding-left: 50px;
    `};

  ${props =>
    props.hasBellowContent &&
    css`
      border-radius: 3px 3px 0 0;
      box-shadow: none;
      margin-bottom: 0;
    `};
`;

export const DashboardFilterDetailScreenBellowContent = styled.div<{ leftMargin?: boolean }>`
  border-radius: 0 0 3px 3px;
  padding: 0px 15px 10px 15px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  margin: 0px 0 3px 0;
  ${props =>
    props.leftMargin &&
    css`
      padding-left: 100px;
    `};
`;

export const DashboardFilterDetailScreenLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 12px;
  padding-right: 5px;
  color: ${props => props.theme.grayDark};
  width: 60%;
`;

export const DashboardFilterDetailScreenValue = styled.div<{ color?: string }>`
  white-space: nowrap;
  display: inline-block;
  font-size: 12px;
  color: ${props => colorMapper(props, 'grayDark')};
`;

export const VehicleInspectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  width: 300px;
  padding: 5px 0 5px 5px;
  margin: 0 0 5px 15px;

  span {
    font-size: 11px;
  }
  :last-child {
    border-bottom: none;
  }
`;

export const DashboardListContainer = styled.div<{
  isLoading?: boolean;
  isForRoutes?: boolean;
  isFilterExpanded?: boolean;
}>`
  position: relative;
  padding: 15px;
  background: #f4f4f4;
  height: ${props => (props.isForRoutes ? css`calc(100% - 177px)` : css`calc(100% - 227px)`)};
  min-height: 215px;
  overflow: scroll;

  ${props =>
    props.isLoading &&
    css`
      ${props => loadingOverlay('24px', props.theme.bodyBackgroundColor)};
    `};

  ${props =>
    props.isFilterExpanded &&
    props.isForRoutes &&
    css`
      height: calc(100% - 310px);
    `};

  ${props =>
    props.isFilterExpanded &&
    !props.isForRoutes &&
    css`
      height: calc(100% - 260px);
    `};
`;

export const DashboardListItemContainer = styled.div<{ isActive?: boolean }>`
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-bottom: 1px solid #ccc;
  margin: 5px;

  > * {
    ${props =>
      !props.isActive &&
      css`
        opacity: 0.35;
      `}
  }

  &:hover {
    cursor: pointer;
    background: ${props => transparentize(0.9, props.theme.brandPrimary)};
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const DashboardContainerItemsApart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`;

export const DashboardContainerFilterExpandButton = styled.button<{ padding?: string }>`
  background: none;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  padding: ${props => sizeMapper(props.padding, 'small no no no')};
  margin: 0;
  margin-right: -10px;
`;

export const DashboardFilterPanelContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;

  // style the scrollbar
  &::-webkit-scrollbar {
    width: 7px;
    padding-top: 100px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const DashboardFilterPanel = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 2200;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 3px 6px ${props => transparentize(0.9, props.theme.grayBase)};
`;

export const DashboardFilterSubPanel = styled(DashboardFilterPanel)`
  z-index: 2100;
  max-width: 350px;
  left: 390px;
  overflow-y: auto;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-out;
  position: absolute;
  padding-right: 20px;
  &::-webkit-scrollbar {
    width: 7px;
    padding-top: 100px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

interface DashboardFilterProps {
  isSubMenuHidden?: boolean;
  isOpen?: boolean;
  isLoading?: boolean;
  isLarger?: boolean;
  noWider?: boolean;
  isDispatchMap?: boolean;
}

export const DashboardFilterContainer = styled.div<DashboardFilterProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2100;
  width: ${props => (props.noWider ? '350px' : '410px')};
  height: calc(100vh - ${props => (props.isLarger ? '70px' : '140px')});
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  ${props =>
    props.isOpen &&
    css`
      transform: none;

      ${DashboardFilterSubPanel} {
        transform: none;
      }
      ${DashboardFilterClose} {
        display: block;
        width: 40px;
        height: 40px;
        margin: 20px 0;
        border-radius: 50%;
        box-shadow: 0 0 6px ${props => transparentize(0.8, props.theme.grayBase)};
        color: ${props => props.theme.brandPrimary};
        background-color: #fff;
        position: absolute;
        top: 0;

        &:active {
          box-shadow: inset 0 0 2px ${props => transparentize(0.8, props.theme.grayBase)};
        }
      }
    `};

  ${props =>
    props.isDispatchMap &&
    css`
      top: 140px;
    `};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px', '#fff', 0.05, 2300)};
    `};
`;

export const DashboardExtraFiltersHeader = styled.div<{ isSubPanelOpen?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 13px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const DashboardExtraFiltersHeaderItem = styled.div`
  position: relative;
  padding: 5px;
  margin-left: 13px;

  & > div {
    margin: 0;
    & > button {
      width: 100%;
      height: auto;
    }
  }

  & > button:nth-child(2) {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    svg {
      margin-top: -2px;
      width: 10px;
      height: 10px;
    }
  }
`;

export const DashboardExtraFiltersPanelContainer = styled.div`
  & input[name*='searchTerm'] {
    font-size: 14px;
    padding: 5px 10px;
  }
`;

export const DashboardExtraFiltersPanelHeader = styled.div`
  padding: 5px 10px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  display: flex;
  align-items: center;
`;

export const DashboardExtraFiltersPanelSearch = styled.div`
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  border-bottom: 1px solid #e8e8e8;
  padding: 0px 10px;
  svg {
    width: 15px;
    height: 15px;
  }
  input {
    margin-left: -15px;
    font-size: 14px;
    padding: 0px 10px;
  }
`;

export const DashboardExtraFiltersPanelBody = styled.div<{ isLoading?: boolean }>`
  padding: 5px 0;
  overflow: hidden;
  background-color: #f4f4f4;

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px', '#fff', 0.05, 2300)};
    `};
`;

export const DashboardExtraFiltersPanelItem = styled.div<{ subFilterLevel?: number; isSubFilterHeader?: boolean }>`
  width: 100%;
  padding: 7px 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 3px;
  background-color: ${props => transparentize(0.1, props.theme.brandPrimary)};
  color: #fff;

  ${props =>
    props.subFilterLevel &&
    css`
      background-color: #fff;
      margin-left: 10px;
      padding-left: 10px;
      color: #000;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      margin-left: ${props.subFilterLevel * 10}px;
    `};

  ${props =>
    props.isSubFilterHeader &&
    css`
      background-color: ${props => transparentize(0.4, props.theme.brandPrimary)};
      color: #fff;
    `};
`;

export const DashboardDriverVehicleItemContainer = styled.div`
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-bottom: 1px solid #ccc;
  margin: 5px;

  &:last-child {
    border-bottom: none;
  }
`;

export const DashboardDriverVehicleItemData = styled.div<{ withBottomBorder?: boolean; leftMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid #ccc;
      padding-bottom: 8px;
    `};

  ${props =>
    props.leftMargin &&
    css`
      margin-left: 25px;
      padding-left: 8px;
    `};

  label {
    min-width: 80px;
    text-align: right;
    font-size: 12px;
    color: ${props => props.theme.grayDarker};
  }
`;

export const DashboardRouteLegendItem = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  & div {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: ${props => props.color};
  }
  &span {
    max-width: 100px;
  }
`;

export const SnowSweeperFilterItemContainer = styled.div`
  padding: 0px 10px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  background-color: #fff;
  border-radius: 5px;
`;

export const SnowSweeperFiltersContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

export const DashboardFilterSectionCollapseButton = styled.button<{
  isCollapsed?: boolean;
  isSnowSweeperDashboard?: boolean;
  isDispatchMap?: boolean;
}>`
  width: 35px;
  height: 50px;

  /* border: 2px solid; */
  border: 2px solid ${props => props.theme.bodyBackgroundColor};
  border-radius: 0 15px 15px 0;
  /* border-left: none; */

  background: ${props => props.theme.bodyBackgroundColor};
  color: ${props => props.theme.brandGreenDark};
  box-shadow: rgba(0, 0, 0, 0.24) 5px 3px 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: ${props => (props.isSnowSweeperDashboard ? '348px' : '409px')};
  top: calc(50% - 25px);
  z-index: 2200;
  cursor: pointer;

  transition: all 0.3s ease-in-out;

  & svg {
    transition: transform 0.3s;
    height: 25px;
    width: 25px;
  }

  &:hover {
    background: ${props => props.theme.brandGreenDark};
    color: ${props => props.theme.bodyBackgroundColor};
  }

  ${props =>
    props.isDispatchMap &&
    css`
      // left: 0;
    `}

  ${props =>
    props.isCollapsed &&
    css`
      left: 0;
      & svg {
        transform: rotate(180deg);
      }
    `}
`;
