import { identity, get, pick, has } from 'lodash-es';
import { createSelector } from 'reselect';

import { DESCRIPTIVE_DATE_RANGE, SPECIFIC_DATE_RANGE } from 'src/core/constants/weekdays';
import { Group } from '../interfaces/Groups';

const getRouteTemplateEditorFormInitialValues = (routeTemplateState: any, geoFenceState: any) => {
  const routeTemplate = get(routeTemplateState, 'routeTemplate', {});
  return {
    ...pick(
      get(routeTemplateState, 'routeTemplate', {}),
      'description',
      'endDay',
      'endDayOfWeek',
      'endMonth',
      'endRecurrenceDayTypeId',
      'endTime',
      'intermediateLocationId',
      'routeConfirmationTypeId',
      'routeTemplateName',
      'scheduledDay',
      'scheduledDay',
      'startDate',
      'startDay',
      'startDayOfWeek',
      'startingLocationId',
      'endingLocationId',
      'startMonth',
      'startRecurrenceDayTypeId',
      'startTime',
      'supervisorId',
      'vehicleTypeId',
      'vendorServiceZoneId',
      'wasteMaterialTypeId',
    ),
    isEnabled: has(routeTemplate, 'isEnabled') ? routeTemplate.isEnabled : true,
    geoFence:
      !!routeTemplateState.routeTemplate && geoFenceState.geoFence && routeTemplateState.routeTemplate.id
        ? geoFenceState.geoFence
        : {
            geoFenceCoordinates: [],
          },
    seasonality: !!routeTemplate.startRecurrenceDayTypeId || !!routeTemplate.endDay,
    dateRangeType:
      (!routeTemplate.startRecurrenceDayTypeId && !routeTemplate.endDay) || !!routeTemplate.endDay
        ? SPECIFIC_DATE_RANGE
        : DESCRIPTIVE_DATE_RANGE,
    groups: get(routeTemplateState, 'routeTemplate.groups', []).map((group: Group) => group.id),
  };
};

const routeTemplateEditorFormInitialValuesSelector = createSelector(getRouteTemplateEditorFormInitialValues, identity);

export default routeTemplateEditorFormInitialValuesSelector;
