import { ChangeEvent, MouseEvent, PureComponent } from 'react';

import { push } from 'connected-react-router';
import { filter, size } from 'lodash-es';
import { Resizable } from 're-resizable';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { formValueSelector, reset, submit } from 'redux-form';

import { ACTIVE, NEW, SUSPENDED, ON_HOLD } from 'src/common/constants/accountStatuses';
import { AppState } from 'src/store';
import {
  Button,
  Grid,
  GridColumn,
  MapContainer,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Text,
} from 'src/core/components/styled';
import { checkLocationHasScheduledDailyRoutes } from 'src/routes/services/routeTemplate';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import {
  CUSTOMERS_DETAILS_ADD_LOCATION,
  CUSTOMERS_DETAILS_DELETE_LOCATION,
  CUSTOMERS_DETAILS_EDIT_LOCATION,
} from 'src/account/constants';
import { Customer, CustomerLocation } from 'src/customers/interfaces/Customers';
import {
  CustomerLocationsEditorModalResolver,
  CustomerProximitySearchModalResolver,
  DeleteCustomerLocationOrServiceModal,
} from 'src/customers/components/modals';
import { CustomerLocationsForm, CustomerLocationServiceFilterForm } from 'src/customers/components/forms';
import { CustomerLocationsTableRow } from '.';
import CustomerMapMapbox from '../../mapGL/CustomerMapMapbox';
import { deleteCustomerLocation, resetCustomerLocations, searchLocations } from 'src/customers/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { hasPermissionSelector } from 'src/account/ducks';
import { MapDragHandle, Table } from 'src/core/components';
import { multiWordAndSearch } from 'src/core/services/search';
import { PageContent } from 'src/common/components/styled';
import { PermissionGuard } from 'src/account/components';
import { StatusIds } from 'src/customers/interfaces/Services';
import { STREET } from 'src/common/constants';
import { TableCell } from 'src/core/components/Table';
import confirm from 'src/core/services/confirm';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';
import { checkLocationHasVendorContainers } from 'src/customers/services/locations';
import TransferContainerModalResolver from '../../modals/TransferContainerModalResolver';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { PaymentManagementModalResolver } from '../../modals/paymentsManagement/PaymentManagementModalResolver';

const filteredCustomerLocations = (locations: CustomerLocation[], searchTerm?: string) => {
  const filteredLocations = filter(
    locations,
    location =>
      !searchTerm ||
      multiWordAndSearch(location.name, searchTerm) ||
      multiWordAndSearch(location.address.formattedAddress, searchTerm),
  );
  return filteredLocations;
};

interface MatchState {
  customerId: string;
}

interface Props extends RouteComponentProps<MatchState, {}, {}> {
  customer?: Customer;
  deleteCustomerLocation: (
    locationId: number,
    shouldRecreateRoutes: boolean,
    moveContainersToFacilityId?: number,
  ) => Promise<any>;
  hasDeleteLocationPermission: boolean;
  hasEditLocationPermission: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  locations: CustomerLocation[];
  push: (url: string) => void;
  reset: (formKey: string) => void;
  resetCustomerLocations: () => void;
  searchLocations: () => void;
  searchTerm?: string;
  vendorId: number;
  isBillingActive?: boolean;
  isBillingFeatureActive?: boolean;
}

interface State {
  filteredLocations?: CustomerLocation[];
  isCustomerLocationsModalOpen: boolean;
  isPaymentsManagementModalOpen: boolean;
  isCustomerProximitySearchModalOpen: boolean;
  isDeleteLocationModalOpen: boolean;
  isTransferContainersModalOpen: boolean;
  locations: CustomerLocation[];
  locationsStatus: string;
  moveContainersToFacilityId?: number;
  searchTerm?: string;
  selectedLocation?: number;
  selectedLocationId: number;
  serviceFilters: StatusIds[];
}

class CustomerLocations extends PureComponent<Props, State> {
  static defaultProps = {
    locations: [],
  };

  constructor(props: Props) {
    super(props);
    const { accountStatusTypeId } = getQueryParams(window.location.search);

    this.state = {
      isPaymentsManagementModalOpen: false,
      filteredLocations: props.locations,
      isCustomerLocationsModalOpen: false,
      isCustomerProximitySearchModalOpen: false,
      isDeleteLocationModalOpen: false,
      isTransferContainersModalOpen: false,
      moveContainersToFacilityId: 0,
      locations: props.locations,
      locationsStatus: accountStatusTypeId || `${ACTIVE},${NEW}`,
      selectedLocation: undefined,
      selectedLocationId: 0,
      serviceFilters: [ACTIVE, NEW, SUSPENDED, ON_HOLD],
    };
  }

  componentDidMount() {
    this.onInitialLocationsLoad();
  }

  componentDidUpdate(prevProps: Props) {
    const { locations, searchTerm } = this.props;
    if (locations !== prevProps.locations) {
      let filteredLocations = filteredCustomerLocations(locations, searchTerm);
      if (this.state && this.state.locationsStatus) {
        filteredLocations = filteredLocations.filter(location =>
          this.state.locationsStatus?.includes(location.accountStatusId.toString()),
        );
      }
      this.setState({ locations, filteredLocations });
    }
  }

  componentWillUnmount() {
    this.props.resetCustomerLocations();
  }

  onInitialLocationsLoad = () => {
    this.onSearchTermChange();
  };

  onCancelSaveCustomerLocation = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('customerLocationEditor');
    this.closeCustomerLocationsModal();
  };

  onSearchTermChange = (searchTerm?: string) => {
    const { locations } = this.state;
    const filteredLocations = filteredCustomerLocations(locations, searchTerm);
    this.setState({ filteredLocations, searchTerm });
    this.props.searchLocations();
  };

  onLocationsStatusChange = (locationsStatus: string) => {
    const { locations, searchTerm } = this.state;
    const filteredLocationsWithSearchTerm = filteredCustomerLocations(locations, searchTerm);
    const filteredLocations = filteredLocationsWithSearchTerm.filter(location =>
      locationsStatus.includes(location.accountStatusId.toString()),
    );
    this.setState({ filteredLocations, locationsStatus });
    this.props.searchLocations();
  };

  onTransferContainer = (values: { facilityTypeId: number }) => {
    this.setState({
      moveContainersToFacilityId: values?.facilityTypeId,
      isTransferContainersModalOpen: false,
      isDeleteLocationModalOpen: true,
    });
  };

  openCustomerLocationsModal = (event: MouseEvent, locationId: number) => {
    event.stopPropagation();
    this.setState({ isCustomerLocationsModalOpen: true, selectedLocation: locationId });
  };

  openCustomerProximitySearchModal = (event: MouseEvent, locationId: number) => {
    event.stopPropagation();
    this.setState({ isCustomerProximitySearchModalOpen: true, selectedLocation: locationId });
  };

  closeCustomerProximitySearchModal = () => {
    this.setState({ isCustomerProximitySearchModalOpen: false, selectedLocation: undefined });
  };

  openPaymentsManagementModal = (event: MouseEvent) => {
    event.stopPropagation();
    this.setState({ isPaymentsManagementModalOpen: true });
  };

  closePaymentsManagementModal = () => {
    this.setState({ isPaymentsManagementModalOpen: false });
  };

  closeCustomerLocationsModal = () => {
    this.setState({ isCustomerLocationsModalOpen: false, selectedLocation: undefined });
    this.onInitialLocationsLoad();
  };

  closeTransferContainersModal = () => {
    this.setState({ isTransferContainersModalOpen: false, moveContainersToFacilityId: 0 });
  };

  openDeleteLocationModal = async (event: MouseEvent, locationId: number) => {
    event.stopPropagation();
    const hasContainers = await checkLocationHasVendorContainers(locationId);
    if (hasContainers) {
      this.setState({ isTransferContainersModalOpen: true, selectedLocationId: locationId });
    } else this.setState({ isDeleteLocationModalOpen: true, selectedLocationId: locationId });
  };

  onCancelDeleteLocation = () => {
    this.setState({ isDeleteLocationModalOpen: false, selectedLocationId: 0 });
  };

  onDeleteLocation = (shouldRecreateRoutes: boolean) => {
    const { selectedLocationId, moveContainersToFacilityId } = this.state;
    this.setState({ isDeleteLocationModalOpen: false, selectedLocationId: 0 });

    this.props
      .deleteCustomerLocation(selectedLocationId, shouldRecreateRoutes, moveContainersToFacilityId)
      .then(() => {
        createSuccessNotification(`${translate('customers.alertMessages.locationDeleted')}`);
      })
      .catch(({ code }) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'customers.alertMessages'))}`);
      });
  };

  render() {
    const {
      customer,
      hasDeleteLocationPermission,
      hasEditLocationPermission,
      isDeleting,
      isLoading,
      isSaving,
      match: {
        params: { customerId },
      },
      push,
      vendorId,
      isBillingActive,
      isBillingFeatureActive,
    } = this.props;

    const {
      filteredLocations = [],
      isCustomerLocationsModalOpen,
      isCustomerProximitySearchModalOpen,
      isPaymentsManagementModalOpen,
      isDeleteLocationModalOpen,
      isTransferContainersModalOpen,
      locationsStatus,
      selectedLocation,
      selectedLocationId,
      serviceFilters,
    } = this.state;
    if (!customer) return null;
    const { customerTypeId } = customer;
    const customerLocationsTableCells: TableCell[] = [
      { name: 'index', label: translate('customers.locationNumber'), width: '10%' },
      { name: 'address', label: translate('common.address'), width: '30%' },
      { name: 'isActive', label: translate('common.status'), width: '15%' },
      {
        name: 'services',
        label: translate('customers.services'),
        component: CustomerLocationServiceFilterForm,
        componentProps: {
          initialValues: { serviceStatusTypeIds: serviceFilters },
          filter: (_e: ChangeEvent<HTMLInputElement>, serviceStatusFilters: StatusIds[]) => {
            this.setState({ serviceFilters: serviceStatusFilters });
          },
        },
        width: '35%',
      },
      {
        name: 'actions',
        label: (hasEditLocationPermission || hasDeleteLocationPermission) && translate('common.options'),
        width: '10%',
        align: 'right',
      },
    ];
    return (
      <PageContent>
        <Panel>
          {customerTypeId !== STREET && (
            <PanelSection withBorder padding="medium small">
              <Grid>
                <GridColumn size="4/12" padding="no">
                  <Text weight="light" block size="large" margin="no no small">
                    {translate('customers.customerLocations')}
                  </Text>
                </GridColumn>
                <GridColumn size="8/12" align="right" padding="no" flexDirection="row">
                  {isBillingFeatureActive && isBillingActive && (
                    <Button
                      id="add-location-button"
                      onClick={this.openPaymentsManagementModal}
                      color="primary"
                      margin="no small"
                    >
                      {translate('finance.paymentManagement.managePaymentMethods')}
                    </Button>
                  )}
                  <PermissionGuard permission={CUSTOMERS_DETAILS_ADD_LOCATION}>
                    <Button
                      id="add-location-button"
                      onClick={this.openCustomerLocationsModal}
                      color="primary"
                      margin="no"
                    >
                      {translate('customers.addLocation')}
                    </Button>
                  </PermissionGuard>
                </GridColumn>
              </Grid>
            </PanelSection>
          )}
          <PanelSectionGroup isLoading={isLoading || isSaving || isDeleting}>
            <CustomerLocationsForm
              onLocationsStatusChange={this.onLocationsStatusChange}
              onSearchTermChange={this.onSearchTermChange}
            />
            <PanelSection vertical withBorder>
              <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
                <MapContainer>
                  <CustomerMapMapbox customerLocations={filteredLocations} onAddressChange={() => {}} />
                </MapContainer>
              </Resizable>
            </PanelSection>
            {!!size(filteredLocations) && (
              <PanelSection>
                <Table
                  cells={customerLocationsTableCells}
                  rowComponent={CustomerLocationsTableRow}
                  rows={filteredLocations}
                  rowProps={{
                    accountStatusType: locationsStatus,
                    customerAccountStatusTypeId: customer.customerAccountStatusTypeId,
                    customerId,
                    customerTypeId,
                    deleteCustomerLocation: this.openDeleteLocationModal,
                    editCustomerLocation: this.openCustomerLocationsModal,
                    openProximitySearchModal: this.openCustomerProximitySearchModal,
                    push,
                    serviceStatusFilters: serviceFilters,
                  }}
                  withClickableRows
                />
              </PanelSection>
            )}

            {!!isDeleteLocationModalOpen && (
              <DeleteCustomerLocationOrServiceModal
                checkMethod={checkLocationHasScheduledDailyRoutes}
                modalTitle={translate('customers.alertMessages.confirmDeleteLocation')}
                onCancel={this.onCancelDeleteLocation}
                onDelete={this.onDeleteLocation}
                selectedId={selectedLocationId}
                vendorId={vendorId}
              />
            )}

            {!!isCustomerLocationsModalOpen && (
              <CustomerLocationsEditorModalResolver
                locationId={selectedLocation}
                onCancel={this.onCancelSaveCustomerLocation}
              />
            )}
            {!!isCustomerProximitySearchModalOpen && (
              <CustomerProximitySearchModalResolver
                onCancel={this.closeCustomerProximitySearchModal}
                locationId={selectedLocation}
              />
            )}
            {!!isTransferContainersModalOpen && (
              <TransferContainerModalResolver
                title={translate('containers.whereTransferContainers', {
                  containerPlace: translate('common.location'),
                })}
                closeModal={this.closeTransferContainersModal}
                handleContainerTransfer={this.onTransferContainer}
              />
            )}
            {!!isPaymentsManagementModalOpen && (
              <PaymentManagementModalResolver onClose={this.closePaymentsManagementModal} customerId={+customerId} />
            )}
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const formSelector = formValueSelector('customerLocations');

const permissionSelector = hasPermissionSelector as any;

const mapStateToProps = (state: AppState) => ({
  customer: state.customers.customer.customer,
  hasDeleteLocationPermission: permissionSelector(state.account.permissions, CUSTOMERS_DETAILS_DELETE_LOCATION),
  hasEditLocationPermission: permissionSelector(state.account.permissions, CUSTOMERS_DETAILS_EDIT_LOCATION),
  isBillingActive: state.vendors.stripeAccount.isBillingActive,
  isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
  isDeleting: state.customers.locations.isDeleting,
  isLoading: state.customers.locations.isLoading,
  isSaving: state.customers.locations.isSaving,
  locations: state.customers.locations.locations as any,
  searchTerm: formSelector(state, 'name'),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  deleteCustomerLocation,
  push,
  reset,
  resetCustomerLocations,
  searchLocations,
  submit,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerLocations));
