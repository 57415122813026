import { useEffect, useRef, useState } from 'react';

import { size } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import moment from 'moment';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { Table } from 'src/core/components';
import CalendarListViewSwitch from 'src/core/components/CalendarListViewSwitch';
import {
  Button,
  ButtonLink,
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  Grid,
  GridColumn,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionHeader,
  PanelSectionSubTitle,
} from 'src/core/components/styled';
import { TableCell } from 'src/core/components/Table';
import { FIFTEEN_DAYS_AFTER_TODAY, NINETY_DAYS_BEFORE_TODAY, TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { generateMonthSpan } from 'src/core/constants/calendar';
import { CALENDAR_VIEW, LIST_VIEW, ViewType } from 'src/core/constants/calendarListView';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { BillingFilterForm, UnbilledChargesFilterForm } from 'src/finance/components/forms';
import * as BillsDucks from 'src/finance/ducks/billing';
import * as UnbilledChargesDucks from 'src/finance/ducks/unbilledCharges';
import billingFilterFormInitialValuesSelector from 'src/finance/services/billingFilterFormInitialValuesSelector';
import unbilledChargesFilterFormInitialValuesSelector from 'src/finance/services/unbilledChargesFilterFormInitialValuesSelector';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import BillingStatistics from './billingPageSections/BillingStatistics';
import BillsListTableRow from './billingPageSections/BillsListTableRow';
import UnbilledChargesCalendar from './billingPageSections/UnbilledChargesCalendar';
import UnbilledChargesTable from './billingPageSections/UnbilledChargesTable';
import { DRAFT } from 'src/finance/constants/invoiceStatuses';

const tableCellWidths = {
  draftBillCheckbox: '3%',
  billId: '6%',
  billStatus: '11%',
  billDate: '10%',
  dueDate: '10%',
  billTotal: '18%',
  customerId: '21%',
  updatedDateTime: '13%',
  options: '8%',
};

const billTableCells: TableCell[] = [
  {
    name: 'billId',
    label: translate('finance.billId'),
    width: tableCellWidths.billId,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'billStatus',
    label: translate('finance.status'),
    width: tableCellWidths.billStatus,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'billDate',
    label: translate('finance.billDate'),
    width: tableCellWidths.billDate,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'dueDate',
    label: translate('finance.dueDate'),
    width: tableCellWidths.dueDate,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'billTotal',
    label: translate('finance.applyChecks.billAmount'),
    width: tableCellWidths.billTotal,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'customerId',
    label: translate('finance.customerInfo'),
    width: tableCellWidths.customerId,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'updatedDateTime',
    label: translate('finance.lastUpdated'),
    width: tableCellWidths.updatedDateTime,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'options',
    label: translate('finance.options'),
    width: tableCellWidths.options,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
  },
];

const checkAllDraftBills = ({ checkAll, checked }: any) => (
  <CheckboxContainer block noLabel size="small" margin="no no xSmall no">
    <CheckboxInput type="checkbox" name="checkAll" checked={checked} onChange={() => checkAll()} />
    <CheckboxCheck />
  </CheckboxContainer>
);

interface QueryParams {
  billsSearchTerm?: string;
  billStatusId?: number;
  isOverdue?: boolean;
  startDate?: StringOrDate;
  endDate?: StringOrDate;
  unbilledSearchTerm?: string;
  workOrderTypeId?: number;
  containerTypeId?: number;
}

const BillingPage: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const unbilledRef = useRef<any>();

  const [loadingUnbilledSection, setLoadingUnbilledSection] = useState(false);
  const [draftBillIds, setDraftBillIds] = useState<number[]>([]);
  const [selectedDraftBills, setSelectedDraftBills] = useState<number[]>([]);

  const vendorId = useSelector(currentVendorId);

  const billedChargesInitialValues = billingFilterFormInitialValuesSelector(search);
  const unbilledChargesInitialValues = unbilledChargesFilterFormInitialValuesSelector(search);

  const {
    billing: { bills, unbilledCharges },
  } = useSelector(state => state.finance.billing);

  const { calendarUnbilledCharges } = useSelector(state => state.finance.unbilledCharges);

  const invoiceStatusesOptions = useSelector(state => state.finance.invoiceStatuses.invoiceStatuses);
  const workFlowTypesOptions = useSelector(state => state.finance.workFlowTypes.workFlowTypes);
  const containerTypesOptions = useSelector(state => state.finance.containerTypes.containerTypes);

  const isLoadingBills = useSelector(
    state =>
      state.finance.billing.isLoadingBilling ||
      state.finance.billing.isChangingStatus ||
      state.finance.billing.isExportingBills,
  );

  const unbilledChargesLoading = useSelector(
    state =>
      state.finance.unbilledCharges.unbilledChargesLoading || state.finance.unbilledCharges.isExportingUnbilledCharges,
  );

  useEffect(() => {
    if (!isLoadingBills && loadingUnbilledSection) {
      unbilledRef?.current?.scrollIntoView();
      setLoadingUnbilledSection(false);
    }
  }, [isLoadingBills, loadingUnbilledSection]);

  useEffect(() => {
    if (draftBillIds.length === 0) {
      setDraftBillIds(bills?.filter(billData => billData.status === DRAFT).map(bill => bill.billId));
    }
  }, [bills, draftBillIds.length]);

  const [ucView, setUcView] = useState<ViewType>(LIST_VIEW);
  const [currentMonth, setCurrentMonth] = useState(generateMonthSpan(new Date()));

  const handleDateChange = (date: Date) => setCurrentMonth(generateMonthSpan(date));

  const onDraftBillSelected = (billId: number) => {
    if (selectedDraftBills.includes(billId)) {
      setSelectedDraftBills([...selectedDraftBills].filter(id => id !== billId));
    } else {
      setSelectedDraftBills([...selectedDraftBills, billId]);
    }
  };

  const releaseSelectedDarftBills = () => {
    BillsDucks.releaseBills(selectedDraftBills)(dispatch)
      .then(result => {
        if (result.failed > 0) {
          createSuccessNotification(
            translate('finance.alertMessages.confirmReleasedBillsWithFailed', {
              success: result.success,
              failed: result.failed,
            }),
          );
        } else {
          createSuccessNotification(
            translate('finance.alertMessages.confirmReleasedBills', {
              success: result.success,
            }),
          );
        }
        setSelectedDraftBills([]);
        reloadBillsData();
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.billReleasesError', {
            billsNumber: selectedDraftBills.length,
          }),
          'finance.alertMessages',
        );
        setSelectedDraftBills([]);
      });
  };

  const {
    billsSearchTerm,
    billStatusId,
    isOverdue,
    startDate = NINETY_DAYS_BEFORE_TODAY,
    endDate = FIFTEEN_DAYS_AFTER_TODAY,
    unbilledSearchTerm,
    workOrderTypeId,
    containerTypeId,
  } = getQueryParams<QueryParams>(search);

  const billOptions = bills?.filter(bill => (!!isOverdue ? !!bill.isOverdue : true));

  const virtualizedPropsBills = {
    height: Math.min(size(billOptions) * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const changeStatusToUnbilled = async (billId: number) => {
    if (!(await confirm(translate('finance.alertMessages.confirmDeleteBill')))) return;
    BillsDucks.changeStatusToUnbilled(
      vendorId,
      billId,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('finance.alertMessages.billDeleted'));
        reloadBillsData();
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.billDeleteError'),
          'finance.alertMessages',
        );
      });
  };

  const exportBills = () => {
    BillsDucks.exportBills(billsSearchTerm, billStatusId, startDate, endDate)(dispatch);
  };

  const exportUnbilledCharges = () => {
    UnbilledChargesDucks.exportUnbilledCharges(unbilledSearchTerm, workOrderTypeId, containerTypeId,
      startDate,
      endDate,
    )(dispatch);
  };

  const reloadBillsData = () => {
    BillsDucks.loadBilling(
      vendorId,
      startDate,
      endDate,
      billsSearchTerm,
      billStatusId,
      unbilledSearchTerm,
      workOrderTypeId,
      containerTypeId,
      true,
    )(dispatch);
  };

  useEffect(() => {
    if (didMountRef.current) {
      BillsDucks.loadBilling(
        vendorId,
        startDate,
        endDate,
        billsSearchTerm,
        billStatusId,
        unbilledSearchTerm,
        workOrderTypeId,
        containerTypeId,
        true,
      )(dispatch).then(() => {
        // jump to unaddigned charges if unassigned filters are changed after load
        if (loadingUnbilledSection) {
          setLoadingUnbilledSection(false);
          unbilledRef?.current?.scrollIntoView();
        }
      });
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    vendorId,
    billsSearchTerm,
    billStatusId,
    startDate,
    endDate,
    unbilledSearchTerm,
    workOrderTypeId,
    containerTypeId,
    search,
  ]);

  useEffect(
    () => () => {
      dispatch(BillsDucks.resetBilling());
    },
    [dispatch],
  );

  useEffect(() => {
    if (ucView === CALENDAR_VIEW) {
      const startDate = moment(currentMonth.startDate).format('MM/DD/YYYY');
      const endDate = moment(currentMonth.endDate).format('MM/DD/YYYY');
      UnbilledChargesDucks.loadCalendarUnbilledCharges(startDate, endDate)(dispatch);
    }
  }, [currentMonth, dispatch, ucView]);

  const onCheckAllDraftBills = () => {
    if (size(draftBillIds) === size(selectedDraftBills)) {
      setSelectedDraftBills([]);
    } else {
      setSelectedDraftBills(draftBillIds);
    }
  };

  const billTableCellsWithCheckDraftBillColumn: TableCell[] = [
    {
      name: 'draftBillCheckbox',
      label: undefined,
      width: tableCellWidths.draftBillCheckbox,
      component: checkAllDraftBills,
      componentProps: { checkAll: onCheckAllDraftBills, checked: size(draftBillIds) === size(selectedDraftBills) },
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: false,
    },
    ...billTableCells,
  ];

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle id="billing-title">{translate('finance.billing')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <ButtonLink to="/finance/billing/create-new-bill" color="primary" id="create-billing-statement-button">
            {translate('finance.createNewBill')}
          </ButtonLink>
        </PageActions>
      </PageHeader>
      <Panel margin="no">
        <PanelSectionGroup padding="no no large" width="100%">
          <BillingFilterForm
            invoiceStatusesOptions={invoiceStatusesOptions}
            initialValues={billedChargesInitialValues}
          />
          <PanelSection withBorder isLoading={isLoadingBills} padding="small">
            <BillingStatistics />
          </PanelSection>
          <PanelSection padding="no medium">
            <PanelSectionHeader margin="large no medium" vertical>
              <PanelSectionSubTitle margin="no">{translate('finance.bills')}</PanelSectionSubTitle>
              <div>
                <Button
                  type="button"
                  color="primary"
                  margin="no small no"
                  line
                  id="bills-export-button"
                  disabled={!size(selectedDraftBills)}
                  onClick={() => releaseSelectedDarftBills()}
                >
                  {translate('finance.release')} {size(selectedDraftBills) ? `(${size(selectedDraftBills)})` : ''}
                </Button>
                <Button
                  type="button"
                  color="primary"
                  line
                  id="bills-export-button"
                  disabled={!size(bills)}
                  onClick={exportBills}
                >
                  {translate('finance.export')}
                </Button>
              </div>
            </PanelSectionHeader>
          </PanelSection>
          <PanelSection padding="no medium medium" isLoading={isLoadingBills} withBorder>
            {!!size(bills) ? (
              <Table
                cells={billTableCellsWithCheckDraftBillColumn}
                rowComponent={BillsListTableRow}
                rows={billOptions}
                rowProps={{
                  changeStatusToUnbilled: changeStatusToUnbilled,
                  onDraftBillSelected,
                  selectedDraftBills,
                  tableCellWidths,
                }}
                scrollMarker
                virtualized
                virtualizedProps={virtualizedPropsBills}
              />
            ) : (
              <Message padding="no no small no">{translate('finance.noBilledCharges')}</Message>
            )}
          </PanelSection>
          <PanelSection padding="no medium" ref={unbilledRef}>
            <PanelSectionHeader margin="large no medium">
              <Grid multiLine>
                <GridColumn size="3/12" padding="no" verticalAlign="center">
                  <PanelSectionSubTitle margin="no">{translate('finance.unbilledCharges')}</PanelSectionSubTitle>
                </GridColumn>
                <GridColumn size="6/12" padding="no" verticalAlign="center">
                  <CalendarListViewSwitch currentView={ucView} switchView={setUcView} />
                </GridColumn>
                <GridColumn size="3/12" padding="no" align="right" verticalAlign="center">
                  <Button
                    type="button"
                    color="primary"
                    line
                    id="unbilledCharges-export-button"
                    disabled={!size(unbilledCharges)}
                    onClick={exportUnbilledCharges}
                  >
                    {translate('finance.export')}
                  </Button>
                </GridColumn>
                {ucView === LIST_VIEW && (
                  <GridColumn size="12/12" padding="small no" margin="small no no" verticalAlign="center">
                    <UnbilledChargesFilterForm
                      containerTypesOptions={containerTypesOptions}
                      workFlowTypesOptions={workFlowTypesOptions}
                      initialValues={unbilledChargesInitialValues}
                      setLoadingUnbilledSection={setLoadingUnbilledSection}
                    />
                  </GridColumn>
                )}
              </Grid>
            </PanelSectionHeader>
          </PanelSection>
          <PanelSection padding="no medium" isLoading={unbilledChargesLoading}>
            {ucView === LIST_VIEW ? (
              !!size(unbilledCharges) ? (
                <UnbilledChargesTable unbilledCharges={unbilledCharges} isViewOnly />
              ) : (
                <Message padding="no no small no">{translate('finance.noUnbilledCharges')}</Message>
              )
            ) : (
              ucView === CALENDAR_VIEW && (
                <UnbilledChargesCalendar
                  calendarUnbilledCharges={calendarUnbilledCharges}
                  currentMonth={currentMonth}
                  handleDateChange={handleDateChange}
                />
              )
            )}
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

export default withRouter(BillingPage);
