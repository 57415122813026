import { map, omit } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { DRIVING_COMPLEXITY_TYPES } from 'src/common/constants/drivingComplexity';
import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import { saveSnowPlowSettings } from '../../ducks';
import { SegmentConfiguration, ServiceConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { SnowPlowForm } from '../forms';
import translate from '../../../core/services/translate';
import {
  SERVICE_CONFIGURATION_TYPES,
  NON_SERVICE_CONFIGURATION_TYPES,
} from 'src/common/constants/serviceConfiguration';

interface Props {
  closeModal: (pristine?: boolean) => void;
}

export default function SnowPlowModal({ closeModal }: Props) {
  const dispatch = useDispatch();

  const { isSaving, isLoadingDefaultSettings, snowPlowSettings } = useSelector(
    (state: AppState) => state.vendors.snowPlowSettings,
  );

  const { isLoading } = useSelector((state: AppState) => state.common.streetSegmentAgingIntervals);
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const initialValues = {
    ...snowPlowSettings,
    drivingComplexityType: snowPlowSettings?.drivingComplexityType?.id.toString(),
    displayConfiguration: {
      ...omit(snowPlowSettings?.displayConfiguration, 'segmentConfiguration'),
      segmentColorSettings: map(
        snowPlowSettings?.displayConfiguration?.segmentConfiguration,
        (el: SegmentConfiguration) => ({
          ...el,
          enabled: !!el.enabled,
        }),
      ),
    },
    serviceSubTypes: snowPlowSettings?.serviceConfiguration
      ?.filter(
        (el: ServiceConfiguration) =>
          !!SERVICE_CONFIGURATION_TYPES[el.alternativeFleetServiceType.technicalName] && el.isActive,
      )
      .map((el: ServiceConfiguration) => el.alternativeFleetServiceType.technicalName),
    nonServiceSubTypes: snowPlowSettings?.serviceConfiguration
      ?.filter(
        (el: ServiceConfiguration) =>
          !!NON_SERVICE_CONFIGURATION_TYPES[el.alternativeFleetServiceType.technicalName] && el.isActive,
      )
      .map((el: ServiceConfiguration) => el.alternativeFleetServiceType.technicalName),
    lastServicedTimeFormatId:
      snowPlowSettings.displayConfiguration.segmentConfiguration &&
      snowPlowSettings.displayConfiguration.segmentConfiguration[0]?.streetSegmentAgingInterval.timeMeasurementType.id,
  };

  const handleSubmit = (formData: any) => {
    const drivingComplexityId = parseInt(formData.drivingComplexityType);
    const serviceConfigurationValues = formData.serviceSubTypes.concat(formData.nonServiceSubTypes);
    const newFormData = {
      ...omit(formData, 'lastServicedTimeFormatId'),
      drivingComplexityType: {
        id: drivingComplexityId,
        name: DRIVING_COMPLEXITY_TYPES[drivingComplexityId].name,
        technicalName: DRIVING_COMPLEXITY_TYPES[drivingComplexityId].name,
      },
      serviceConfiguration: snowPlowSettings?.serviceConfiguration.map((el: ServiceConfiguration) => ({
        ...el,
        isActive: serviceConfigurationValues.includes(el.alternativeFleetServiceType.technicalName),
      })),
      displayConfiguration: {
        ...omit(formData.displayConfiguration, 'segmentColorSettings'),
        segmentConfiguration: map(formData.displayConfiguration.segmentColorSettings, (el: any) => {
          const enabled = el.enabled ? 1 : 0;
          const id = el.streetSegmentAgingInterval.id;
          const red = el.color ? el.color.r : el.red;
          const green = el.color ? el.color.g : el.green;
          const blue = el.color ? el.color.b : el.blue;

          return {
            ...omit(el, 'color'),
            enabled,
            red,
            green,
            blue,
            streetSegmentAgingInterval: {
              id,
              timeMeasurementType: {
                id: el.streetSegmentAgingInterval.timeMeasurementType.id,
              },
            },
          };
        }),
      },
    };
    saveSnowPlowSettings(
      vendorId,
      newFormData as any,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.snowPlowSettingSaved'));
        closeModal();
      })
      .catch(error => {
        const { code } = error;

        switch (code) {
          case 'StreetSegmentAgingIntervalMustBeConsecutive':
            createErrorNotification(translate('vendors.alertMessages.snowPlowSettingErrorIntervalMustBeConsecutive'));
            break;

          case 'ExactlyOneMandatoryEndingSegmentAgingIntervalMustBeSelected':
            createErrorNotification(translate('vendors.alertMessages.snowPlowSettingErrorAgingIntervalMustBeSelected'));
            break;
          case 'AtLeastOneServiceSubTypeAndOneNonServiceSubTypeMustBeSelected':
            createErrorNotification(
              translate('vendors.alertMessages.snowPlowSettingErrorServiceAndNonServiceSubTypes'),
            );
            break;

          default:
            createErrorNotification(translate('vendors.alertMessages.snowPlowSettingError'));
            break;
        }
      });
  };

  return (
    <Modal size="smallMedium" title={translate('vendors.snowPlow.snowPlow')} onClose={closeModal}>
      <ModalSection padding="no" isLoading={isLoading || isSaving || isLoadingDefaultSettings}>
        <SnowPlowForm onCancel={closeModal} onSubmit={handleSubmit} initialValues={initialValues} vendorId={vendorId} />
      </ModalSection>
    </Modal>
  );
}
