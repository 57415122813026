import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { ReleaseNote } from '../interfaces/ReleaseNote';
import { PanelSectionTitle, PanelSectionSubTitle, PanelSection } from '../../core/components/styled';
import { RELEASE_TYPES_MAP } from '../constants/releaseTypes';
import translate from '../../core/services/translate';
import { hideReleaseNoteNotification } from '../ducks/releaseNotes';
import {
  PageContent,
  ReleaseItemDescription,
  ReleaseNoteContentContainer,
  SideNavigationContainer,
  SideNavigationHeader,
  SideNavigationItem,
} from './styled/ReleaseNotes';

type ReleaseNoteDetailsProps = {
  notes: ReleaseNote[];
};

export const SupportedOSandDevices: React.FC<{ androidAppVersion?: string; iosAppVersion?: string }> = ({
  androidAppVersion,
  iosAppVersion,
}) => (
  <>
    <PanelSectionTitle>{translate('versioning.supportedOSandDevices.supportedOSandDevices')}</PanelSectionTitle>
    <PanelSection padding="no medium xSmall medium" vertical>
      <PanelSectionSubTitle margin="no no xxSmall">
        {translate('versioning.supportedOSandDevices.browsers')}
      </PanelSectionSubTitle>
      <ReleaseItemDescription padding="xxSmall medium">
        {translate('versioning.supportedOSandDevices.googleChrome')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="no medium">
        {translate('versioning.supportedOSandDevices.microsoftEdge')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="xxSmall medium">
        {translate('versioning.supportedOSandDevices.safari')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="no medium">
        {translate('versioning.supportedOSandDevices.fireFox')}
      </ReleaseItemDescription>
    </PanelSection>
    <PanelSection padding="no medium xSmall medium" vertical>
      <PanelSectionSubTitle margin="no no xxSmall">
        {translate('versioning.supportedOSandDevices.operatingSystemsSupported')}
      </PanelSectionSubTitle>
      <ReleaseItemDescription padding="xxSmall medium">
        {translate('versioning.supportedOSandDevices.appleSupportedOS')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="xxSmall medium">
        {translate('versioning.supportedOSandDevices.androidSupportedOS')}
      </ReleaseItemDescription>
    </PanelSection>
    <PanelSection padding="no medium xSmall medium" vertical>
      <PanelSectionSubTitle margin="no no xxSmall">
        {translate('versioning.supportedOSandDevices.mobileDevicesSupported')}
      </PanelSectionSubTitle>
      <ReleaseItemDescription padding="xxSmall medium">
        {translate('versioning.supportedOSandDevices.apple')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="xxSmall large">
        {translate('versioning.supportedOSandDevices.appleTabledSupportedDevices')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="xxSmall large">
        {translate('versioning.supportedOSandDevices.applePhoneSupportedDevices')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="xxSmall large">
        {translate('versioning.supportedOSandDevices.currentAppVersion')}: {iosAppVersion || '-'}
      </ReleaseItemDescription>
    </PanelSection>
    <PanelSection padding="no medium xSmall medium" vertical>
      <ReleaseItemDescription padding="xxSmall medium">
        {translate('versioning.supportedOSandDevices.android')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="xxSmall large">
        {translate('versioning.supportedOSandDevices.androidTabledSupportedDevices')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="xxSmall large">
        {translate('versioning.supportedOSandDevices.androidPhoneSupportedDevices')}
      </ReleaseItemDescription>
      <ReleaseItemDescription padding="xxSmall large">
        {translate('versioning.supportedOSandDevices.currentAppVersion')}: {androidAppVersion || '-'}
      </ReleaseItemDescription>{' '}
    </PanelSection>
  </>
);

export const ReleaseNoteContent: React.FC<{ releaseNote: ReleaseNote }> = ({ releaseNote }) => (
  <ReleaseNoteContentContainer>
    <PanelSectionTitle>
      {moment(releaseNote.releaseDate).format('MM/DD/YYYY')} - {RELEASE_TYPES_MAP[releaseNote.releaseType].label}
    </PanelSectionTitle>
    {releaseNote.items.map((i, index) => (
      <PanelSection key={index} padding="no medium xSmall medium" vertical>
        <PanelSectionSubTitle margin={i.description ? 'no no xSmall' : 'no'}>{i.title}</PanelSectionSubTitle>
        {i.description && <ReleaseItemDescription padding="no medium">{i.description}</ReleaseItemDescription>}
      </PanelSection>
    ))}
    <SupportedOSandDevices
      androidAppVersion={releaseNote.androidAppVersion}
      iosAppVersion={releaseNote.iosAppVersion}
    ></SupportedOSandDevices>
  </ReleaseNoteContentContainer>
);

export const ReleaseNotesPageContent: React.FC<ReleaseNoteDetailsProps> = ({ notes }) => {
  const [activeReleaseNote, setActiveReleaseNote] = useState(notes[0]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (notes[0]) {
      hideReleaseNoteNotification(notes[0].publishDate)(dispatch);
    }
  }, [notes, dispatch]);

  return (
    <PageContent>
      <SideNavigationContainer>
        <SideNavigationHeader key="releaseDates">{translate('versioning.releaseDates')}</SideNavigationHeader>
        {notes.map((note: ReleaseNote) => (
          <SideNavigationItem
            key={note.id}
            isActive={activeReleaseNote === note}
            onClick={() => setActiveReleaseNote(note)}
          >
            {moment(note.releaseDate).format('MM/DD/YYYY')}
          </SideNavigationItem>
        ))}
      </SideNavigationContainer>
      <>{activeReleaseNote && <ReleaseNoteContent releaseNote={activeReleaseNote} />}</>
    </PageContent>
  );
};
