import { find, includes, uniqBy } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { change, getFormValues } from 'redux-form';

import { SOS_ALERT_VEHICLE_ID_KEY } from 'src/common/constants/sosAlert';
import { getSupervisorsFiltersPreferencesIds, getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { clearPersistentSOSAlertVehicleId } from 'src/common/utils/sosAlerts';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon } from 'src/core/components/styled';
import { MAP_CITY_ZOOM, MAP_CITY_ZOOM_IN, TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { getItem } from 'src/core/services/persistentStorage';
import { getSegmentsToShow } from 'src/customers/components/pages/streetNetwork/utils';
import { loadStreetNetwork, loadStreetNetworkSegmentDetails, resetDashboardStreetNetwork } from 'src/customers/ducks';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import {
  loadRouteStops,
  loadRoutesList,
  loadRoutesVehiclePositions,
  loadVehiclesList,
  resetRoutesData,
  resetVehiclePositions,
  setRouteHistoryModalOpen,
  setVehicleInsightDetails,
} from 'src/dashboard/ducks';
import {
  getDashboardMapboxInitialFormValues,
  resetDashboardMapboxInitialFilterFormValues,
} from 'src/dashboard/ducks/initialFilterFormValuesMapbox';
import {
  clearDashboardSelectedFeature,
  clearSOSAlertVehicle,
  setDashboardSelectedFeature,
  setDashboardViewport,
  setMapModalData,
} from 'src/dashboard/ducks/mapControls';
import { resetRoadConditions } from 'src/dashboard/ducks/roadConditions';
import { resetSnowRoadConditions } from 'src/dashboard/ducks/snowRoadConditions';
import useFitBoundsDashboardMapGL from 'src/dashboard/hooks/useFitBoundsDashboardMapGL';
import useLoadDataForMapboxDashboard, {
  getRoutesListParams,
  getVehiclesListParams,
  loadActiveVehiclePositions,
} from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { RouteStopItem } from 'src/dashboard/interfaces/routesData';
import { VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import { getSegmentCenter, parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { loadRouteSegments, loadSnowPlowSegmentList } from 'src/routes/ducks';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { RUBICON_Z } from 'src/vendors/constants';
import { resetCityAlerts } from 'src/vendors/ducks';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import { breakDownDeviceRoleTypeId } from 'src/vendors/services/vendorEditorFormInitialValuesSelector';
import { PermissionGuard } from '../../../account/components';
import { DASHBOARD_FILTERS, DASHBOARD_REAL_TIME_INSIGHTS_FEED } from '../../../account/constants';
import { isRubiconNonTechHauler, isSmartCitySelector, vendorGusIdSelector } from '../../../account/ducks';
import { Banner } from '../../../common/components';
import { PageContent } from '../../../common/components/styled';
import {
  resetFilters,
  resetFleetInsights,
  resetMapInsights,
  resetVehicleTrackings,
  resetVendorLocations,
} from '../../ducks';
import { DashboardMapboxVehicleDetailsForm } from '../forms';
import DashboardMapboxFiltersForm, { FILTER_TABS, MapboxFiltersFormValues } from '../forms/DashboardMapboxFiltersForm';
import DashboardMapboxRouteDetailsForm, {
  DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME,
} from '../forms/DashboardMapboxRouteDetailsForm';
import MapModalResolver from '../modals/MapModal/MapModalResolver';
import RouteHistoryModalResolver from '../modals/RouteHistoryModal/RouteHistoryModalResolver';
import { DashboardMapWrapper } from '../styled';
import { DashboardFilterSectionCollapseButton } from '../styled/DashboardFilterMapbox';
import { DashboardFleetInsightsMapbox, DashboardMapLoadingOverlay } from './dashboardPageSections';
import DashboardOperationsTabs from './dashboardPageSections/DashboardOperationsTabs';
import DashboardMapGL from './dashboardPageSections/mapGL/DashboardMapGL';

interface Props {
  vendorId: number;
}

const DashboardPageMapbox: FC<Props> = ({ vendorId }) => {
  const [isVehicleDetailsOpen, setIsVehicleDetailsOpen] = useState(false);
  const [isRouteDetailsOpen, setIsRouteDetailsOpen] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<number>();
  const [isSOSVehicle, setIsSOSVehicle] = useState<boolean>(false);
  const [selectedRouteId, setSelectedRouteId] = useState<number>();
  const [selectedSnowOrStreetSweeper, setSelectedSnowOrStreetSweeper] = useState<boolean>(false);
  const [isSourceAdvancedSearch, setIsSourceAdvancedSearch] = useState<boolean>(false);
  const [isRouteStopsSectionOpen, setIsRouteStopsSectionOpen] = useState<boolean>(false);
  const [isAutoRefresh, setIsAutoRefresh] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const { search, key: locationKey } = useLocation();

  const formValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;

  const isLoadingFleetInsights = useSelector(state => state.dashboard.fleetInsights.isLoading);
  const isLoadingMapInsights = useSelector(state => state.dashboard.mapInsightsMapbox.isLoading);
  const isLoadingRoadConditions = useSelector(state => state.dashboard.roadConditions.isLoading);
  const isLoadingSnowRoadConditions = useSelector(state => state.dashboard.snowRoadConditions.isLoading);
  const isLoadingStreetSweeper = useSelector(state => state.dashboard.streetSweeper.isLoading);
  const isLoadingVehiclePositions = useSelector(state => state.dashboard.vehiclesData.isLoading);
  const isLoadingVehicleTrackings = useSelector(state => state.dashboard.vehicleTrackings.isLoading);
  const isLoadingCityAlerts = useSelector(state => state.vendors.cityAlerts.isLoading);
  const isLoadingGeoFences = useSelector(state => state.routes.geoFences.isLoading);

  const {
    isLoading: isLoadingVehicles,
    isLoadingVehicleBreadcrumbs,
    isLoadingVehicleInsightDetails,
  } = useSelector(state => state.dashboard.vehiclesData);
  const isLoadingRouteVehiclePositions = useSelector(
    state => state.dashboard.routesData.isLoadingRouteVehiclePositions,
  );
  const isLoadingRouteVehiclesBreadCrumbs = useSelector(
    state => state.dashboard.routesData.isLoadingRouteVehiclesBreadCrumbs,
  );
  const isLoadingGeoFenceIncidents = useSelector(state => state.dashboard.routesData.isLoadingGeoFenceIncidents);

  const filterPreferences = useSelector(state => state.common.filters.filters);

  const isNonTechHauler = useSelector(state =>
    isRubiconNonTechHauler(state.account.login, state.vendors.defaultVendor, state.account.createUserAccount),
  );
  const isSmartCity = useSelector(state => isSmartCitySelector(state.account.login, state.vendors.defaultVendor));
  const isVendorWithGusId = useSelector(state => vendorGusIdSelector(state.account.login, state.vendors.defaultVendor));
  const vendor = useSelector(state => state.vendors.vendor.vendor) || ({} as Vendor);

  const { streetNetwork, streetNetworkForDashboardMap } = useSelector(state => state.customers.streetNetwork);
  const { routeSegments: segmentsList } = useSelector(state => state.routes.routeSegments);
  const { segmentList } = useSelector(state => state.routes.snowPlowSegmentList);

  const vehiclePositions = useSelector(state => (state.dashboard.vehiclesData.vehiclesList || []) as VehiclePosition[]);
  const { isModalMapOpen } = useSelector(state => state.dashboard.mapControls.mapModalData);
  const { isModalOpen: isRouteHistoryModalOpen } = useSelector(state => state.dashboard.routeHistoryData);
  const { latitude: vendorAddressLatitude, longitude: vendorAddressLongitude } = useSelector(
    state => state.vendors?.vendor?.vendor?.homeAddress,
  );

  const filteredSegments = useMemo(() => {
    const routeSegments = segmentList;
    const routeSegmentIds = [...routeSegments.map(segment => (segment as any).id)];
    const routeSegmentsWithStreetPasses = uniqBy(
      segmentsList.filter((el: RouteSegment) => routeSegmentIds.includes(el.streetSegmentId)),
      'streetSegmentId',
    );
    const segments = getSegmentsToShow(routeSegments, streetNetwork, routeSegmentsWithStreetPasses, segmentsList, []);

    return segments as any;
  }, [segmentList, segmentsList, streetNetwork]);

  const isRubiconZ = includes(breakDownDeviceRoleTypeId(vendor?.deviceRoleTypeId), RUBICON_Z);

  const isLoading =
    isLoadingFleetInsights ||
    isLoadingMapInsights ||
    isLoadingRoadConditions ||
    isLoadingSnowRoadConditions ||
    isLoadingStreetSweeper ||
    isLoadingVehiclePositions ||
    isLoadingVehicleTrackings ||
    isLoadingGeoFences ||
    isLoadingVehicleBreadcrumbs ||
    isLoadingRouteVehiclePositions ||
    isLoadingGeoFenceIncidents ||
    isLoadingRouteVehiclesBreadCrumbs ||
    isLoadingVehicleInsightDetails ||
    isLoadingCityAlerts;

  const isSubMenuHidden = !isVendorWithGusId && !isNonTechHauler;

  const openVehicleDetails = useCallback(
    (isSnowSweeper = false) => {
      isRouteDetailsOpen && setIsRouteDetailsOpen(false);
      setSelectedSnowOrStreetSweeper(isSnowSweeper);
      setIsVehicleDetailsOpen(true);
    },
    [isRouteDetailsOpen],
  );

  const openRouteDetails = (isSnowOrSweeper = false) => {
    isVehicleDetailsOpen && setIsVehicleDetailsOpen(false);
    setSelectedSnowOrStreetSweeper(isSnowOrSweeper);
    setIsRouteDetailsOpen(true);
  };

  const closeDetailsScreen = () => {
    if (isVehicleDetailsOpen) {
      setIsVehicleDetailsOpen(false);
      setSelectedVehicleId(undefined);

      if (isSOSVehicle) {
        setIsSOSVehicle(false);
        clearPersistentSOSAlertVehicleId();
        dispatch(clearSOSAlertVehicle());
        resetState();
      }
      const vehiclesListParams = getVehiclesListParams(formValues);
      loadVehiclesList(
        vehiclesListParams.date,
        vehiclesListParams.includeInactive,
        vehiclesListParams.vehicleTypeIds.toString(),
        vehiclesListParams.supervisorIds.toString(),
        formValues?.searchTerm,
      )(dispatch).then((response: any) => {
        setIsAutoRefresh(false);
        loadActiveVehiclePositions(response.vehiclesList, vendorId, vehiclesListParams.date, dispatch);
      });
      dispatch(clearDashboardSelectedFeature());
    }

    setSelectedSnowOrStreetSweeper(false);

    if (isRouteDetailsOpen) {
      setIsRouteDetailsOpen(false);
      setSelectedRouteId(undefined);
      setIsRouteStopsSectionOpen(false);

      dispatch(resetDashboardStreetNetwork());

      const routesListParams = getRoutesListParams(formValues);
      loadRoutesList(routesListParams)(dispatch).then(() => {
        dispatch(clearDashboardSelectedFeature());

        loadRoutesVehiclePositions(routesListParams)(dispatch).then((res: any) => {
          if (!res.payload.length) {
            vendorAddressLatitude &&
              vendorAddressLongitude &&
              dispatch(
                setDashboardViewport({
                  latitude: vendorAddressLatitude,
                  longitude: vendorAddressLongitude,
                  zoom: MAP_CITY_ZOOM_IN,
                }),
              );
          }
        });
      });
    }

    isVehicleDetailsOpen && setIsVehicleDetailsOpen(false);
    isRouteDetailsOpen && setIsRouteDetailsOpen(false);
    setSelectedVehicleId(undefined);
    setIsSourceAdvancedSearch(false);
    setIsAutoRefresh(false);
    dispatch(setVehicleInsightDetails([]));
  };

  const resetState = useCallback(
    (exclude: { filters?: boolean; initialFilterFormValues?: boolean; vendorLocations?: boolean } = {}) => {
      if (!exclude.filters) {
        dispatch(resetFilters());
      }

      if (!exclude.initialFilterFormValues) {
        dispatch(resetDashboardMapboxInitialFilterFormValues());
      }

      if (!exclude.vendorLocations) {
        dispatch(resetVendorLocations());
      }

      dispatch(resetVehicleTrackings());
      dispatch(resetMapInsights());
      dispatch(resetFleetInsights());
      dispatch(resetRoadConditions());
      dispatch(resetSnowRoadConditions());
      dispatch(resetRoutesData());
      dispatch(resetVehiclePositions());
      dispatch(resetCityAlerts());
    },
    [dispatch],
  );

  // ==========================
  // Load data for the map
  // ==========================
  useLoadDataForMapboxDashboard(selectedRouteId, setIsAutoRefresh);

  // ==========================
  // FIT MAP BOUNDS (VIEWPORT)
  // ==========================
  useFitBoundsDashboardMapGL(
    isLoadingVehiclePositions || isLoadingRouteVehiclePositions,
    isSourceAdvancedSearch,
    isAutoRefresh,
  );

  useEffect(
    () => () => {
      resetState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSelectVehicle = useCallback(
    (vehicleId: number, isSnowPlowOrStreetSweeperRoute?: boolean) => {
      clearPersistentSOSAlertVehicleId();
      dispatch(setDashboardSelectedFeature('vehiclePositions', vehicleId, { zoomToIt: true, doNotShowPopup: true }));
      setSelectedVehicleId(vehicleId);
      openVehicleDetails(isSnowPlowOrStreetSweeperRoute);
      setIsAutoRefresh(false);
    },
    [dispatch, openVehicleDetails],
  );

  // SOS Alert
  useEffect(() => {
    const storageKey = getItem(SOS_ALERT_VEHICLE_ID_KEY);
    const sosAlertVehicleId = storageKey && storageKey !== 'undefined' ? JSON.parse(storageKey) : undefined;
    const hasSosAlertVehicleId = sosAlertVehicleId !== undefined;
    const vehicle = find(vehiclePositions, { id: sosAlertVehicleId });

    if (hasSosAlertVehicleId && !vehicle && formValues && !formValues.includeInactive) {
      dispatch(change(DASHBOARD_FILTER_FORM_MAPBOX, 'includeInactive', true));
    }

    if (hasSosAlertVehicleId && !vehicle && formValues && formValues.date) {
      const date = formValues.date;
      if (date !== TODAY_FORMATTED) {
        dispatch(change(DASHBOARD_FILTER_FORM_MAPBOX, 'date', TODAY_FORMATTED));
      }
    }

    if (hasSosAlertVehicleId && !vehicle && !isLoadingVehicles) {
      dispatch(change(DASHBOARD_FILTER_FORM_MAPBOX, 'activeTab', FILTER_TABS.vehicles));
      setIsRouteDetailsOpen(false);
      setIsVehicleDetailsOpen(false);
    }

    if (hasSosAlertVehicleId && vehicle && !isLoadingVehicles) {
      setIsSOSVehicle(true);
      onSelectVehicle(sosAlertVehicleId);
    }
  }, [locationKey, vehiclePositions]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectRoute = (
    routeId: number,
    isSourceAdvancedSearch?: boolean,
    routeLocationId?: number,
    date?: Date | string,
    isSnowPlowOrStreetSweeperRoute?: boolean,
  ) => {
    dispatch(setDashboardSelectedFeature('route', routeId, { zoomToIt: true }));
    setSelectedRouteId(routeId);
    setIsSourceAdvancedSearch(!!isSourceAdvancedSearch);
    openRouteDetails(isSnowPlowOrStreetSweeperRoute);

    setIsAutoRefresh(false);

    if (routeLocationId && date) {
      setIsRouteStopsSectionOpen(true);

      const checkSelectedRouteLocation = (routeLocations: RouteStopItem[]) => {
        dispatch(change(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME, `routeStopsFilters.stops._${routeLocationId}`, true));
        const selectedRouteLocation = routeLocations?.find(
          (routeLocation: RouteStopItem) => routeLocation.id === routeLocationId,
        );

        const segmentCenter =
          selectedRouteLocation &&
          isSnowPlowOrStreetSweeperRoute &&
          getSegmentCenter(parseSegmentJSON(selectedRouteLocation.lineSegment));

        if (selectedRouteLocation) {
          dispatch(
            setDashboardViewport({
              latitude: segmentCenter ? segmentCenter[1] : selectedRouteLocation?.displayLatitude,
              longitude: segmentCenter ? segmentCenter[0] : selectedRouteLocation?.displayLongitude,
              zoom: MAP_CITY_ZOOM,
            }),
          );

          dispatch(
            setDashboardSelectedFeature(
              isSnowPlowOrStreetSweeperRoute ? 'routeSegments' : 'routeStop',
              routeLocationId,
            ),
          );

          isSnowPlowOrStreetSweeperRoute && loadStreetNetworkSegmentDetails(vendorId, routeLocationId)(dispatch);
        }
      };

      if (isSnowPlowOrStreetSweeperRoute) {
        loadRouteSegments({ vendorId, routeId, isForDashboard: true })(dispatch).then(routeSegments => {
          const segmentsIds = routeSegments.map((segment: RouteSegment) => segment.streetSegmentId);
          if (segmentsIds.length) loadSnowPlowSegmentList(vendorId, segmentsIds)(dispatch);

          const routeTemplateId = undefined;
          const includeRouteTemplateIds = undefined;
          const streetSegmentAssignedStatus = true;

          loadStreetNetwork({
            vendorId,
            routeId,
            routeTemplateId,
            includeRouteTemplateIds,
            streetSegmentAssignedStatus,
          })(dispatch).then((response: any) => {
            checkSelectedRouteLocation(response);
          });
        });
      } else {
        loadRouteStops({ routeId, date })(dispatch).then((response: any) => {
          checkSelectedRouteLocation(response?.payload);
        });
      }
    }
  };

  const formInitialValues = useMemo(() => {
    const vehicleFiltersPreferencesIds = getVehicleFiltersPreferencesIds(filterPreferences);
    const supervisorsFiltersPreferencesIds = getSupervisorsFiltersPreferencesIds(filterPreferences);
    return getDashboardMapboxInitialFormValues(search, vehicleFiltersPreferencesIds, supervisorsFiltersPreferencesIds);
  }, [search, filterPreferences]);

  return (
    <PageContent fluid fitToVerticalViewport isSubMenuHidden={isSubMenuHidden} margin="no" tabletMargin="no">
      <PermissionGuard permission={DASHBOARD_FILTERS}>
        <DashboardFilterSectionCollapseButton isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
          <TooltipIconButton
            tooltip={isCollapsed ? 'openPanel' : 'closePanel'}
            noButtonWrapper
            tooltipPosition="right"
            tooltipColor="grayDarker"
            color="secondary"
            margin="no no"
          >
            <IconButtonIcon icon="arrowLeft" />
          </TooltipIconButton>
        </DashboardFilterSectionCollapseButton>

        <DashboardMapboxFiltersForm
          initialValues={formInitialValues}
          isCollapsed={isCollapsed}
          isSubMenuHidden={isSubMenuHidden}
          onSelectRoute={onSelectRoute}
          onSelectVehicle={onSelectVehicle}
          vendorId={vendorId}
          setIsAutoRefresh={setIsAutoRefresh}
        />

        <DashboardMapboxVehicleDetailsForm
          close={closeDetailsScreen}
          initialValues={{ displayBreadCrumbs: !isSOSVehicle }}
          isCollapsed={isCollapsed}
          isOpen={isVehicleDetailsOpen}
          isSOSVehicle={isSOSVehicle}
          selectedVehicleId={selectedVehicleId}
        />

        <DashboardMapboxRouteDetailsForm
          close={closeDetailsScreen}
          initialValues={{ routeId: selectedRouteId }}
          isCollapsed={isCollapsed}
          isOpen={isRouteDetailsOpen}
          isRouteStopsSectionOpen={isRouteStopsSectionOpen}
          isSourceAdvancedSearch={isSourceAdvancedSearch}
          segmentList={filteredSegments}
          selectedRouteId={selectedRouteId}
        />
      </PermissionGuard>

      {!isSmartCity && <Banner />}

      <PermissionGuard permission={DASHBOARD_REAL_TIME_INSIGHTS_FEED}>
        <DashboardFleetInsightsMapbox />
      </PermissionGuard>

      <DashboardMapWrapper ref={wrapperRef} isBetaPage isCollapsed={isCollapsed}>
        <DashboardMapGL
          showVehicleTypesPanel={
            formValues?.activeTab === FILTER_TABS.vehicles && getVehiclesListParams(formValues).date === TODAY_FORMATTED
          }
          isRubiconZ={isRubiconZ}
          selectedVehicleId={selectedVehicleId}
          streetNetwork={streetNetworkForDashboardMap}
          filteredSegments={streetNetworkForDashboardMap}
          vehicleTypeId={selectedSnowOrStreetSweeper ? SNOW_PLOW_ID : undefined}
          routeId={selectedRouteId}
        />
        <DashboardMapLoadingOverlay isLoading={isLoading} />
      </DashboardMapWrapper>

      <DashboardOperationsTabs isCollapsed={isCollapsed} />

      {isModalMapOpen && (
        <MapModalResolver
          closeModal={() =>
            dispatch(setMapModalData({ isModalMapOpen: false, vehicleId: null, date: null, isSourceRoute: false }))
          }
        />
      )}
      {isRouteHistoryModalOpen && (
        <RouteHistoryModalResolver closeModal={() => dispatch(setRouteHistoryModalOpen(false, null, null))} />
      )}
    </PageContent>
  );
};

export default DashboardPageMapbox;
