import { isEqual } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ComplexMapControl } from './styled/RouteMap';
import { Coordinates } from 'src/common/components/map/Coordinates';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getMapBounds } from 'src/common/components/map/util';
import { IconButtonIcon } from 'src/core/components/styled';
import { InfoSection } from 'src/core/components/styled/Button';
import { loadPinOnMapGeoFences, resetPinOnMapGeoFences } from '../ducks';
import { LocationData } from 'src/customers/interfaces/LocationServiceTypes';
import { MAP_CITY_ZOOM } from 'src/core/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { MapGLWrapper } from 'src/customers/components/styled';
import OnMapFiltersForm, {
  OnMapFiltersFormValues,
} from './pages/dispatchBoard/dispatchBoardMapSections/map/OnMapFiltersForm';
import { useSelector } from 'src/core/hooks/useSelector';
import LocationMapPickerLayer from './LocationMapPickerLayer';
import RouteMapGeoFencesGL from './pages/routes/routePageSections/routeMap/geoFences/RouteMapGeoFencesGL';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import translate from 'src/core/services/translate';
import useGeoFenceControllerMapbox from 'src/common/hooks/geoFenceControllerMapbox';

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

interface Props {
  handleLocationChange: (location: Coordinates) => void;
  isLocationPickerChanged: boolean;
  isPinOnMapVisible: boolean;
  location?: LocationData;
  showMapFilters?: boolean;
}

const LocationMapPicker: FC<Props> = ({
  handleLocationChange,
  isLocationPickerChanged,
  isPinOnMapVisible,
  location,
  showMapFilters,
}) => {
  const vendorAddress = useSelector(state => state.vendors.vendor.vendor.homeAddress);

  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const [mapRef, setMapRef] = useState<mapboxgl.Map>();
  const [dragPan, setDragPan] = useState(true);
  const [isMapFiltersOpen, setIsMapFiltersOpen] = useState(false);

  const [viewport, setViewport] = useState<MapGLViewport>({
    latitude: location ? location.latitude : vendorAddress?.latitude,
    longitude: location ? location.longitude : vendorAddress?.longitude,
    zoom: MAP_CITY_ZOOM,
  });

  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapZoom: MAP_CITY_ZOOM,
  });

  // when location changes, update the map viewport
  useEffect(() => {
    if (
      location &&
      location.latitude &&
      location.longitude &&
      location.latitude !== viewport.latitude &&
      location.longitude !== viewport.longitude &&
      isLocationPickerChanged
    ) {
      setViewport({
        ...viewport,
        latitude: location.latitude,
        longitude: location.longitude,
      });
    }
  }, [location, viewport, isLocationPickerChanged]);

  // GEO_FENCES
  const { geoFences: geoFenceJsonList } = useSelector(state => state.routes.pinOnMapGeoFences.geoFences);

  const { getAllCoords, allGeoFencesGeoJson, bulkAddGeoFences, emptyGeoFences } = useGeoFenceControllerMapbox();

  // add all the geoFences to the controller
  useEffect(() => {
    const bulkToSet = (geoFenceJsonList || []).map((geoFence: any) => {
      const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
      return {
        ...geoFence,
        geoFenceCoordinates: parsedGeoFenceJson?.geometry?.coordinates,
      };
    });
    bulkToSet.length && bulkAddGeoFences(bulkToSet);
  }, [geoFenceJsonList, bulkAddGeoFences]);

  useEffect(
    () => () => {
      dispatch(resetPinOnMapGeoFences());
    },
    [dispatch],
  );

  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];
    if (geoFenceJsonList?.length > 0) {
      points.push(...getAllCoords);
    }

    if (points.length > 0) {
      const bounds = getMapBounds(points, {
        capZoom: MAP_CITY_ZOOM,
      });

      !isEqual(viewport, bounds) &&
        setViewport({
          ...viewport,
          ...bounds,
        });
    }
  }, [getAllCoords, geoFenceJsonList?.length, viewport]);

  const handleSubmitGeoFenceFilters = (formData: OnMapFiltersFormValues) => {
    const normalizedGeoFencesIds = normalizeFilterArray(formData.geoFenceSubFilters);
    const normalizedGeoFencesTypesIds = normalizeFilterArray(formData.geoFencesTypesFilters);

    if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
      dispatch(
        loadPinOnMapGeoFences({
          vendorId,
          geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
          limit: 200,
          geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
        }),
      );
    } else {
      emptyGeoFences();
    }
    setIsMapFiltersOpen(false);
  };

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: mapRef?.getCenter(),
      mapZoom: mapRef?.getZoom(),
    });
  };

  return (
    <>
      <MapGLWrapper>
        <MapGL
          key="locationMapPicker"
          disableDefaultSatelliteView
          enableNewSatelliteView
          disableDefaultNavigationControl
          enableNewNavigationControl
          onMapRefLoaded={setMapRef}
          viewport={viewport}
          dragPan={dragPan}
          setIsSatelliteViewEnabled={handleMapViewStyleChange}
          doubleClickZoom={false}
        >
          {showMapFilters && (
            <>
              <ComplexMapControl position="top-right" vertical>
                <TooltipIconButton
                  tooltip="filters"
                  tooltipPosition="left"
                  tooltipColor="grayDarker"
                  color="secondary"
                  margin="no"
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    setIsMapFiltersOpen(true);
                  }}
                >
                  <IconButtonIcon icon="filter" color="primary" />
                </TooltipIconButton>
              </ComplexMapControl>

              <OnMapFiltersForm
                isOnMapFiltersFormValuesOpen={isMapFiltersOpen}
                closeRouteMapFilters={() => setIsMapFiltersOpen(false)}
                initialValues={{ geoFencesTypesFilters: [], geoFenceSubFilters: [], geoFenceSearchTerm: '' }}
                onSubmit={handleSubmitGeoFenceFilters}
                isOnRightSide
              />
            </>
          )}
          {mapRef && (
            <>
              <RouteMapGeoFencesGL
                map={mapRef}
                geoFencesGeoJSON={allGeoFencesGeoJson || []}
                isSatellite={mapViewStyle.isSatelliteEnabled}
              />

              <LocationMapPickerLayer
                setDragPan={setDragPan}
                map={mapRef}
                location={location}
                onLocationChanged={handleLocationChange}
                isPinOnMapVisible={isPinOnMapVisible}
              />
            </>
          )}

          {isPinOnMapVisible && <InfoSection>{translate('vendors.cityAlerts.addAlertInfo')}</InfoSection>}
        </MapGL>
      </MapGLWrapper>
    </>
  );
};

export default LocationMapPicker;
