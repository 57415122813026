import moment from 'moment';

import { createErrorNotification } from 'src/core/services/createNotification';
import { HOURS_TYPE_ID, DAYS_TYPE_ID, WEEKS_TYPE_ID } from 'src/common/constants';
import { RoutesResourceAvailability } from '../interfaces/Route';
import translate from 'src/core/services/translate';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';

export const getRouteTime = (time?: string) => {
  const currentDate = moment().format('MM/DD/YYYY');
  return time ? moment(currentDate + ' ' + time).format(timeFormatWithoutSeconds) : undefined;
};

export const getRouteSaveErrorMessage = (code?: string, date?: Date | string) => {
  switch (code) {
    case 'RouteExistsOnDate':
      createErrorNotification(
        translate(`routes.alertMessages.routeExistsOnDate`, {
          routeDate: date,
        }),
      );
      break;

    case 'VehicleIsNotAvailable':
      createErrorNotification(
        translate(`routes.alertMessages.vehicleIsNotAvailable`, {
          routeDate: date,
        }),
      );
      break;

    case 'SupervisorIsNotAvailable':
      createErrorNotification(
        translate(`routes.alertMessages.supervisorIsNotAvailable`, {
          routeDate: date,
        }),
      );
      break;

    case 'InvalidSupervisor':
      createErrorNotification(translate(`routes.alertMessages.invalidSupervisor`));
      break;

    case 'EntityAlreadyExists':
      createErrorNotification(translate(`routes.alertMessages.entityAlreadyExists`));
      break;

    default:
      createErrorNotification(translate('routes.alertMessages.routeSaveError'));
      break;
  }
};

export const getIsDriverUnavailable = (routesResourceAvailability: RoutesResourceAvailability[]) =>
  !!routesResourceAvailability.filter((resource: RoutesResourceAvailability) => !resource.isDriverAvailable).length;

export const getIsVehicleUnavailable = (routesResourceAvailability: RoutesResourceAvailability[]) =>
  !!routesResourceAvailability.filter((resource: RoutesResourceAvailability) => !resource.isVehicleAvailable).length;

export const getRouteNames = (routesResourceAvailability: RoutesResourceAvailability[]) =>
  routesResourceAvailability
    .filter((resource: RoutesResourceAvailability) => !resource.isDriverAvailable || !resource.isVehicleAvailable)
    .map((resource: RoutesResourceAvailability) => resource.routeName);

export const getRoutesResourceAvailabilityTranslation = (isDriverUnavailable: boolean, isVehicleUnavailable: boolean) =>
  translate(
    `routes.alertMessages.${
      isDriverUnavailable && isVehicleUnavailable
        ? 'routesHaveVehicleAndDriverNotAvailable'
        : isVehicleUnavailable
        ? 'routesHaveVehicleNotAvailable'
        : 'routesHaveDriverNotAvailable'
    }`,
  );

export const getAgingIntervalOptionLabel = (id?: number, name?: string) =>
  id === HOURS_TYPE_ID
    ? translate('vendors.streetSweeper.noOfHours', { hours: name?.replace('h', '') })
    : id === DAYS_TYPE_ID
    ? translate('vendors.streetSweeper.noOfDays', { days: name?.replace('d', '') })
    : id === WEEKS_TYPE_ID
    ? translate('vendors.streetSweeper.noOfWeeks', { weeks: name?.replace('w', '') })
    : translate('vendors.streetSweeper.noOfMonths', { months: name?.replace('m', '') });
