import styled, { css } from 'styled-components';

interface Props {
  isDrawing?: boolean;
  drawingEnabled?: boolean;
  isEditMode?: boolean;
  hidden?: boolean;
  height?: string | number;
}

const MapGLWrapper = styled.div<Props>`
  border: ${p => (p.isEditMode ? '4px solid ' + p.theme.brandPrimary : 'none')};

  height: ${props =>
    typeof props.height === 'number' ? `${props.height}px` : typeof props.height === 'string' ? props.height : '100%'};
  position: relative;

  & .overlays {
    cursor: ${p => (p.isDrawing ? 'crosshair' : 'initial')};
    pointer-events: ${p => (p.drawingEnabled ? 'all' : 'none !important')};

    & > :not(#editor) {
      pointer-events: all;
    }
  }

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib {
    display: none;
  }

  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
`;

export const EditableMapGLInfo = styled.div<Props>`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 0.8;
  z-index: 1;
`;

export default MapGLWrapper;
