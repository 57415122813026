import { Link } from 'react-router-dom';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Icon } from '../../../core/components';
import { loading, loadingOverlay, slideInUp } from '../../../core/styles';
import { Popover } from '../../../core/components/styled';
import { RouteDetail, RouteDetailLabel, RouteDetailValue } from 'src/routes/components/styled/RouteDetails';
import { sizeMapper, colorMapper, mapper } from '../../../utils/styles';

type Props = Partial<{
  align: string;
  animated: boolean;
  autoHide?: boolean;
  color: string;
  fitToVerticalViewport: boolean;
  fluid: boolean;
  fullScreen: boolean;
  height: string;
  isBottomPositioned: boolean;
  isLoading: boolean;
  isSubMenuHidden: boolean;
  margin: string;
  marginBottom?: string;
  maxContentWidth?: boolean;
  paddingBottom?: string;
  shouldHide: boolean;
  size: string;
  tabletMargin: string;
  textTransform: string;
  tooltipLeftPosition: number;
  tooltipTopPosition: number;
  width: string;
  withBackButton: boolean;
  withSubTitle: boolean;
  zIndex: number;
  positionUnset?: boolean;
}>;

export const PageTitle = styled.h1<Props>`
  margin: 5px 0 10px;
  line-height: 40px;
  text-transform: capitalize;
  text-transform: ${props => mapper(props.textTransform, { none: 'none' }) || 'capitalize'};
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 36px;
`;

export const SlimPageTitle = styled(PageTitle)`
  margin: 0;
  font-size: 25px;
  font-weight: ${props => props.theme.fontWeightMedium};
  line-height: 1;
`;

export const PageSubtitle = styled.span<Props>`
  display: block;
  line-height: 17px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: ${props => mapper(props.size, { small: '12px', medium: '16px' }) || '12px'};
  color: ${props => props.theme.grayDarker};
  margin: ${props => sizeMapper(props.margin, 'no auto small')};
`;

export const SlimPageSubtitle = styled(PageSubtitle)`
  margin: 4px 0 0;
  font-size: 14px;
`;

export const PageBackButtonIcon = styled(Icon).attrs({ icon: 'back' })`
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  margin-top: 12px;
`;

export const PageBackButton = styled(Link)`
  position: absolute;
  top: 0;
  left: -30px;
  display: inline-block;
  height: 40px;
  width: 20px;
  color: ${props => props.theme.brandPrimary};
`;

export const PageBackButtonAction = styled.div<{ disabled?: boolean }>`
  position: absolute;
  top: 0;
  left: -30px;
  display: inline-block;
  cursor: pointer;
  height: 40px;
  width: 20px;
  color: ${props => props.theme.brandPrimary};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    `};
`;

export const PageBackAction = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 40px;
  padding-top: 5px;
  color: ${props => props.theme.brandPrimary};
`;

export const PageTitleContainer = styled.div<Props>`
  position: relative;
`;

interface PageDetailsProps {
  withBackButton?: boolean;
  flex?: boolean;
}

export const PageDetails = styled.div<PageDetailsProps>`
  ${props =>
    props.withBackButton &&
    css`
      padding-left: 30px;
    `};

  ${props =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
    `}
`;

interface PageActionsProps {
  align?: string;
  alignItems?: string;
  flex?: boolean;
  shouldHide?: boolean;
  width?: string;
}

export const PageActions = styled.div<PageActionsProps>`
  min-width: fit-content;

  @media (max-width: ${props => props.theme.tabletBreakpoint}) {
    min-width: auto;
    text-align: center;
    line-height: 50px;
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${props =>
    props.shouldHide &&
    css`
      display: none;
    `}

  ${props =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
    `}

  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
      margin-top: 15px;
    `}
`;

export const PageActionsSmall = styled.div<PageActionsProps>`
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 10px;

  @media (max-width: ${props => props.theme.tabletBreakpoint}) {
    min-width: auto;
    text-align: center;
    line-height: 50px;
  }

  ${RouteDetail} {
    flex-direction: row;
    flex: 0;
    margin: 0 0 5px 0;

    ${RouteDetailValue} {
      font-size: 14px;
      margin: 0;
    }

    ${RouteDetailLabel} {
      font-size: 13px;
      margin: 0 5px 0 0;
    }
  }
`;

export const PageHeader = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;

  ${props =>
    props.withSubTitle &&
    css`
      padding: 30px 0 0 0;
    `};
`;

export const PageContent = styled.div<Props>`
  position: relative;
  z-index: ${p => p.zIndex || 'initial'};
  width: 1140px;
  margin: ${props => sizeMapper(props.margin, 'no auto medium')};
  margin-bottom: 70px;
  transition: width 1s;

  @media (max-width: ${props => props.theme.desktopBreakpoint}) {
    width: 100%;
    padding: 0 15px;
    margin-bottom: ${props => (props.tabletMargin ? props => sizeMapper(props.tabletMargin) : '15px')};
  }

  @media (max-width: ${props => props.theme.mobileBreakpoint}) {
    padding: 0;
    margin: 0;
  }

  ${props =>
    props.paddingBottom &&
    css`
      padding-bottom: ${props.paddingBottom};
    `}

  ${props =>
    props.positionUnset &&
    css`
      position: unset;
    `}

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}

  ${p =>
    p.fullScreen &&
    css`
      width: 100%;
      padding: 0 30px;
      margin-bottom: 10px;
    `}

  ${props =>
    props.fluid &&
    css`
      width: 100%;
    `};

  ${props =>
    props.fitToVerticalViewport &&
    css`
      height: ${props.isSubMenuHidden ? 'calc(100vh - 70px)' : 'calc(100vh - 140px)'};
      margin-bottom: 0;
    `};

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;

      &:after {
        content: '';
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -16px 0 0 -16px;
        ${loading('24px')};
      }
    `};
`;

export const PageLoading = styled.div<Props>`
  height: ${props => (props.height ? props.height : 'calc(100vh - 140px)')};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
    ${loading('24px')};
  }
`;

export const PageLoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6002;
  ${props => loadingOverlay('24px', props.theme.grayBase, 0.7, 6000)};
`;

export const PageFooter = styled.div<Props>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0 -3px 6px ${props => transparentize(0.95, props.theme.grayBase)};

  ${props =>
    props.animated &&
    css`
      animation: ${slideInUp} 0.3s ease-out;
    `};
`;

export const Page = styled.div<{ mobile?: boolean }>`
  min-height: 100vh;

  ${p =>
    p.mobile &&
    css`
      padding-top: 70px;
    `}
`;

export const ButtonWrapperWithPopover = styled.div<Props & { cursor?: string; align?: string }>`
  position: relative;
  display: inline-block;

  ${props =>
    props.margin &&
    css`
      margin: ${sizeMapper(props.margin)};
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper}};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  &:hover {
    ${Popover} {
      visibility: visible;

      ${props =>
        props.autoHide &&
        css`
          -webkit-animation: cssAnimation 5s forwards;
          animation: cssAnimation 5s forwards;
        `}
    }
    @keyframes cssAnimation {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @-webkit-keyframes cssAnimation {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

export const PopoverWrap = styled.div<Props & { orientation?: 'right' | 'left' }>`
  position: relative;
  width: ${props =>
    mapper(
      props.size,
      { xSmall: '150px', small: '200px', medium: '280px', large: '360px', xLarge: '520px' },
      'medium',
    )};
  position: fixed;
  left: ${props => props.tooltipLeftPosition}px;
  top: ${props => props.tooltipTopPosition}px;
  text-align: center;
  z-index: 9999;

  ${Popover} {
    transform: none;
    width: ${props =>
      mapper(
        props.size,
        { xSmall: '150px', small: '200px', medium: '280px', lMedium: '310px', large: '360px', xLarge: '520px' },
        'medium',
      )};
    position: static;

    &:before {
      left: ${p => (p.orientation ? (p.orientation === 'left' ? 'calc(100% - 10px)' : '10px') : '50%')};
      transform: translateX(-50%);
      bottom: -10px;
      border-top: 10px solid ${props => props.theme.grayLight};
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    &:after {
      left: ${p => (p.orientation ? (p.orientation === 'left' ? 'calc(100% - 10px)' : '10px') : '50%')};
      transform: translateX(-50%);
      bottom: -8px;
      border-top: 8px solid #fff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    ${props =>
      props.isBottomPositioned &&
      css`
        &:before {
          left: 50%;
          transform: translateX(-50%);
          top: -10px;
          bottom: auto;
          border-top: 0;
          border-bottom: 10px solid ${props => props.theme.grayLight};
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        }
        &:after {
          left: 50%;
          transform: translateX(-50%);
          top: -8px;
          bottom: auto;
          border-top: 0;
          border-bottom: 8px solid #fff;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
        }
      `}
    ${props =>
      props.maxContentWidth &&
      css`
        width: max-content;
      `}
  }
`;
