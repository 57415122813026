import { camelCase, map, orderBy, size, uniq } from 'lodash-es';
import { change, getFormValues } from 'redux-form';
import { submit } from 'redux-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { Button, Grid, GridColumn, Message, Panel, PanelSection } from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { ENABLED_ID, DISABLED_ID } from 'src/finance/constants/rateManager';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadRateCodes, saveRateCodes, exportRateConfiguration } from 'src/finance/ducks';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { PageFooter } from 'src/common/components/styled';
import { RATE_CONFIGURATION_EDIT_FORM_NAME } from '../../forms/RateConfigurationEditForm';
import { RateConfigurationEditForm } from '../../forms';
import { RateConfigurationSearchForm } from '../../forms';
import {
  RateConfigurationSearchFormValues,
  RATE_CONFIURATION_SEARCH_FORM_NAME,
} from '../../forms/RateConfigurationSearchForm';
import {
  RateCodes,
  RateCodeServiceType,
  RateConfigurationEditFormValues,
  RateConfigurationFormValuesToSubmit,
  RateManagerReducerState,
} from 'src/finance/interfaces/RateManager';
import { setChangedRateCodes } from 'src/finance/ducks';
import { Table } from 'src/core/components';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import RateConfigurationAddRateModalResolver from '../../modals/RateConfigurationAddRateModalResolver';
import RateConfigurationTableRow from './RateConfigurationTableRow';
import translate from 'src/core/services/translate';

interface Props {
  rateConfiguationSearchInitialValues: RateConfigurationSearchFormValues;
}

const RateConfigurationPage: React.FC<Props> = ({ rateConfiguationSearchInitialValues }) => {
  const didMountRef = useRef(false);

  const dispatch = useDispatch();

  const { billingUnitOfMeasureTypes, isExporting, isLoading, isSavingRateCodes, rateCodes, changedRateCodes } =
    useSelector(state => state.finance.rateManager) as RateManagerReducerState;

  const formValues: RateConfigurationSearchFormValues = useSelector(state =>
    getFormValues(RATE_CONFIURATION_SEARCH_FORM_NAME)(state),
  );
  const location = useLocation();
  const params = getQueryParams(location.search);

  const [filteredRateCodes, setFilteredRateCodes] = useState(rateCodes);
  const [isAddRateModalVisible, setIsAddRateModalVisible] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [rowScrollTopPosition, setRowScrollTopPosition] = useState(0);

  const setRateCodesFilter = useCallback(
    (statusTypeId?: number, serviceTypeId?: number, searchText?: string, shouldChangeRateCodes?: boolean) => {
      const curentStatusTypeId = statusTypeId || formValues?.statusTypeId;
      const curentServiceTypeId = serviceTypeId || formValues?.serviceTypeId;
      const currentSearchText = searchText || searchText === '' ? searchText : formValues?.searchText;

      const getFieldIncludesSearchText = (rateCode: RateCodes) =>
        currentSearchText
          ? rateCode.typeCode?.toUpperCase().includes(currentSearchText.toUpperCase()) ||
          rateCode.description?.toUpperCase().includes(currentSearchText.toUpperCase())
          : true;

      let newFilteredRateCodes: RateCodes[] = [];

      switch (Number(curentStatusTypeId)) {
        case ENABLED_ID:
          const rateAccountingCodesActive = rateCodes.filter(rateCode =>
            rateCode.rateCodeServiceType.find(
              rateCodeType => rateCodeType.serviceTypeId === Number(curentServiceTypeId),
            ),
          );

          const rateAccountingCodesActiveFiltered = rateAccountingCodesActive.filter(rateCode =>
            currentSearchText || currentSearchText === '' ? getFieldIncludesSearchText(rateCode) : true,
          );
          newFilteredRateCodes = rateAccountingCodesActiveFiltered;

          break;

        case DISABLED_ID:
          const rateAccountingCodesInactive = rateCodes.filter(
            rateCode =>
              !rateCode.rateCodeServiceType.find(
                rateCodeType => rateCodeType.serviceTypeId === Number(curentServiceTypeId),
              ),
          );

          const rateAccountingCodesInactiveFiltered = rateAccountingCodesInactive.filter(rateCode =>
            currentSearchText || currentSearchText === '' ? getFieldIncludesSearchText(rateCode) : true,
          );
          newFilteredRateCodes = rateAccountingCodesInactiveFiltered;

          break;

        default:
          const rateAccountingCodesFilteredBySearchTerm = rateCodes.filter(rateCode =>
            currentSearchText || currentSearchText === '' ? getFieldIncludesSearchText(rateCode) : true,
          );
          newFilteredRateCodes = rateAccountingCodesFilteredBySearchTerm;
          break;
      }

      const orderedRateCodesByEnabled = orderBy(
        newFilteredRateCodes.map(filteredRateCode => {
          const currentRateCode = newFilteredRateCodes.find(rateCode => rateCode.id === filteredRateCode.id);
          const currentRateCodeServiceType = currentRateCode?.rateCodeServiceType.find(
            rateCodeService => Number(rateCodeService.serviceTypeId) === Number(formValues?.serviceTypeId),
          );

          const isEnabled = !!currentRateCodeServiceType;

          return { ...filteredRateCode, isEnabled };
        }),
        ['isEnabled', filteredRateCode => filteredRateCode?.typeCode?.toLowerCase().trim()],
        ['desc', 'asc'],
      );

      setFilteredRateCodes(orderedRateCodesByEnabled);

      if (shouldChangeRateCodes && isInEditMode) {
        changedRateCodes.forEach(changedRateCode => {
          const rateCodePosition = orderedRateCodesByEnabled.findIndex(rateCode => rateCode.id === changedRateCode.id);

          dispatch(change(RATE_CONFIGURATION_EDIT_FORM_NAME, `[${rateCodePosition}].enabled`, changedRateCode.enabled));

          !!changedRateCode.accountingCode &&
            dispatch(
              change(
                RATE_CONFIGURATION_EDIT_FORM_NAME,
                `[${rateCodePosition}].accountingCode`,
                changedRateCode.accountingCode,
              ),
            );

          changedRateCode.unitOfMeasureTypeIds?.forEach((_, index) => {
            changedRateCode?.unitOfMeasureTypeIds &&
              changedRateCode?.unitOfMeasureTypeIds[index] &&
              dispatch(
                change(
                  RATE_CONFIGURATION_EDIT_FORM_NAME,
                  `[${rateCodePosition}].unitOfMeasureTypeIds.[${changedRateCode?.unitOfMeasureTypeIds[index]?.index}]`,
                  changedRateCode?.unitOfMeasureTypeIds[index]?.value,
                ),
              );
          });
        });
      }
    },
    [changedRateCodes, dispatch, formValues, isInEditMode, rateCodes],
  );

  const virtualizedProps = {
    itemSize: TABLE_ROW_HEIGHT_LARGE,
    height: Math.min(filteredRateCodes.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
  };

  const getUomTableCells = () => {
    const uomTableCells: any[] = [];

    billingUnitOfMeasureTypes?.forEach(uom => {
      const uomTableCell = {
        name: uom.name,
        label: translate(`finance.rateManager.uom.${camelCase(uom.technicalName)}`),
        width: `${40 / billingUnitOfMeasureTypes?.length}%`,
        noPaddingRight: true,
        rotate: true,
      };

      uomTableCells.push(uomTableCell);
    });

    return uomTableCells;
  };

  const tableCells = [
    {
      name: 'enabled',
      label: translate('common.enabled'),
      width: '10%',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'typeCode',
      label: translate('finance.rateManager.rateCode'),
      width: '15%',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'description',
      label: translate('common.description'),
      width: '20%',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'accountingCode',
      label: translate('finance.rateManager.accountingCode'),
      width: '15%',
      noPaddingRight: true,
      sortable: true,
    },
    ...getUomTableCells(),
  ];

  const handleRateConfigurationExport = () => {
    const enabled =
      formValues?.statusTypeId === ENABLED_ID ? true : formValues?.statusTypeId === DISABLED_ID ? false : undefined;
    exportRateConfiguration(formValues?.serviceTypeId, formValues?.searchText, enabled)(dispatch);
  };

  const rateConfigurationEditInitialValues = useMemo(() => {
    const newFilteredRateCodes = map(filteredRateCodes, rateCode => {
      const currentRateCodeService = rateCode.rateCodeServiceType.find(
        rateCode => Number(rateCode.serviceTypeId) === Number(formValues?.serviceTypeId),
      );

      const { rateAccountingCode } = currentRateCodeService || ({} as RateCodeServiceType);

      const curentUoms = currentRateCodeService?.unitOfMeasureTypeIds?.map(uom =>
        billingUnitOfMeasureTypes?.find(allUom => allUom.id === uom),
      );

      return {
        ...rateCode,
        enabled: !!currentRateCodeService,
        accountingCode: rateAccountingCode?.id,
        accountingCodeDescription: rateAccountingCode?.description,
        unitOfMeasureTypeIds: billingUnitOfMeasureTypes?.map(
          uom => !!curentUoms?.find(curentUom => curentUom?.id === uom.id),
        ),
      };
    });

    return newFilteredRateCodes;
  }, [filteredRateCodes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveRateCodes = () => {
    dispatch(submit(RATE_CONFIGURATION_EDIT_FORM_NAME));
  };

  const handleSubmit = (formData: RateConfigurationEditFormValues[]) => {

    const newFormData = formData.map(data => ({
      rateCodeId: data.id,
      rateCode: data.typeCode,
      rateCodeDescription: data.description,
      rateAccountingCodeId: data.accountingCode ? Number(data.accountingCode) : undefined,
      enabledForServiceType: data.enabled,
      validUOMsForServiceType: billingUnitOfMeasureTypes
        .filter((uom, index) => (!!uom && !!data.unitOfMeasureTypeIds[index] ? uom.id : undefined))
        ?.map(uom => uom.id),
    }));

    const rateCodeWithError = newFormData.find(
      data =>
        (data.enabledForServiceType && !data.validUOMsForServiceType.length) ||
        data.rateCode === '' ||
        data.rateCodeDescription === '',
    );

    const rateCodeWithErrorPosition = newFormData.findIndex(data => data.rateCodeId === rateCodeWithError?.rateCodeId);
    rateCodeWithErrorPosition >= 0 &&
      setRowScrollTopPosition(rateCodeWithErrorPosition === 0 ? 1 : TABLE_ROW_HEIGHT_LARGE * rateCodeWithErrorPosition);

    let newFormDataWithChanges: RateConfigurationFormValuesToSubmit[] = [];

    rateCodes.forEach(rateCode => {
      const currentChangedRateCode = changedRateCodes.find(changedRateCode => changedRateCode.id === rateCode.id);
      const rateCodeServiceType = rateCode.rateCodeServiceType.find(
        rateCodeType => Number(rateCodeType.serviceTypeId) === Number(formValues?.serviceTypeId),
      );

      const currentRateCode = {
        enabledForServiceType: !!rateCodeServiceType,
        rateAccountingCodeId: rateCodeServiceType?.rateAccountingCode?.id,
        rateCode: rateCode?.typeCode,
        rateCodeDescription: rateCode?.description,
        rateCodeId: rateCode?.id,
        validUOMsForServiceType: rateCodeServiceType?.unitOfMeasureTypeIds || [],
      };

      if (!!currentChangedRateCode) {

        if (currentChangedRateCode.enabled === true || currentChangedRateCode.enabled === false) {
          currentRateCode.enabledForServiceType = currentChangedRateCode.enabled;
        }

        if (currentChangedRateCode.hasOwnProperty('accountingCode')) {
          currentRateCode.rateAccountingCodeId = currentChangedRateCode.accountingCode
        }

        if (!!currentChangedRateCode.rateCode) {
          currentRateCode.rateCode = currentChangedRateCode.rateCode;
        }

        if (!!currentChangedRateCode.rateCodeDescription) {
          currentRateCode.rateCodeDescription = currentChangedRateCode.rateCodeDescription;
        }

        if (!!currentChangedRateCode.unitOfMeasureTypeIds?.length) {
          const validUOMsForServiceType = uniq([
            ...currentRateCode.validUOMsForServiceType,
            ...currentChangedRateCode.unitOfMeasureTypeIds.filter(uom => !!uom.value).map(uom => uom.id),
          ]);

          const currentValidUOMsForServiceType: number[] = [];

          validUOMsForServiceType.forEach(uom => {
            const unitOfMeasureType = currentChangedRateCode?.unitOfMeasureTypeIds?.find(
              unitOfMeasureTypeId => Number(unitOfMeasureTypeId.id) === Number(uom),
            );
            const unitOfMeasureTypeIdValue = unitOfMeasureType?.value;

            if (unitOfMeasureTypeIdValue === false) {
            } else {
              currentValidUOMsForServiceType.push(uom);
            }
          });

          currentRateCode.validUOMsForServiceType = currentValidUOMsForServiceType;
        }

        newFormDataWithChanges.push(currentRateCode);
      }
    });

    !rateCodeWithError &&
      saveRateCodes(
        newFormDataWithChanges,
        formValues?.serviceTypeId,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('finance.rateManager.alertMessages.rateCodesSaved'));

          setIsInEditMode(false);
          setChangedRateCodes([])(dispatch);

          loadRateCodes()(dispatch);
        })
        .catch(error => {
          const { code, message } = error.response.data;

          switch (code) {
            case 'RateCodeNotUnique':
              const errorMessage = `${translate('finance.rateManager.alertMessages.rateCodeNotUnique')}: ${message}`;
              createErrorNotification(errorMessage);
              break;

            default:
              createErrorNotification(translate('finance.rateManager.alertMessages.rateCodesSaveError'));
              break;
          }
        });
  };

  const onCancelEditMode = () => {
    setIsInEditMode(false);
    setChangedRateCodes([])(dispatch);
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setRateCodesFilter(params?.statusTypeId, params?.serviceTypeId, params?.searchText);

      didMountRef.current = true;
    }
  }, [params, setRateCodesFilter]);

  useEffect(() => {
    setRateCodesFilter(params?.statusTypeId, params?.serviceTypeId, params?.searchText);
  }, [rateCodes, setRateCodesFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('finance.rateManager.rateConfiguration')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions flex align="right">
            <Button color="primary" onClick={handleRateConfigurationExport} disabled={isInEditMode}>
              {translate('common.export')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSection vertical isLoading={isLoading || isExporting || isSavingRateCodes}>
            <Grid padding="small xSmall" multiLine>
              <RateConfigurationSearchForm
                initialValues={rateConfiguationSearchInitialValues}
                setRateCodesFilter={setRateCodesFilter}
                isInEditMode={isInEditMode}
              />

              <GridColumn size="4/12" verticalAlign="center" align="right">
                <Button color="primary" onClick={() => setIsAddRateModalVisible(true)} disabled={isInEditMode}>
                  + {translate('common.add')}
                </Button>

                <Button
                  color="primary"
                  onClick={() => setIsInEditMode(true)}
                  margin="no no no small"
                  disabled={isInEditMode}
                >
                  {translate('common.edit')}
                </Button>
              </GridColumn>
            </Grid>

            {!!size(filteredRateCodes) ? (
              <>
                {isInEditMode ? (
                  <RateConfigurationEditForm
                    filteredRateCodes={filteredRateCodes}
                    initialValues={rateConfigurationEditInitialValues}
                    onSubmit={handleSubmit}
                    rowScrollTopPosition={rowScrollTopPosition}
                    tableCells={tableCells}
                    virtualizedProps={virtualizedProps}
                  />
                ) : (
                  <Table
                    cells={tableCells}
                    rows={filteredRateCodes}
                    rowComponent={RateConfigurationTableRow}
                    rowProps={{}}
                    virtualizedProps={virtualizedProps}
                    virtualized
                    scrollMarker
                  />
                )}
              </>
            ) : (
              <Message padding="sMedium">{translate('finance.rateManager.noRateCodes')}</Message>
            )}
          </PanelSection>
        </Panel>

        {isInEditMode && (
          <PageFooter>
            <GridColumn size="12/12" verticalAlign="center" align="center">
              <Button color="secondary" onClick={onCancelEditMode}>
                {translate('common.cancel')}
              </Button>

              <Button color="primary" onClick={() => handleSaveRateCodes()} margin="no no no small">
                {translate('common.save')}
              </Button>
            </GridColumn>
          </PageFooter>
        )}
      </PageContent>

      {isAddRateModalVisible && (
        <RateConfigurationAddRateModalResolver onCancel={() => setIsAddRateModalVisible(false)} />
      )}
    </>
  );
};

export default RateConfigurationPage;
