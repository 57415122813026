import { orderBy } from 'lodash-es';

import { http } from '../../core/services/http';
import { MaterialTypes } from '../interfaces/MaterialTypes';
import { OTHER_ID } from '../constants/bulkyItemScheduler';

const getMaterialTypesResponse = (responseData: MaterialTypes[]) => {
  const materialTypesOrdered = orderBy(responseData, 'category.id', 'asc');
  const index = materialTypesOrdered.findIndex(
    (materialTypes: MaterialTypes) => materialTypes.category.id === OTHER_ID,
  );

  materialTypesOrdered.push(materialTypesOrdered.splice(index, 1)[0]);

  return !!responseData.length ? materialTypesOrdered : responseData;
};

export const loadMaterialTypes = (vendorId: number, isMaterialTypeActive?: boolean, vehicleTypeId?: number) =>
  http
    .get(`/vendors/${vendorId}/settings/materialTypesConfiguration`, {
      params: { isActive: isMaterialTypeActive, vehicleTypeId },
    })
    .then(response => {
      return getMaterialTypesResponse(response.data);
    });

export const loadDispatchBoardMaterialTypes = (
  vendorId: number,
  vehicleTypeId?: number,
  forceMaterialTypeId?: string,
) =>
  http
    .get(`/dispatchBoard/${vendorId}/materialTypes`, {
      params: { vehicleTypeId, forceMaterialTypeId },
    })
    .then(response => {
      return getMaterialTypesResponse(response.data);
    });

export const saveMaterialTypes = (vendorId: number, materialTypes: MaterialTypes[]) =>
  http
    .post(`/vendors/${vendorId}/settings/materialTypesConfiguration`, [...materialTypes])
    .then(response => response.data);
