import { round } from '@turf/turf';
import { camelCase, filter, find, keyBy, map, mapValues, orderBy, size, uniqueId } from 'lodash-es';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps, change, getFormValues, reduxForm, reset } from 'redux-form';

import { vendorGusIdSelector } from 'src/account/ducks';
import routeAssistance from 'src/common/assets/img/insights/routeAssistance.png';
import { loadJobPriorityTypes } from 'src/common/ducks';
import { ActionButtonTooltip, Checkbox, Table, TypedField } from 'src/core/components';
import { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import { Label, Link, Separator, TableActionButton, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TABLE_ROW_HEIGHT_LARGER, TABLE_ROW_HEIGHT_xLARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { multiWordAndSearch } from 'src/core/services/search';
import translate from 'src/core/services/translate';
import { RouteSegmentExtended } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { loadStreetNetwork } from 'src/customers/ducks';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import {
  loadRouteDriversVehicles,
  loadRouteHistory,
  loadRouteInformation,
  loadRouteStops,
  resetRoutesData,
  setRouteHistoryModalOpen,
} from 'src/dashboard/ducks';
import { RouteHistoryDriverVehicle, RouteHistoryItem, RouteStopItem } from 'src/dashboard/interfaces/routesData';
import { DELIVERY_UTILITY_ID, ROLL_OFF_ID, SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { IN_PROGRESS, LABEL_COLORS, ROUTE_STATUSES_BY_ID } from 'src/routes/constants';
import { loadRouteSegments, loadSnowPlowSegmentList } from 'src/routes/ducks';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { timeFormat } from 'src/utils/services/validator';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  DashboardFilterClose,
  DashboardFilterCloseIcon,
  DashboardFilterContainer,
  DashboardFilterDetailScreenSection,
  DashboardFilterPanel,
  DashboardFilterPanelContent,
  DashboardFilterPanelContentDetails,
  DashboardFilterSection,
  DashboardFilterTitleWrapper,
} from '../styled/DashboardFilterMapbox';
import { MapboxFiltersFormValues } from './DashboardMapboxFiltersForm';
import DashboardMapboxRouteStopTableRow from './DashboardMapboxRouteStopTableRow';
import DashboardMapboxSnowPlowOrStreetSweeperRouteStopTableRow from './DashboardMapboxSnowPlowOrStreetSweeperRouteStopTableRow';
import {
  DashboardFilterCategoryMapbox,
  DashboardFilterDetailScreenLabelValue,
  DashboardRouteHistoryItem,
} from './dashboardFilterFormSections';
import DashboardDriverVehicleItem from './dashboardFilterFormSections/DashboardDriverVehicleItem';
import DashboardFilterRouteStops from './dashboardFilterFormSections/DashboardFilterRouteStops';

interface PropsWithoutReduxForm {
  close: () => void;
  isCollapsed?: boolean;
  isOpen: boolean;
  isRouteStopsSectionOpen: boolean;
  isSourceAdvancedSearch: boolean;
  segmentList: StreetNetwork[];
  selectedRouteId?: number;
}

export interface DashboardMapboxRouteDetailsFormValues {
  routeId: number;
  incidents: {
    offRoute: boolean;
    offRouteStationary: boolean;
    insideGeoFence: boolean;
    outsideGeoFence: boolean;
  };
  driversVehicles: any;
  vehicleBreadCrumbs: any;
  showAppStatus: any;
  routeStopsFilters: {
    searchTerm: string;
    pickupStatusIds?: number[];
    issueReported?: boolean;
    locationAlert?: boolean;
    showAll?: boolean;
    stops: any;
    showOrderNumbers: boolean;
    jobPriorityTypeIds?: number[];
  };
}

export const DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME = 'dashboardMapboxRouteDetailsForm';

type Props = PropsWithoutReduxForm & InjectedFormProps<DashboardMapboxRouteDetailsFormValues, PropsWithoutReduxForm>;

enum FILTER_CATEGORIES {
  routeData = 'routeData',
  driversVehicles = 'driversVehicles',
  routeStops = 'routeStops',
  routeHistory = 'routeHistory',
}

const DashboardMapboxRouteDetailsForm = ({
  close,
  handleSubmit,
  isCollapsed,
  isOpen,
  isRouteStopsSectionOpen,
  isSourceAdvancedSearch,
  segmentList,
  selectedRouteId,
}: Props) => {
  const dispatch = useDispatch();
  const [openedCategory, setOpenedCategory] = useState({
    routeData: true,
    driversVehicles: false,
    routeTimes: false,
    routeMiles: false,
    routeStops: isRouteStopsSectionOpen || false,
    routeHistory: false,
  });

  const mainFormValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;
  const thisFormValues = useSelector(
    getFormValues(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME),
  ) as DashboardMapboxRouteDetailsFormValues;

  const vendorId = useSelector(currentVendorId);
  const isVendorWithGusId = useSelector(state => vendorGusIdSelector(state.account.login, state.vendors.defaultVendor));
  const {
    routeInformation,
    isLoadingRouteInformation,
    routeDriversVehicles,
    isLoadingRouteDriversVehicles,
    routeStops,
    isLoadingRouteStops,
    isLoadingRouteHistory,
    routeHistory,
  } = useSelector(state => state.dashboard.routesData);
  const { isLoading: isLoadingStreetNetwork } = useSelector(state => state.customers.streetNetwork);
  const { isLoading: isLoadingRouteSegments } = useSelector(state => state.routes.routeSegments);
  const { isLoading: isLoadingSegmentList } = useSelector(state => state.routes.snowPlowSegmentList);
  const { isLoading: isLoadingJobPriorityTypes, jobPriorityTypes } = useSelector(
    state => state.common.jobPriorityTypes,
  );
  const isLoadingRouteStopsDetails =
    isLoadingRouteStops ||
    isLoadingStreetNetwork ||
    isLoadingRouteSegments ||
    isLoadingSegmentList ||
    isLoadingJobPriorityTypes;

  const wasteTypes = useSelector(state => state.common.wasteTypes.wasteTypes);
  const materialType = routeInformation?.wasteMaterialType
    ? find(wasteTypes, wasteType => wasteType.id === routeInformation.wasteMaterialType.id)?.name
    : '';

  const isSnowPlowRoute = routeInformation?.vehicleType?.id === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeInformation?.vehicleType?.id === STREET_SWEEPER_ID;
  const isDeliveryRoute = routeInformation?.vehicleType?.id === DELIVERY_UTILITY_ID;
  const isRollOffRoute = routeInformation?.vehicleType?.id === ROLL_OFF_ID;

  const isContainerVisible = isOpen && !isCollapsed;

  useEffect(() => {
    setOpenedCategory({
      routeData: true,
      driversVehicles: false,
      routeTimes: false,
      routeMiles: false,
      routeStops: isRouteStopsSectionOpen || false,
      routeHistory: false,
    });
  }, [selectedRouteId, isRouteStopsSectionOpen]);

  // on first render, load route information (first section) and route drivers and vehicles (second section)
  useEffect(
    () => {
      if (selectedRouteId && mainFormValues?.date && isOpen) {
        loadRouteInformation(selectedRouteId, mainFormValues?.date)(dispatch);
        loadRouteDriversVehicles(
          selectedRouteId,
          mainFormValues?.date,
        )(dispatch).then(data => {
          // if vehicles were previously selected, keep them selected
          if (!thisFormValues?.driversVehicles) {
            const vehicleIds = map(data.vehicles, item => item.vehicleId);
            const objectValues = mapValues(
              keyBy(vehicleIds, id => `_${id}`),
              () => true,
            );
            dispatch(change(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME, 'driversVehicles', objectValues));
          }
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectedRouteId, isSourceAdvancedSearch, isOpen, mainFormValues?.date],
  );

  // when closing the filter, reset
  useEffect(() => {
    if (!isOpen) {
      dispatch(resetRoutesData());
      dispatch(reset(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME));
      setOpenedCategory({
        routeData: true,
        driversVehicles: false,
        routeTimes: false,
        routeMiles: false,
        routeStops: false,
        routeHistory: false,
      });
    }
  }, [dispatch, isOpen]);

  const toggleCategoryHandler = async (category: FILTER_CATEGORIES) => {
    if (!openedCategory[category] && selectedRouteId && mainFormValues?.date) {
      const date = mainFormValues.date;
      switch (category) {
        case FILTER_CATEGORIES.driversVehicles:
          loadRouteDriversVehicles(
            selectedRouteId,
            date,
          )(dispatch).then(data => {
            // if vehicles were previously selected, keep them selected
            if (!thisFormValues?.driversVehicles) {
              const vehicleIds = map(data.vehicles, item => item.vehicleId);
              const objectValues = mapValues(
                keyBy(vehicleIds, id => `_${id}`),
                () => true,
              );
              dispatch(change(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME, 'driversVehicles', objectValues));
            }
          });

          break;
        case FILTER_CATEGORIES.routeStops:
          const routeTemplateId = undefined;
          const includeRouteTemplateIds = undefined;
          const streetSegmentAssignedStatus = true;

          if (isSnowPlowOrStreetSweeperRoute) {
            loadRouteSegments({ vendorId, routeId: selectedRouteId, isForDashboard: true })(dispatch).then(
              routeSegments => {
                const segmentsIds = routeSegments.map((segment: RouteSegment) => segment.streetSegmentId);
                if (segmentsIds.length) loadSnowPlowSegmentList(vendorId, segmentsIds)(dispatch);

                loadStreetNetwork({
                  vendorId,
                  routeId: selectedRouteId,
                  routeTemplateId,
                  includeRouteTemplateIds,
                  streetSegmentAssignedStatus,
                })(dispatch);
              },
            );
          } else {
            loadRouteStops({ routeId: selectedRouteId, date })(dispatch);
            if (!size(jobPriorityTypes)) loadJobPriorityTypes()(dispatch);
          }

          break;
        case FILTER_CATEGORIES.routeHistory:
          const startDateTime = moment(date).startOf('day').subtract(14, 'day').toISOString();
          const endDateTime = moment(date).endOf('day').add(14, 'days').toISOString();
          loadRouteHistory(selectedRouteId, startDateTime, endDateTime)(dispatch);
          break;
        default:
          break;
      }
    }
    setOpenedCategory({ ...openedCategory, [category]: !openedCategory[category] });
  };

  const isSnowPlowOrStreetSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;
  const isDeliveryOrRollOffOrSnowPlowOrStreetSweeperRoute =
    isDeliveryRoute || isRollOffRoute || isSnowPlowRoute || isStreetSweeperRoute;
  const currentRouteStops = isSnowPlowOrStreetSweeperRoute ? segmentList : routeStops;
  const tableRowHeight = isSnowPlowOrStreetSweeperRoute ? TABLE_ROW_HEIGHT_xLARGE : TABLE_ROW_HEIGHT_LARGER;
  const isRouteInProgress = routeInformation?.statusType.id === IN_PROGRESS;

  const routeStopsVirtualizedProps = {
    height: Math.min(currentRouteStops.length * tableRowHeight, tableRowHeight * 5) || 1,
    itemSize: tableRowHeight,
  };

  const filteredRouteStops = useMemo(() => {
    const searchTerm = thisFormValues?.routeStopsFilters?.searchTerm;
    const locationAlerts = thisFormValues?.routeStopsFilters?.locationAlert;
    const issueReported = thisFormValues?.routeStopsFilters?.issueReported;
    const pickupStatuses = thisFormValues?.routeStopsFilters?.pickupStatusIds || [];
    const jobPriorityTypeIds = thisFormValues?.routeStopsFilters?.jobPriorityTypeIds || [];

    return isSnowPlowOrStreetSweeperRoute
      ? filter(
          segmentList,
          (stop: RouteSegmentExtended) =>
            (!pickupStatuses || !pickupStatuses.length || pickupStatuses.includes(stop.statusId)) &&
            (!searchTerm ||
              multiWordAndSearch(stop.customerName, searchTerm) ||
              multiWordAndSearch(stop.name, searchTerm) ||
              multiWordAndSearch(stop.streetName, searchTerm)),
        )
      : filter(
          routeStops,
          (stop: RouteStopItem) =>
            (!pickupStatuses || !pickupStatuses.length || pickupStatuses.includes(stop.pickupStatusTypeId)) &&
            (!jobPriorityTypeIds ||
              !jobPriorityTypeIds.length ||
              jobPriorityTypeIds.includes(stop.jobPriorityTypeId || 0)) &&
            ((locationAlerts && locationAlerts === stop.hasLocationAlert) || !locationAlerts) &&
            ((issueReported && issueReported === stop.hasIssueReported) || !issueReported) &&
            (!searchTerm ||
              multiWordAndSearch(stop.customerName, searchTerm) ||
              multiWordAndSearch(stop.locationName, searchTerm) ||
              multiWordAndSearch(`${stop.locationName}, ${stop.streetNumber} ${stop.street}`, searchTerm) ||
              multiWordAndSearch(stop.accountNumber, searchTerm) ||
              multiWordAndSearch(stop.vendorAccountNo, searchTerm)),
        );
  }, [
    thisFormValues?.routeStopsFilters?.searchTerm,
    thisFormValues?.routeStopsFilters?.locationAlert,
    thisFormValues?.routeStopsFilters?.issueReported,
    thisFormValues?.routeStopsFilters?.pickupStatusIds,
    thisFormValues?.routeStopsFilters?.jobPriorityTypeIds,
    isSnowPlowOrStreetSweeperRoute,
    segmentList,
    routeStops,
  ]);

  const filteredRouteHistory = useMemo(
    () =>
      orderBy(
        // some items might be in the future, so we filter them out
        filter(routeHistory, r => moment(r.routeDate).isSameOrBefore(moment(), 'day')),
        r => {
          // the route that has the current date should be the first one in the list
          if (moment(r.routeDate).isSame(moment(mainFormValues?.date), 'day'))
            return moment().add(1, 'year').toISOString();
          return moment(r.routeDate).toISOString();
        },
        'desc',
      ),
    [mainFormValues?.date, routeHistory],
  );

  const handleViewAllStops: TypedFieldOnChangeFunction = (_, isChecked) => {
    dispatch(change(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME, 'routeStopsFilters.showAll', isChecked));

    const stops = (filteredRouteStops as any).reduce((acc: any, stop: any) => {
      acc[`_${stop.id}`] = isChecked;
      return acc;
    }, {} as any);

    dispatch(change(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME, 'routeStopsFilters.stops', stops));
  };

  const vehicleDetailsUrl = useMemo(() => {
    if (!routeInformation) return '/routes/route-tracker';
    if (isSnowPlowRoute) return `/routes/snow-tracker/${routeInformation.id}`;
    if (isStreetSweeperRoute) return `/routes/sweeper-tracker/${routeInformation.id}`;

    return `/routes/route-tracker/${routeInformation.id}`;
  }, [routeInformation, isSnowPlowRoute, isStreetSweeperRoute]);

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <DashboardFilterContainer isOpen={isContainerVisible} isLarger={!isVendorWithGusId}>
          <DashboardFilterPanel>
            <DashboardFilterPanelContent>
              <DashboardFilterPanelContentDetails isCollapsed={isCollapsed}>
                <DashboardFilterClose isDetailsScreen onClick={close} id="dashboard-filter-close-button">
                  <DashboardFilterCloseIcon />
                </DashboardFilterClose>
                {routeInformation && (
                  <DashboardFilterSection>
                    <Link block decoration="none" to={vehicleDetailsUrl}>
                      <Text
                        block
                        size="xLarge"
                        weight="medium"
                        margin="xSmall no medium large"
                        singleLine
                        maxWidth="300"
                        color="primary"
                        title={routeInformation.routeName}
                      >
                        {routeInformation.routeName}
                      </Text>
                    </Link>

                    {!!routeInformation.assistingVehiclesCount && (
                      <Box display="flex" alignItems="flex-end" justifyContent="flex-end" margin="no no xxSmall no">
                        <TableActionButton margin="no">
                          <ActionButtonTooltip
                            imageSrc={routeAssistance}
                            tooltip={
                              routeInformation.assistingVehiclesCount
                                ? routeInformation.statusType?.id === IN_PROGRESS
                                  ? 'assistingVehicles'
                                  : 'assistedVehicles'
                                : undefined
                            }
                            tooltipProps={{ numberOfAssistingVehicles: routeInformation.assistingVehiclesCount }}
                          />
                        </TableActionButton>
                      </Box>
                    )}
                    <DashboardFilterTitleWrapper>
                      <Text margin="no no no medium">
                        {translate(`vehicles.vehicleTypes.${camelCase(routeInformation.vehicleType.technicalName)}`)}
                      </Text>
                      <Label color={LABEL_COLORS[routeInformation.statusType?.id]}>
                        {`${ROUTE_STATUSES_BY_ID[routeInformation.statusType?.id]?.name} - ${round(
                          routeInformation.completedPercentage,
                        ).toFixed(0)}%`}
                      </Label>
                    </DashboardFilterTitleWrapper>
                    <Separator margin="small no" size={2} color="grayDark" />
                  </DashboardFilterSection>
                )}
                <DashboardFilterSection>
                  <DashboardFilterCategoryMapbox
                    title={translate('dashboard.routeData')}
                    isOpen={openedCategory.routeData}
                    category={FILTER_CATEGORIES.routeData}
                    toggleCategory={toggleCategoryHandler}
                    id="dashboard-filter-vendor-routeData"
                  >
                    <DashboardFilterDetailScreenSection isLoading={isLoadingRouteInformation}>
                      {routeInformation ? (
                        <>
                          {!isDeliveryOrRollOffOrSnowPlowOrStreetSweeperRoute && (
                            <DashboardFilterDetailScreenLabelValue
                              label={translate('routes.materialType')}
                              value={materialType ? materialType : null}
                            />
                          )}

                          <DashboardFilterDetailScreenLabelValue
                            label={translate('vendors.routeStartTime')}
                            value={
                              routeInformation?.tripStartTime
                                ? moment(routeInformation?.tripStartTime).format(timeFormat)
                                : null
                            }
                          />
                          <DashboardFilterDetailScreenLabelValue
                            label={translate('dashboard.filterKeys.routeEndTime')}
                            value={
                              routeInformation?.tripEndTime && !isRouteInProgress
                                ? moment(routeInformation?.tripEndTime).format(timeFormat)
                                : null
                            }
                          />
                          {!isSnowPlowOrStreetSweeperRoute && (
                            <>
                              <DashboardFilterDetailScreenLabelValue
                                label={translate('dashboard.stopCount')}
                                value={`${routeInformation.completedStopsCount} / ${
                                  routeInformation?.totalStopsCount
                                } (${round(routeInformation.completedPercentage)}%)`}
                              />
                              <DashboardFilterDetailScreenLabelValue
                                label={translate('routes.disposals')}
                                value={`${routeInformation.disposalTicketsCount || 0} ${translate(
                                  'tooltips.tickets',
                                )} / ${routeInformation.disposalTrips || 0}  ${translate('common.trips')} / ${
                                  routeInformation.tonsDisposed ? routeInformation.tonsDisposed.toFixed(2) : 0
                                } ${translate('insights.reportTypeUnits.tons')}`}
                              />
                            </>
                          )}
                          <DashboardFilterDetailScreenLabelValue
                            label={translate('dashboard.issuesReported')}
                            value={routeInformation.issuesReported}
                          />

                          {!isSnowPlowOrStreetSweeperRoute && (
                            <DashboardFilterDetailScreenLabelValue
                              label={translate('dashboard.disposals')}
                              value={routeInformation.disposalTicketsCount}
                            />
                          )}

                          {isSnowPlowRoute && (
                            <DashboardFilterDetailScreenLabelValue
                              label={translate('dashboard.materialPickups')}
                              value={routeInformation.snowPlowMaterialPickUpTicketsCount}
                            />
                          )}

                          {isStreetSweeperRoute && (
                            <>
                              <DashboardFilterDetailScreenLabelValue
                                label={translate('dashboard.waterFillUps')}
                                value={routeInformation.streetSweeperWaterFillUpsCount}
                              />
                              <DashboardFilterDetailScreenLabelValue
                                label={translate('dashboard.loadsDumped')}
                                value={routeInformation.streetSweeperLoadsDumpedCount}
                              />
                            </>
                          )}

                          <DashboardFilterDetailScreenLabelValue
                            label={translate('dashboard.offRoute')}
                            value={routeInformation.offRouteCount}
                            rightContent={
                              routeInformation.offRouteCount > 0 ? (
                                <TypedField
                                  name="incidents.offRoute"
                                  component={Checkbox}
                                  props={{
                                    size: 'small',
                                    margin: 'no',
                                  }}
                                />
                              ) : null
                            }
                          />
                          <DashboardFilterDetailScreenLabelValue
                            label={translate('dashboard.offRouteStationary')}
                            value={routeInformation.offRouteStationaryCount}
                            rightContent={
                              routeInformation.offRouteStationaryCount > 0 ? (
                                <TypedField
                                  name="incidents.offRouteStationary"
                                  component={Checkbox}
                                  props={{
                                    size: 'small',
                                    margin: 'no',
                                  }}
                                />
                              ) : null
                            }
                          />
                          <DashboardFilterDetailScreenLabelValue
                            label={translate('dashboard.insideGeoFence')}
                            value={routeInformation.insideGeoFenceCount}
                            rightContent={
                              routeInformation.insideGeoFenceCount > 0 ? (
                                <TypedField
                                  name="incidents.insideGeoFence"
                                  component={Checkbox}
                                  props={{
                                    size: 'small',
                                    margin: 'no',
                                  }}
                                />
                              ) : null
                            }
                          />
                          <DashboardFilterDetailScreenLabelValue
                            label={translate('dashboard.outsideGeoFence')}
                            value={routeInformation.outsideGeoFenceCount}
                            rightContent={
                              routeInformation.outsideGeoFenceCount > 0 ? (
                                <TypedField
                                  name="incidents.outsideGeoFence"
                                  component={Checkbox}
                                  props={{
                                    size: 'small',
                                    margin: 'no',
                                  }}
                                />
                              ) : null
                            }
                          />
                        </>
                      ) : (
                        <Text color="grayDarker" margin="small" block>
                          <em>{translate('routes.planner.noData')}</em>
                        </Text>
                      )}
                    </DashboardFilterDetailScreenSection>
                  </DashboardFilterCategoryMapbox>

                  <DashboardFilterCategoryMapbox
                    title={`${translate('drivers.drivers')} / ${translate('vehicles.vehicles')} ${
                      size(routeDriversVehicles) ? `- ${size(routeDriversVehicles)}` : ''
                    }`}
                    isOpen={openedCategory.driversVehicles}
                    category={FILTER_CATEGORIES.driversVehicles}
                    toggleCategory={toggleCategoryHandler}
                    id="dashboard-filter-vendor-drivers-vehicles"
                  >
                    <DashboardFilterDetailScreenSection isLoading={isLoadingRouteDriversVehicles}>
                      {!size(routeDriversVehicles) && (
                        <Text color="grayDarker" margin="small" block>
                          <em>{translate('routes.planner.noData')}</em>
                        </Text>
                      )}
                      {map(orderBy(routeDriversVehicles, ['isRouteAssist'], 'asc'), driverVehicle => (
                        <DashboardDriverVehicleItem
                          key={uniqueId('driverVehicle_')}
                          item={driverVehicle}
                          hasVehicleBreadCrumbs={
                            !!find(
                              thisFormValues?.vehicleBreadCrumbs,
                              (value, key) => key === `_${driverVehicle.vehicleId}` && value,
                            )
                          }
                          hasDriversVehicles={
                            !!find(
                              thisFormValues?.driversVehicles,
                              (value, key) => key === `_${driverVehicle.vehicleId}` && value,
                            )
                          }
                        />
                      ))}
                    </DashboardFilterDetailScreenSection>
                  </DashboardFilterCategoryMapbox>

                  {isSnowPlowOrStreetSweeperRoute && (
                    <DashboardFilterCategoryMapbox
                      title={translate('dashboard.filterKeys.routeHistory')}
                      isOpen={openedCategory.routeHistory}
                      category={FILTER_CATEGORIES.routeHistory}
                      toggleCategory={toggleCategoryHandler}
                      id="dashboard-filter-vendor-route-history"
                    >
                      <DashboardFilterDetailScreenSection isLoading={isLoadingRouteHistory}>
                        {size(filteredRouteHistory) ? (
                          map(filteredRouteHistory, item => (
                            <DashboardRouteHistoryItem
                              item={item}
                              isCurrent={moment(item.routeDate).isSame(moment(mainFormValues?.date), 'day')}
                              key={uniqueId('routeHistoryItem_')}
                              openRouteHistoryModal={(
                                item: RouteHistoryItem,
                                driverVehicle: RouteHistoryDriverVehicle,
                              ) => {
                                dispatch(setRouteHistoryModalOpen(true, item, driverVehicle));
                              }}
                            />
                          ))
                        ) : (
                          <Text color="grayDarker" margin="small" block>
                            <em>{translate('routes.planner.noData')}</em>
                          </Text>
                        )}
                      </DashboardFilterDetailScreenSection>
                    </DashboardFilterCategoryMapbox>
                  )}

                  <DashboardFilterCategoryMapbox
                    title={
                      isSnowPlowOrStreetSweeperRoute
                        ? translate('insights.routeSegments')
                        : translate('insights.routeStops')
                    }
                    isOpen={openedCategory.routeStops}
                    category={FILTER_CATEGORIES.routeStops}
                    toggleCategory={toggleCategoryHandler}
                    id="dashboard-filter-vendor-route-stops"
                  >
                    <DashboardFilterDetailScreenSection
                      isLoading={isLoadingRouteStopsDetails}
                      noPadding
                      minHeight="500px"
                    >
                      {!size(routeStops) && !size(segmentList) ? (
                        <Text color="grayDarker" margin="small" block>
                          <em>{translate('routes.planner.noData')}</em>
                        </Text>
                      ) : (
                        <>
                          <DashboardFilterRouteStops
                            handleViewAllStopsChange={handleViewAllStops}
                            isSnowPlowOrStreetSweeperRoute={isSnowPlowOrStreetSweeperRoute}
                          />
                          {size(filteredRouteStops) ? (
                            <Table
                              cells={[]}
                              virtualized
                              virtualizedProps={routeStopsVirtualizedProps}
                              rows={filteredRouteStops}
                              rowComponent={
                                isSnowPlowOrStreetSweeperRoute
                                  ? DashboardMapboxSnowPlowOrStreetSweeperRouteStopTableRow
                                  : DashboardMapboxRouteStopTableRow
                              }
                              noOverflow
                            />
                          ) : (
                            <Text color="grayDarker" margin="small" block>
                              <em>{translate('routes.planner.noDataMatches')}</em>
                            </Text>
                          )}
                        </>
                      )}
                    </DashboardFilterDetailScreenSection>
                  </DashboardFilterCategoryMapbox>
                </DashboardFilterSection>
              </DashboardFilterPanelContentDetails>
            </DashboardFilterPanelContent>
          </DashboardFilterPanel>
        </DashboardFilterContainer>
      </form>
    </>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME,
  enableReinitialize: true,
  onSubmit: () => {},
})(DashboardMapboxRouteDetailsForm);
