import React from 'react';
import { useSelector } from 'react-redux';
import { DispatchBoardRouteJobRoute } from 'src/routes/interfaces/DispatchBoardRouteJob';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
} from 'src/common/components/map/MapGLPopup';
import { Box } from 'src/core/components/styled/Box';
import { getJobIsUnassignedOrOnHold } from './utils';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { Label } from 'src/core/components/styled';
import { PICKUP_STATUS_LABEL_COLORS } from 'src/routes/constants';
import { PICKUP_STATUSES } from 'src/common/constants';
import { UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import JobPriorityTypeIndicator from '../../common/JobPriorityTypeIndicator';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';
import { AppState } from 'src/store';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { date as formatDate } from 'src/utils/services/formatter';

interface Props {
  selectedJob: DispatchBoardRouteJobRoute;
  onClose(): void;
}

const DispatchBoardMapGLPopup: React.FC<Props> = ({ selectedJob, onClose }) => {
  const reasonCodeTypes = useSelector((state: AppState) => state.common.reasonCodeTypes.reasonCodeTypes);

  const jobIsPendingOptimization = selectedJob.orderNo === JOB_PENDING_OPTIMIZATION_ID;
  const jobIsUnassginedOrOnHold = getJobIsUnassignedOrOnHold(selectedJob.routeId);
  const reasonCode = reasonCodeTypes.find(code => code.id === selectedJob.reasonCodeTypeId);

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedJob.locationAddress.displayLatitude}
      longitude={selectedJob.locationAddress.displayLongitude}
      onClose={onClose}
    >
      <MapGLPopup maxHeight={400}>
        <MapGLPopupTitle
          title={selectedJob.routeId === UNASSIGNED_ROUTE_ID
            ? translate('routes.unassignedJob')
            : translate('routes.job')
          }
          afterTitleContent={
            <Box ml={8} display="flex" alignItems="center">
              {!!selectedJob.jobStatusId && (
                <Label color={PICKUP_STATUS_LABEL_COLORS[selectedJob.jobStatusId.toString()]}>
                  {PICKUP_STATUSES[selectedJob.jobStatusId].name}
                </Label>
              )}
              <Box ml={8}>
                <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={selectedJob.jobPriorityTypeId} />
              </Box>
            </Box>
          }
        />

        <MapGLPopupContent>
          {!!selectedJob.vehicleName && !jobIsUnassginedOrOnHold && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.truck')}
              value={selectedJob.vehicleName}
            />
          )}

          {!!selectedJob.routeName && !jobIsUnassginedOrOnHold && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.route')}
              value={selectedJob.routeName}
            />
          )}

          {!!selectedJob.orderNo && !jobIsUnassginedOrOnHold && (
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('routes.stopNumber')}
              value={jobIsPendingOptimization ? null : selectedJob.orderNo}
              children={jobIsPendingOptimization && <em>{translate('routes.pendingOptimization')}</em>}
            />
          )}

          {!!selectedJob.containerType && !!selectedJob.containerSize && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('containers.container')}
              value={`${selectedJob.containerType} - ${selectedJob.containerSize}`}
            />
          )}

          {!!selectedJob.customerName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.customer')}
              value={selectedJob.customerName}
            />
          )}

          {!!selectedJob.locationName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.locationName')}
              value={selectedJob.locationName}
            />
          )}

          {!!selectedJob.createdDate && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.creationDate')}
              value={formatDate(selectedJob.createdDate)}
            />
          )}

          {!!selectedJob.date && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('opportunity.scheduledDate')}
              value={formatDate(selectedJob.date)}
            />
          )}

          {!!selectedJob.serviceType && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.serviceType')}
              value={translate(createTranslationKey(selectedJob.serviceType.technicalName, 'common.serviceTypes'))}
            />
          )}

          {!!reasonCode && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.reasonCode')}
              value={reasonCode.name}
            />
          )}

          {!!selectedJob.wasteMaterialTypeTechnicalName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.wasteType')}
              value={translate(createTranslationKey(selectedJob.wasteMaterialTypeTechnicalName, 'common.wasteTypes'))}
            />
          )}

          {!!selectedJob.workOrderNumber && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('routes.workOrderNumber')}
              value={selectedJob.workOrderNumber}
            />
          )}

          {!!selectedJob.locationAddress.formattedAddress && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.address')}
              value={selectedJob.locationAddress.formattedAddress}
            />
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default DispatchBoardMapGLPopup;
