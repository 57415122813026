import { ChangeEvent, FC, useMemo } from 'react';

import { camelCase, map, size } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { MultiSelectEnhanced, TypedField } from '../../core/components';
import { MultiSelectOption, MultiSelectProps } from '../../core/components/MultiSelect';
import { NONE_ID } from '../constants/dispatchBoard';

import translate from '../../core/services/translate';
import { useSelector } from 'src/core/hooks/useSelector';
import { MaterialTypes } from 'src/vendors/interfaces/MaterialTypes';
import { Text } from 'src/core/components/styled';
import { MaterialPickupSettings } from 'src/vendors/interfaces/BulkyItemScheduler';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('vendors.materialTypes.allMaterialTypes')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('vendors.materialTypes.noMaterialTypesPlaceholder')
    : translate('vendors.materialTypes.xMaterialTypesSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  vehicleTypeId?: number;
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  excludeServiceZonesIds?: number[];
  includeNoneOption?: boolean;
}

const MaterialTypesMultiSelect: FC<Props> = ({
  input,
  label,
  multiSelectProps,
  placeholder,
  withLabel,
  withPlaceholder,
  includeNoneOption,
}) => {
  const { materialTypes } = useSelector(state => state.vendors.materialTypes);
  const { bulkyCategoryTypes, bulkyItemTypes, bulkyItemScheduler } = useSelector(
    state => state.vendors.bulkyItemScheduler,
  );
  const { materialPickupSettings: bulkyItemSchedulerMaterialTypes } = bulkyItemScheduler;

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, value: any) => {
    input.onChange(value);
    multiSelectProps?.onChange && multiSelectProps.onChange(value);
  };

  const materialTypesOptions = useMemo(() => {
    let filtered: any[] = map(materialTypes, (materialType: MaterialTypes) => {
      if (!!materialType.bulkyItemTypes?.length || !!materialType.customBulkyItemTypes?.length)
        return {
          label: translate(
            `vendors.bulkyItemScheduler.categoryTypes.${camelCase(materialType.category.technicalName)}`,
          ),
          value: materialType.category.id,
          renderCustomOption: () => (
            <Text weight="medium" size="sMedium">
              {translate(`vendors.bulkyItemScheduler.categoryTypes.${camelCase(materialType.category.technicalName)}`)}
            </Text>
          ),
          options: materialType.bulkyItemTypes
            ? map(materialType.bulkyItemTypes, item => ({
                label: translate(`vendors.bulkyItemScheduler.itemTypes.${camelCase(item.bulkyItem.technicalName)}`),
                value: item.bulkyItem.id.toString(),
              }))
            : map(materialType.customBulkyItemTypes, item => ({
                label: item.customBulkyItemName,
                value: item && item.id && item.id.toString(),
              })),
        };
    });
    filtered = filtered.filter(Boolean);
    return filtered as MultiSelectOption[];
  }, [materialTypes]);

  const bulkyMaterialTypesOptions = useMemo(() => {
    let filtered: any[] = map(
      bulkyItemSchedulerMaterialTypes,
      (bulkyItemSchedulerMaterialType: MaterialPickupSettings) => {
        let subOptions: any[] = map(bulkyItemSchedulerMaterialType.subsetItems, subsetItem => {
          if (subsetItem.isEnabled === true)
            return {
              label: !!subsetItem.bulkyItemTypeId
                ? translate(
                    `vendors.bulkyItemScheduler.itemTypes.${camelCase(
                      bulkyItemTypes.find(itemType => itemType.id === subsetItem.bulkyItemTypeId)?.technicalName,
                    )}`,
                  )
                : subsetItem.customBulkyItemName,
              value: subsetItem.bulkyItemTypeId?.toString() || `c_${subsetItem.id}`,
            };
        });
        subOptions = subOptions.filter(Boolean);
        if (
          !!bulkyItemSchedulerMaterialType.subsetItems?.length &&
          bulkyItemSchedulerMaterialType.categorySettings.isEnabled
        )
          return {
            label: translate(
              `vendors.bulkyItemScheduler.categoryTypes.${camelCase(
                bulkyCategoryTypes.find(
                  categoryType => categoryType.id === bulkyItemSchedulerMaterialType.bulkyCategoryTypeId,
                )?.technicalName,
              )}`,
            ),
            value: bulkyItemSchedulerMaterialType.bulkyCategoryTypeId,
            renderCustomOption: () => (
              <Text weight="medium" size="sMedium">
                {translate(
                  `vendors.bulkyItemScheduler.categoryTypes.${camelCase(
                    bulkyCategoryTypes.find(
                      categoryType => categoryType.id === bulkyItemSchedulerMaterialType.bulkyCategoryTypeId,
                    )?.technicalName,
                  )}`,
                )}
              </Text>
            ),
            options: subOptions,
          };
      },
    );
    filtered = filtered.filter(Boolean);
    return filtered as MultiSelectOption[];
  }, [bulkyCategoryTypes, bulkyItemSchedulerMaterialTypes, bulkyItemTypes]);

  const multiSelectOptions = useMemo(() => {
    let options = !!materialTypesOptions.length ? materialTypesOptions : bulkyMaterialTypesOptions;
    if (includeNoneOption)
      options.unshift({
        label: translate('common.noJobPriority'),
        value: NONE_ID,
        renderCustomOption: () => (
          <Text weight="medium" size="sMedium">
            {translate('common.noJobPriority')}
          </Text>
        ),
      });
    return options as MultiSelectOption[];
  }, [bulkyMaterialTypesOptions, includeNoneOption, materialTypesOptions]);

  return (
    <TypedField
      name={input.name}
      component={MultiSelectEnhanced}
      onChange={handleChange}
      props={{
        label: label || (withLabel ? translate('vendors.materialTypes.materialType') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('vendors.materialTypes.materialType') : undefined),
        options: multiSelectOptions,
        normalizeValues: Number,
        formatText,
        ...multiSelectProps,
      }}
    />
  );
};

export default MaterialTypesMultiSelect;
