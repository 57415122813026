import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { mapper, colorMapper } from '../../../utils/styles';
import { COLOR_SECONDARY } from '../../styles';

const getLabelBackgroundColor = (props: any) =>
  !props.color || props.color === COLOR_SECONDARY
    ? transparentize(0.95, props.theme.brandDefault)
    : transparentize(0.9, colorMapper(props));

const getLabelColor = (props: any) =>
  !props.color || props.color === COLOR_SECONDARY ? props.theme.brandDefault : colorMapper(props);

interface LabelProps {
  fontSize?: string;
  alignTop?: boolean;
  disabled?: boolean;
  marginLeftAuto?: boolean; // used for align last flex item to the right
  size?: string;
}

export const Label = styled.span<LabelProps>`
  display: inline-block;
  width: ${props =>
    mapper(props.size, { auto: 'auto', small: '60px', medium: '80px', large: '100px', xLarge: '185px' }, 'auto')};
  max-width: 100%;
  height: 22px;
  padding: 0 6px;
  overflow: hidden;
  border-radius: 5px;
  background-color: ${getLabelBackgroundColor};
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 22px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: ${props =>
    mapper(props.fontSize, { small: '12px', medium: '14px', large: '16px', xLarge: '18px' }, 'small')};
  color: ${getLabelColor};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    `};

  ${props =>
    props.alignTop &&
    css`
      position: relative;
      top: 5px;
    `};

  ${props =>
    props.marginLeftAuto &&
    css`
      margin-left: auto;
    `}
`;

export const LabelButton = styled(Label.withComponent('button')).attrs({ type: 'button' })`
  border: none;
  cursor: pointer;
  outline: none;
  background: ${transparentize(0.2, '#fff')};
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  &:active {
    background: ${transparentize(0.6, '#fff')};
  }

  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
`;

export const LabelButtonWrapper = styled.div`
  width: auto;
  margin-left: auto;
  margin-right: 40px;
  color: black;
`;
