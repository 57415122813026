import { darken, transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { COLOR_SECONDARY, fadeAndSlideInUp } from '../../../core/styles';
import { colorMapper, mapper, sizeMapper } from '../../../utils/styles';
import { colorMapperWithoutProps } from '../../../utils/styles/colorMapper';
import { Popover } from '../../../core/components/styled';

interface Props {
  hasBorderRight?: boolean;
  isWider?: boolean;
}

const getLabelColor = (props: any) =>
  !props.color || props.color === COLOR_SECONDARY ? props.theme.brandDefault : colorMapper(props);

export const RouteTrackerSummary = styled.div`
  width: 100%;
  padding: 20px 5px;
`;

export const ChartContainer = styled.div<{ isCursorPointer?: boolean }>`
  position: relative;
  ${props =>
    props.isCursorPointer &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
  display: flex;
  justify-content: center;
  padding: 0 20px 10px;
`;

export const IssueValue = styled.span`
  display: block;
  margin-bottom: 4px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 20px;
  text-align: center;
`;

export const DisposalValue = styled.span`
  display: block;
  margin-bottom: 4px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 13px;
  text-align: center;
`;

export const RouteSeeDetails = styled(Link)`
  font-size: 13px;
  text-transform: capitalize;
  text-align: center;
  color: ${props => props.theme.brandPrimary};
  &:hover {
    color: ${props => darken(0.05, props.theme.brandPrimary)};
  }
`;

export const LinkContainer = styled.div<{ margin?: string; isAbsolute?: boolean }>`
  margin: ${props => sizeMapper(props.margin, 'large no no no')};
  text-align: center;
  cursor: pointer;

  ${props =>
    props.isAbsolute &&
    css`
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      margin: 0;
      top: 85%;
    `}
`;

interface BetaBaseWrapperProps {
  inline?: boolean;
}

export const BetaBaseWrapper = styled.div<BetaBaseWrapperProps>`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: column;
  justify-content: center;
`;

interface BetaBaseProps {
  padding?: string;
  margin?: string;
  align?: 'left' | 'center' | 'right';
  color?: string;
  noUnderline?: boolean;
}

const BetaBaseStyle = css<BetaBaseProps>`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    mapper(
      props.align,
      {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
      },
      'left',
    )};
  text-transform: capitalize;
  color: ${props => colorMapperWithoutProps(props.color, 'primary')};
  padding: ${props => sizeMapper(props.padding, 'xSmall')};
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    !props.noUnderline &&
    css`
      text-decoration: underline;
    `}
`;

export const BetaLink = styled(Link)<BetaBaseProps>`
  ${BetaBaseStyle}
`;

interface BetaBaseIconProps {
  margin?: string;
}

export const BetaBaseIcon = styled.div<BetaBaseIconProps>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  font-size: 0;
`;

export const BetaButton = styled.button<BetaBaseProps>`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  ${BetaBaseStyle}
`;

export const IssueValues = styled.div<{ isSmall?: boolean }>`
  ${props =>
    props.isSmall &&
    css`
      display: flex;
      flex-wrap: wrap;
      margin-top: 14px;
      justify-content: center;
      flex-direction: column;
    `};

  ${DisposalValue} {
    ${props =>
      props.isSmall &&
      css`
        font-size: 10px;
        margin-bottom: 10px;
      `};
  }
`;

export const RouteProgressWrapper = styled.div<{ height?: number; isWrap?: boolean }>`
  display: flex;
  width: 100%;
  height: ${p => p.height || 18}px;
  align-items: center;
  justify-content: center;

  ${props =>
    props.isWrap &&
    css`
      height: auto;
      flex-wrap: wrap;
    `};
`;

export const RouteProgressElementWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const RouteProgressElement = styled.div<{ type?: string; width?: string; height?: string }>`
  height: 100%;
  position: relative;

  ${props =>
    props.type &&
    css`
      background-color: ${mapper(
        props.type,
        {
          FirstGPSMovement: transparentize(0.5, '#fff'),
          Scheduled: '#9fc5f9',
          InProgress: '#fcfe01',
          Completed: '#1e9f0e',
          Cancelled: '#fb9901',
          IssueReported: '#d02b27',
          OnHold: '#bd10e0',
          LastGPSMovement: transparentize(0.5, '#fff'),
        },
        'small',
      )};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width}%;
    `};

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};

  &:before {
    content: '';
    height: 33px;
  }
  ${Popover} {
    display: none;
  }

  &:hover {
    ${Popover} {
      display: block;
    }
  }
`;

export const PieChartPlaceholderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const PieChartPlaceholderTooltip = styled.span`
  display: none;
  position: absolute;
  padding: 6px 10px;
  background-color: ${props => props.theme.brandPrimary};
  border-radius: 6px;
  font-size: 13px;
  color: #fff;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -8px;
    left: calc(50% - 8px);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${props => props.theme.brandPrimary};
  }
`;

export const PieChartPlaceholder = styled.div<{ size?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => mapper(props.size, { small: '150px', medium: '350px', large: '550px' })};
  height: ${props => mapper(props.size, { small: '150px', medium: '350px', large: '550px' })};
  background-color: ${props => props.theme.grayLight};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    ${PieChartPlaceholderTooltip} {
      display: block;
      animation: ${fadeAndSlideInUp} 0.3s ease-out;
    }
  }
`;

interface StatusContainerProps {
  margin?: string;
  vertical?: boolean;
}

export const StatusContainer = styled.div<StatusContainerProps>`
  display: flex;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.vertical &&
    css`
      align-items: center;
    `};
`;

export const PassesStatus = styled.div<{ customColor?: string }>`
  border-top: 4px solid ${props => (props.customColor ? props.customColor : getLabelColor)};
  border-bottom: 4px solid ${props => (props.customColor ? props.customColor : getLabelColor)};
  width: 36px;
  height: 10px;
`;

export const PassesStatusComplex = styled.div<{ forwardColor?: string; reverseColor?: string }>`
  width: 36px;
  height: 10px;

  ${props =>
    props.forwardColor &&
    css`
      border-top: 4px solid ${props.forwardColor};
    `};

  ${props =>
    ((!props.forwardColor && props.reverseColor) || (props.forwardColor && !props.reverseColor)) &&
    css`
      height: 4px;
    `};

  ${props =>
    props.reverseColor &&
    css`
      border-bottom: 4px solid ${props.reverseColor};
    `};
`;

export const PassesStatusSimpleWrapper = styled.div`
  width: 36px;
  height: 16px;
`;

export const PassesStatusSimple = styled.div<{ customColor?: string }>`
  width: 36px;
  height: 6px;

  ${props =>
    props.customColor &&
    css`
      background: #fff;
      border: 1px solid #ccc;
      margin-bottom: 3px;
    `};
`;

export const PlowHistoryIcon = styled.div`
  color: ${props => props.theme.grayDark};

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const PlowHistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.gray};
  margin: 0 0 12px 0;
`;

export const PlowHistoryContent = styled.div`
  flex-direction: column;
  display: flex;
`;

export const RouteTrackerChartsWrapper = styled.div<Props>`
  padding: 5px;
  align-items: center;
  display: flex;
  width: max-content;
  ${props =>
    props.hasBorderRight &&
    css`
      border-right: 1px solid ${props => props.theme.grayLight};
    `};
  ${props =>
    props.isWider &&
    css`
      width: 350px;
      justify-content: center;
    `};
`;

export const SnowSweeperDatePickerWrapper = styled.div<Props>`
  position: absolute;
  margin-top: 5px;
  pointer-events: none;
`;
