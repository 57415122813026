import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps, SubmissionError, getFormValues, reduxForm } from 'redux-form';

import { useSelector } from 'src/core/hooks/useSelector';
import { createSuccessNotification } from 'src/core/services/createNotification';
import ConfirmationTypeDropDown from 'src/routes/components/ConfirmationTypeDropdown';
import { refreshWorkSession, upsertDraftRouteTemplateForWorkSession } from 'src/routes/ducks/routeTemplateBuilder';
import { RouteTemplateBuilderService } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import store from 'src/store';
import { WasteTypeDropdown } from '../../../../../common/components';
import { DatePicker, Dropdown, Input, Modal, TypedField } from '../../../../../core/components';
import { DropdownOption } from '../../../../../core/components/Dropdown';
import { Button, Grid, GridColumn, Text } from '../../../../../core/components/styled';
import { ShadowedButtonSet } from '../../../../../core/components/styled/ButtonSet';
import { WEEKDAYS } from '../../../../../core/constants/weekdays';
import translate from '../../../../../core/services/translate';
import { customerLocationsFlashSelector } from '../../../../../customers/ducks/customersTyped';
import { DELIVERY_UTILITY_ID, RESIDENTIAL_ID, ROLL_OFF_ID, ROUTE } from '../../../../../fleet/constants';
import { isDateValidValidator, isRequired, maxLength50 } from '../../../../../utils/services/validator';
import ServiceZoneDropdown from '../../../ServiceZoneDropdown';
import VehicleTypeForVendorDropdown from '../../../VehicleTypeForVendorDropdown';
import { RouteTemplateBuilderModalForm, RouteTemplateBuilderSpacer } from '../../../styled/RouteTemplateBuilderModals';
import RouteTemplateBuilderStopCountsPerDay from '../routeTemplateBuilderSections/RouteTemplateBuilderStopCountsPerDay';
import { RouteTemplateBuilderLeaveStopsOnRoutesToggle } from './RouteTemplateBuilderLeaveStopsOnRoutesToggle';

const daysOfServiceIds: DropdownOption[] = WEEKDAYS.map(day => ({
  label: day.name,
  value: day.id,
}));

export interface RouteTemplateBuilderCreateRouteModalPropsFormValues {
  name: string;
  dayOfService: number;
  endingLocationId?: number;
  vehicleTypeId: number;
  wasteMaterialTypeId: number;
  startDate: string;
  startingLocationId?: number;
  vendorServiceZoneId?: number;
  routeConfirmationTypeId?: number;
}

export interface RouteTemplateBuilderCreateRouteModalProps {
  newRouteTemplateId?: number;
  services?: RouteTemplateBuilderService[];
  onClose(actionSuccessful?: boolean): void;
  hasAssignedStops: boolean;
}

type Props = RouteTemplateBuilderCreateRouteModalProps &
  InjectedFormProps<RouteTemplateBuilderCreateRouteModalPropsFormValues, RouteTemplateBuilderCreateRouteModalProps>;

const RouteTemplateBuilderCreateRouteModal: React.FC<Props> = ({
  change,
  onClose,
  form,
  services,
  handleSubmit,
  newRouteTemplateId,
  hasAssignedStops,
}) => {
  const dispatch = useDispatch();
  const selector = getFormValues(form);

  const formValues = useSelector(selector) as any;
  const customerLocations = useSelector(customerLocationsFlashSelector);
  const workSessionId = useSelector(state => state.routes.routeTemplateBuilder.workSession?.id);
  const isLoading = useSelector(state => state.routes.routeTemplateBuilder.serivcesAssignToRouteLoading);
  const selectedDraftId = useSelector(state => state.routes.routeTemplateBuilder.selectedDraftId);

  const [leaveStopsOnOriginalTemplates, setLeaveStopsOnOriginalTemplates] = useState(false);
  const routeTemplateIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.routeTemplateIds);
  const routeTemplatesDictionary = useSelector(state => state.routes.routeTemplateBuilder.routeTemplatesDictionary)

  const onSubmit = (values: RouteTemplateBuilderCreateRouteModalPropsFormValues) => {
    if (!workSessionId) {
      return;
    }

    const selectedRouteTemplateIds = Object.keys(routeTemplatesDictionary)
      .filter(id => routeTemplateIds.includes(routeTemplatesDictionary[id]))
      .map(id => +id);

    const routeIds = selectedDraftId ? [-selectedDraftId] : selectedRouteTemplateIds;

    return upsertDraftRouteTemplateForWorkSession(
      workSessionId,
      newRouteTemplateId || null,
      {
        ...values,
        serviceContracts: (services || []).map(({ id }) => ({ id })),
        routeTemplateIds: !leaveStopsOnOriginalTemplates && hasAssignedStops ? routeIds : undefined,
      },
      !!newRouteTemplateId,
    )(dispatch)
      .then(() => {
        createSuccessNotification(
          translate(
            !newRouteTemplateId
              ? 'routeTemplateBuilder.serviceContractsAssignedSuccessfullyToNewRoute'
              : 'routeTemplateBuilder.routeTemplateUpdatedSuccessfully',
            {
              name: values.name,
            },
          ),
        );
        onClose(true);
        refreshWorkSession()(dispatch, store.getState);
      })
      .catch(error => {
        switch (error?.response?.data?.code) {
          case 'RouteTemplatePlannerWorkSessionRouteTemplateNameAlreadyExists':
            throw new SubmissionError({ name: translate('routeTemplateBuilder.errors.routeTemplateNameExists') });

          case 'RouteTemplatePlannerDeleteTransferredStops':
            throw new SubmissionError({
              name: translate('routeTemplateBuilder.errors.routeTemplateDeleteTransferredStops'),
            });

          case 'RouteTemplatePlannerWorkSessionRouteTemplateNameExists':
            throw new SubmissionError({
              name: translate('routeTemplateBuilder.errors.routeTemplateDraftNameExists'),
            });
        }
      });
  };

  const onStartingLocationChange = (event: any, value: number) => {
    change('endingLocationId', value);
  };
  return (
    <Modal
      isLoading={isLoading}
      hasBorderRadius
      flex
      flexDirection="column"
      title={translate(
        !newRouteTemplateId ? 'routeTemplateBuilder.createNewRoute' : 'routeTemplateBuilder.editRouteTemplateDraft',
      )}
      size="small"
      minHeight="80vh"
      minWidth="700px"
      padding="medium no no"
      onClose={() => onClose()}
    >
      {!!services && (
        <Text block color="primary" align="center">
          {translate('routeTemplateBuilder.fromXStopsSelected', { count: services.length })}
        </Text>
      )}

      <RouteTemplateBuilderModalForm>
        <Grid multiLine margin="small no no">
          {!!services && (
            <GridColumn size="12/12" padding="no small xSmall" verticalAlign="center" align="center">
              <RouteTemplateBuilderStopCountsPerDay services={services} />
            </GridColumn>
          )}
          {hasAssignedStops && (
            <GridColumn size="12/12" padding="no small xSmall" verticalAlign="center" align="center">
              <RouteTemplateBuilderLeaveStopsOnRoutesToggle
                checked={leaveStopsOnOriginalTemplates}
                onChange={setLeaveStopsOnOriginalTemplates}
              />
            </GridColumn>
          )}

          <GridColumn size="4/12" padding="xSmall small">
            <TypedField
              name="name"
              component={Input}
              validate={[isRequired, maxLength50]}
              props={{
                margin: 'no',
                width: '100%',
                label: translate('routes.routeName'),
              }}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="xSmall small">
            <TypedField
              name="dayOfService"
              component={Dropdown}
              validate={[isRequired]}
              props={{
                margin: 'no',
                label: translate('routes.dayOfService'),
                options: daysOfServiceIds,
              }}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="xSmall small">
            <TypedField
              name="vehicleTypeId"
              component={VehicleTypeForVendorDropdown}
              validate={[isRequired]}
              props={
                {
                  vehicleRoleTypeId: ROUTE,
                  withLabel: true,
                  dropdownProps: {
                    margin: 'no',
                    disabled: true,
                  },
                } as any /* VehicleTypeForVendorDropdown is not typed (yet) */
              }
            />
          </GridColumn>

          {formValues &&
            formValues.vehicleTypeId !== ROLL_OFF_ID &&
            formValues.vehicleTypeId !== DELIVERY_UTILITY_ID && (
              <GridColumn size="4/12" padding="xSmall small">
                <TypedField
                  name="wasteMaterialTypeId"
                  component={WasteTypeDropdown}
                  validate={[isRequired]}
                  props={{
                    withLabel: true,
                    dropdownProps: {
                      margin: 'no',
                    },
                  }}
                />
              </GridColumn>
            )}

          <GridColumn size="4/12" padding="xSmall small">
            <TypedField
              name="startDate"
              component={DatePicker}
              validate={[isDateValidValidator]}
              props={{
                margin: 'no',
                label: translate('common.startDate'),
              }}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="xSmall small">
            <TypedField
              name="startingLocationId"
              component={Dropdown}
              props={{
                isClearable: true,
                margin: 'no',
                label: translate('routes.startingLocation'),
                options: customerLocations,
              }}
              onChange={onStartingLocationChange}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="xSmall small">
            <TypedField
              name="vendorServiceZoneId"
              component={ServiceZoneDropdown}
              props={{
                label: translate('routes.serviceZone'),
                dropdownProps: { margin: 'no' },
              }}
            />
          </GridColumn>

          {formValues && formValues.vehicleTypeId === RESIDENTIAL_ID && (
            <GridColumn size="4/12" padding="xSmall small">
              <TypedField
                name="routeConfirmationTypeId"
                component={ConfirmationTypeDropDown}
                validate={[isRequired]}
                props={{
                  label: translate('routes.confirmationType'),
                }}
              />
            </GridColumn>
          )}
        </Grid>
      </RouteTemplateBuilderModalForm>

      <RouteTemplateBuilderSpacer />

      <ShadowedButtonSet align="center">
        <Button onClick={handleSubmit(onSubmit)} line color="primary" margin="no small no no">
          {translate(
            !newRouteTemplateId ? 'routeTemplateBuilder.saveRouteAsDraft' : 'routeTemplateBuilder.updateDraft',
          )}
        </Button>
      </ShadowedButtonSet>
    </Modal>
  );
};

export default reduxForm<
  RouteTemplateBuilderCreateRouteModalPropsFormValues,
  RouteTemplateBuilderCreateRouteModalProps
>({
  form: 'routeTemplateBuilderCreateRoute',
})(RouteTemplateBuilderCreateRouteModal);
