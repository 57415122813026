import { filter, map, orderBy, size } from 'lodash-es';
import { ChangeEvent, FC, useMemo } from 'react';
import { WrappedFieldInputProps } from 'redux-form';

import { DropDownFieldProps } from 'src/common/components/DropDownFieldProps';
import { MultiSelect, TypedField } from 'src/core/components';
import { MultiSelectProps } from 'src/core/components/MultiSelect';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { NONE_ID } from '../constants/dispatchBoard';
import { NONE_ZERO_ID } from '../constants/routePriorityTypes';
import { RouteTemplateStatus } from './styled/RouteTemplateBuilderMap';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('routes.groups.allGroups')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('routes.groups.noGroup')
    : translate('routes.groups.xGroupsSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  excludeGroupsIds?: number[];
  includeNoneOption?: boolean;
  excludeInactive?: boolean;
  cantSelectInactive?: boolean;
  minWidth?: string;
  maxWidth?: string;
}

const GroupsMultiSelect: FC<Props> = ({
  input,
  multiSelectProps,
  excludeGroupsIds,
  label,
  withLabel,
  placeholder,
  withPlaceholder,
  includeNoneOption,
  excludeInactive,
  cantSelectInactive,
  ...rest
}) => {
  const groups = useSelector(state => state.routes.groups.groups);

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    input.onChange(value);
    multiSelectProps?.onChange && multiSelectProps.onChange(value);
  };

  const options = useMemo(() => {
    const filtered = map(
      orderBy(
        filter(
          groups,
          group => !!group.id && !excludeGroupsIds?.includes(group.id) && (!excludeInactive || group.isActive),
        ),
        ['isActive', 'groupName'],
        ['desc', 'asc'],
      ),
      gr => ({
        label: gr.groupName,
        value: gr.id,
        canOnlyUnselect: !gr.isActive && cantSelectInactive,
        isUncheckedByDefault: !gr.isActive,
        renderCustomOption: () => (
          <>
            {gr.groupName}

            {!gr.isActive && (
              <RouteTemplateStatus neutral margin="xxSmall no xxSmall xxSmall">
                {translate('common.inactive')}
              </RouteTemplateStatus>
            )}
          </>
        ),
      }),
    );
    if (includeNoneOption) {
      filtered.unshift({
        label: translate('routes.groups.noGroup'),
        value: NONE_ZERO_ID,
        canOnlyUnselect: false,
        isUncheckedByDefault: false,
        renderCustomOption: () => <>{translate('routes.groups.noGroup')}</>,
      });
    }
    return filtered;
  }, [cantSelectInactive, excludeGroupsIds, excludeInactive, groups, includeNoneOption]);

  return (
    <TypedField
      name={input.name}
      component={MultiSelect}
      onChange={handleChange}
      {...rest}
      props={{
        label: label || (withLabel ? translate('routes.groups.groups') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('routes.groups.allGroups') : undefined),
        options: options,
        canCheckAll: multiSelectProps?.canCheckAll || false,
        isClearable: true,
        normalizeValues: Number,
        formatText,
        fitContentWidth: true,
        ...multiSelectProps,
      }}
    />
  );
};

export default GroupsMultiSelect;
