import transformLoadServiceTypes from 'src/common/services/transformLoadServiceTypes';
import { http } from 'src/core/services/http';
import { CustomerLocation } from 'src/customers/interfaces/Customers';
import { transformLoadLocations, transformSaveLocation } from './transformLocations';

export const createCustomerLocation = (location: CustomerLocation) =>
  http
    .post('locations', transformSaveLocation(location))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const updateCustomerLocation = (location: CustomerLocation & { shouldRecreateRoutes?: boolean }) =>
  http
    .put(`locations/${location.id}`, transformSaveLocation(location), {
      params: { shouldRecreateRoutes: location.shouldRecreateRoutes || false },
    })
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadCustomerLocations = (customerId: number) =>
  http
    .get('locations', {
      params: {
        customerId,
      },
    })
    .then(response => transformLoadLocations(response.data));

export const deleteCustomerLocation = (
  locationId: number,
  shouldRecreateRoutes?: boolean,
  moveContainersToFacilityId?: number,
) =>
  http
    .delete(`locations/${locationId}`, {
      params: { shouldRecreateRoutes, moveContainersToFacilityId },
    })
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

const loadServiceTypesForLocation = (locationId: number, serviceContactId?: number) =>
  http
    .get(`locations/${locationId}/serviceTypesAndSubTypes`, { params: { serviceContactId } })
    .then(response => transformLoadServiceTypes(response.data));

export const saveGeocode = (latitude: number, longitude: number) =>
  http
    .post('locations/reverseGeocodeAddress', null, { params: { latitude, longitude } })
    .then(res => res.data)
    .catch(error => Promise.reject(error));

export const checkLocationHasVendorContainers = (locationId: number) =>
  http
    .get(`locations/${locationId}/hasVendorContainers`)
    .then(res => res.data)
    .catch(error => Promise.reject(error));

export default loadServiceTypesForLocation;
