import update from 'immutability-helper';
import { AnyAction } from 'redux';
import {
  loadExceptions as doLoadExceptions,
  loadRouteNames as doLoadRouteNames,
  saveExceptions as doSaveExceptions,
  clearException as doClearException,
} from '../../services/dispatchBoardExceptionManager';
import { ThunkDispatch } from 'redux-thunk';
import { ExceptionManagerJobDispatchException } from '../../interfaces/ExceptionManagerJob';

// Actions
const START_LOAD_EXCEPTIONS = 'dispatchBoard/exceptions/START_LOAD_EXCEPTIONS';
const FAIL_LOAD_EXCEPTIONS = 'dispatchBoard/exceptions/FAIL_LOAD_EXCEPTIONS';
const COMPLETE_LOAD_EXCEPTIONS = 'dispatchBoard/exceptions/COMPLETE_LOAD_EXCEPTIONS';

const START_LOAD_ROUTES = 'dispatchBoard/exceptions/START_LOAD_ROUTES';
const FAIL_LOAD_ROUTES = 'dispatchBoard/exceptions/FAIL_LOAD_ROUTES';
const COMPLETE_LOAD_ROUTES = 'dispatchBoard/exceptions/COMPLETE_LOAD_ROUTES';
const CLEAR_ROUTES = 'dispatchBoard/exceptions/CLEAR_ROUTES';

const START_CLEAR_EXCEPTION = 'dispatchBoard/exceptions/START_CLEAR_EXCEPTIONS';
const FAIL_CLEAR_EXCEPTION = 'dispatchBoard/exceptions/FAIL_CLEAR_EXCEPTIONS';
const COMPLETE_CLEAR_EXCEPTION = 'dispatchBoard/exceptions/COMPLETE_CLEAR_EXCEPTIONS';

const START_SAVE = 'dispatchBoard/exceptions/START_SAVE';
const FAIL_SAVE = 'dispatchBoard/exceptions/FAIL_SAVE';
const COMPLETE_SAVE = 'dispatchBoard/exceptions/COMPLETE_SAVE';

interface ExceptionsState {
  isLoadingExceptions: boolean;
  isSavingExceptions: boolean;

  isLoadingRoutes: boolean;
  isSavingRoutes: boolean;

  isClearingExceptions: boolean;

  jobsWithExceptions: any[];
  routes?: any[];
}

// Initial state
const initialState: ExceptionsState = {
  isLoadingExceptions: false,
  isSavingExceptions: false,
  isLoadingRoutes: false,
  isSavingRoutes: false,
  isClearingExceptions: false,

  jobsWithExceptions: [],
  routes: undefined,
};

type Dispatch = ThunkDispatch<ExceptionsState, {}, AnyAction>;

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_EXCEPTIONS:
      return update(state, {
        $merge: { isLoadingExceptions: true },
      });

    case COMPLETE_LOAD_EXCEPTIONS:
      return update(state, {
        $merge: {
          isLoadingExceptions: false,
          jobsWithExceptions: action.jobs,
        },
      });

    case FAIL_LOAD_EXCEPTIONS:
      return update(state, {
        $merge: {
          isLoadingExceptions: false,
        },
      });

    case START_LOAD_ROUTES:
      return update(state, {
        $merge: { isLoadingRoutes: true },
      });

    case COMPLETE_LOAD_ROUTES:
      return update(state, {
        $merge: {
          isLoadingRoutes: false,
          routes: action.routes,
        },
      });

    case FAIL_LOAD_ROUTES:
      return update(state, {
        $merge: {
          isLoadingRoutes: false,
        },
      });

    case CLEAR_ROUTES:
      return update(state, {
        $merge: { routes: undefined },
      });

    case START_CLEAR_EXCEPTION:
      return update(state, {
        $merge: { isClearingExceptions: true },
      });

    case COMPLETE_CLEAR_EXCEPTION:
      state.jobsWithExceptions.splice(
        state.jobsWithExceptions.findIndex(j => j.routeLocationId === action.routeLocationId),
        1,
      );
      return update(state, {
        $merge: { isClearingExceptions: false, jobsWithExceptions: [...state.jobsWithExceptions] },
      });

    case FAIL_CLEAR_EXCEPTION:
      return update(state, {
        $merge: { isClearingExceptions: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSavingExceptions: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSavingExceptions: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSavingExceptions: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD_EXCEPTIONS,
});

const completeLoad = (jobs: any[]) => ({
  type: COMPLETE_LOAD_EXCEPTIONS,
  jobs,
});

const failLoad = () => ({
  type: FAIL_LOAD_EXCEPTIONS,
});

const startLoadRoutes = () => ({
  type: START_LOAD_ROUTES,
});

const completeLoadRoutes = (routes: any[]) => ({
  type: COMPLETE_LOAD_ROUTES,
  routes,
});

const failLoadRoutes = () => ({
  type: FAIL_LOAD_ROUTES,
});

const startClearException = () => ({
  type: START_CLEAR_EXCEPTION,
});

const completeClearException = (routeLocationId: number) => ({
  type: COMPLETE_CLEAR_EXCEPTION,
  routeLocationId,
});

const failClearException = () => ({
  type: FAIL_CLEAR_EXCEPTION,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const clearRoutesAction = () => ({
  type: CLEAR_ROUTES,
});

export const loadExceptions = (
  vendorId: number,
  vehicleTypeId: number,
  startDate: Date | string,
  endDate: Date | string,
  exceptionTypeIds: number[],
  wasteTypeIds: number[],
  jobPriorityTypeIds: number[],
  equipmentTypeIds: number[],
  pickupTypeIds: number[],
  reasonCodeTypeIds: number[],
  serviceZoneIds: number[],
  supervisorsIds: number[],
  materialTypeIds: string[],
) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDispatchBoardRoutePromise = doLoadExceptions(
    vendorId,
    vehicleTypeId,
    startDate,
    endDate,
    exceptionTypeIds,
    wasteTypeIds,
    jobPriorityTypeIds,
    equipmentTypeIds,
    pickupTypeIds,
    reasonCodeTypeIds,
    serviceZoneIds,
    supervisorsIds,
    materialTypeIds
  );
  loadDispatchBoardRoutePromise.then(jobs => dispatch(completeLoad(jobs))).catch(() => dispatch(failLoad()));
  return loadDispatchBoardRoutePromise;
};

export const saveExceptions = (vehicleTypeId: number, vendorId: number, exceptions: ExceptionManagerJobDispatchException[]) => (
  dispatch: Dispatch,
) => {
  dispatch(startSave());
  const saveDispatchBoardRoutePromise = doSaveExceptions(vehicleTypeId, vendorId, exceptions);
  saveDispatchBoardRoutePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveDispatchBoardRoutePromise;
};

export const loadRouteNames = (vendorId: number, vehicleTypeId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadRoutes());
  const saveDispatchBoardRoutePromise = doLoadRouteNames(vendorId, vehicleTypeId, date);
  saveDispatchBoardRoutePromise
    .then(routes => dispatch(completeLoadRoutes(routes)))
    .catch(() => dispatch(failLoadRoutes()));
  return saveDispatchBoardRoutePromise;
};

export const clearException = (vendorId: number, routeLocationId: number) => (dispatch: Dispatch) => {
  dispatch(startClearException());
  const saveDispatchBoardRoutePromise = doClearException(vendorId, routeLocationId);
  saveDispatchBoardRoutePromise
    .then(() => dispatch(completeClearException(routeLocationId)))
    .catch(() => dispatch(failClearException()));
  return saveDispatchBoardRoutePromise;
};

export const clearRoutes = () => (dispatch: Dispatch) => {
  dispatch(clearRoutesAction());
};
