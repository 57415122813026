import { mapKeys } from 'lodash-es';
import { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } from 'src/core/constants/weekdays';
import translate from 'src/core/services/translate';

export const BULKY_ITEM_SCHEDULER_FORM = 'bulkyItemSchedulerForm';

const APPLIANCE_ID = 1;
const BATHROOM_ID = 2;
const BED_ID = 3;
const BUILDING_MATERIAL_ID = 4;
const ELECTRONICS_ID = 5;
const FLOORING_ID = 6;
const YARD_MATERIALS_ID = 7;
export const OTHER_ID = 8;
const WASTE_ID = 9;

export const BULKY_CATEGORY_TYPES = mapKeys(
  [
    { id: APPLIANCE_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.appliance') },
    { id: BATHROOM_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.bathroom') },
    { id: BED_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.bed') },
    { id: BUILDING_MATERIAL_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.buildingMaterial') },
    { id: ELECTRONICS_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.electronics') },
    { id: FLOORING_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.flooring') },
    { id: YARD_MATERIALS_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.yardMaterials') },
    { id: OTHER_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.other') },
    { id: WASTE_ID, name: translate('vendors.bulkyItemScheduler.categoryTypes.waste') },
  ],
  'id',
);

const HEATER_FUEL_DRAINED_ID = 1;
const MICROWAVE_OVEN_ID = 2;
const REFRIGERATOR_FREEZER_ID = 3;
const STOVE_ID = 4;
const WASHER_DRYER_ID = 5;
const WATER_HEATER_ID = 6;
const AIR_CONDITIONER_ID = 7;
const TUB_ID = 8;
const SINK_ID = 9;
const TOILET_ID = 10;
const MATTRESS_BOX_SPRINGS_ID = 11;
const METAL_HEAD_BOARD_FOOT_BOARD_FRAME_ID = 12;
const WOOD_HEAD_BOARD_FOOT_BOARD_FRAME_ID = 13;
const BOARDS_PLYWOOD_PANEL_ID = 14;
const GUTTER_PIPE_ID = 15;
const METAL_OR_METAL_FENCE_ID = 16;
const COUNTERTOPS_AND_CABINETS_ID = 17;
const TV_OR_STEREO_SYSTEM_ID = 18;
const CARPET_PADDING_LARGE_RUG_ID = 19;
const TILE_LINOLEUM_VINYL_ID = 20;
const CHAIR_OR_RECLINER_ID = 21;
const COUCH_SOFA_LOVESEAT_ID = 22;
const METAL_CABINET_OR_TABLE_ID = 23;
const WOOD_CABINET_OR_TABLE_ID = 24;
const PIANO_ORGAN_OR_LARGE_MUSICAL_KEYBOARD_ID = 25;
const POOL_OR_POOL_COVER_ID = 26;
const METAL_SWING_SET_OR_GYM_SET_ID = 27;
const WOOD_SWING_SET_OR_GYM_SET_ID = 28;
const TRAMPOLINE_ID = 29;
const WHEELBARROW_ID = 30;
const TOOLS_ID = 31;
const CHRISTMAS_TREE_ID = 32;
const BULK_TRASH_ID = 33;
const BULK_RECYCLE_ID = 34;
const BULK_GREEN_WASTE_ID = 35;
const HANDHELD_LAWN_EQUIPMENT_ID = 36;

export const BULKY_ITEM_TYPES = mapKeys(
  [
    { id: HEATER_FUEL_DRAINED_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.heaterFuelDrained') },
    { id: MICROWAVE_OVEN_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.microwaveOven') },
    { id: REFRIGERATOR_FREEZER_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.refrigeratorFreezer') },
    { id: STOVE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.stove') },
    { id: WASHER_DRYER_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.washerDryer') },
    { id: WATER_HEATER_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.waterHeater') },
    { id: AIR_CONDITIONER_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.airConditioner') },
    { id: TUB_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.tub') },
    { id: SINK_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.sink') },
    { id: TOILET_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.toilet') },
    { id: MATTRESS_BOX_SPRINGS_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.mattressBoxDprings') },
    {
      id: METAL_HEAD_BOARD_FOOT_BOARD_FRAME_ID,
      name: translate('vendors.bulkyItemScheduler.itemTypes.metalHeadboardFootboardFrame'),
    },
    {
      id: WOOD_HEAD_BOARD_FOOT_BOARD_FRAME_ID,
      name: translate('vendors.bulkyItemScheduler.itemTypes.woodHeadboardFootboardFrame'),
    },
    { id: BOARDS_PLYWOOD_PANEL_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.boardsPlywoodPanel') },
    { id: GUTTER_PIPE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.gutterPipe') },
    { id: METAL_OR_METAL_FENCE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.metalOrMetalFence') },
    { id: COUNTERTOPS_AND_CABINETS_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.countertopsAndCabinets') },
    { id: TV_OR_STEREO_SYSTEM_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.tvOrStereosystem') },
    { id: CARPET_PADDING_LARGE_RUG_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.carpetPaddingLargeRug') },
    { id: TILE_LINOLEUM_VINYL_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.tileLinoleumVinyl') },
    { id: CHAIR_OR_RECLINER_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.chairOrRecliner') },
    { id: COUCH_SOFA_LOVESEAT_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.couchSofaLoveseat') },
    { id: METAL_CABINET_OR_TABLE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.metalCabinetOrTable') },
    { id: WOOD_CABINET_OR_TABLE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.woodCabinetOrTable') },
    {
      id: PIANO_ORGAN_OR_LARGE_MUSICAL_KEYBOARD_ID,
      name: translate('vendors.bulkyItemScheduler.itemTypes.pianoOrganOrLargeMusicalKeyboard'),
    },
    { id: POOL_OR_POOL_COVER_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.poolOrPoolCover') },
    {
      id: METAL_SWING_SET_OR_GYM_SET_ID,
      name: translate('vendors.bulkyItemScheduler.itemTypes.metalSwingSetOrGymSet'),
    },
    { id: WOOD_SWING_SET_OR_GYM_SET_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.woodSwingSetOrGymSet') },
    { id: TRAMPOLINE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.trampoline') },
    { id: WHEELBARROW_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.wheelbarrow') },
    { id: TOOLS_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.tools') },
    { id: CHRISTMAS_TREE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.christmasTree') },
    { id: BULK_TRASH_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.bulkTrash') },
    { id: BULK_RECYCLE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.bulkRecycle') },
    { id: BULK_GREEN_WASTE_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.bulkGreenWaste') },
    { id: HANDHELD_LAWN_EQUIPMENT_ID, name: translate('vendors.bulkyItemScheduler.itemTypes.handheldLawnEquipment') },
  ],
  'id',
);

export const DAY_ID = 1;
export const WEEK_ID = 2;
export const MONTH_ID = 3;
export const QUARTER_ID = 4;
export const YEAR_ID = 5;

export const PERIOD_TYPES = [
  { id: DAY_ID, name: translate('opportunity.vendorRates.uom.day'), technicalName: 'Day' },
  { id: WEEK_ID, name: translate('opportunity.vendorRates.uom.week'), technicalName: 'Week' },
  { id: MONTH_ID, name: translate('opportunity.vendorRates.uom.month'), technicalName: 'Month' },
  { id: QUARTER_ID, name: translate('opportunity.vendorRates.uom.quarter'), technicalName: 'Quarter' },
  { id: YEAR_ID, name: translate('opportunity.vendorRates.uom.year'), technicalName: 'Year' },
];

export const PERIOD_TYPES_MAPPING = mapKeys(PERIOD_TYPES, 'id');

export const BLACKOUT_SINGLE_DATE = 'yes';
export const BLACKOUT_DATE_RANGE = 'no';

export const RESTRICT_PICKUP_DAYS = [
  { name: 'restrictMondayPickup', label: translate('common.weekdays.monday'), day: MONDAY },
  { name: 'restrictTuesdayPickup', label: translate('common.weekdays.tuesday'), day: TUESDAY },
  { name: 'restrictWednesdayPickup', label: translate('common.weekdays.wednesday'), day: WEDNESDAY },
  { name: 'restrictThursdayPickup', label: translate('common.weekdays.thursday'), day: THURSDAY },
  { name: 'restrictFridayPickup', label: translate('common.weekdays.friday'), day: FRIDAY },
  { name: 'restrictSaturdayPickup', label: translate('common.weekdays.saturday'), day: SATURDAY },
  { name: 'restrictSundayPickup', label: translate('common.weekdays.sunday'), day: SUNDAY },
];
