import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { DuckAction, DuckFunction } from 'src/contracts/ducks';
import { Resolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { loadStreetNetworkServiceAreas, setIsEditSegmentsModeOn } from 'src/customers/ducks';
import { loadGeoFenceFilterOptions } from 'src/routes/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadStreetNetwork } from '../../ducks';
import ServiceNetworkPage from './ServiceNetworkPage';
import { loadUserById } from 'src/vendors/ducks';

interface Props {
  loadGeoFenceFilterOptions: DuckFunction<typeof loadGeoFenceFilterOptions>;
  loadStreetNetwork: DuckFunction<typeof loadStreetNetwork>;
  setIsEditSegmentsModeOn: DuckAction<typeof setIsEditSegmentsModeOn>;
  vendorId: number;
}

const ServiceNetworkPageResolver: FC<Props> = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const user = useSelector(state => state.account.login.user);

  const loadDependencies = () => {
    const dependencies = [
      loadUserById(vendorId, user.userId)(dispatch),
      loadStreetNetwork({ vendorId })(dispatch),
      loadGeoFenceFilterOptions({ vendorId })(dispatch),
      loadStreetNetworkServiceAreas(vendorId)(dispatch),
    ];
    dispatch(setIsEditSegmentsModeOn(false));

    return Promise.all(dependencies);
  };

  return (
    <Fragment>
      <DocumentTitle>{translate('customers.streetNetwork.streetNetwork')}</DocumentTitle>
      <Resolver
        successComponent={ServiceNetworkPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/customers/customers"
      />
    </Fragment>
  );
};

export default ServiceNetworkPageResolver;
