import { debounce, find, map } from 'lodash-es';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { RouteComponentProps, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import {
  ButtonLink,
  Grid,
  GridColumn,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Popover,
} from 'src/core/components/styled';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { MapDragHandle, PopoverWrapper, Table } from 'src/core/components';
import { multiWordAndSearch } from 'src/core/services/search';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { PermissionGuard } from 'src/account/components';
import { resetFilters } from 'src/dashboard/ducks';
import { resetGeoFence } from 'src/routes/ducks/routeGeoFence';
import { resetRouteTemplate, setSearchTerm } from 'src/routes/ducks';
import { resetSnowOrSweeperStreetNetwork } from 'src/customers/ducks';
import { ROUTES_PLANNER_DETAILS_EDIT } from 'src/account/constants';
import { SortOrder, getOrderedRows } from 'src/core/components/Table';
import { STREET_SEGMENTS_MAP_HEIGHT } from 'src/routes/constants/streetSegmentsMap';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { StreetNetworkMapWrapper } from 'src/customers/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteStopsSearchForm from '../../forms/RouteStopsSearchForm';
import SnowOrSweeperRouteTemplateSummary from './SnowOrSweeperRouteTemplateSummary';
import SnowOrSweeperRouteTemplateTableRow from './SnowOrSweeperRouteTemplateTableRow';
import StreetNetworkMapGL, {
  RouteSegmentExtended,
} from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import translate from 'src/core/services/translate';

export const filterSegmentsPredicate = (searchTerm?: string) => (routeSegment: StreetNetwork) =>
  !searchTerm ||
  multiWordAndSearch(routeSegment.name, searchTerm) ||
  multiWordAndSearch(routeSegment.streetId, searchTerm);

interface Props extends RouteComponentProps {
  routeTemplateId?: string;
}

const SnowOrSweeperRouteTemplatePage: FC<Props> = ({ routeTemplateId }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const segmentsList = useSelector(state => state.customers.streetNetwork.streetNetwork);
  const routeTemplate = useSelector(state => state.routes.routeTemplate.routeTemplate);
  const isLoadingTemplate = useSelector(state => state.routes.routeTemplate.isLoading);
  const searchTerm = useSelector(state => state.routes.filters.searchTerm);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const isSnowPlowFeatureEnabled = useSelector(checkIfSnowPlowIsEnabled);
  const isStreetSweeperFeatureEnabled = useSelector(checkIfStreetSweepingIsEnabled);
  const { travelPathStatusDetails } = useSelector(state => state.routes.travelPath);

  const [routeSegments, setRouteSegments] = useState<RouteSegmentExtended[]>([]);
  const [mapHeight, setMapHeight] = useState(STREET_SEGMENTS_MAP_HEIGHT);
  const [rowScrollTopPosition, setRowScrollTopPosition] = useState(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [sortBy, setSortBy] = useState<string>('');

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const isSnowPlow = pathname.includes('snow-plow');

  useEffect(
    () => () => {
      dispatch(resetRouteTemplate());
      dispatch(resetGeoFence());
      dispatch(resetFilters());
      dispatch(resetSnowOrSweeperStreetNetwork());
    },
    [dispatch],
  );

  // route segments existing in the route template
  useEffect(() => {
    setRouteSegments(
      map(routeTemplate?.routeLocations, l => {
        const segmentData = find(segmentsList, { id: l.id }) || {};
        return {
          ...l.location.streetSegment,
          ...segmentData,
          isSelected: false,
          name: l.location.name,
          orderNo: l.orderNumber,
        } as any;
      }),
    );
  }, [routeTemplate, segmentsList]);

  const filteredSegments = useMemo(() => {
    return getOrderedRows(routeSegments.filter(filterSegmentsPredicate(searchTerm)), sortBy, sortOrder);
  }, [routeSegments, searchTerm, sortBy, sortOrder]);

  useEffect(() => {
    if (!selectedFeature?.noTableScroll) {
      const segmentIndex = filteredSegments.findIndex((segment: StreetNetwork) => segment.id === selectedFeature?.id);
      let rowScrollTopPosition = segmentIndex * TABLE_ROW_HEIGHT_LARGE + 1;
      if (filteredSegments.length > 8) {
        if (segmentIndex > filteredSegments.length - 7) {
          rowScrollTopPosition = (filteredSegments.length - 7) * TABLE_ROW_HEIGHT_LARGE + 1;
        }
        setRowScrollTopPosition(rowScrollTopPosition);
      }
    }
  }, [filteredSegments, selectedFeature?.id, selectedFeature?.noTableScroll]);

  const handleGoBack = async () => {
    if (history.length) {
      history.goBack();
    } else {
      dispatch(push(`/routes/route-templates/${isSnowPlow ? 'snow-plow' : 'street-sweeper'}/${routeTemplateId}`));
    }
  };

  const handleResizeMap = (_: any, __: any, map: HTMLElement) => {
    setMapHeight(map.offsetHeight);
  };

  const onSearchTermChange = debounce(searchTerm => {
    dispatch(setSearchTerm(searchTerm));
  }, 200);

  const handleOnSortChanged = (sortBy: string, sortOrder: SortOrder) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  const virtualizedProps = {
    height: Math.min(filteredSegments?.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const snowOrSweeperRouteStopsTableCells = [
    { name: 'name', label: translate('common.locationName'), width: '43%', sortable: true },
    { name: 'isActive', label: translate('routes.snowPlow.status'), width: '19%', sortable: true },
    { name: 'passes', label: translate('routes.snowPlow.passes'), width: '19%', sortable: true },
    {
      name: 'isOneWay',
      label: translate('routes.snowPlow.direction'),
      width: '19%',
      sortable: true,
    },
  ];

  const isEditButtonDisabled =
    (isSnowPlow ? !isSnowPlowFeatureEnabled : !isStreetSweeperFeatureEnabled) ||
    (!!travelPathStatusDetails && travelPathStatusDetails.inProgress);

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={handleGoBack} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle margin="small">{routeTemplate?.routeTemplateName}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <PermissionGuard permission={ROUTES_PLANNER_DETAILS_EDIT}>
            <PopoverWrapper
              triggerButton={
                <ButtonLink
                  color="primary"
                  id="edit-route-template-button"
                  line
                  margin="no xSmall no no"
                  to={`/routes/route-templates/${isSnowPlow ? 'snow-plow' : 'street-sweeper'}/${routeTemplateId}/edit`}
                  disabled={isEditButtonDisabled}
                >
                  {translate('common.edit')}
                </ButtonLink>
              }
              popoverContent={
                (isSnowPlow ? !isSnowPlowFeatureEnabled : !isStreetSweeperFeatureEnabled) ? (
                  <Popover>{translate('routes.alertMessages.featureNotEnabled')}</Popover>
                ) : travelPathStatusDetails?.inProgress ? (
                  <Popover>{translate('routes.travelPath.alertMessages.cantMakeChangesRouteTemplate')}</Popover>
                ) : null
              }
              size="large"
            />
          </PermissionGuard>
        </PageActions>
      </PageHeader>
      <Panel margin="no no xLarge" withBoxShadow>
        <PanelSectionGroup>
          <PanelSection withBoxShadow>
            <SnowOrSweeperRouteTemplateSummary isSnowPlow={isSnowPlow} />
          </PanelSection>

          <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }} onResize={handleResizeMap}>
            <StreetNetworkMapWrapper ref={wrapperRef} height={mapHeight}>
              <StreetNetworkMapGL
                routeSegments={filteredSegments}
                routeTemplateId={routeTemplateId ? Number(routeTemplateId) : undefined}
                hasFilteredSegments={filteredSegments.length !== routeSegments.length}
                isRouteTemplate
                isStreetSweeperRoute={!isSnowPlow}
                routeName={routeTemplate?.routeTemplateName}
              />
            </StreetNetworkMapWrapper>
          </Resizable>
          <PanelSection>
            <Grid>
              <GridColumn size="12/12">
                <RouteStopsSearchForm
                  onSearchTermChange={onSearchTermChange}
                  isSnowPlowRoute={isSnowPlow}
                  isStreetSweeperRoute={!isSnowPlow}
                  initialValues={{ routeStopsSearchTerm: searchTerm }}
                />
              </GridColumn>
            </Grid>
          </PanelSection>
          <PanelSection centered isLoading={isLoadingTemplate}>
            <Grid centered>
              {!!filteredSegments.length ? (
                <Table
                  cells={snowOrSweeperRouteStopsTableCells}
                  rowComponent={SnowOrSweeperRouteTemplateTableRow}
                  rows={filteredSegments}
                  rowProps={{
                    totalRows: filteredSegments.length,
                    rowHeight: TABLE_ROW_HEIGHT_LARGE,
                  }}
                  sort={handleOnSortChanged}
                  virtualized
                  virtualizedProps={virtualizedProps}
                  scrollMarker
                  noOverflow
                  withClickableRows
                  rowScrollTopPosition={rowScrollTopPosition}
                />
              ) : !filteredSegments.length && routeSegments.length ? (
                <Message padding="medium">{translate('routes.snowPlow.noSegmentsMatchCriteria')}</Message>
              ) : (
                <Message padding="medium">{translate('routes.noRouteStreetSegmentsAddedTemplate')}</Message>
              )}
            </Grid>
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

export default SnowOrSweeperRouteTemplatePage;
