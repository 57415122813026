import { filter, find, map, size, some, uniq } from 'lodash-es';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { InjectedFormProps, formValueSelector, reduxForm } from 'redux-form';

import { ROUTES_PLANNER_SCHEDULE } from 'src/account/constants';
import { hasPermission } from 'src/account/utils/permissions';
import { hasPermissionBasedOnProperties } from 'src/common/utils/filters';
import { DatePicker, Dropdown, RadioCheckbox, TypedField } from 'src/core/components';
import { Button, ButtonSet, Grid, GridColumn } from 'src/core/components/styled';
import { TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { RoutePlannerNewSchedulerFormValues } from 'src/routes/interfaces/RouteTemplates';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import { isDateValidValidator, isRequired } from 'src/utils/services/validator';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import GroupsMultiSelect from '../GroupsMultiSelect';
import { RouteTemplateScheduleNotice } from '../styled/RouteTemplates';

interface ComponentProps {
  isScheduleAll: boolean;
}

export const ROUTE_TYPES_FOR_SCHEDULER = {
  SNOW_PLOW: 'snowPlow',
  STREET_SWEEPER: 'streetSweeper',
};

type Props = ComponentProps & InjectedFormProps<RoutePlannerNewSchedulerFormValues, ComponentProps>;

export const ROUTE_PLANNER_NEW_SCHEDULER_FORM_NAME = 'ROUTE_PLANNER_NEW_SCHEDULER_FORM';

const selector = formValueSelector(ROUTE_PLANNER_NEW_SCHEDULER_FORM_NAME);

const RoutePlannerNewSchedulerForm: FC<Props> = ({ handleSubmit, isScheduleAll }) => {
  const schedulers = useSelector(state => state.routes.routeTemplates.schedulers);
  const isLoadingSchedulers = useSelector(state => state.routes.routeTemplates.isLoadingSchedulers);
  const isScheduling = useSelector(state => state.routes.routeTemplates.isScheduling);
  const keepOriginalServiceDate = useSelector(state => selector(state, 'keepOriginalServiceDate'));
  const filtersPreferences = useSelector(state => state.common.filters.filters);
  const isStreetSweeperFeatureEnabled = useSelector(checkIfStreetSweepingIsEnabled);
  const isSnowPlowFeatureEnabled = useSelector(checkIfSnowPlowIsEnabled);
  const vehicleTypesForVendor = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const isSnowPlowVehicleTypeConfigured = some(vehicleTypesForVendor, { id: SNOW_PLOW_ID });
  const isStreetSweeperVehicleTypeConfigured = some(vehicleTypesForVendor, { id: STREET_SWEEPER_ID });

  const routeTypeSelected = useSelector(state => selector(state, 'routeType'));
  const groups = useSelector(state => state.routes.groups.groups);

  const groupIdsToExclude = useMemo(() => {
    if (routeTypeSelected === ROUTE_TYPES_FOR_SCHEDULER.SNOW_PLOW) {
      return map(
        filter(groups, gr => !size(gr.vehicleTypeIds) || !find(gr.vehicleTypeIds, id => id === SNOW_PLOW_ID)),
        'id',
      );
    }
    if (routeTypeSelected === ROUTE_TYPES_FOR_SCHEDULER.STREET_SWEEPER) {
      return map(
        filter(groups, gr => !size(gr.vehicleTypeIds) || !find(gr.vehicleTypeIds, id => id === STREET_SWEEPER_ID)),
        'id',
      );
    }
    return [];
  }, [groups, routeTypeSelected]);

  const routesAlreadyCreated = useMemo(() => some(schedulers, scheduler => scheduler.isRouteCreated), [schedulers]);

  const originalDatesList = useMemo(
    () =>
      uniq(
        schedulers
          .filter((scheduler: any) => !scheduler.isRouteCreated)
          .map((scheduler: any) => moment(scheduler.routeDate).format('MM/DD/YYYY')),
      ),
    [schedulers],
  );

  const oneOriginalDateOnly = originalDatesList.length === 1;

  const hasScheduleSnowPlowAccess =
    isSnowPlowFeatureEnabled &&
    isSnowPlowVehicleTypeConfigured &&
    hasPermission(ROUTES_PLANNER_SCHEDULE) &&
    hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: SNOW_PLOW_ID });

  const hasScheduleStreetSweeperAccess =
    isStreetSweeperFeatureEnabled &&
    isStreetSweeperVehicleTypeConfigured &&
    hasPermission(ROUTES_PLANNER_SCHEDULE) &&
    hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: STREET_SWEEPER_ID });

  const routeTypeOptions = [];
  if (hasScheduleSnowPlowAccess)
    routeTypeOptions.push({ label: translate('dashboard.snowPlow'), value: ROUTE_TYPES_FOR_SCHEDULER.SNOW_PLOW });

  if (hasScheduleStreetSweeperAccess)
    routeTypeOptions.push({
      label: translate('dashboard.streetSweeper'),
      value: ROUTE_TYPES_FOR_SCHEDULER.STREET_SWEEPER,
    });

  return (
    <form onSubmit={handleSubmit}>
      <Grid multiLine>
        <GridColumn size="12/12">
          <TypedField
            name="date"
            component={DatePicker}
            validate={[isRequired, isDateValidValidator]}
            props={{
              disabledDays: [{ before: TODAY }],
              margin: 'large auto',
              width: '50%',
              placeholder: translate('common.date'),
            }}
          />
        </GridColumn>

        {isScheduleAll && (
          <>
            <GridColumn size="6/12" alignVerticalCenter>
              <TypedField
                name="routeType"
                component={Dropdown}
                validate={[isRequired]}
                props={{
                  margin: 'no',
                  placeholder: translate('common.vehicleType'),
                  options: routeTypeOptions,
                }}
              />
            </GridColumn>
            <GridColumn size="6/12" alignVerticalCenter>
              <TypedField
                name="groupIds"
                component={GroupsMultiSelect}
                props={{
                  withLabel: true,
                  includeNoneOption: true,
                  excludeInactive: true,
                  excludeGroupsIds: groupIdsToExclude,
                  multiSelectProps: {
                    defaultToAll: false,
                    margin: 'no no small',
                    menuPosition: 'fixed',
                    maxWidth: '250px',
                    minWidth: '250px',
                    canCheckAll: true,
                    disabled: !routeTypeSelected,
                  },
                }}
              />
            </GridColumn>
          </>
        )}
        {!!size(originalDatesList) && (
          <>
            <GridColumn size="12/12">
              <RouteTemplateScheduleNotice>
                {oneOriginalDateOnly
                  ? translate('routes.originalServiceDate', { date: originalDatesList.join(', ') })
                  : translate('routes.originalServiceDates', { dates: originalDatesList.join(', ') })}
              </RouteTemplateScheduleNotice>
              <TypedField
                name="keepOriginalServiceDate"
                component={RadioCheckbox}
                props={{
                  label: translate('common.yes'),
                  margin: 'small no no',
                  block: true,
                  type: 'radio',
                  value: 'yes',
                }}
              />
              <TypedField
                name="keepOriginalServiceDate"
                component={RadioCheckbox}
                props={{
                  label: translate('common.no'),
                  margin: 'small no no',
                  block: true,
                  type: 'radio',
                  value: 'no',
                }}
              />
              {keepOriginalServiceDate === 'yes' && (
                <RouteTemplateScheduleNotice>
                  {translate(
                    oneOriginalDateOnly ? 'routes.routeWillRemainScheduled' : 'routes.routesWillRemainScheduled',
                  )}
                </RouteTemplateScheduleNotice>
              )}

              {keepOriginalServiceDate === 'no' && (
                <RouteTemplateScheduleNotice>
                  {translate(
                    oneOriginalDateOnly ? 'routes.routeWillNotBeScheduled' : 'routes.routesWillNotBeScheduled',
                  )}
                </RouteTemplateScheduleNotice>
              )}
            </GridColumn>
          </>
        )}

        {routesAlreadyCreated && (
          <RouteTemplateScheduleNotice>{translate('routes.oneOrMoreRoutesAlreadyCreated')}</RouteTemplateScheduleNotice>
        )}
      </Grid>
      <ButtonSet align="center">
        <Button type="submit" color="primary" disabled={isScheduling || isLoadingSchedulers}>
          {translate('common.save')}
        </Button>
      </ButtonSet>
    </form>
  );
};

export default reduxForm<RoutePlannerNewSchedulerFormValues, ComponentProps>({
  form: ROUTE_PLANNER_NEW_SCHEDULER_FORM_NAME,
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(RoutePlannerNewSchedulerForm);
