import { http } from '../../core/services/http';
import { ExceptionManagerJobDispatchException } from '../interfaces/ExceptionManagerJob';

export const loadExceptions = (
  vendorId: number,
  vehicleTypeId: number,
  startDate: Date | string,
  endDate: Date | string,
  exceptionTypeIds: number[],
  wasteTypeIds: number[],
  jobPriorityTypeIds: number[],
  equipmentTypeIds: number[],
  pickupTypeIds: number[],
  reasonCodeTypeIds: number[],
  serviceZoneIds: number[],
  supervisorsIds: number[],
  materialTypeIds: string[],
) =>
  http
    .get(`exceptionManagement/exceptions`, {
      params: {
        vendorId,
        vehicleTypeId,
        startDate,
        endDate,
        ...(exceptionTypeIds.length ? { exceptionTypeIds: exceptionTypeIds.join(',') } : {}),
        ...(wasteTypeIds.length ? { wasteTypeIds: wasteTypeIds.join(',') } : {}),
        ...(jobPriorityTypeIds.length ? { jobPriorityTypeIds: jobPriorityTypeIds.join(',') } : {}),
        ...(equipmentTypeIds.length ? { equipmentTypeIds: equipmentTypeIds.join(',') } : {}),
        ...(pickupTypeIds.length ? { pickupTypeIds: pickupTypeIds.join(',') } : {}),
        ...(reasonCodeTypeIds.length ? { reasonCodeTypeIds: reasonCodeTypeIds.join(',') } : {}),
        ...(serviceZoneIds.length ? { serviceZoneIds: serviceZoneIds.join(',') } : {}),
        ...(supervisorsIds.length ? { supervisorsIds: supervisorsIds.join(',') } : {}),
        ...(materialTypeIds.length ? { materialTypeIds: materialTypeIds.join(',') } : {}),
      },
    })
    .then(response => response.data);

export const saveExceptions = (vehicleTypeId: number, vendorId: number, exceptionList: ExceptionManagerJobDispatchException[]) =>
  http.post('exceptionManagement/exceptions/process', {
    vehicleTypeId,
    vendorId,
    exceptions: exceptionList.map(e => ({
      routeId: e.route.id,
      wasteMaterialTypeId: e.wasteMaterialType ? e.wasteMaterialType.id : undefined,
      ...e,
    })),
  });

export const clearException = (vendorId: number, routeLocationId: number) =>
  http.delete('exceptionManagement/exception', { params: { vendorId, routeLocationId } });

export const loadRouteNames = (vendorId: number, vehicleTypeId: number, date: Date | string) =>
  http
    .get('exceptionManagement/routes', {
      params: {
        vendorId,
        vehicleTypeId,
        date,
      },
    })
    .then(response => response.data);
