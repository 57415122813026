import { camelCase, filter, forEach, map, maxBy, uniqBy, mapKeys, uniq, startCase } from 'lodash-es';
import moment from 'moment';

import { AlternativeFleetStreetSegment } from 'src/dashboard/interfaces/alterativeFleetOps';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import { getFeatureCollection, getLineStringFeature } from 'src/common/components/map/util';
import { getLastPassType, getLastPassValue } from 'src/dashboard/utils/alternativeFleet';
import { getSegmentCenter, parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { RouteSegmentExtended } from './StreetNetworkMapGL';
import { SegmentConfiguration, ServiceConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { SIMPLE } from 'src/common/constants/drivingComplexity';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import store from 'src/store';
import translate from 'src/core/services/translate';

export type RouteSegmentFeatureProperties = {
  id: number;
  clickable: boolean;
  segmentCenter: number[];
};

export const ROUTE_SEGMENT_LANE_COLORS = {
  green: '#00a599',
  black: '#000',
  orange: '#ffa452',
  blue: '#0066a6',
  purple: '#c200cc',
  red: '#ff5252',
  yellow: '#fcfe01',
  white: '#fff',
};

const FORWARD_PASS_TYPE_ID = 1;
const REVERSE_PASS_TYPE_ID = 2;
const ANY_PASS_TYPE_ID = 3;

export const ROUTE_SEGMENT_LANE_COLOR_IDS = {
  1: ROUTE_SEGMENT_LANE_COLORS.blue,
  2: ROUTE_SEGMENT_LANE_COLORS.yellow,
  3: ROUTE_SEGMENT_LANE_COLORS.green,
};

const getSegmentColor = (
  segment: StreetNetwork,
  segmentOnRoute?: RouteSegmentExtended,
  isRouteTemplate?: boolean,
  isDailyRoute?: boolean,
  isEditMode?: boolean,
  isReverse?: boolean,
  isSatelliteView?: boolean,
  segmentColorType?: 'segmentStatus' | 'agingInterval' | 'servicingDriver',
  driversColorMap?: { [key: string]: string },
  isSnowPlowRoute?: boolean,
) => {
  // color: BLACK assigned to another route
  let color: any = isSatelliteView ? ROUTE_SEGMENT_LANE_COLORS.white : ROUTE_SEGMENT_LANE_COLORS.black;

  // color: ORANGE => if is not assigned to any route template
  if (isRouteTemplate && segment.routeTemplateIds?.length === 0) {
    color = ROUTE_SEGMENT_LANE_COLORS.orange;
  }

  // color: BLUE => if is assigned to this route, or if is in scheduled
  if (segmentOnRoute) {
    color = ROUTE_SEGMENT_LANE_COLORS.blue;
  }

  if (isReverse) {
    // color: GREEN => if status is completed
    if (!isEditMode && isDailyRoute && segmentOnRoute?.reverseStatusId === COMPLETED) {
      color = ROUTE_SEGMENT_LANE_COLORS.green;
    }

    // color: YELLOW => if status is in progress
    if (!isEditMode && isDailyRoute && segmentOnRoute?.reverseStatusId === IN_PROGRESS) {
      color = ROUTE_SEGMENT_LANE_COLORS.yellow;
    }
  } else {
    // color: GREEN => if status is completed
    if (!isEditMode && isDailyRoute && segmentOnRoute?.forwardStatusId === COMPLETED) {
      color = ROUTE_SEGMENT_LANE_COLORS.green;
    }

    // color: YELLOW => if status is in progress
    if (!isEditMode && isDailyRoute && segmentOnRoute?.forwardStatusId === IN_PROGRESS) {
      color = ROUTE_SEGMENT_LANE_COLORS.yellow;
    }
  }

  // color: based on last activity
  if (segmentColorType === 'agingInterval' && !isEditMode && isDailyRoute && segmentOnRoute)
    color = getSegmentColorBasedOnLastActivity(segmentOnRoute, isSnowPlowRoute, isReverse);

  // color: based on servicing drivers
  if (segmentColorType === 'servicingDriver' && driversColorMap && !isEditMode && isDailyRoute && segmentOnRoute) {
    color = getServicingDriversColor(segmentOnRoute, driversColorMap, isReverse);
  }

  return color;
};

const getSegmentForwardColor = (
  segment: StreetNetwork,
  segmentOnRoute?: RouteSegmentExtended,
  isRouteTemplate?: boolean,
  isDailyRoute?: boolean,
  isEditMode?: boolean,
  isSatelliteView?: boolean,
  segmentColorType?: 'segmentStatus' | 'agingInterval' | 'servicingDriver',
  driversColorMap?: { [key: string]: string },
  isSnowPlowRoute?: boolean,
) => {
  const isReverse = false;
  return getSegmentColor(
    segment,
    segmentOnRoute,
    isRouteTemplate,
    isDailyRoute,
    isEditMode,
    isReverse,
    isSatelliteView,
    segmentColorType,
    driversColorMap,
    isSnowPlowRoute,
  );
};

const getSegmentReverseColor = (
  segment: StreetNetwork,
  segmentOnRoute?: RouteSegmentExtended,
  isRouteTemplate?: boolean,
  isDailyRoute?: boolean,
  isEditMode?: boolean,
  isSatelliteView?: boolean,
  segmentColorType?: 'segmentStatus' | 'agingInterval' | 'servicingDriver',
  driversColorMap?: { [key: string]: string },
  isSnowPlowRoute?: boolean,
) => {
  const isReverse = true;
  return getSegmentColor(
    segment,
    segmentOnRoute,
    isRouteTemplate,
    isDailyRoute,
    isEditMode,
    isReverse,
    isSatelliteView,
    segmentColorType,
    driversColorMap,
    isSnowPlowRoute,
  );
};

const getSegmentOpacity = (
  segmentOnRoute?: RouteSegmentExtended,
  isRouteTemplate?: boolean,
  isDailyRoute?: boolean,
  isEditMode?: boolean,
) => {
  if (isEditMode || isRouteTemplate) return 0.5;

  if (isDailyRoute) {
    const numberOfStreetPasses = segmentOnRoute?.streetPasses?.length || 0;
    const totalStreetPasses = segmentOnRoute?.numberOfPasses || 0;
    let opacity = 0.5;
    if (numberOfStreetPasses > 0) {
      opacity = 0.6;
    }
    // full opacity if was fully plowed
    if (numberOfStreetPasses >= totalStreetPasses) {
      opacity = 0.75;
    }
    return opacity;
  }

  return 0.5;
};

export const getRouteSegmentsGeoJSON = (
  streetNetwork: RouteSegmentExtended[],
  routeSegments?: RouteSegmentExtended[],
  isRouteTemplate?: boolean,
  isDailyRoute?: boolean,
  isEditMode?: boolean,
  isSatelliteView?: boolean,
  segmentColorType?: 'segmentStatus' | 'agingInterval' | 'servicingDriver',
  driversColorMap?: { [key: string]: string },
  isSnowPlowRoute?: boolean,
) => {
  const isDriverExperienceSimple = isDailyRoute && calculateIsDriverExperienceSimple(isSnowPlowRoute);

  // moving the here to improve performance
  const routeSegmentsById = mapKeys(routeSegments, 'id');

  const processedSegments = streetNetwork.map(segment => {
    const parsedSegment = parseSegmentJSON(segment.lineSegment);
    const currentRouteSegment = routeSegmentsById[segment.id] || undefined;

    const currentRouteStreetNetworkSegment = streetNetwork?.find((s: RouteSegmentExtended) => s.id === segment.id);
    const segmentOnRoute = !!routeSegmentsById[segment.id];
    const isOneWay = segmentOnRoute ? currentRouteSegment?.isOneWay : currentRouteStreetNetworkSegment?.isOneWay;
    const segmentIsNew =
      !segmentOnRoute ||
      (currentRouteSegment?.forwardStatusId === undefined && currentRouteSegment?.reverseStatusId === undefined);
    const hasForwardPass =
      !currentRouteSegment || currentRouteSegment?.forwardStatusId === undefined
        ? true
        : !!currentRouteSegment?.forwardStatusId;
    const hasReversePass = segmentIsNew
      ? !isOneWay
      : !currentRouteSegment || currentRouteSegment?.reverseStatusId === undefined
      ? true
      : !!currentRouteSegment?.reverseStatusId;
    const hasOnlyOnePass = segmentIsNew
      ? isOneWay
      : !currentRouteSegment?.forwardStatusId || !currentRouteSegment?.reverseStatusId;

    const twoWayOffset = 5;

    const lineString = getLineStringFeature(segment.id, parsedSegment, {
      id: segment.id,
      clickable: true,
      segmentCenter: getSegmentCenter(parsedSegment),
      color: getSegmentForwardColor(
        segment,
        currentRouteSegment,

        isRouteTemplate,
        isDailyRoute,
        isEditMode,
        isSatelliteView,
        segmentColorType,
        driversColorMap,
        isSnowPlowRoute,
      ),
      reverseSegmentColor: getSegmentReverseColor(
        segment,
        currentRouteSegment,

        isRouteTemplate,
        isDailyRoute,
        isEditMode,
        isSatelliteView,
        segmentColorType,
        driversColorMap,
        isSnowPlowRoute,
      ),
      opacity: getSegmentOpacity(currentRouteSegment, isRouteTemplate, isDailyRoute, isEditMode),
      hasForwardPass,
      hasReversePass,
      lineOffset: hasOnlyOnePass || isDriverExperienceSimple ? 0 : twoWayOffset,
      lineOffsetNegative: hasOnlyOnePass || isDriverExperienceSimple ? 0 : -twoWayOffset,
    });

    return lineString;
  });

  return getFeatureCollection<GeoJSON.LineString, RouteSegmentFeatureProperties>(processedSegments);
};

export const calculateIsDriverExperienceSimple = (isSnowPlowRoute?: boolean) => {
  const snowSettings = store.getState().vendors.snowPlowSettings.snowPlowSettings;
  const sweeperSettings = store.getState().vendors.streetSweepingSettings.streetSweepingSettings;
  return (
    (isSnowPlowRoute !== undefined && isSnowPlowRoute ? snowSettings : sweeperSettings)?.drivingComplexityType?.id ===
    SIMPLE
  );
};

export const getSegmentsToShow = (
  routeSegments: StreetNetwork[],
  streetNetwork: StreetNetwork[],
  routeSegmentsWithStreetPasses: RouteSegment[],
  segmentsList: RouteSegment[],
  selectedSegments: number[],
  isSnowPlowRoute?: boolean,
) => {
  const isDriverExperienceSimple = calculateIsDriverExperienceSimple(isSnowPlowRoute);

  const segments = map(
    routeSegments.filter((s: StreetNetwork) => streetNetwork.map((el: StreetNetwork) => el.id)),
    (s: StreetNetwork) => {
      const currentRouteSegmentsWithStreetPasses = routeSegmentsWithStreetPasses.find(
        (el: RouteSegment) => el.streetSegmentId === s.id,
      );

      const currentSegmentsList = segmentsList.find((el: RouteSegment) => el.streetSegmentId === s.id);

      const hasNoServiceStatus = !currentSegmentsList?.forwardStatusId && !currentSegmentsList?.reverseStatusId;

      return {
        ...s,

        forwardStatusId:
          hasNoServiceStatus && isDriverExperienceSimple
            ? SCHEDULED
            : hasNoServiceStatus && !isDriverExperienceSimple
            ? SCHEDULED
            : currentSegmentsList?.forwardStatusId,
        reverseStatusId:
          hasNoServiceStatus && isDriverExperienceSimple
            ? undefined
            : hasNoServiceStatus && !isDriverExperienceSimple
            ? SCHEDULED
            : currentSegmentsList?.reverseStatusId,
        lastForwardPassDateTime: currentSegmentsList?.lastForwardPassDateTime,
        lastReversePassDateTime: currentSegmentsList?.lastReversePassDateTime,
        isSelected: selectedSegments.includes(s.id),
        streetPasses: currentRouteSegmentsWithStreetPasses?.streetPasses || [],
        statusId: currentRouteSegmentsWithStreetPasses?.statusId,
        streetName: currentRouteSegmentsWithStreetPasses?.streetName,
        numberOfIssues: currentRouteSegmentsWithStreetPasses?.numberOfIssues,
        customerName: currentRouteSegmentsWithStreetPasses?.customerName,
        locationName: currentRouteSegmentsWithStreetPasses?.locationName,
        routeStopId: currentRouteSegmentsWithStreetPasses?.routeStopId,
        passDateTime: currentRouteSegmentsWithStreetPasses?.streetPasses[0]?.passDateTime,
        numberOfPasses: s.reversePasses + s.forwardPasses,
      };
    },
  );

  // filter out segments duplicates
  return uniqBy(segments, 'id');
};

export const getPassForwardColor = (forwardStatusId?: number, reverseStatusId?: number) => {
  return forwardStatusId === undefined && reverseStatusId === undefined
    ? ROUTE_SEGMENT_LANE_COLORS.blue
    : forwardStatusId
    ? (ROUTE_SEGMENT_LANE_COLOR_IDS as any)[forwardStatusId]
    : forwardStatusId === null
    ? undefined
    : ROUTE_SEGMENT_LANE_COLORS.black;
};

export const getPassReverseColor = (forwardStatusId?: number, reverseStatusId?: number, isOneWay?: boolean) => {
  return forwardStatusId === undefined && reverseStatusId === undefined && !isOneWay
    ? ROUTE_SEGMENT_LANE_COLORS.blue
    : reverseStatusId
    ? (ROUTE_SEGMENT_LANE_COLOR_IDS as any)[reverseStatusId]
    : undefined;
};

export const filterTrackerSegments = (
  routeSegments: AlternativeFleetStreetSegment[],
  selectedLastActivityIds: string[],
  isSnowPlowRoute?: boolean,
) => {
  const snowSettings = store.getState().vendors.snowPlowSettings.snowPlowSettings;
  const sweeperSettings = store.getState().vendors.streetSweepingSettings.streetSweepingSettings;

  const selectedConfigurationsSnow = selectedLastActivityIds.length
    ? filter(snowSettings?.displayConfiguration?.segmentConfiguration, segment =>
        selectedLastActivityIds.includes(segment.streetSegmentAgingInterval.id.toString()),
      )
    : snowSettings?.displayConfiguration?.segmentConfiguration;
  const selectedConfigurationsSweeper = selectedLastActivityIds.length
    ? filter(sweeperSettings?.segmentColorSettings, segment =>
        selectedLastActivityIds.includes(segment.streetSegmentAgingInterval.id.toString()),
      )
    : sweeperSettings?.segmentColorSettings;
  const settingsToUse = isSnowPlowRoute ? selectedConfigurationsSnow : selectedConfigurationsSweeper;

  const filteredSegments: AlternativeFleetStreetSegment[] = [];
  for (let i = 0; i <= routeSegments.length - 1; i++) {
    const mostRecentPassDate = routeSegments[i].lastPassDateTime || moment().subtract(1, 'year'); // if no last pass date, we set it to a year ago

    const lastPassInHours = getLastPassType(mostRecentPassDate, 'hours');
    const lastPassInDays = getLastPassType(mostRecentPassDate, 'days');
    const lastPassInWeeks = getLastPassType(mostRecentPassDate, 'weeks');
    const lastPassInMonths = getLastPassType(mostRecentPassDate, 'months');

    let isInInterval = false;

    forEach(settingsToUse, setting => {
      const {
        streetSegmentAgingInterval: {
          minValue,
          maxValue,
          timeMeasurementType: { id: timeMeasurementTypeId },
        },
      } = setting;

      const lastPassValue = getLastPassValue(
        timeMeasurementTypeId,
        lastPassInHours,
        lastPassInDays,
        lastPassInWeeks,
        lastPassInMonths,
      );

      if (maxValue) {
        if (lastPassValue >= minValue && lastPassValue < maxValue) {
          isInInterval = true;
        }
      } else if (lastPassValue >= minValue) {
        isInInterval = true;
      }
    });

    if (isInInterval) filteredSegments.push(routeSegments[i]);
  }

  return filteredSegments;
};

export const getServiceActivityOptions = (serviceConfiguration: ServiceConfiguration[]) =>
  serviceConfiguration
    ?.map((item: ServiceConfiguration) => {
      const { alternativeFleetServiceType, isActive, isNonServiceActivity } = item;

      return {
        label: translate(
          `customers.streetNetwork.serviceActivity.${camelCase(alternativeFleetServiceType.technicalName)}`,
        ),
        id: `_${alternativeFleetServiceType.id}`,
        isActive,
        isNonServiceActivity,
      };
    })
    .filter(item => item.isActive && !item.isNonServiceActivity) || [];

const getServicingDriversColor = (
  segment: RouteSegmentExtended,
  driversColors: { [key: string]: string },
  isReverse?: boolean,
) => {
  const passes = isReverse
    ? filter(
        segment.streetPasses,
        pass => pass.passTypeId === REVERSE_PASS_TYPE_ID || pass.passTypeId === ANY_PASS_TYPE_ID,
      )
    : filter(
        segment.streetPasses,
        pass => pass.passTypeId === FORWARD_PASS_TYPE_ID || pass.passTypeId === ANY_PASS_TYPE_ID,
      );

  const colors = uniq(map(passes, pass => driversColors[camelCase(pass.driverName)]));

  if (colors.length > 1) {
    return 'blue'; // if more than one driver, return blue
  }

  if (!colors.length) {
    return ROUTE_SEGMENT_LANE_COLORS.black;
  }

  return colors[0];
};

const getSegmentColorBasedOnLastActivity = (
  segment?: RouteSegmentExtended,
  isSnowPlowRoute?: boolean,
  isReverse?: boolean,
) => {
  if (!segment) return null;

  const mostRecentPassDate =
    (isReverse ? segment.lastReversePassDateTime : segment.lastForwardPassDateTime) || moment().subtract(1, 'year'); // if no last pass date, we set it to a year ago

  const segmentConfiguration = filter(
    isSnowPlowRoute
      ? store.getState().vendors.snowPlowSettings.snowPlowSettings.displayConfiguration.segmentConfiguration
      : store.getState().vendors.streetSweepingSettings.streetSweepingSettings.segmentColorSettings,
    (configuration: SegmentConfiguration) => configuration.enabled,
  ) as SegmentConfiguration[];

  const { red, green, blue } = maxBy(
    segmentConfiguration,
    (configuration: SegmentConfiguration) => configuration.streetSegmentAgingInterval.minValue,
  ) as SegmentConfiguration;
  const defaultSegmentColor = `rgb(${red}, ${green}, ${blue})`;

  const lastPassInHours = getLastPassType(mostRecentPassDate, 'hours');
  const lastPassInDays = getLastPassType(mostRecentPassDate, 'days');
  const lastPassInWeeks = getLastPassType(mostRecentPassDate, 'weeks');
  const lastPassInMonths = getLastPassType(mostRecentPassDate, 'months');

  let segmentColor: string | number[] = defaultSegmentColor;

  segmentConfiguration.forEach((segment: SegmentConfiguration) => {
    const {
      red,
      green,
      blue,
      streetSegmentAgingInterval: {
        minValue,
        maxValue,
        timeMeasurementType: { id: timeMeasurementTypeId },
      },
    } = segment;

    const lastPassValue = getLastPassValue(
      timeMeasurementTypeId,
      lastPassInHours,
      lastPassInDays,
      lastPassInWeeks,
      lastPassInMonths,
    );

    if (maxValue) {
      if (lastPassValue >= minValue && lastPassValue < maxValue) {
        segmentColor = `rgb(${red}, ${green}, ${blue})`;
      }
    } else if (lastPassValue >= minValue) {
      segmentColor = `rgb(${red}, ${green}, ${blue})`;
    }
  });

  return segmentColor;
};

const vividHexColors: string[] = [
  '#33FF57', // Green
  '#FF33C7', // Pink
  '#33FFC7', // Turquoise
  '#FFB933', // Orange
  '#FF33E5', // Magenta
  '#3366FF', // Blue
  '#33FF8C', // Lime
  '#FF5733', // Red
  '#FF336B', // Coral
  '#33FFA1', // Aqua
  '#FF33A1', // Fuchsia
  '#33FF94', // Teal
  '#FF334C', // Salmon
  '#33FFD9', // Cyan
  '#33FF33', // Bright Green
  '#FF3344', // Ruby
  '#33FFB2', // Seafoam
  '#FFB233', // Apricot
  '#33FF4F', // Mint
  '#FF33B2', // Orchid
];

export const getSegmentColorsLegendBasedOnDriversPasses = (driversOptions: { driverName: string }[]) => {
  let driversColorMapWithColors: { [key: string]: string } = {};

  // Map each driver name to a color
  driversOptions.forEach((driver, index) => {
    const formattedDriverName = camelCase(driver.driverName);
    driversColorMapWithColors[formattedDriverName] = vividHexColors[index % vividHexColors.length];
  });

  return driversColorMapWithColors;
};

// method to make the string without spaces and camelCase
export const getCamelCaseString = (string: string) => {
  return camelCase(string);
};

export const transformFromCamelCase = (text: string) => {
  // Convert camelCase to space-separated words
  const spacedText = startCase(camelCase(text));
  return spacedText;
};
